import React, { useEffect, useState } from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';
import { DashboardStyle, ReportStyle, TableListStyle } from '../templates/_style';
import Table from '../atoms/Table';
import { TableStyle } from '../atoms/_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import Select from '../atoms/Select';
import { Swiper, SwiperSlide } from 'swiper/swiper-react';
import { Navigation, Pagination } from 'swiper/types/modules';
import IcoNoData from '../../assets/images/ico-not-selected.svg';
import PopupReportImage from '../templates/popup/PopupReportImage';

const SkeletonConstructionTable = ({ winSize }: { winSize?: number }) => {
  const [themeMode] = useRecoilState(themeState);

  return (
    <SkeletonContainer
      headerType={'table'}
      element={
        <>
          <ReportStyle.DescArea className={themeMode === 'light' ? 'cScroll light' : 'cScroll'} style={{ height: '100%' }}>
            <TableListStyle.Cont>
              <TableListStyle.SelectArea className={'w1920'}>
                <TableListStyle.SelectWrap>
                  <dt>
                    <div className={'skeleton-item'} style={{ width: 42, height: 16 }}></div>
                  </dt>
                  <dd className={'skeleton-item'} style={{ width: 120, height: 36 }}></dd>
                  <dd className={'skeleton-item'} style={{ width: 120, height: 36, marginTop: 10 }}></dd>
                </TableListStyle.SelectWrap>
              </TableListStyle.SelectArea>
              <TableListStyle.TableContainer>
                <TableListStyle.SelectArea className={'w1600'}>
                  <div className={'skeleton-item'} style={{ width: 132, height: 36, marginRight: 10 }}></div>
                  {/*<div className={'skeleton-item'} style={{ width: 132, height: 36 }}></div>*/}
                </TableListStyle.SelectArea>
                <Table
                  caption={'시공 목록'}
                  colgroup={['20%', '20%', '20%', '20%', 'auto']}
                  height={Number(winSize) <= 1660 ? 'calc(100% - 243px)' : 'calc(100% - 194px)'}
                  tbodyHeight={'calc(100% - 60px)'}
                  tbodyId={'materialList'}
                  thead={
                    <tr>
                      <th scope={'col'}>
                        <div className={'skeleton-item'} style={{ width: 60, height: 16, margin: '0 auto' }}></div>
                      </th>
                      <th scope={'col'}>
                        <div className={'skeleton-item'} style={{ width: 60, height: 16, margin: '0 auto' }}></div>
                      </th>
                      <th scope={'col'}>
                        <div className={'skeleton-item'} style={{ width: 60, height: 16, margin: '0 auto' }}></div>
                      </th>
                      <th scope={'col'}>
                        <div className={'skeleton-item'} style={{ width: 60, height: 16, margin: '0 auto' }}></div>
                      </th>
                      <th scope={'col'}>
                        <div className={'skeleton-item'} style={{ width: 60, height: 16, margin: '0 auto' }}></div>
                      </th>
                    </tr>
                  }
                  tbody={
                    <>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                        </td>
                      </tr>
                    </>
                  }
                />

                <TableListStyle.DailyArea>
                  <TableListStyle.DailyBox>
                    <TableListStyle.DailyTitle>
                      <div className={'skeleton-item'} style={{ width: 100, height: 20 }}></div>
                    </TableListStyle.DailyTitle>
                    <TableListStyle.DailyCont>
                      <TableListStyle.DailyList>
                        <TableListStyle.DailyItem>
                          <strong>
                            <div className={'skeleton-item'} style={{ width: 40, height: 16 }}></div>
                          </strong>
                          <span>
                            <div className={'skeleton-item'} style={{ width: 30, height: 16 }}></div>
                          </span>
                        </TableListStyle.DailyItem>
                        <TableListStyle.DailyItem>
                          <strong>
                            <div className={'skeleton-item'} style={{ width: 40, height: 16 }}></div>
                          </strong>
                          <span>
                            <div className={'skeleton-item'} style={{ width: 30, height: 16 }}></div>
                          </span>
                        </TableListStyle.DailyItem>
                        <TableListStyle.DailyItem>
                          <strong>
                            <div className={'skeleton-item'} style={{ width: 40, height: 16 }}></div>
                          </strong>
                          <span>
                            <div className={'skeleton-item'} style={{ width: 30, height: 16 }}></div>
                          </span>
                        </TableListStyle.DailyItem>
                        <TableListStyle.DailyItem>
                          <strong>
                            <div className={'skeleton-item'} style={{ width: 50, height: 16 }}></div>
                          </strong>
                          <span>
                            <div className={'skeleton-item'} style={{ width: 40, height: 16 }}></div>
                          </span>
                        </TableListStyle.DailyItem>
                      </TableListStyle.DailyList>
                    </TableListStyle.DailyCont>
                  </TableListStyle.DailyBox>
                  <TableListStyle.DailyBox>
                    <TableListStyle.DailyTitle>
                      <div className={'skeleton-item'} style={{ width: 100, height: 20 }}></div>
                    </TableListStyle.DailyTitle>
                    <TableListStyle.DailyCont>
                      <TableListStyle.DailyList>
                        <TableListStyle.DailyItem>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                        </TableListStyle.DailyItem>
                      </TableListStyle.DailyList>
                    </TableListStyle.DailyCont>
                  </TableListStyle.DailyBox>
                </TableListStyle.DailyArea>
              </TableListStyle.TableContainer>

              <ReportStyle.PhotoArea>
                <ReportStyle.PhotoHead>
                  <ReportStyle.PhotoTitle>
                    <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                  </ReportStyle.PhotoTitle>
                </ReportStyle.PhotoHead>
                <ReportStyle.PhotoWrap>
                  <ReportStyle.PhotoCont>
                    <ReportStyle.PhotoInner>
                      <ReportStyle.PhotoItem>
                        <ReportStyle.PhotoName>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 100, height: 20, marginLeft: 10 }}></div>
                        </ReportStyle.PhotoName>

                        <ReportStyle.PhotoGroup style={{ marginBottom: 21 }}>
                          <div className={'skeleton-item'} style={{ width: 112, height: 90, marginRight: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 112, height: 90 }}></div>
                        </ReportStyle.PhotoGroup>
                      </ReportStyle.PhotoItem>

                      <ReportStyle.PhotoItem>
                        <ReportStyle.PhotoName>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 100, height: 20, marginLeft: 10 }}></div>
                        </ReportStyle.PhotoName>

                        <ReportStyle.PhotoGroup style={{ marginBottom: 21 }}>
                          <div className={'skeleton-item'} style={{ width: 112, height: 90, marginRight: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 112, height: 90 }}></div>
                        </ReportStyle.PhotoGroup>
                      </ReportStyle.PhotoItem>
                    </ReportStyle.PhotoInner>
                  </ReportStyle.PhotoCont>
                  <ReportStyle.DetailInfoArea>
                    <ReportStyle.DetailInfoHead>
                      <ReportStyle.DetailInfoTitle>
                        <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                      </ReportStyle.DetailInfoTitle>
                    </ReportStyle.DetailInfoHead>
                    <ReportStyle.DetailInfoCont>
                      <ReportStyle.DetailInfoBox>
                        <ReportStyle.DetailInfoItem>
                          <div className={'skeleton-item'} style={{ width: 80, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                        </ReportStyle.DetailInfoItem>
                        <ReportStyle.DetailInfoItem>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 50, height: 20 }}></div>
                        </ReportStyle.DetailInfoItem>
                        <ReportStyle.DetailInfoItem>
                          <div className={'skeleton-item'} style={{ width: 80, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 70, height: 20 }}></div>
                        </ReportStyle.DetailInfoItem>
                        <ReportStyle.DetailInfoItem>
                          <div className={'skeleton-item'} style={{ width: 80, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                        </ReportStyle.DetailInfoItem>
                        <ReportStyle.DetailInfoItem>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                          <div className={'skeleton-item'} style={{ width: 60, height: 20 }}></div>
                        </ReportStyle.DetailInfoItem>
                      </ReportStyle.DetailInfoBox>
                    </ReportStyle.DetailInfoCont>
                    <ReportStyle.BtnGroup>
                      <div className={'skeleton-item'} style={{ width: 132, height: 40 }}></div>
                      <div className={'skeleton-item'} style={{ width: 132, height: 40 }}></div>
                    </ReportStyle.BtnGroup>
                  </ReportStyle.DetailInfoArea>
                </ReportStyle.PhotoWrap>
              </ReportStyle.PhotoArea>
            </TableListStyle.Cont>
          </ReportStyle.DescArea>
        </>
      }
    />
  );
};

export default SkeletonConstructionTable;
