import React, { Dispatch, SetStateAction } from 'react';

const useParamChange = (param: object, setParam: Dispatch<SetStateAction<any>>) => {
  const onParamChange = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLIElement> | React.ChangeEvent<HTMLTextAreaElement>, str?: string) => {
    if (str) {
      const { ariaValueText } = e.target as HTMLLIElement;
      setParam({
        ...param,
        [str]: ariaValueText,
      });
    } else {
      const { name, value } = e.target as HTMLInputElement;
      setParam({
        ...param,
        [name]: value,
      });
    }
    console.log(param);
  };
  return [onParamChange];
};

export default useParamChange;
