import React from 'react';
import { SurveyStyle } from '../../components/templates/_style';
import SurveySiteList from '../../components/templates/survey/SurveySiteList';
import { useNavigate } from 'react-router-dom';
import WorkPlaceList from '../../components/templates/admin/WorkPlaceList';

const WorkPlace = () => {
  const navigate = useNavigate();
  //현장 리스트에서 현장 선택
  const onSelectSite = (id: string) => {
    sessionStorage.setItem('survey_siteId', id);
    navigate(`/workplace/detail/${id}/info`);
  };

  return (
    <SurveyStyle.Contain>
      <SurveyStyle.Inner>
        <SurveyStyle.Wrap style={{ height: '100%' }}>
          {/* step1 */}
          <WorkPlaceList onSelectSite={onSelectSite} />
        </SurveyStyle.Wrap>
      </SurveyStyle.Inner>
    </SurveyStyle.Contain>
  );
};

export default WorkPlace;
