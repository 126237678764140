import { deleteSurveyPoints, deleteSurveyPointsByDate, getSurveyBbox, getSurveyPointsFirstQuery, getSurveyPointsQuery, getSurveyPointsByDateQuery } from '../points';

// 측량성과 등록 - 관 종류별 쿼리 분리, 측점 테이블 조회
export function useSurveyPoints({ info }) {
  const { type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum, facility } = info || {};

  return getSurveyPointsQuery({ facility: facility, type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum });
}

// 측량성과 등록 - 관 종류별 쿼리 분리, 측점 테이블 조회
export function useSurveyPointsFirst({ info }) {
  const { siteId, facility } = info || {};

  return getSurveyPointsFirstQuery({ facility: facility, siteId });
}

// 측량성과 관 종류별 선택삭제 bbox 조회
export function useSurveyBbox({ params }) {
  const { pointIds, siteId, facility } = params || {};
  return getSurveyBbox({ pointIds, siteId, facility });
}

//측점 선택삭제
export function useSurveyDeletePoints({ params }) {
  const { pointIds, siteId, facility } = params || {};
  return deleteSurveyPoints({ siteId, facility, pointIds });
}

//측점 날짜삭제
export function useSurveyDeletePointsByDate({ params }) {
  const { date, siteId, facility } = params || {};
  return deleteSurveyPointsByDate({ siteId, facility, date });
}

// 측점 날짜별 조회
export function useSurveyPointsByDate({ facility, siteId, date, workDate }) {
  return getSurveyPointsByDateQuery({ facility, siteId, date, workDate });
}
