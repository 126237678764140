import React from 'react';
import { useRecoilState } from 'recoil';
import { popupFailState } from 'store/popupAtoms';
import PopupFrame from './PopupFrame';
import { PopupFailSt } from './_style';

// 웹 단에서 파일 체크 (not server)
// 업로드 실패 알림 팝업 (alert : 미지원파일  대신 사용)
function PopupFail({ noDesc }: { noDesc?: boolean }) {
  const [popFail, setPopFail] = useRecoilState(popupFailState);

  const closePop = () => {
    setPopFail((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <>
      <PopupFrame title="파일 업로드 실패" close={closePop}>
        <PopupFailSt.Wrap>
          <PopupFailSt.Cont>
            <p className="mainText">{'업로드 할 수 없는 파일입니다.'}</p>
            <div className="thumbWrap">
              <div className="icoTextWrap">
                <div className="icoWrap">
                  <div className="ico"></div>
                </div>
                <p className="fileText">{popFail.fileName || '-'}</p>
              </div>
              <div className="closeBtn"></div>
            </div>
            {!noDesc ? (
              <div className="helpBoxWrap">
                <div className="helpText">{'※ [측량성과등록] 용으로는 다음과 같은 파일을 업로드할 수 있습니다. \n 오토캐드 맵에서 무브먼츠 플러그인으로 추출한 측점 및 관로 csv 파일'}</div>
              </div>
            ) : null}
          </PopupFailSt.Cont>
        </PopupFailSt.Wrap>
      </PopupFrame>
    </>
  );
}

export default React.memo(PopupFail);
