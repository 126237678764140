import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import * as keys from '../../keys/queryKeys';
import api from '../../service';
import { useQueryState } from 'hooks/RQuery/useQueryState';
import useMutateHook from '../../useMutateHook';
import queryKeys, { calendarKeys, pointKeys } from '../../keys/queryKeys';

/* 전기 - elt */

// 2d 지도 - 옵션
export function getPointsOptions({ siteId }) {
  let options = {
    queryKey: [...keys.pointKeys.elt.all(siteId)],
    queryFn: () => api.points.elt.getPoints({ siteId }),
    enabled: !!siteId,
  };

  return options;
}

// 2d 지도 - 쿼리
export function usePoints({ siteId }) {
  return useQuery(getPointsOptions({ siteId }));
}

// survey, 측점 타입별로 나눠서 요청
export function useSurveyPoints({ type, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) {
  // *type: activeTab (points, joints, holes, valves)
  let params = { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum };

  let querykey: any[] = [];
  let queryFn: any = () => {};
  let options: QueryObserverOptions = {
    enabled: !!siteId,
  };

  if (type === 'points') {
    querykey = [...keys.pointKeys.elt.survey.all({ siteId, pageSize, pageNum, sort })];
    queryFn = () => api.points.elt.getSurveyPoints({ ...params });
  }
  if (type === 'joints') {
    querykey = [...keys.pointKeys.elt.survey.joints({ ...params })];
    queryFn = () => api.points.elt.getSurveyJoints({ ...params });
  }
  if (type === 'holes') {
    querykey = [...keys.pointKeys.elt.survey.holes({ ...params })];
    queryFn = () => api.points.elt.getSurveyHoles({ ...params });
  }
  if (type === 'valves') {
    querykey = [...keys.pointKeys.elt.survey.valves({ ...params })];
    queryFn = () => api.points.elt.getSurveyValves({ ...params });
  }

  return useQueryState(querykey, queryFn, options);
}

// survey, 측점 데이터 유무 체크
export function useSurveyPointsFirst({ siteId, facility, pageSize, pageNum, sort, startDate, endDate, ptNum }) {
  // *type: activeTab (points, joints, holes, valves, fires)
  let params = { siteId, facility, pageSize, pageNum, sort, startDate, endDate, ptNum };

  let querykey: any[] = [];
  let queryFn: any = () => {};
  let options: QueryObserverOptions = {
    enabled: !!siteId,
  };

  querykey = [queryKeys.MainKey.SURVEY, siteId, facility];
  queryFn = () => api.points.elt.getSurveyPoints({ ...params });

  return useQueryState(querykey, queryFn, options);
}
// 전기 - 포인트 상세정보 조회 - 타입별로 조회
export function usePointDetail({ type, pointId }) {
  let querykey: any[] = [];
  let queryFn: any = () => {};
  let options: QueryObserverOptions = {
    enabled: !!pointId,
  };

  if (type === 'joints') {
    querykey = [...keys.pointKeys.elt.jointDetail(pointId)];
    queryFn = () => api.points.elt.getJointDetail(pointId);
  }
  if (type === 'holes') {
    querykey = [...keys.pointKeys.elt.holeDetail(pointId)];
    queryFn = () => api.points.elt.getHoleDetail(pointId);
  }
  if (type === 'valves') {
    querykey = [...keys.pointKeys.elt.valveDetail(pointId)];
    queryFn = () => api.points.elt.getValveDetail(pointId);
  }
  return useQueryState(querykey, queryFn, options);
}

//Bbox 내 파이프 리스트
export function useSurveyBbox({ pointIds, siteId }) {
  return useQuery({
    queryKey: [...keys.pointKeys.elt.survey.bbox, { pointIds, siteId }],
    queryFn: () => api.points.elt.getSurveyBbox({ pointIds, siteId }),
    enabled: !!pointIds,
  });
}

//측량성과 선택 삭제
export function useSurveyDeletePoints({ siteId, pointIds }) {
  return useMutateHook({
    fetchApi: () => api.points.elt.deleteSurveyPoints({ siteId, pointIds }),
    invalidQuery: [[queryKeys.MainKey.SURVEY, siteId], [...calendarKeys.surveyPoints({ siteId })], [...calendarKeys.surveyPipes({ siteId })], [...pointKeys.elt.common, 'list', { siteId }], [queryKeys.MainKey.SURVEY]],
  });
}

//측량성과 날짜 삭제
export function useSurveyDeletePointsByDate({ siteId, date }) {
  return useMutateHook({
    fetchApi: () => api.points.elt.deleteSurveyPointsByDate({ siteId, date }),
    invalidQuery: [[queryKeys.MainKey.SURVEY, siteId], [...calendarKeys.surveyPoints({ siteId })], [...calendarKeys.surveyPipes({ siteId })], [...pointKeys.elt.common, 'list', { siteId }], [queryKeys.MainKey.SURVEY]],
  });
}

//측량성과 날짜별 포인트 데이터 조회
export function useSurveyPointsByDate({ siteId, date, workDate }) {
  return useQuery({
    queryKey: [...keys.pointKeys.elt.survey.date({ siteId, date, workDate })],
    queryFn: () => api.points.elt.getSurveyPointsByDate({ siteId, date }),
    enabled: !!date,
  });
}
