import styled from 'styled-components';
import LogoPdf from 'assets/images/logo_pdf.png';

export const ReportPDFSt = {
  Wrap: styled.div`
    position: fixed;
    top: 100px;
    left: 400px;
    z-index: 9;
    width: 100%;
    height: calc(100vh - 200px);
    padding: 30px;
    overflow: auto;
    background: #eee;
  `,
  Page: styled.div`
    /* border: 1px solid red; */
    width: 595px;
    height: 842px;
    margin: 30px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 200;
    > .outLine {
      width: 100%;
      height: 100%;
      .title {
        /* border: 1px solid black; */
        padding: 20px;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 5px;
      }
      .content_site {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        > ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
          > li {
            height: 20px;
            line-height: 20px;
            .subTit {
              font-weight: 500;
            }
            .subCont {
            }
          }
          &:last-child {
            text-align: right;
          }
        }
      }
      .content_item {
        padding: 20px;
        > .titleWrap {
          > .subTit {
            font-weight: 500;
          }
          > .subCont {
          }
        }
        > .contWrap {
          margin-top: 10px;
          border-top: 2px solid #b0b4c3;
          border-bottom: 2px solid #b0b4c3;
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          gap: 80px;
          > ul {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
            > li {
              display: flex;
              justify-content: space-between;
              > span:last-child {
                font-weight: 500;
              }
            }
          }
        }
      }
      .content_img {
        padding: 0 20px;
        /* padding: 0px 20px; */
        > .titleWrap {
          > .subTit {
            font-weight: 500;
          }
          > .subCont {
          }
        }
        > .contWrap {
          margin-top: 10px;
          border-top: 2px solid #b0b4c3;
          border-bottom: 2px solid #b0b4c3;
          padding: 10px 0;

          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          > .imgItem {
            width: 48%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            > .imgWrap {
              width: 100%;
              height: 100%;
              border: 1px solid #b0b4c3;
              background-color: #b0b4c3;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              /* 높이 고정 & 넓이 자동 & 넘치는 부분 가리기 */
              > img {
                height: 250px;
                width: auto;
                display: block;
                margin: 0 auto;
              }
            }
            > .imgTit {
              text-align: center;
              margin-top: auto;
              font-weight: 500;
              height: 20px;
            }
          }
        }
      }
      .logoWrap {
        width: 100%;
        height: 40px;
        padding: 0 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > .logo {
          display: inline-block;
          width: 80px;
          height: auto;
        }
      }
    }
  `,
  TableWrap: styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Table: styled.div`
    width: 100%;
    border: 1px solid #000;
    display: flex;
    justify-content: flex-start;
    .item {
      flex: 1 1;
      border-right: 1px solid #000;
      &:last-child {
        border-right: 0;
      }
      .tHead {
        .tr {
          display: flex;
          /* justify-content: center; */
          .th {
            flex: 1 1;
            text-align: center;
            border-bottom: 1px solid #000;
            border-right: 1px solid #000;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
      .tBody {
        .tr {
          border-bottom: 1px solid #000;
          display: flex;
          &:last-child {
            border-bottom: 0;
          }
          .td {
            flex: 1 1;
            text-align: center;
            border-right: 1px solid #000;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  `,
};
