import React from 'react';
import { SurveyStyle } from '../../_style';
import { useTranslation } from 'react-i18next';

const PopupFilter = ({ onColumnToggle, filter, setFilter, isFilterOpen, setIsFilterOpen }) => {
  const { t } = useTranslation();
  return (
    <div style={{ position: 'relative' }}>
      <SurveyStyle.FilterItem className={isFilterOpen ? 'active more' : 'more'} onClick={() => setIsFilterOpen(!isFilterOpen)}>
        <SurveyStyle.FilterEllipsis>
          <span></span>
          <span></span>
          <span></span>
        </SurveyStyle.FilterEllipsis>
      </SurveyStyle.FilterItem>
      {isFilterOpen ? (
        <SurveyStyle.FilterBox>
          <SurveyStyle.FilterBoxInner>
            <SurveyStyle.FilterBoxList>
              {filter.map((i, index) => {
                return (
                  <SurveyStyle.FilterItem key={index} className={i.active ? 'active filterItem' : 'filterItem'} onClick={() => onColumnToggle(filter, setFilter, i.key)} style={{ margin: '6px 2px' }}>
                    {t(i.name)}
                  </SurveyStyle.FilterItem>
                );
              })}
            </SurveyStyle.FilterBoxList>
          </SurveyStyle.FilterBoxInner>
          <SurveyStyle.FilterClose type={'button'} onClick={() => setIsFilterOpen(false)}>
            {t('닫기')}
          </SurveyStyle.FilterClose>
        </SurveyStyle.FilterBox>
      ) : null}
    </div>
  );
};

export default PopupFilter;
