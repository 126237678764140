import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ChangeMapBtnSt = {
  Wrap: styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 9;
    border: 1px solid #ccc;
    border-radius: 1px;
    overflow: hidden;
    button {
      width: 70px;
      height: 40px;
      background-color: #fff;
      color: #777777;
      font-weight: 500;
      font-size: 14px;
      &:first-child {
        border-right: 1px solid #ccc;
      }
      &.active {
        color: #111;
      }
      &:hover {
        transition: 0.4s;
        background-color: #eee;
      }
    }
  `,
};

function ChangeMapBtn({ style, onClick }: { style?: any; onClick?: any }) {
  const [type, setType] = useState('map'); //map, satellite

  useEffect(() => {
    if (onClick) {
      onClick(type);
    }
  }, [onClick, type]);

  const onClickButton = (type: 'map' | 'satellite') => {
    setType(type);
    //  onClick(type); // 지도 전환 이벤트
  };

  return (
    <ChangeMapBtnSt.Wrap style={style}>
      <button className={type === 'map' ? 'active' : ''} onClick={() => onClickButton('map')}>
        Map
      </button>
      <button className={type === 'satellite' ? 'active' : ''} onClick={() => onClickButton('satellite')}>
        Satellite
      </button>
    </ChangeMapBtnSt.Wrap>
  );
}

export default ChangeMapBtn;
