import React, { useEffect, useState } from 'react';
import { DashboardStyle, LanguageStyle, PopupStyle } from '../components/templates/_style';
import { useRecoilState } from 'recoil';
import { constructionInfoState } from '../store/Atoms';
import { escKeyModalClose } from '../util/global';
import { useTranslation } from 'react-i18next';

const PopupSiteInfo = ({ onClose }: { onClose: (key: 'siteInfo') => void }) => {
  const [info] = useRecoilState(constructionInfoState);
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('keydown', (e) => escKeyModalClose(e, () => onClose('siteInfo')));
    return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, () => onClose('siteInfo')));
  }, []);
  return (
    <>
      <PopupStyle.Dimmed></PopupStyle.Dimmed>
      <PopupStyle.Popup $width={400}>
        <PopupStyle.Head $height={48} $align={'left'}>
          <PopupStyle.Title>{t('현장정보')}</PopupStyle.Title>
          <PopupStyle.CloseBtn $right={12} type={'button'} onClick={() => onClose('siteInfo')}></PopupStyle.CloseBtn>
        </PopupStyle.Head>
        <PopupStyle.Cont>
          <PopupStyle.Inner $padding={'20px 24px'}>
            <DashboardStyle.InfoItem>
              <strong>{t('사업명')}</strong>
              <p>{info.projectName}</p>
            </DashboardStyle.InfoItem>
            <DashboardStyle.InfoItem>
              <strong>{t('발주처')}</strong>
              {info.owner.map((i, index) => {
                return (
                  <DashboardStyle.InfoBoxGroup key={index}>
                    <p>{i.companyName}</p>
                  </DashboardStyle.InfoBoxGroup>
                );
              })}
            </DashboardStyle.InfoItem>
            <DashboardStyle.InfoItem>
              <strong>{t('시공사')}</strong>
              {info.contractor.map((i, index) => {
                return (
                  <DashboardStyle.InfoBoxGroup key={index}>
                    <p>{i.companyName}</p>
                  </DashboardStyle.InfoBoxGroup>
                );
              })}
            </DashboardStyle.InfoItem>
            <DashboardStyle.InfoItem>
              <strong>{t('주소')}</strong>
              <p>{info.address}</p>
            </DashboardStyle.InfoItem>
          </PopupStyle.Inner>
        </PopupStyle.Cont>
      </PopupStyle.Popup>
    </>
  );
};

export default PopupSiteInfo;
