import VectorModule from './VectorModule';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
class LineStringModule extends VectorModule {
  // 단일 피쳐 생성 (속성 정보 추가)
  public createLineStringFeature(data: any) {
    const feature = new Feature({
      geometry: new LineString(data),
    });

    return feature;
  }

  // 피쳐 배열 리턴
  public createLineStringFeatures(datas: any[]) {
    return datas?.map((data) => {
      return this.createLineStringFeature(data);
    });
  }
}

export default LineStringModule;
