import { request } from '../../../service/api/common';
import * as apiUrl from './apiUrl';

//작업일보 포인트 데이터
export const getWorkLogPoints = async ({ siteId, date }) => {
  const { data } = await request('d2').get(apiUrl.workLogs.points, {
    params: {
      siteId: siteId,
      date: date,
    },
  });
  return data.data;
};

//작업일보 파이프 데이터
export const getWorkLogPipes = async ({ siteId, date }) => {
  const { data } = await request('d2').get(apiUrl.workLogs.pipes, {
    params: {
      siteId: siteId,
      date: date,
    },
  });
  return data.data;
};

//작업일보 내보내기
export const getWorkLogExport = async ({ siteId, dateFrom, dateTo }) => {
  const { data } = await request('d2').get(apiUrl.workLogs.export, {
    params: {
      siteId: siteId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });
  return data.data;
};
