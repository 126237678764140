import { useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
/* 
   queryKey,
   queryFn,
   그 외 options 설정
*/

export function usePoints({ siteId, date }) {
  return useQuery({
    queryKey: [...keys.workLogKeys.point, date],
    queryFn: () => api.workLogs.getWorkLogPoints({ siteId, date }),
    enabled: !!siteId && siteId !== 'null',
  });
}

export function usePipes({ siteId, date }) {
  return useQuery({
    queryKey: [...keys.workLogKeys.pipe, date],
    queryFn: () => api.workLogs.getWorkLogPipes({ siteId, date }),
    enabled: !!siteId && siteId !== 'null',
  });
}

export function useExport({ siteId, dateFrom, dateTo }) {
  return useQuery({
    queryKey: [...keys.workLogKeys.export],
    queryFn: () => api.workLogs.getWorkLogExport({ siteId, dateFrom, dateTo }),
    enabled: false,
  });
}
