import OlCore from '../OlCore';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';
import { Collection, Feature } from 'ol';
import Select from 'ol/interaction/Select.js';
import { click } from 'ol/events/condition';
import Layer from 'ol/layer/Layer';
import { Circle, Icon } from 'ol/style';
import { LineString, Point } from 'ol/geom';
import IcoMarker from 'assets/images/ico-map-marker.svg';
import ReportPipeLineModule from '../Module/ReportPipeLineModule';
import getFacilityColor from '../style/getFacilityColor';
class ReportPipeLineLayer extends ReportPipeLineModule {
  private pipeLayers: VectorLayer<VectorSource>[] = [];
  private pointLayer: VectorLayer<VectorSource>;
  private selectClick?: Select;

  //TODO: 2d 지도 데이터에 있는 관종 리스트 (토글 기능에 이용)
  public facilityList: any;

  public pipeTypeList = [
    //  { value: '상수', code: 'WATER', show: true },
    //  { value: '오수', code: 'WASTE', show: true },
    //  { value: '우수', code: 'RAIN', show: true },
    //  { value: '가스', code: 'GAS', show: true },
    //  { value: '통신', code: 'NETWORK', show: true },
    //  { value: '전기', code: 'ELECTRIC', show: true },
    //  { value: '송유', code: 'OIL', show: true },
    //  { value: '난방', code: 'HEAT', show: true },
  ];

  // 버퍼 여부
  public buffer: boolean = true;

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    const facilitys = JSON.parse(sessionStorage.getItem('facilitys') || '');
    this.pipeTypeList = facilitys?.map((ele) => ({ ...ele, show: true }));

    this.pipeTypeList.map((ele: any) => {
      const layer = new VectorLayer<VectorSource>({
        properties: {
          id: ele.code,
          value: ele.value,
        },
        visible: ele.show,
        zIndex: 3,
        // FIXME: 미리 스타일 부여
        style: this.defaultStyle,
      });
      this.pipeLayers.push(layer);
    });
    this.pointLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'pointLayer',
      },
      visible: true,
      zIndex: 4,
      style: this.pointStyle,
    });

    this.setLayers([...this.pipeLayers, this.pointLayer]);
  }

  // 스타일(기본)
  private defaultStyle() {
    return [
      new Style({
        stroke: new Stroke({
          width: 10,
          color: '#478dff',
          lineCap: 'round',
        }),
      }),
    ];
  }

  private pointStyle(feature) {
    return [
      new Style({
        image: feature.getProperties().properties.marker
          ? new Icon({
              src: IcoMarker,
              anchor: [0.5, 0.8],
            })
          : feature.getProperties().properties.line
            ? undefined
            : new Circle({
                radius: 5,
                fill: new Fill({
                  color: '#F31E1E',
                }),
                stroke: new Stroke({
                  width: 2,
                  color: '#fff',
                }),
              }),
      }),

      new Style({
        stroke: feature.getProperties().properties.line
          ? new Stroke({
              width: 14,
              color: 'rgba(255,255,255,.4)',
            })
          : undefined,
      }),
    ];
  }

  // 기본 라인 스타일 (Width:10)
  private onStyle(feature: any) {
    const pipeType = feature.getProperties().properties.layerId;
    const diameter = Number(feature.getProperties().properties.diameter) * 100; // 300
    const resol = this.core.mapInstance.getView().getResolution();

    return [
      new Style({
        stroke: new Stroke({
          width: 10,
          color: getFacilityColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
    ];
  }

  // 스타일(선택 활성화) (Width:10)
  private selectStyle(feature: any) {
    const pipeType = feature.getProperties().properties.layerId;

    return [
      new Style({
        stroke: new Stroke({
          width: 10,
          color: getFacilityColor(pipeType, 0.4),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
      new Style({
        fill: new Fill({
          color: getFacilityColor(pipeType, 0.4),
        }),
        stroke: new Stroke({
          width: 14,
          color: `rgba(255, 255, 255, .8)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
    ];
  }

  // 데이터 그리기
  public draw(datas: any, layerId: string) {
    const features = this.createReportPipeLineFeatures(datas, layerId);

    const source = this.createSource(features);

    this.pipeLayers.map((layer: any) => {
      if (layer.get('id') === layerId) {
        layer.setSource(source);

        // 레이어에 스타일 부여
        layer.setStyle((feature: Feature) => {
          return this.onStyle(feature);
        });
      }
    });

    // 해당 레이어로 이동
    this.moveLayer(layerId);
  }

  //작업일보 지도 파이프 클릭시 시점, 종점, 파이프마커 추가
  public addPoints(feature: any) {
    console.log(feature);
    if (feature) {
      if (Object.keys(feature).includes('pipeId')) {
        //파이프
        const stPoint = new Feature({
          geometry: new Point([feature.coordinates[0].y, feature.coordinates[0].x]),
          properties: feature,
        });
        const endPoint = new Feature({
          geometry: new Point([feature.coordinates[1].y, feature.coordinates[1].x]),
          properties: feature,
        });
        const pipeMarker = new Feature({
          geometry: new Point([(feature.coordinates[0].y + feature.coordinates[1].y) / 2, (feature.coordinates[0].x + feature.coordinates[1].x) / 2]),
          properties: { ...feature, marker: true },
        });
        const coverLine = new Feature({
          geometry: new LineString([
            [feature.coordinates[0].y, feature.coordinates[0].x],
            [feature.coordinates[1].y, feature.coordinates[1].x],
          ]),
          properties: { ...feature, line: true },
        });
        const source = this.createSource([stPoint, pipeMarker, endPoint, coverLine]);
        this.pointLayer.setSource(source);
        this.moveLayer('pointLayer', { padding: [80, 80, 80, 80], duration: 500 });
      } else {
        const pipeMarker = new Feature({
          geometry: new Point([feature.coordinate.y, feature.coordinate.x]),
          properties: { ...feature, marker: true },
        });
        const source = this.createSource([pipeMarker]);
        this.pointLayer.setSource(source);
        this.moveLayer('pointLayer', { maxZoom: 22, duration: 500 });
      }
    }
  }

  // 레이어 클릭 이벤트
  public addClick(callback?: any) {
    this.selectClick = new Select({
      condition: click,
      style: (feature) => this.selectStyle(feature),
      // style: () => this.flowLineStyle(),
      // style: () => this.strokePatternSt(),
      layers: this.pipeLayers,
    });
    if (callback) {
      this.selectClick.on('select', (e) => {
        const targetCollection = e.target.getFeatures().getArray();
        if (targetCollection.length) {
          callback(targetCollection[0].getProperties().properties);
        } else {
          callback({});
        }
      });
    }

    this.core.mapInstance.addInteraction(this.selectClick);
  }

  public onToggleLegend(str: string) {}

  public onToggleBuffer() {}

  //--------------------------------------------

  // CHECK: facility 선택 후 서버 데이터 받아서 draw
  public newDraw(datas: any[], facilityType?: string) {
    console.log(datas);

    //   const features = this.createPipeLine(datas);
    //  const source = this.createSource(features);

    const layer = this.getLayerById('waterLayer');
    console.log(layer);
  }

  public setFacilityList(data?: []) {
    console.log(data);
    this.facilityList = data?.map((ele) => ({ code: '', color: '', value: '' }));
    // this.pipeLayer
  }
}

export default ReportPipeLineLayer;
