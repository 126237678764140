import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PopupFrame from 'components/templates/survey/popup/PopupFrame';
import { useTranslation } from 'react-i18next';
import SurveyDelMap from 'components/templates/survey/SurveyDelMap';
import ConfirmAlert from 'components/templates/ConfirmAlert';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tableListPoint } from 'store/surveyAtoms';
import useMutateHook from '../hooks/RQuery/useMutateHook';
import { PagingTableStyle } from '../components/atoms/_style';
import { themeState } from '../store/Atoms';
import { CheckboxProps } from '../components/atoms/_interface';
import Checkbox from '../components/atoms/Checkbox';
import queryKeys from 'hooks/RQuery/keys/queryKeys';
import query from '../hooks/RQuery/utils';

export const PopupSelectDelSt = {
  HelpArea: styled.div`
    padding: 14px 18px;
    background: #0076ff14;
    border: 1px solid #0076ff;
    border-radius: 8px;
    .text {
      color: #0076ff;
      font-weight: 500;
      font-size: 14px;
    }
  `,
  ContWrap: styled.div`
    margin-top: 12px;
    display: flex;
    gap: 12px;
    color: ${(p) => p.theme.delModal.color};
  `,
  MapArea: styled.div`
    width: 360px;
    height: auto;
    min-height: 360px;
    border: 1px solid ${(p) => p.theme.delModal.mapBd};
    box-sizing: border-box;
    border-radius: 4px;
  `,
  ListArea: styled.div`
    min-width: 260px;
    > .titleWrap {
      height: 48px;
      box-sizing: border-box;
      background: ${(p) => p.theme.delModal.list.header.bg};
      border: 1px solid ${(p) => p.theme.delModal.list.border};
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      padding: 8px 0;
      > .column {
        flex: 1;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        &:first-child {
          flex: 0.75;
          border-right: 1px solid ${(p) => p.theme.delModal.list.divider};
        }
      }
    }
    > .contWrap {
      > ul {
        max-height: 310px;
        border: 1px solid ${(p) => p.theme.delModal.list.border};
        border-top: 0;
        border-radius: 0 0 4px 4px;
        > li {
          height: 48px;
          background: ${(p) => p.theme.delModal.list.cont.bg};
          border-bottom: 1px solid ${(p) => p.theme.delModal.list.border};
          /* border: 1px solid ${(p) => p.theme.delModal.list.border}; */
          border-top: 0;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          &.points {
            cursor: pointer;
          }
          &.active {
            background: red;
          }
          &:last-child {
            border-radius: 0 0 4px 4px;
            border-bottom: 0;
          }
          > div {
            line-height: 48px;
            flex: 1;
            text-align: center;
            font-weight: 600;
            font-size: 14px;
            &:first-child {
              flex: 0.75;
              border-right: 1px solid ${(p) => p.theme.delModal.list.divider};
            }
          }
        }
      }
    }
  `,
};

// 선택된 항목 삭제 팝업
function PopupSurveySelectDel({ onClose, siteInfo, activeCategory }) {
  const [themeMode, setThemeMode] = useRecoilState(themeState);
  const siteId = siteInfo?.id || '';
  const { t, i18n } = useTranslation();
  const tableListPointState = useRecoilValue(tableListPoint); // 포인트 테이블 체크리스트
  const [checkPoints, setCheckPoints] = useState([]);
  const [checkNeighborPipe, setCheckNeighborPipe] = useState([]);
  //체크박스 배열
  const [checkList, setCheckList] = useState<CheckboxProps[]>([]);

  const [checkAll, setCheckAll] = useState(false);

  const [alertPop, setAlertPop] = useState({ isOpen: false, mainText: '', subText: '' });
  // 선택된 Point 기준 bbox 안에 있는 파이프 정보
  const { data: bboxData } = query.survey.points.useSurveyBbox({ params: { siteId: siteId, pointIds: [...tableListPointState?.checked], facility: activeCategory } });

  // 선택한 측점 삭제
  const { mutate: deletePoints } = query.survey.points.useSurveyDeletePoints({ params: { siteId: siteId, facility: activeCategory, pointIds: [...tableListPointState?.checked] } });
  // 현재 팝업 - 닫기
  const closePopup = () => {
    onClose('selectDel');
  };

  // 현재 팝업 - 삭제 버튼 클릭 이벤트
  const onClickDelete = () => {
    //확인 팝업 오픈
    const dataName = '측점 데이터';
    const mainText = i18n.language === 'ko' ? `${dataName}${t('데이터삭제여부')}` : `${t('데이터삭제여부')}${dataName}`;
    setAlertPop((prev) => ({ ...prev, isOpen: true, mainText: mainText, subText: t('데이터복구불가') }));
  };

  // 삭제 확인 팝업 - 닫기
  const closeAlertPop = () => {
    setAlertPop((prev) => ({ ...prev, isOpen: false }));
  };

  //체크한 항목, 연계된 파이프 삭제
  const onRemoveChecked = async () => {
    const pointList: any = [];
    checkPoints
      .filter((i: any) => i.active)
      .map((i: any) => {
        pointList.push(i.pointId);
      });
    await deletePoints(
      { siteId, pointIds: pointList },
      {
        onSettled(data, error, variables, context) {
          console.log(data, error);
          closeAlertPop(); //삭제 확인 팝업 - 닫기
          closePopup();
        },
      },
    );
  };

  //선택 체크
  const onClickPoint = (id) => {
    const newArr: any = [...checkPoints];
    const newArr2: any = [];

    newArr.filter(({ pointId }) => pointId === id)[0].active = !newArr.filter(({ pointId }) => pointId === id)[0].active;
    setCheckPoints(newArr);

    const neighborList: string[] = [];
    newArr
      .filter((j) => j.active)
      .map((k) => {
        k.neighborPipeIds.map((l) => {
          neighborList.push(l);
        });
      });
    [...bboxData.pipes].map((i) => {
      newArr2.push({ ...i, active: !![...new Set(neighborList)].filter((j) => j === i.pipeId).length });
    });

    setCheckAll(newArr.length === newArr.filter(({ active }) => active).length);
    setCheckNeighborPipe(newArr2);
  };

  //전체체크
  const onCheckAll = () => {
    const newArr = [...checkPoints];
    const newArr2 = [...checkNeighborPipe];

    if (newArr.length === newArr.filter(({ active }) => active).length) {
      newArr.map((i: any) => {
        i.active = false;
      });
      newArr2.map((i: any) => {
        i.active = false;
      });
      setCheckAll(false);
    } else {
      newArr.map((i: any) => {
        i.active = true;
      });
      newArr2.map((i: any) => {
        i.active = true;
      });
      setCheckAll(true);
    }
    setCheckPoints(newArr);
    setCheckNeighborPipe(newArr2);
  };

  //초기 데이터 가공
  useEffect(() => {
    if (bboxData) {
      const newArr: any = [];
      const newArr2: any = [];
      const newArr3: { id: string; checked: boolean }[] = [];
      //팝업 내 테이블 리스트(active 포함)
      [...bboxData.points].map((i) => {
        newArr.push({ ...i, active: !!tableListPointState?.checkedPointData.points.filter((j) => j.pointId === i.pointId).length });
      });

      const neighborList: string[] = [];
      newArr
        .filter((j) => j.active)
        .map((k) => {
          k.neighborPipeIds.map((l) => {
            neighborList.push(l);
          });
        });
      [...bboxData.pipes].map((i) => {
        newArr2.push({ ...i, active: !![...new Set(neighborList)].filter((j) => j === i.pipeId).length });
      });

      setCheckAll(newArr.length === newArr.filter(({ active }) => active).length);
      setCheckPoints(newArr);
      setCheckNeighborPipe(newArr2);
    }
  }, [bboxData]);
  return (
    <>
      <PopupFrame title={t('삭제')} close={closePopup} leftText="취소" rightText="삭제" leftFunc={closePopup} rightFunc={onClickDelete}>
        <PopupSelectDelSt.HelpArea style={{ width: 742 }}>
          <p className="text">
            ※ 선택된 측점 또는 관로에 연결된 항목이 전부 삭제되며, 삭제된 항목은 복구되지 않습니다.
            <br />
            다음 항목을 삭제하시겠습니까?
          </p>
        </PopupSelectDelSt.HelpArea>
        <PopupSelectDelSt.ContWrap>
          <PopupSelectDelSt.MapArea>
            {/* 지도영역 */}
            <SurveyDelMap
              bboxData={{
                pipes: checkNeighborPipe,
                points: checkPoints,
              }}
              setCheckPoints={setCheckPoints}
              setCheckNeighborPipe={setCheckNeighborPipe}
            />
          </PopupSelectDelSt.MapArea>
          <PopupSelectDelSt.ListArea style={{ flex: 1 }}>
            <div className="contWrap">
              <div style={{ flex: 1, width: '100%', height: 206 }} className={themeMode === 'light' ? 'cScroll cScrollXY light' : 'cScroll cScrollXY'}>
                <PagingTableStyle.Wrap>
                  <caption>{'측점리스트'}</caption>
                  <colgroup>
                    <col style={{ width: 60 }} />
                    <col style={{ width: 116 }} />
                    <col style={{ width: 'auto' }} />
                  </colgroup>
                  <PagingTableStyle.Thead>
                    <tr>
                      <th scope={'col'}>
                        <Checkbox dark={true} size={18} id={'allCheck'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'}>구분</th>
                      <th scope={'col'}>측점명</th>
                    </tr>
                  </PagingTableStyle.Thead>
                  <PagingTableStyle.Tbody>
                    <>
                      {checkPoints.map((ele: any) => {
                        return (
                          <tr className={ele.active ? 'active' : undefined} onClick={() => onClickPoint(ele.pointId)} key={ele.pointId}>
                            <td style={{ height: 48 }}>
                              <Checkbox checked={ele.active} size={18} dark={true} onChange={() => onClickPoint(ele.pointId)} />
                            </td>
                            <td style={{ height: 48 }}>측점</td>
                            <td style={{ height: 48 }}>{ele?.ptNum}</td>
                          </tr>
                        );
                      })}
                    </>
                  </PagingTableStyle.Tbody>
                </PagingTableStyle.Wrap>
              </div>

              {/* 삭제되는 측점 및 관로 리스트*/}
              {checkPoints.filter((i: any) => i.active).length + [...new Set(checkNeighborPipe)].filter((i: any) => i.active).length ? (
                <div style={{ flex: 1, width: '100%', height: 206 }} className={themeMode === 'light' ? 'cScroll cScrollXY light' : 'cScroll cScrollXY'}>
                  <PagingTableStyle.Wrap>
                    <caption>{'삭제되는 측점 및 관로'}</caption>
                    <colgroup>
                      <col style={{ width: 176 }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <PagingTableStyle.Thead>
                      <tr>
                        <th scope={'col'} className={'type2'}>
                          삭제되는 측점 및 관로
                        </th>
                        <th scope={'col'} className={'type2'}>
                          측점명
                        </th>
                      </tr>
                    </PagingTableStyle.Thead>
                    <PagingTableStyle.Tbody>
                      <>
                        {checkPoints
                          .filter((i: any) => i.active)
                          .map((ele: any) => {
                            return (
                              <tr className={'type2'} key={ele.pointId}>
                                <td style={{ height: 48 }}>측점</td>
                                <td style={{ height: 48 }}>{ele?.ptNum}</td>
                              </tr>
                            );
                          })}
                      </>
                      <>
                        {[...new Set(checkNeighborPipe)]
                          .filter((i: any) => i.active)
                          .map((i: any) => {
                            return (
                              <tr className={'type2'} key={i.pipeId}>
                                <td style={{ height: 48 }}>관로</td>
                                <td style={{ height: 48 }}>{i.ptNum}</td>
                              </tr>
                            );
                          })}
                      </>
                    </PagingTableStyle.Tbody>
                  </PagingTableStyle.Wrap>
                </div>
              ) : null}
            </div>
          </PopupSelectDelSt.ListArea>
        </PopupSelectDelSt.ContWrap>
      </PopupFrame>

      {/* 삭제 확인 알림창  */}
      {alertPop.isOpen && <ConfirmAlert title={t('삭제')} close={closeAlertPop} mainText={alertPop.mainText} subText={alertPop.subText} leftText={t('취소')} rightText={t('삭제')} leftFunc={closeAlertPop} rightFunc={onRemoveChecked} />}
    </>
  );
}

export default PopupSurveySelectDel;
