import React, { CSSProperties, useEffect, useState } from 'react';
import { BarChartStyle } from './_style';

const BarChart = ({ label, percent, color, value, style }: { label: string; percent: number; color?: string; value?: string; style?: CSSProperties }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(!active);
  }, []);
  return (
    <BarChartStyle.Wrap style={style}>
      <BarChartStyle.BarRow>
        <BarChartStyle.Label $color={color}>{label}</BarChartStyle.Label>
        <BarChartStyle.Percent>{value ? value : percent + '%'}</BarChartStyle.Percent>
      </BarChartStyle.BarRow>
      <BarChartStyle.BarWrap>
        <BarChartStyle.Bar className={active ? 'active' : undefined} $percent={percent} $color={color}></BarChartStyle.Bar>
      </BarChartStyle.BarWrap>
    </BarChartStyle.Wrap>
  );
};

export default BarChart;
