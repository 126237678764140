import React from 'react';
import PopupFrame from './PopupFrame';
import { SuccessContSt, FailedContSt } from './_style';
import { UploadResultProps, popupResultState } from 'store/popupAtoms';
import { useRecoilState } from 'recoil';
import { FILE_TYPES } from 'util/global';

// 성공 결과 내용
function SuccessCont({ type, response }: UploadResultProps) {
  const curIdx = FILE_TYPES.findIndex((ele) => ele.type === type);
  const curText = FILE_TYPES.find((ele) => ele.type === type)?.text;
  const nextText = FILE_TYPES[curIdx + 1]?.text;

  console.log('Result: Success!', response);
  const successCount = (response?.successCount * 1)?.toLocaleString('ko-KR');
  console.log('successCount:', successCount);
  return (
    <SuccessContSt.Wrap>
      <SuccessContSt.Header>
        <p>{`총 ${curText} 개수`}</p>
        <span className="number">{successCount || '-'}</span>
      </SuccessContSt.Header>
      <SuccessContSt.Cont style={type === 'image' ? { padding: '43px 0' } : {}}>
        <div className="ico"></div>
        <div className="textWrap">
          <p className="main">{`${curText} 파일 업로드가 완료되었습니다.`}</p>
          {type !== 'image' && <p className="sub">{`계속해서 ${nextText} 파일을 업로드해 주세요.`}</p>}
        </div>
      </SuccessContSt.Cont>
    </SuccessContSt.Wrap>
  );
}

// 실패 결과 내용
function FailedCont({ type, response, isDuplicate }: UploadResultProps) {
  const curText = FILE_TYPES.find((ele) => ele.type === type)?.text;
  let mainText = response?.errorMsg ? response?.errorMsg : `${curText} 파일 업로드에 실패했습니다.`;

  console.log('Result: Fail!', response);
  const failCount = response?.failList?.length?.toLocaleString('ko-KR');
  const successCount = (response?.successCount * 1)?.toLocaleString('ko-KR');
  console.log('failCount:', failCount);
  console.log('isDuplicate:', isDuplicate);

  return (
    <FailedContSt.Wrap>
      {/* 건너뛰기 & 덮어쓰기 */}
      {isDuplicate && (
        <>
          <FailedContSt.Cont>
            <div className={`ico ${isDuplicate && 'alert'}`}></div>
            <div className="textWrap">
              <p className="main">{`이미 다음 ${curText} 정보가 등록되어 있습니다.`}</p>
              <p className="sub">{'건너뛰기 또는 덮어쓰기를 선택하세요'}</p>
            </div>
          </FailedContSt.Cont>

          {/*  csv체크 : ptNumDuplicateList 있을때 */}
          {response?.ptNumDuplicateList && (
            <FailedContSt.ErrorCont>
              <ul className="listWrap cScroll error">
                {response?.ptNumDuplicateList?.map((ele, idx) => (
                  <li key={`${idx}-${ele?.ptNum}`}>
                    <span className="id">{ele?.ptNum || ele?.no || '-'}</span>
                    <span className="msg">{ele?.errorMessage || '-'}</span>
                  </li>
                ))}
              </ul>
            </FailedContSt.ErrorCont>
          )}

          {/* 이미지 체크 : duplicateList 있을때 */}
          {response?.duplicateList && (
            <FailedContSt.ErrorCont>
              <ul className="listWrap cScroll error">
                {response?.duplicateList?.map((ele, idx) => (
                  <li key={`${idx}-${ele?.ptNum}`}>
                    <span className="id">{idx + 1}</span>
                    <span className="msg">{ele || '-'}</span>
                  </li>
                ))}
              </ul>
            </FailedContSt.ErrorCont>
          )}
        </>
      )}

      {!isDuplicate && type !== 'image' && (
        <>
          <FailedContSt.Cont>
            <div className="ico"></div>
            <div className="textWrap">
              <p className="main">{mainText}</p>
              <p className="sub">{'CSV 파일을 확인 후 재업로드 해주세요'}</p>
            </div>
          </FailedContSt.Cont>
          {/*  failList 있을때 */}
          {response?.failList && (
            <FailedContSt.ErrorCont>
              <ul className="listWrap cScroll error">
                {response?.failList?.map((ele, idx) => (
                  <li key={`${idx}-${ele?.ptNum}`}>
                    <span className="id">{ele?.ptNum || ele?.no || '-'}</span>
                    <span className="msg">{ele?.errorMessage || '-'}</span>
                  </li>
                ))}
              </ul>
            </FailedContSt.ErrorCont>
          )}
        </>
      )}

      {!isDuplicate &&
        type === 'image' &&
        /* 일부 성공, 일부 실패 */
        (failCount > 0 ? (
          <FailedContSt.ImgCont>
            <div className="success">
              <p>{`이미지 업로드 성공`}</p>
              <span className="number">{successCount}</span>
            </div>
            <div className="fail">
              <p>{`이미지 업로드 실패`}</p>
              <span className="number">{failCount}</span>
            </div>
          </FailedContSt.ImgCont>
        ) : (
          /* 그 외의 에러 */
          <FailedContSt.Cont>
            <div className="ico"></div>
            <div className="textWrap">
              <p className="main">{mainText}</p>
            </div>
          </FailedContSt.Cont>
        ))}
    </FailedContSt.Wrap>
  );
}

// 서버 결과값을 받아서 여기서 성공 실패를 나누고 표현 (서버 response 데이터 표현)
// response : 성공 실패 개수, 에러 문구 등
function PopupResult() {
  const [popResultState, setPopResultState] = useRecoilState(popupResultState);
  const { type, isSuccess, isDuplicate, title, leftText, rightText, leftFunc, rightFunc, close, response } = popResultState;

  return (
    <>
      <PopupFrame title={title} leftText={leftText} rightText={rightText} leftFunc={leftFunc} rightFunc={rightFunc} close={close}>
        {isSuccess && <SuccessCont type={type} response={response} />}
        {!isSuccess && <FailedCont type={type} response={response} isDuplicate={isDuplicate} />}
      </PopupFrame>
    </>
  );
}

export default React.memo(PopupResult);
