import React, { useEffect, useRef, useState } from 'react';
import { PopupStyle } from '../components/templates/_style';
import { useTranslation } from 'react-i18next';
import SurveyMap from 'components/templates/survey/SurveyMap';

const PopupSurveyMap = ({ onClose, siteInfo, activeCategory }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [position, setPosition] = useState({ x: (window.innerWidth - 1200) / 2, y: (window.innerHeight - 856) / 2 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const { t } = useTranslation();

  //마우스 누르고 있을 때
  const handleMouseDown = (e) => {
    const { clientX, clientY } = e;

    setIsDragging(true);
    setStartPosition({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  //마우스 드래그 이동
  const handleMouseMove = (e) => {
    if (isDragging) {
      const maxX = window.innerWidth - (document.getElementById('dragPopup') as any).offsetWidth;
      const maxY = window.innerHeight - (document.getElementById('dragPopup') as any).offsetHeight;
      const newX = Math.min(maxX, Math.max(0, e.clientX - startPosition.x));
      const newY = Math.min(maxY, Math.max(0, e.clientY - startPosition.y));
      setPosition({ x: newX, y: newY });
    }
  };

  //마우스 뗌
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  });

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  });

  return (
    <PopupStyle.Popup
      id={'dragPopup'}
      style={{ height: 800, left: position.x, top: position.y, zIndex: 90, cursor: isResizing ? 'nwse-resize' : 'inherit', border: '1px solid #ccc', resize: 'both', overflow: 'hidden', transform: 'none', minWidth: 200, minHeight: 200 }}
    >
      <PopupStyle.Head onMouseDown={handleMouseDown}>
        <PopupStyle.Title>{t('지도')}</PopupStyle.Title>
        <PopupStyle.CloseBtn type={'button'} onClick={() => onClose('map')}></PopupStyle.CloseBtn>
      </PopupStyle.Head>
      <PopupStyle.Cont style={{ height: 'calc(100% - 56px)' }}>
        {/* 지도 */}
        <SurveyMap siteInfo={siteInfo} activeCategory={activeCategory} />
      </PopupStyle.Cont>
    </PopupStyle.Popup>
  );
};

export default PopupSurveyMap;
