import { request } from '../../../service/api/common';
import * as apiUrl from './apiUrl';

//로그인
export const login = async ({ id, password }) => {
  const { data } = await request('auth').post(apiUrl.auth.login, { id, password });
  return data.data;
};

//회사 + 관리자 계정 추가
export const addCompany = async ({ companyName, address, id, password, name, registration, contract, solutionSaveDto }) => {
  const { data } = await request('auth').post(apiUrl.auth.addCompany, { companyName, address, registration, contract, adminAccount: { id, password, name }, solutionSaveDto });
  return data.data;
};

//회사 리스트
export const getCompanies = async ({ pageNum, keyword, category }) => {
  const { data } = await request('auth').get(apiUrl.auth.getCompanies, { params: { pageNum, pageSize: 10, sort: 'DESC', keyword, category } });
  return data.data;
};

//회사 상세정보
export const getCompanyDetail = async (companyId) => {
  const { data } = await request('auth').get(apiUrl.auth.companyDetail(companyId));
  return data.data;
};

//아이디 중복검사
export const getCheckDuplicate = async (loginId) => {
  const { data } = await request('auth').get(apiUrl.auth.duplicate, { params: { loginId } });
  return data.data;
};

//사용자 추가
export const addUser = async ({ id, password, name, auth, sites }) => {
  const { data } = await request('auth').post(apiUrl.auth.addUser, {
    id,
    password,
    name,
    auth,
    sites,
  });
  return data.data;
};

//사용자 목록 조회
export const getMembers = async ({ pageSize, pageNum, sort, auth }) => {
  const { data } = await request('auth').get(apiUrl.auth.memberList, {
    params: {
      pageSize,
      pageNum,
      sort,
      auth,
    },
  });
  return data.data;
};

//사용자 조회
export const getMyInfo = async () => {
  const { data } = await request('auth').get(apiUrl.auth.memberDetail);
  return data.data;
};
