import VectorModule from './VectorModule';
import { Feature } from 'ol';
import { Point, LineString, MultiPolygon, Polygon } from 'ol/geom';
import { baseProjection, targetProjection } from '../../projection';

//폴리곤 관련 모듈 (벡터의 함수 상속받음)
class ReportPipeLineModule extends VectorModule {
  //

  // 단일 피쳐 생성 (속성 정보 추가)
  public createReportPipeLineFeature(data: any, layerId: string) {
    const points = [
      [data.coordinates[0]?.y, data.coordinates[0]?.x],
      [data.coordinates[1]?.y, data.coordinates[1]?.x],
    ];
    const lineStringFeature = new Feature({
      geometry: new LineString(points),
      properties: {
        ...data,
        layerId,
      },
    });
    //  lineStringFeature.getGeometry()?.transform(targetProjection, baseProjection);
    return lineStringFeature;
  }

  // 피쳐 배열 리턴
  public createReportPipeLineFeatures(datas: any[], layerId: string) {
    return datas?.map((data) => {
      return this.createReportPipeLineFeature(data, layerId);
    });
  }
}

export default ReportPipeLineModule;
