import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PagingTableStyle, TableStyle } from '../../atoms/_style';
import Table from '../../atoms/Table';
import { ContAddBtn, DataFilterStyle, SurveyStyle } from '../_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import PagingTable from '../../atoms/PagingTable';
import SearchFilter from '../../atoms/SearchFilter';
import { OptionProps } from '../../atoms/_interface';
import NoData from '../../atoms/NoData';
import query from 'hooks/RQuery/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// 사업장 목록
const CompanyList = ({ onSelectCompany }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [themeMode] = useRecoilState(themeState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState<any>([]);
  const [option, setOption] = useState<OptionProps[]>([
    { text: '전체', value: 'ALL', active: true },
    { text: '회사명', value: 'COMPANY', active: false },
    { text: '주소', value: 'ADDRESS', active: false },
  ]);
  const [keyword, setKeyword] = useState('');

  const list = query.auths.useCompanies({ currentPage, category: String(option.filter((i) => i.active)[0].value), keyword: keyword });

  //기간 선택 변경
  const onChange = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    // onParamChange(e)
    const value = (e.target as any).ariaValueText;
    const newArr: OptionProps[] = [];
    option.map((i) => {
      newArr.push({ ...i, active: value === i.value });
    });
    setOption(newArr);
  };

  //검색어 변경
  const onChangeWord = (e) => {
    setKeyword(e.target.value);
  };

  //검색버튼 클릭
  const onSearch = async () => {
    await setCurrentPage(1);
    list.refetch();
  };

  //api 호출시 테이블 데이터 변경
  useEffect(() => {
    if (list.data) {
      setTableData(list.data.list);
      setTotalPages(list.data.page.totalPages);
    }
  }, [list.data]);

  useEffect(() => {
    list.refetch();
  }, [currentPage]);

  return (
    <SurveyStyle.DescArea>
      <SearchFilter option={option} onChange={onChange} onChangeWord={onChangeWord} onSearch={onSearch} placeholder={'회사명, 주소로 검색'} />
      {tableData?.length ? (
        <PagingTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          caption={'고객사 리스트'}
          colgroup={['27%', '27%', 'auto']}
          totalPages={totalPages}
          height={'calc(100% - 147px)'}
          thead={
            <tr>
              <th scope={'col'}>회사명</th>
              <th scope={'col'}>주소</th>
              <th scope={'col'}>생성일자</th>
            </tr>
          }
          tbody={
            <>
              {tableData?.map((i, index) => {
                return (
                  <tr key={index} onClick={() => onSelectCompany(i.id)}>
                    <td>
                      <PagingTableStyle.SiteName>{i.companyName}</PagingTableStyle.SiteName>
                    </td>
                    <td>{i.address}</td>
                    <td>{i.createdDate.replace('T', ' ').split('.')[0]}</td>
                  </tr>
                );
              })}
            </>
          }
          leftBtnGroup={
            <ContAddBtn type={'button'} onClick={() => navigate('/auth/register')}>
              {t('고객사 추가')}
            </ContAddBtn>
          }
        />
      ) : (
        <>
          <NoData style={{ height: 'auto' }} />

          <ContAddBtn type={'button'} onClick={() => navigate('/auth/register')}>
            {t('고객사 추가')}
          </ContAddBtn>
        </>
      )}
    </SurveyStyle.DescArea>
  );
};

export default CompanyList;
