import styled, { css } from 'styled-components';
import { ButtonProps, InputProps, SelectProps, TableProps, CheckboxProps } from './_interface';
import IcoSelectDown from '../../assets/images/ico-select-down.svg';
import IcoSelectUp from '../../assets/images/ico-select-up.svg';
import IcoCheckbox from 'assets/images/ico-checkbox.svg';
import IcoCheckboxA from 'assets/images/ico-checkbox-active.svg';
import IcoCheckboxDark from 'assets/images/ico-checkbox-dark.svg';
import IcoCheckboxDarkA from 'assets/images/ico-checkbox-dark-active.svg';
import IcoTableSite from 'assets/images/ico-table-site.svg';
export const ButtonStyleDefault = {
  Wrap: styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(p) => (p.radius ? p.radius + 'px' : '2px')};
    width: ${(p) => (p.width === 'full' ? '100%' : typeof Number(p.width) === 'number' ? p.width + 'px' : 'auto')};
    height: ${(p) => (p.height ? p.height + 'px' : '40px')};
    transition: 0.4s;
    background: ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.bg : '#ccc')};
    border-width: 1px;
    border-style: solid;
    border-color: ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.border : 'transparent')};

    &:before,
    &:after {
      content: '';
      transition: 0.4s;
      width: ${(p) => (p.icoSize ? p.icoSize + 'px' : '24px')};
      height: ${(p) => (p.icoSize ? p.icoSize + 'px' : '24px')};
      background-position: center;
      background-repeat: no-repeat;
      background-image: ${(p) => (p.ico ? 'url(' + p.ico + ')' : 'none')};
    }

    &:before {
      display: ${(p) => (p.ico && p.icoPosition === 'left' ? 'block' : 'none')};
      margin-right: 10px;
    }

    &:after {
      display: ${(p) => (p.ico && p.icoPosition === 'right' ? 'block' : 'none')};
      margin-left: 10px;
    }

    &:hover {
      border-color: ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.hoverBorder : 'transparent')};
      background: ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.hoverBg : '#ccc')};
      box-shadow: 0 4px 4px 0 ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.hoverShadow : '#ccc')};

      &:before,
      &:after {
        background-image: ${(p) => (p.icoHover ? 'url(' + p.icoHover + ')' : 'url(' + p.ico + ')')};
      }
    }

    &:disabled {
      background: #ccc;
    }
  `,
  Text: styled.span<ButtonProps>`
    font-size: ${(p) => (p.fontSize ? p.fontSize + 'px' : '16px')};
    transition: 0.4s;
    font-weight: 400;
    color: ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.color : '#888')};
  `,
};

export const ButtonStyle = {
  ...ButtonStyleDefault,
  Wrap: styled(ButtonStyleDefault.Wrap)`
    &:hover {
      ${ButtonStyleDefault.Text} {
        color: ${(p) => (p.colorType === 'greyLineToRed' ? p.theme.button.greyLineToRed.hoverColor : '#888')};
      }
    }

    &:disabled {
      border: 0;

      ${ButtonStyleDefault.Text} {
        color: #fff;
      }
    }
  `,
};

export const InputStyle = {
  Wrap: styled.div<InputProps>`
    position: relative;
    display: flex;
    align-items: center;
    width: ${(p) => (p.width === 'full' ? '100%' : typeof p.width === 'number' ? p.width + 'px' : 'auto')};
    height: ${(p) => (p.height ? p.height + 'px' : '40px')};

    /* 단위 표시 */
    .unit {
      width: min-content;
      display: ${(p) => (p.unit ? 'block' : 'none')};
      color: #7b7b7b;
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      z-index: 1;
    }

    .rmdp-container {
      width: 100%;
      height: 100% !important;

      input {
        width: 100%;
        height: 100%;
        background: none;
        font-size: 16px;
        color: #1b1b1b;
        font-weight: 200;
        padding: 0 16px;
        text-align: center;

        &:focus {
          box-shadow: none;
          border: none;
        }
      }

      .rmdp-header-values {
        display: flex;
        flex-direction: row-reverse;

        span {
          position: relative;
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #1b1b1b;
          font-size: 16px;

          &:first-child {
            &:after {
              content: '월';
            }
          }

          &:last-child {
            &:after {
              content: '년';
            }

            &:before {
              width: 5px;
              height: 100%;
              position: absolute;
              left: 100%;
              top: 0;
              background: #fff;
              content: '';
            }
          }
        }
      }

      .rmdp-arrow-container {
        .rmdp-arrow {
          border-color: #2d9999;
        }

        &:hover {
          background: #2d9999;

          .rmdp-arrow {
            border-color: #fff;
          }
        }
      }

      .rmdp-shadow {
        box-shadow: none !important;
        border: 1px solid #e2e2e2;
        border-radius: 2px;
      }

      .rmdp-ep-arrow {
        display: none;

        & + div {
          transform: translate(-86px, 59px) !important;
          width: 268px !important;

          .rmdp-wrapper {
            width: 100%;
          }

          .rmdp-calendar {
            flex: 1;
            padding: 10px 15px 20px;
          }

          .rmdp-day-picker {
            padding: 0;

            > div {
              flex: 1;
            }

            .rmdp-week-day {
              color: #7e7e81;
              font-size: 14px;
              font-weight: 300;
            }

            .rmdp-week {
              &:not(:last-child) {
                margin-bottom: 12px;
              }
            }

            .rmdp-day {
              color: #1b1b1b;
              font-size: 14px;
              font-weight: 200;
              width: 34px;
              height: 34px;
              flex: 1;

              &.rmdp-today {
                span {
                  color: #2d9999;
                  font-weight: 500;
                  background: none;
                }
              }

              &.rmdp-range {
                background: #e2f7f7;
                box-shadow: none;
              }

              &.start,
              &.end {
                background: #2d9999;
                border-radius: 2px;

                span {
                  color: #fff;
                  font-weight: 500;

                  &:hover {
                    color: #fff !important;
                    border-radius: 2px;
                  }
                }
              }

              &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                background: none;
                color: #1b1b1b;
                font-weight: 500;
              }

              &.rmdp-disabled span {
                color: #ccc !important;
              }
            }
          }
        }
      }
    }
  `,
  Input: styled.input<InputProps>`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid ${(p) => (p.isLogin ? '#313d4f' : p.theme.input.border)};
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    color: ${(p) => (p.isLogin ? '#fff' : p.theme.input.color)};
    margin-right: ${(p) => (p.component ? '10px' : 0)};
    flex: ${(p) => (p.component ? 1 : 'none')};
    padding: ${(p) => (p.bg ? '0 16px 0 52px ' : '0 30px 0 16px')};
    background: ${(p) => (p.bg ? (p.isLogin ? '#323D4E' : p.theme.input.bg) + ' url(' + p.bg + ')no-repeat 16px center' : p.theme.input.bg)};
    transition: 0.4s;
    &[type='number'] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }

    &::placeholder {
      color: #94949a;
    }

    &:focus {
      outline: none;
      border: 1px solid #0076ff;
    }

    &:disabled {
      background: ${(p) => p.theme.button.type1.disabled.bg};
    }
    &:read-only {
      &:focus {
        border: 1px solid ${(p) => p.theme.input.border};
      }
    }

    ${(p) => p.error && 'outline:1px solid #dc5700'}

    position: relative;
  `,
  ErrMsg: styled.p`
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    font-size: 14px;
    color: #dc5700;
    font-weight: 200;
  `,
};

export const MapScaleStyle = {
  Wrap: styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    height: 28px;
    display: flex;
    padding: 0 16px;
    z-index: 10;
    background: rgba(255, 255, 255, 0.8);
    align-items: center;
  `,
  Label: styled.p`
    color: #1b1b1b;
    font-size: 14px;
    font-weight: 300;
    margin-right: 10px;
  `,
  Bar: styled.div`
    height: 7px;
    border: 2px solid #1b1b1b;
    border-top: 0;

    .ol-scale-line-inner {
      font-size: 0;
    }
  `,
};

export const BarChartStyle = {
  Wrap: styled.div`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  `,
  Label: styled.strong<{ $color?: string }>`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    min-width: 70px;
    color: ${(p) => p.theme.dashboard.barChart.label};
    // &:before{content:'';width:8px;height:8px;border-radius:50%;margin-right:5px;
    //   background:${(p) => (p.$color ? p.$color : 'transparent')};
    // }
  `,
  BarRow: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
  `,
  BarWrap: styled.div`
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 50px;
    overflow: hidden;
    background: ${(p) => p.theme.dashboard.barChart.back};
  `,
  Bar: styled.div<{ $percent: number; $color?: string }>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: 0.8s;
    width: 0;
    background: ${(p) => (p.$color ? p.$color : 'transparent')};

    &.active {
      width: ${(p) => (p.$percent ? p.$percent + '%' : 0)};
    }
  `,
  Percent: styled.strong`
    font-size: 12px;
    margin-left: auto;
    width: 67px;
    text-align: right;
    font-weight: 500;
    color: ${(p) => p.theme.dashboard.barChart.percent};
  `,
};

export const TableStyle = {
  Wrap: styled.div<TableProps>`
    position: relative;
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    height: ${(p) => (p.height ? p.height : 'auto')};
  `,
  Thead: styled.div<TableProps>`
    position: ${(p) => (p.height ? 'relative' : 'sticky')};
    top: ${(p) => (p.height ? 'auto' : 0)};
    width: calc(100% - 48px);
    margin: 0 auto;
    ${(p) =>
      p.type === 2 &&
      `
    &:after{display:none}`}
    &:after {
      position: absolute;
      right: -24px;
      top: 48px;
      content: '';
      width: calc(100% + 56px);
      height: 1px;
      background: ${(p) => p.theme.dashboard.table.border};
    }

    table {
      width: 100%;
      box-sizing: border-box;

      th {
        color: ${(p) => (p.isLogin ? '#fff' : p.theme.dashboard.table.th)};
        border-bottom: 1px solid ${(p) => (p.isLogin ? '#323d4e' : p.theme.dashboard.table.border)};
        box-sizing: border-box;
        height: 48px;
        font-size: 14px;
        font-weight: 600;
        background: ${(p) => (p.isLogin ? '#273142' : p.theme.dashboard.box.cont)};

        &.txtLeft {
          text-align: left;
          padding-left: 20px;
        }
      }
    }
  `,
  Tbody: styled.div<TableProps>`
    width: calc(100% - 48px);
    margin: 20px auto 0;
    ${(p) => p.type === 2 && 'width:calc(100% - 30px);margin: 16px auto 10px;height: 100%;'}
    table {
      width: 100%; //border-collapse:separate;
      /*
      ${(p) => p.type === 2 && 'height: 100%;'}
         */
      tr {
        box-sizing: border-box;

        &:hover,
        &.active {
          td {
            background: rgba(72, 128, 255, 0.15);
            color: #4880ff;
          }
        }

        &:first-child {
          td {
            border-top: 0;
          }
        }

        td {
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          color: ${(p) => (p.isLogin ? '#fff' : p.theme.dashboard.table.td)};
          box-sizing: border-box;
          transition: 0.4s;
          height: 48px;
          border-bottom: 1px solid ${(p) => (p.isLogin ? '#1c2531' : p.theme.dashboard.table.tdBottom)};
          background: ${(p) => (p.isLogin ? '#323D4e' : p.theme.dashboard.table.tdBg)};

          &.txtLeft {
            text-align: left;
            padding-left: 20px;
          }
        }
      }
    }
  `,
  SortBtn: styled.button<{ $status: { sort: string; key: string }; $key: string }>`
    display: inline-flex;
    align-items: center;
    color: ${(p) => p.theme.dashboard.table.th};
    font-size: 14px;
    font-weight: 600;

    &:after {
      display: ${(p) => (p.$status.key === p.$key ? 'block' : 'none')};
      content: '';
      width: 20px;
      height: 20px;
      background: url(${(p) => p.theme.dashboard.table.sort}) no-repeat center;
      transform: rotate(${(p) => (p.$status.sort === 'upper' ? '0deg' : '180deg')});
      margin-left: 4px;
    }
  `,
};

export const SelectStyle = {
  Area: styled.div`
    display: flex;
    align-items: center;
  `,
  Wrap: styled.div<SelectProps>`
    position: relative;
    /* z-index: 10; */
    width: ${(p) => (p.width === 'full' ? '100%' : typeof Number(p.width) === 'number' ? p.width + 'px' : 'auto')};
  `,
  Selected: styled.strong<SelectProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    height: ${(p) => (p.height ? p.height + 'px' : '40px')};
    border: 1px solid ${(p) => p.theme.select.selected.border};
    color: ${(p) => p.theme.select.selected.color};
    background: ${(p) => p.theme.select.selected.bg} url(${IcoSelectDown}) no-repeat right 16px center;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 11px 0 16px;
    transition: 0.4s;

    &.active {
      background: url(${(p) => p.theme.select.selected.activeIco}) no-repeat right 16px center ${(p) => p.theme.select.selected.activeBg};
      border: 1px solid #0076ff;

      + div {
        display: block;
        z-index: 100;
      }
    }

    &.disabled {
      background-color: #ececec;
    }

    &:focus-visible {
      outline: none;
    }

    &:hover {
      background-color: rgba(72, 128, 255, 0.15);
      border: 1px solid #313d4f;
    }
  `,
  Label: styled.div<{ height?: number }>`
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.select.selected.border};
    border-right-color: ${(p) => p.theme.select.selected.labelBorder};
    height: ${(p) => (p.height ? p.height + 'px' : '40px')};
    background: ${(p) => p.theme.select.selected.bg};
    color: #767676;
    font-size: 14px;
    font-weight: 500;
  `,
  ListWrap: styled.div`
    position: absolute;
    z-index: 10;
    left: 0;
    top: calc(100% + 4px);
    display: none;
    width: 100%;
    border: 1px solid ${(p) => p.theme.select.selected.activeBorder};
    background: ${(p) => p.theme.select.selected.bg};
    box-sizing: border-box;
    box-shadow: 0 4px 6px 0 rgba(0, 118, 255, 0.2);
  `,
  Search: styled.div`
    padding: 16px 16px 10px;

    input {
      background: #fff no-repeat right 20px center;
      margin: 0 auto;
      border: 1px solid #e2e2e2 !important;
    }
  `,
  List: styled.ul`
    max-height: 200px;
    overflow-y: auto;
  `,
  Item: styled.li<SelectProps>`
    height: ${(p) => (p.height ? p.height + 'px' : '33px')};
    line-height: ${(p) => (p.height ? p.height + 'px' : '33px')};
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    cursor: pointer;
    color: ${(p) => p.theme.select.item.color};

    &:hover,
    &:focus {
      background: ${(p) => p.theme.select.item.bgHover};
      color: ${(p) => p.theme.select.item.colorHover};
      outline: none;
    }
  `,
};

const CheckboxDefaultStyle = {
  Wrap: styled.div<{ size?: number }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(p) => (p.size ? p.size + 'px' : '24px')};
    overflow: hidden;
  `,
  Label: styled.label<CheckboxProps>`
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: ${(p) => p.theme.container.title};

    &:before {
      cursor: pointer;
      content: '';
      width: ${(p) => (p.size ? p.size + 'px' : '24px')};
      height: ${(p) => (p.size ? p.size + 'px' : '24px')};
      background-image: ${(p) => `url(${p.theme.checkbox?.ico})`};
      /* background-image: ${(p) => (p.theme === 'dark' ? `url(${IcoCheckboxDark})` : `url(${IcoCheckbox})`)}; */
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: 0.4s;
      box-sizing: border-box;
      margin-right: ${(p) => (p.text ? '10px' : 0)};
    }
  `,
  Text: styled.label<CheckboxProps>`
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: ${(p) => p.theme.container.title};
  `,
  Input: styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 0;
    width: 0;
    height: 0;
  `,
};

export const CheckboxStyle = {
  ...CheckboxDefaultStyle,
  Input: styled(CheckboxDefaultStyle.Input)`
    &.dark {
      &:checked + ${CheckboxDefaultStyle.Label} {
        &:before {
          background-image: ${(p) => `url(${IcoCheckboxA})`};
        }
      }
    }
    &:checked + ${CheckboxDefaultStyle.Label} {
      &:before {
        background-image: ${(p) => `url(${p.theme.checkbox.activeIco})`};
      }
    }
  `,
};

export const PagingTableStyle = {
  Wrap: styled.table<{ width?: number }>`
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    border-bottom: 1px solid ${(p) => p.theme.pagingTable.table.border};
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  `,
  Thead: styled.thead`
    position: sticky;
    top: 0;
    z-index: 21;
    tr {
      &.txtLeft {
        th,
        td {
          padding-left: 40px;
          text-align: left;
        }
      }
      &:first-child {
        th {
          border-top: 1px solid ${(p) => p.theme.pagingTable.table.border};
        }
      }
      th {
        background: ${(p) => p.theme.pagingTable.table.thBg};
        color: ${(p) => p.theme.pagingTable.table.thColor};
        font-size: 14px;
        font-weight: 600;
        height: 47px;
        border-bottom: 1px solid ${(p) => p.theme.pagingTable.table.border};
        box-sizing: border-box;
        position: relative;
        &.type2 {
          background: ${(p) => p.theme.pagingTable.table.thBgType2};
        }
        > button {
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          color: ${(p) => p.theme.pagingTable.table.thColor};
          &:after {
            content: '';
            width: 5px;
            height: 5px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
            margin-top: -2px;
            margin-left: 10px;
            transition: 0.4s;
          }
          &.asc {
            &:after {
              transform: rotate(135deg);
              margin-bottom: -4px;
              margin-top: 0;
            }
          }
        }
        &:first-child {
          border-left: 1px solid ${(p) => p.theme.pagingTable.table.border};
        }
        &:last-child {
          border-right: 1px solid ${(p) => p.theme.pagingTable.table.border};
        }
        &.rightBorderNone {
          &:after {
            display: none;
          }
        }
        &.leftBorderNone {
          &:before {
            display: none;
          }
        }
        &.leftBorder {
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: calc(100% - 15px);
            background: ${(p) => p.theme.pagingTable.table.border};
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &:not(:last-child) {
          &:after {
            position: absolute;
            content: '';
            width: 1px;
            height: calc(100% - 15px);
            background: ${(p) => p.theme.pagingTable.table.border};
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  `,
  Tbody: styled.tbody`
    tr {
      &.txtLeft {
        th,
        td {
          padding-left: 40px;
          text-align: left;
        }
      }
      cursor: pointer;
      td {
        background: ${(p) => p.theme.pagingTable.table.tdBg};
        color: ${(p) => p.theme.pagingTable.table.tdColor};
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        height: 60px;
        &.type2 {
          background: ${(p) => p.theme.pagingTable.table.thBgType2};
        }
        &:not(:last-child) {
          border-right: 1px solid ${(p) => p.theme.pagingTable.table.border};
          box-sizing: border-box;
        }
        &:first-child {
          border-left: 1px solid ${(p) => p.theme.pagingTable.table.border};
        }
        &:last-child {
          border-right: 1px solid ${(p) => p.theme.pagingTable.table.border};
        }
      }
      &:not(:last-child) {
        td {
          border-bottom: 1px solid ${(p) => p.theme.pagingTable.table.border};
        }
      }
      &.noHover {
        &:hover {
          td {
            cursor: auto;
            background: ${(p) => p.theme.pagingTable.table.tdBg};
          }
        }
      }
      &:hover {
        td {
          background: ${(p) => p.theme.pagingTable.table.hoverTdBg};
        }
      }
      &:active,
      &.active {
        td {
          background: ${(p) => p.theme.pagingTable.table.thBgType2};
        }
      }
      &.type2 {
        cursor: auto;
        td {
          background: ${(p) => p.theme.pagingTable.table.thBgType2};
          color: #0076ff;
        }
        &:hover {
          td {
            background: ${(p) => p.theme.pagingTable.table.thBgType2};
          }
        }
      }
    }
  `,
  SiteName: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0076ff;
    &.txtLeft {
      justify-content: flex-start;
    }
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${IcoTableSite}) no-repeat center;
      margin-right: 9px;
    }
  `,
  PagiArea: styled.div`
    position: relative;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  LeftBtnGroup: styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    > button {
      &:not(:last-child) {
        margin-right: 7px;
      }
    }
  `,
  PagiBtn: styled.button`
    min-width: 36px;
    height: 36px;
    padding: 0 4px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: ${(p) => p.theme.pagingTable.pagination.color};
    &.active {
      color: ${(p) => p.theme.pagingTable.pagination.activeColor};
      background: ${(p) => p.theme.pagingTable.pagination.activeBg};
    }
    &:disabled {
      visibility: hidden;
    }
    &.prev,
    &.next,
    &.first,
    &.last {
      position: relative;
      background: ${(p) => p.theme.pagingTable.pagination.arrowBg};
      &:hover {
        background: ${(p) => p.theme.pagingTable.pagination.arrowHoverBg};
        border: 1px solid ${(p) => p.theme.pagingTable.pagination.arrowHoverBorder};
      }
      &:active {
        &:before,
        &:after {
          border-color: ${(p) => p.theme.pagingTable.pagination.arrowActiveBorder};
        }
      }
      &:before,
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-bottom: 2px solid ${(p) => p.theme.pagingTable.pagination.arrow};
        border-left: 2px solid ${(p) => p.theme.pagingTable.pagination.arrow};
      }
    }
    &.prev {
      margin-right: 10px;
      &:after {
        transform: translate(calc(-50% + 2px), -50%) rotate(45deg);
      }
      &:before {
        display: none;
      }
    }
    &.next {
      margin-left: 10px;
      &:after {
        transform: translate(calc(-50% - 2px), -50%) rotate(-135deg);
      }
      &:before {
        display: none;
      }
    }
    &.first {
      margin-right: 10px;
      &:before {
        transform: translate(calc(-50% + 4px), -50%) rotate(45deg);
      }
      &:after {
        transform: translate(calc(-50% - 2px), -50%) rotate(45deg);
      }
    }
    &.last {
      margin-left: 10px;
      &:before {
        transform: translate(calc(-50% - 4px), -50%) rotate(-135deg);
      }
      &:after {
        transform: translate(calc(-50% + 2px), -50%) rotate(-135deg);
      }
    }
  `,
  PagiGroup: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
    > button,
    > div {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  `,
};

export const SearchFilterStyle = {
  Area: styled.div`
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: ${(p) => p.theme.survey.filter};
    border: 1px solid ${(p) => p.theme.survey.border};
    border-radius: 4px;
    margin-bottom: 12px;
  `,
  Text: styled.p`
    color: ${(p) => p.theme.survey.text};
    font-size: 12px;
    font-weight: 500;
    margin-right: 20px;
    margin-left: auto;
  `,
};
