import { getImageBuffer } from '../../service/api/common';
import Fabric from './ImageTable';

const photoSheet = async (wb, photoSheet, list, title) => {
  photoSheet.getColumn(1).width = 18;
  photoSheet.getColumn(2).width = 18;
  photoSheet.getColumn(3).width = 16;
  photoSheet.getColumn(4).width = 13;
  photoSheet.getColumn(5).width = 13;
  photoSheet.getColumn(6).width = 16;
  photoSheet.getColumn(7).width = 13;

  list.forEach((i, index) => {
    let allRow =
      index === 0 || index % 2 === 0
        ? photoSheet.addRows([
            ['시설물 위치측량 조서', '', '', '', '', '', ''],
            ['사업명', title, '', '', '', '', ''],
            ['측량날짜', i.ymd, '', '측점명', i.ptNum, '', ''],
            ['좌표(GRS80)', '', '시설물종류', '재질', '관경', '이격거리', '심도'],
            ['X(N)', 'Y(E)', '', '', '', '', ''],
            [i.x, i.y, i.facilityType, i.mopValue, i.hol && i.vel ? i.hol + 'X' + i.vel : i.diameter, i.separationDistance, i.depth ? i.depth : '-'],
            ['', '', '', '', '', '', ''],
            ['실시간 측량점', '', '', '관로 측량점', '', '', ''],
          ])
        : photoSheet.addRows([
            ['측량날짜', i.ymd, '', '측점명', i.ptNum, '', ''],
            ['좌표(GRS80)', '', '시설물종류', '재질', '관경', '이격거리', '심도'],
            ['X(N)', 'Y(E)', '', '', '', '', ''],
            [i.x, i.y, i.facilityType, i.mopValue, i.hol && i.vel ? i.hol + 'X' + i.vel : i.diameter, i.separationDistance, i.depth ? i.depth : '-'],
            ['', '', '', '', '', '', ''],
            ['실시간 측량점', '', '', '관로 측량점', '', '', ''],
          ]);
    allRow.forEach((i, num) => {
      i.eachCell((cell, colNum) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        if ((index === 0 || index % 2 === 0) && (num === 0 || num === 1)) {
          cell.font = { size: 16, bold: true, color: { argb: '000000' } };
        } else {
          cell.font = { size: 11, bold: false, color: { argb: '000000' } };
        }
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });
  });
  const rowHeights = [45, 20, 20, 20, 20, 20, 215, 20, 20, 20, 20, 20, 215, 20];

  for (let i = 0; 2 * i < list.length; i++) {
    //한페이지 6줄 반복
    let line = 14 * i;
    const mergeCellList = [
      `A${line + 1}:G${line + 1}`,
      `B${line + 2}:G${line + 2}`,
      `B${line + 3}:C${line + 3}`,
      `E${line + 3}:G${line + 3}`,
      `A${line + 4}:B${line + 4}`,
      `C${line + 4}:C${line + 5}`,
      `D${line + 4}:D${line + 5}`,
      `E${line + 4}:E${line + 5}`,
      `F${line + 4}:F${line + 5}`,
      `G${line + 4}:G${line + 5}`,
      `A${line + 7}:C${line + 7}`,
      `D${line + 7}:G${line + 7}`,
      `A${line + 8}:C${line + 8}`,
      `D${line + 8}:G${line + 8}`,
      `B${line + 9}:C${line + 9}`,
      `E${line + 9}:G${line + 9}`,
      `A${line + 10}:B${line + 10}`,
      `C${line + 10}:C${line + 11}`,
      `D${line + 10}:D${line + 11}`,
      `E${line + 10}:E${line + 11}`,
      `F${line + 10}:F${line + 11}`,
      `G${line + 10}:G${line + 11}`,
      `A${line + 13}:C${line + 13}`,
      `D${line + 13}:G${line + 13}`,
      `A${line + 14}:C${line + 14}`,
      `D${line + 14}:G${line + 14}`,
    ];
    for (let j = 0; j < mergeCellList.length; j++) {
      photoSheet.mergeCells(mergeCellList[j]);
    }
    for (let j = 0; j < rowHeights.length; j++) {
      photoSheet.getRow(line + j + 1).height = rowHeights[j];
    }

    //실시간 이미지
    const distanceImageTop = list[2 * i]?.transImage;
    const elDistanceImageTop = list[2 * i]?.elDistanceImage;
    const distanceImageBottom = list[2 * i + 1]?.transImage;
    const elDistanceImageBottom = list[2 * i + 1]?.elDistanceImage;

    //실시간 이미지
    if (distanceImageTop) {
      await getImageBuffer(distanceImageTop)
        .then((res) => {
          const imageId = wb.addImage({
            buffer: res,
            extension: 'jpeg',
          });
          photoSheet.addImage(imageId, `A${line + 7}:C${line + 7}`);
          // pipeSheet.addImage(imageId, `A${line + 16}:F${line + 16}`);
        })
        .catch((e) => {
          console.log('에-러');
          console.log(e);
        });
    }
    if (elDistanceImageTop) {
      await getImageBuffer(elDistanceImageTop)
        .then((res) => {
          const imageId = wb.addImage({
            buffer: res,
            extension: 'jpeg',
          });
          photoSheet.addImage(imageId, `D${line + 7}:G${line + 7}`);
          // pipeSheet.addImage(imageId, `A${line + 16}:F${line + 16}`);
        })
        .catch((e) => {
          console.log('에-러');
          console.log(e);
        });
    }

    //실시간 이미지
    if (distanceImageBottom) {
      await getImageBuffer(distanceImageBottom)
        .then((res) => {
          const imageId = wb.addImage({
            buffer: res,
            extension: 'jpeg',
          });
          photoSheet.addImage(imageId, `A${line + 13}:C${line + 13}`);
          // pipeSheet.addImage(imageId, `A${line + 16}:F${line + 16}`);
        })
        .catch((e) => {
          console.log('에-러');
          console.log(e);
        });
    }
    if (elDistanceImageBottom) {
      await getImageBuffer(elDistanceImageBottom)
        .then((res) => {
          const imageId = wb.addImage({
            buffer: res,
            extension: 'jpeg',
          });
          photoSheet.addImage(imageId, `D${line + 13}:G${line + 13}`);
          // pipeSheet.addImage(imageId, `A${line + 16}:F${line + 16}`);
        })
        .catch((e) => {
          console.log('에-러');
          console.log(e);
        });
    }
  }

  // 페이지 설정을 통해 한 페이지에 맞추기
  photoSheet.pageSetup.paperSize = 9;
  photoSheet.pageSetup.fitToPage = true;
  photoSheet.pageSetup.fitToHeight = 15;
  photoSheet.pageSetup.fitToWidth = 1;
  photoSheet.pageSetup.horizontalCentered = true; // 수평 가운데 정렬
  photoSheet.pageSetup.verticalCentered = true; // 수직 가운데 정렬
};

export default photoSheet;
