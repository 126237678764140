import VectorModule from './VectorModule';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';

class ShpModule extends VectorModule {
  //
  // 단일 피쳐 생성 (속성 정보 추가)
  public createPipeFeature(data: any, layerId: string) {
    const points: number[][] = [];
    data.geometry.map((i) => {
      points.push([i.x, i.y]);
    });
    const lineStringFeature = new Feature({
      geometry: new LineString(points),
      properties: {
        ...data,
        layerId,
      },
    });

    return lineStringFeature;
  }

  // 피쳐 배열 리턴
  public createPipeFeatures(datas: any[], layerId: string) {
    return datas?.map((data) => {
      return this.createPipeFeature(data, layerId);
    });
  }
}

export default ShpModule;
