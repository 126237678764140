import React, { useState, useEffect } from 'react';
import { SettingWrapSt } from './_style';
import { useRecoilState } from 'recoil';
import { constructionInfoState } from 'store/Atoms';
import LabelCheckbox, { LabelCheckboxSt } from './LabelCheckbox';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';
import Checkbox from 'components/atoms/Checkbox';
import { allFacilityList } from '../../../util/global';

function SettingWrap({ showSetting, setShowSetting, toggleLayer, toggleBuffer, toggleDesign, togglePin }: { showSetting?: boolean; setShowSetting: any; toggleLayer?: any; toggleBuffer?: any; toggleDesign?: any; togglePin?: any }) {
  const [tabNum, setTabNum] = useState(1); //1:관종류, 2:JobID
  const { t } = useTranslation();
  const [listState, setListState] = useState<any[]>([]); // 관 종류 on,off
  const [designListState, setDesignListState] = useState<any[]>([]); // 관종별 설계 on,off
  const [onBuffer, setOnBuffer] = useState(true); //배관 직경 표현 여부 on,off
  const [onEdit, setOnEdit] = useState(false);
  const [onEditType, setOnEditType] = useState({ point: false, pipe: false });
  const [pinState, setPinState] = useState(true);
  const code = query.codes.useCodes(); // 코드 조회
  const facilitys = code?.data?.FACILITY; // 시설물 코드

  const [constructionInfo] = useRecoilState(constructionInfoState); // 현재 현장 정보
  const { facilityKinds } = constructionInfo || {};

  // 전체 시설물 리스트 목록 (isChecked 추가)
  useEffect(() => {
    if (facilitys) {
      setListState(() => facilitys?.map((ele) => ({ ...ele, isChecked: true })));
      setDesignListState(() => facilitys?.map((ele) => ({ ...ele, isChecked: false })));
    } else {
      setListState(allFacilityList);
      setDesignListState(allFacilityList);
    }
  }, [facilitys]);

  // 탭 전환 (관종류, Jobid)
  const onClickTab = (num: number) => {
    setTabNum(num);
  };
  const onChangePin = () => {
    setPinState(!pinState);
    togglePin();
  };

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, name, checked } = e.target;
    //  console.log(id, name, checked);

    //  /* 편집모드일때, 종류 선택 */
    //  if (onEdit) {
    //    if (name === 'mode') {
    //      if (id === 'point') {
    //        setOnEditType({ point: true, pipe: false });
    //        toggleEditMode(checked, 'point');
    //      }
    //      if (id === 'pipe') {
    //        setOnEditType({ point: false, pipe: true });
    //        toggleEditMode(checked, 'pipe');
    //      }
    //      return;
    //    }
    //  }

    //  /* 편집모드 체크박스 일떄 */
    //  if (name === 'editMode') {
    //    setOnEdit(checked);
    //    toggleEditMode(checked, 'point');
    //    if (checked) {
    //      setOnEditType((prev) => ({ ...prev, point: true }));
    //    } else {
    //      setOnEditType({ point: false, pipe: false });
    //    }
    //    return;
    //  }

    /* 버퍼(배관 직경 표현) 체크박스 일떄 */
    if (name === 'buffer') {
      setOnBuffer(checked);
      toggleBuffer(checked);
      return;
    }

    /* 시설물 on,off */
    setListState((prev) => {
      let newArr = [...prev];
      return newArr.map((ele) => (ele.value === name ? { ...ele, isChecked: checked } : ele));
    });

    toggleLayer(id); // 시설물 레이어 토글
  };

  //설계도면 레이어 토글
  const onChangeDesignCheckBox = (e, code) => {
    const { id, name, checked } = e.target;

    /* 시설물 on,off */
    setDesignListState((prev) => {
      let newArr = [...prev];
      return newArr.map((ele) => (ele.value === name ? { ...ele, isChecked: checked } : ele));
    });
    toggleDesign(code);
  };

  const onClickToggle = () => {
    setShowSetting(!showSetting);
  };

  return (
    <SettingWrapSt.Wrap className={showSetting ? 'isOpen' : undefined}>
      <SettingWrapSt.CloseBtn className={showSetting ? 'isOpen' : undefined} onClick={onClickToggle} />
      {/*<SettingWrapSt.SiteWrap>*/}
      {/*<SettingWrapSt.SiteHead>*/}
      {/*  <SettingWrapSt.SiteTitle>{t('현장목록')}</SettingWrapSt.SiteTitle>*/}
      {/*  /!*<SettingWrapSt.SiteCount>선택 2</SettingWrapSt.SiteCount>*!/*/}
      {/*  <Checkbox id={'siteAll'} style={{ marginLeft: 'auto' }} size={20} />*/}
      {/*</SettingWrapSt.SiteHead>*/}
      {/*<SettingWrapSt.SiteCont>*/}
      {/*<SettingWrapSt.SiteList className={'cScroll'}>*/}
      {/*  <SettingWrapSt.SiteItem>*/}
      {/*    <LabelCheckbox id={'wqf'} size={20} text={'부산 EDC 2-1공구'} style={{ flex: 1 }} />*/}
      {/*  </SettingWrapSt.SiteItem>*/}
      {/*</SettingWrapSt.SiteList>*/}
      {/*</SettingWrapSt.SiteCont>*/}
      {/*</SettingWrapSt.SiteWrap>*/}
      <SettingWrapSt.TabWrap>
        <button className={tabNum === 1 ? 'active' : undefined} onClick={() => onClickTab(1)}>
          {t('관종류')}
        </button>
        <button className={tabNum === 2 ? 'active' : undefined} onClick={() => onClickTab(2)}>
          Job ID
        </button>
      </SettingWrapSt.TabWrap>
      <SettingWrapSt.ContWrap style={{ marginBottom: 8 }}>
        <SettingWrapSt.ContHead>
          <LabelCheckbox id={'constructAll'} size={20} text={t('시공')} style={{ flex: 1 }} />
        </SettingWrapSt.ContHead>
        {/* 관 종류 리스트 컴포넌트 */}
        {tabNum === 1 && (
          <SettingWrapSt.LabelCheckWrap>
            <SettingWrapSt.LabelCheckList className={'cScroll'}>
              {listState?.map(
                (ele: any) =>
                  facilityKinds.find((val) => val?.facilityKind === ele.code) && (
                    <LabelCheckbox key={ele?.code} id={ele?.code} name={ele?.value} text={t(ele?.value)} onChange={onChangeCheckbox} checked={ele?.isChecked} color={ele?.color} size={20} />
                  ),
              )}
            </SettingWrapSt.LabelCheckList>
          </SettingWrapSt.LabelCheckWrap>
        )}

        {/* Job ID 리스트 컴포넌트 */}
        {tabNum === 2 && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {listState?.map(
              (ele: any) =>
                facilityKinds.find((val) => val?.facilityKind === ele.code) && (
                  <SettingWrapSt.JobIdWrap key={'jobId' + ele?.code}>
                    <div className="header">
                      <LabelCheckbox id={ele?.code} name={ele?.value} text={t(ele?.value)} onChange={onChangeCheckbox} checked={ele?.isChecked} color={ele?.color} />
                    </div>
                    <ul className="list">{facilityKinds?.find((facility: { facilityKind?: string; jobId?: [] }) => facility?.facilityKind === ele?.code)?.jobId?.map((job, idx) => <li key={job + idx}>{job}</li>)}</ul>
                  </SettingWrapSt.JobIdWrap>
                ),
            )}
          </div>
        )}
      </SettingWrapSt.ContWrap>
      <SettingWrapSt.ContWrap style={{ marginBottom: 8 }}>
        <SettingWrapSt.ContHead>
          <LabelCheckbox id={'designAll'} size={20} text={t('설계')} style={{ flex: 1 }} />
        </SettingWrapSt.ContHead>
        <SettingWrapSt.LabelCheckWrap>
          <SettingWrapSt.LabelCheckList className={'cScroll'}>
            {designListState?.map(
              (ele: any) =>
                facilityKinds.find((val) => val?.facilityKind === ele.code) && (
                  <LabelCheckbox key={ele?.code} id={ele?.code + '_design'} name={ele?.value} text={t(ele?.value)} onChange={(e) => onChangeDesignCheckBox(e, ele.code)} checked={ele?.isChecked} color={ele?.color} size={20} />
                ),
            )}
          </SettingWrapSt.LabelCheckList>
        </SettingWrapSt.LabelCheckWrap>
      </SettingWrapSt.ContWrap>
      {/*<SettingWrapSt.ContWrap style={{ marginBottom: 8 }}>*/}
      {/*  <SettingWrapSt.ContHead>*/}
      {/*    <LabelCheckbox id={'shpAll'} size={20} text={'Shp 파일'} style={{ flex: 1 }} />*/}
      {/*  </SettingWrapSt.ContHead>*/}
      {/*  <SettingWrapSt.LabelCheckWrap>*/}
      {/*    <SettingWrapSt.LabelCheckList className={'cScroll'}>*/}
      {/*      <LabelCheckbox id={'tndw'} name={'name'} text={'상수'} size={20} color={'#0076ff'} />*/}
      {/*    </SettingWrapSt.LabelCheckList>*/}
      {/*  </SettingWrapSt.LabelCheckWrap>*/}
      {/*</SettingWrapSt.ContWrap>*/}
      <SettingWrapSt.Divider />
      <SettingWrapSt.EtcWrap>
        {t('배관직경표현')}
        <SettingWrapSt.EtcToggle
          type={'button'}
          className={onBuffer ? 'active' : undefined}
          onClick={() => {
            setOnBuffer(!onBuffer);
            toggleBuffer(!onBuffer);
          }}
        ></SettingWrapSt.EtcToggle>
      </SettingWrapSt.EtcWrap>
      {/*<LabelCheckbox key={'buffer'} id={'buffer'} name={'buffer'} text={t('배관직경표현')} onChange={onChangeCheckbox} checked={onBuffer} color={''} />*/}
      <SettingWrapSt.Divider />
      {String(sessionStorage.getItem('solution')).includes('KCITY') ? (
        <>
          <SettingWrapSt.EtcWrap>
            {t('스마트핀')}
            <SettingWrapSt.EtcToggle
              type={'button'}
              className={pinState ? 'active' : undefined}
              onClick={() => {
                onChangePin();
              }}
            ></SettingWrapSt.EtcToggle>
          </SettingWrapSt.EtcWrap>
          {/*<SettingWrapSt.ContWrap>*/}
          {/*  <LabelCheckbox id={'pin'} name={'pin'} text={t('스마트핀')} onChange={onChangePin} checked={pinState} />*/}
          {/*</SettingWrapSt.ContWrap>*/}
          <SettingWrapSt.Divider />
        </>
      ) : null}
      {/* todo 미구현기능 임시 주석(0823) */}
      {/*<SettingWrapSt.ContWrap>*/}
      {/*  <LabelCheckbox key={'editMode'} id={'editMode'} name={'editMode'} text={t('편집모드')} onChange={onChangeCheckbox} color={''} checked={onEdit} />*/}
      {/*</SettingWrapSt.ContWrap>*/}
      {/*<SettingWrapSt.Divider />*/}
      {/*<SettingWrapSt.ContWrap>*/}
      {/*  <Checkbox name="mode" id="point" type="radio" text={t('포인트')} onChange={onChangeCheckbox} checked={onEditType.point} />*/}
      {/*  <Checkbox name="mode" id="pipe" type="radio" text={t('파이프')} onChange={onChangeCheckbox} checked={onEditType.pipe} />*/}
      {/*</SettingWrapSt.ContWrap>*/}
    </SettingWrapSt.Wrap>
  );
}

export default SettingWrap;
