import React, { useEffect, useState } from 'react';
import OlCore from 'maps/wfs/OlCore';
import ObjectMapPipeLine from 'maps/wfs/Object/MapPipeLine';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import ChangeMapBtn from '../2dMap/ChangeMapBtn';
import Toolbar from '../2dMap/Toolbar';

import { getPipesQuery } from 'hooks/RQuery/utils/pipes';
import { getPointsQuery } from 'hooks/RQuery/utils/points';
import ObjectMapPoint from 'maps/wfs/Object/MapPoint';

// 측량성과등록 - 지도 팝업용
function SurveyMap({ siteInfo, activeCategory }: { siteInfo?: { id: string; name: string }; activeCategory: string }) {
  const siteId = siteInfo?.id || '';
  const [isObjectReady, setIsObjectReady] = useState(false);
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [point, setPoint] = useState<ObjectMapPoint>();
  const [line, setLine] = useState<ObjectMapPipeLine>();

  // 파이프 맵 데이터 - activeCategory 해당하는 데이터만 리턴
  const { data: pointMapData } = getPointsQuery({ siteId, facility: activeCategory });
  const { data: pipeMapData } = getPipesQuery({ siteId, facility: activeCategory });

  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const point = new ObjectMapPoint(core);
      const line = new ObjectMapPipeLine(core);

      setMapInstance(core);
      setPoint(point);
      setLine(line);
      setIsObjectReady(true);
    }
  }, []);

  // 풀스크린 버튼 위치 변경
  useEffect(() => {
    const fullScreenBtn: any = document.getElementsByClassName('ol-full-screen')?.[0];

    if (fullScreenBtn) {
      fullScreenBtn.style.top = '20px';
      fullScreenBtn.style.right = '20px';
    }
  }, [isObjectReady]);

  // 포인트 그리기
  useEffect(() => {
    if (isObjectReady) {
      if (pointMapData) {
        // 시점,종점 아이콘 아님
        // 모든 측점 표현 - 관로는 텍스트, 맨홀,밸브등은 아이콘으로 표현됨
        point!.draw(pointMapData, activeCategory); // (+moveLayer?)
      }
      if (pointMapData?.length === 0) {
        console.log('측점 데이터가 없습니다.');
        mapInstance?.moveToInitPosition();
      }
    }
  }, [isObjectReady, point, pointMapData, mapInstance, activeCategory]);

  // 파이프 라인 그리기
  useEffect(() => {
    if (isObjectReady) {
      if (pipeMapData) {
        line!.draw(pipeMapData, activeCategory); // (+moveLayer?)
      }
    }
  }, [isObjectReady, line, pipeMapData, activeCategory]);

  //   // 뷰 처음 위치로 가기
  //   const goInitPosition = () => {
  //     //  mapInstance?.moveToInitPosition();
  //     let pipeLayerId = activeCategory;
  //     mapInstance?.moveToLayer(mapInstance?.getLayerFromName(pipeLayerId) as VectorLayer<VectorSource>, { padding: [130, 130, 130, 130], duration: 400 });
  //   };

  // 지도 전환
  const onChangeMapType = (type: 'map' | 'satellite') => {
    type === 'map' ? mapInstance?.mapChange(mapInstance!.osmMapSource) : mapInstance?.mapChange(mapInstance!.googleMapSource);
  };

  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'relative' }} id={'mapContainer'}>
        {/* 지도 타입 변경 버튼 */}
        <ChangeMapBtn onClick={onChangeMapType} style={{ left: 20, top: 20, right: 'unset' }} />

        {/* 초기 위치 버튼 */}
        {/* <Toolbar onClick={goInitPosition} /> */}
      </div>
    </>
  );
}

export default SurveyMap;
