import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { PopupStyle } from '../components/templates/_style';
import { useTranslation } from 'react-i18next';
import PopupFrame from 'components/templates/survey/popup/PopupFrame';
import styled from 'styled-components';

import DashboardCalendar from 'components/templates/DashboardCalendar';
import { DateObject } from 'react-multi-date-picker';

import ConfirmAlert from 'components/templates/ConfirmAlert';
import useMutateHook from 'hooks/RQuery/useMutateHook';
import { useQueryState } from 'hooks/RQuery/useQueryState';
import { getAxiosData } from 'service/api/common';
import query from 'hooks/RQuery/utils';
import queryKeys, { calendarKeys } from 'hooks/RQuery/keys/queryKeys';

export const PopupDateDelSt = {
  Wrap: styled.div``,
  ContWrap: styled.div`
    width: 584px;
    box-sizing: border-box;
    color: ${(p) => p.theme.delModalDate.color};
  `,
  Type: styled.div<{ $color: string }>`
    margin-bottom: 6px;
    padding: 11px 16px;
    background-color: ${(p) => p.theme.delModalDate.type.bg};
    border: 1px solid ${(p) => p.theme.delModalDate.border};
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 10px;
    > .colorTag {
      width: 12px;
      height: 12px;
      display: inline-block;
      border: 2px solid ${(p) => (p.$color ? p.$color : 'transparent')};
      border-radius: 2px;
      box-sizing: border-box;
      position: relative;
      /* 투명도 배경 */
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: ${(p) => (p.$color ? p.$color : 'transparent')};
        opacity: 0.15;
      }
    }
    > p {
      font-weight: 600;
      font-size: 16px;
    }
  `,
  Cont: styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
  `,
  CalendarArea: styled.div`
    flex: 1;
    min-height: 330px;
  `,
  ListArea: styled.div`
    flex: 1;
    height: 330px;
    display: flex;
    flex-direction: column;
    gap: 6px;

    > .selectedDate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid ${(p) => p.theme.delModalDate.border};
      background-color: ${(p) => p.theme.delModalDate.date.bg};
      box-sizing: border-box;
      border-radius: 2px;
      padding: 8px 16px;
      > p {
        font-weight: 500;
        font-size: 14px;
      }
      > .ico {
        width: 24px;
        height: 24px;
        background: url(${(p) => p.theme.delModalDate.date.ico}) no-repeat center;
      }
    }
    > .ptList {
      height: 283px;
      border: 1px solid ${(p) => p.theme.delModalDate.border};
      box-sizing: border-box;
      border-radius: 2px;
      > .title {
        height: 48px;
        padding: 0 16px;
        box-sizing: border-box;
        border-bottom: 1px solid ${(p) => p.theme.delModalDate.border};
        background: ${(p) => p.theme.delModalDate.list.header.bg};
        display: flex;
        align-items: center;
        justify-content: space-between;

        > p {
          font-weight: 600;
          font-size: 16px;
        }
        > .length {
          color: #0076ff;
          font-weight: 600;
          font-size: 14px;
        }
      }
      > ul {
        max-height: 233px;
        li {
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${(p) => p.theme.delModalDate.list.cont.bg};
          border-bottom: 1px solid ${(p) => p.theme.delModalDate.border};
          box-sizing: border-box;
          font-weight: 400;
          font-size: 14px;
          &:last-child {
            /* border-bottom: 0; */
          }
        }
      }
    }
  `,
  HelpArea: styled.div`
    margin-top: 11px;
    padding: 14px 18px;
    background: #0076ff14;
    border: 1px solid #0076ff;
    border-radius: 8px;
    .text {
      color: #0076ff;
      font-weight: 500;
      font-size: 14px;
    }
  `,
};

// 1) 활성화 된 탭에 맞게 쿼리요청 후 데이터를 리턴
const getActiveCalendarData = ({ activeCategoryTabKey, activeTabKey, siteId }) => {
  const isPipe = activeTabKey === 'pipes';

  const { data: workDay } = query.calendars.useSurveyPoints({ siteId: siteId, facilityKind: activeCategoryTabKey, isPipe: isPipe });
  const { data: workDayPipe } = query.calendars.useSurveyPipes({ siteId: siteId, facilityKind: activeCategoryTabKey, isPipe: isPipe });

  // const { data: workDayPipe = [] } = useCalendarQuery.useGetDateByFacilityPipe({ siteId, facilityKind: activeCategoryTabKey, options: { enabled: isPipe } });
  if (isPipe) {
    return {
      workDay: workDayPipe,
    };
  } else {
    return {
      workDay: workDay,
    };
  }
};

// 날짜 선택시, 해당 날짜의 포인트 또는 파이프 리스트
const getCurDateData = ({ activeCategoryTabKey, activeTabKey, siteId, date, workDate }) => {
  const isPipe = activeTabKey === 'pipes';

  let curDateData;
  // Point
  if (!isPipe) {
    const { data } = query.survey.points.useSurveyPointsByDate({ facility: activeCategoryTabKey, siteId, date, workDate });
    curDateData = data;
  }
  // Pipe
  if (isPipe) {
    const { data } = query.survey.pipes.useSurveyPipesByDate({ facility: activeCategoryTabKey, siteId, date, workDate });
    curDateData = data;
  }
  return {
    curDateData,
  };
};

// 날짜별 삭제 팝업
function PopupSurveyDateDel({
  onClose,
  categoryTabList,
  tabList,
  siteInfo,
}: {
  onClose: (key: string) => void;
  categoryTabList: { name: string; key: string; active: boolean }[];
  tabList: { name: string; key: string; active: boolean }[];
  siteInfo?: { id: string; name: string };
}) {
  const siteId = siteInfo?.id || '';
  const { t, i18n } = useTranslation();
  const [workDate, setWorkDate] = useState([]); // 캘린더에 데이터 유무 표시할 날짜들
  const [date, setDate] = useState<DateObject>(new DateObject()); // 선택한 날짜 curDate
  const [alertPop, setAlertPop] = useState({ isOpen: false, mainText: '', subText: '' });

  //공통 코드 쿼리
  const code = query.codes.useCodes(); // 코드 조회
  const facility_kinds = code?.data?.FACILITY; // 관 종류(쿼리요청)

  // 현재 활성화 된 [관종] 탭
  const activeCategoryTab = categoryTabList?.filter((i) => i.active)[0];
  const activeCategoryTabKey = categoryTabList?.filter((i) => i.active)[0]?.key;
  const selectedFacility = facility_kinds.find((ele) => ele.code === activeCategoryTab.key);
  // 현재 활성화된 [서브] 탭 (연결부, 관로 등)
  const activeTabKey = tabList?.filter((i) => i.active)[0]?.key; // pipes와 그외

  //[포인트/파이프] 캘린더 데이터 요청 (관종)
  const { workDay } = getActiveCalendarData({ activeCategoryTabKey, activeTabKey, siteId });

  //[포인트/파이프] 선택한 날짜의 데이터 리스트
  const { curDateData } = getCurDateData({ activeCategoryTabKey, activeTabKey, siteId, date: date.format('YYYY-MM-DD'), workDate });

  // [삭제] 측량점 리스트 (선택한 날짜) - 측점
  const { mutate: deletePointsDataByDate } = query.survey.points.useSurveyDeletePointsByDate({ params: { facility: activeCategoryTabKey, siteId, date: date.format('YYYY-MM-DD') } });

  // [삭제] 측량점 리스트 (선택한 날짜) - 파이프
  const { mutate: deletePipesDataByDate } = query.survey.pipes.useSurveyDeletePipesByDate({ params: { facility: activeCategoryTabKey, siteId, date: date.format('YYYY-MM-DD') } });

  // 시공일 리스트 호출 후 마지막 시공일 선택
  useEffect(() => {
    if (workDay) {
      setWorkDate(workDay);
      // 마지막 시공일 선택 - 배열 첫번째 항목
      // setDate(new DateObject(workDay?.[0]));
    }
  }, [workDay]);

  // 삭제 처리
  const deleteData = async () => {
    if (activeTabKey === 'pipes') {
      await deletePipesDataByDate(
        // { siteId, date: date.format('YYYY-MM-DD') },
        {},
        {
          onSettled(data, error, variables, context) {
            console.log(data, error);
            closeAlertPop(); //삭제 확인 팝업 - 닫기
          },
        },
      );
    } else {
      await deletePointsDataByDate(
        // { siteId, date: date.format('YYYY-MM-DD') },
        {},
        {
          onSettled(data, error, variables, context) {
            console.log(data, error);
            closeAlertPop(); //삭제 확인 팝업 - 닫기
          },
        },
      );
    }
  };
  // 현재 팝업 - 닫기
  const closePopup = () => {
    onClose('dateDel');
  };

  // 현재 팝업 - 삭제 버튼 클릭 이벤트
  const onClickDelete = () => {
    const isPipe = Object.keys(curDateData).find((i) => i === 'pipes');
    //확인 팝업 오픈
    const curDate = date.format('YYYY-MM-DD');
    const dataName = `${isPipe ? '관로' : '측점'} 데이터(${curDate})`; //TODO:  ko, en 버전
    const mainText = i18n.language === 'ko' ? `${dataName}${t('데이터삭제여부')}` : `${t('데이터삭제여부')}${dataName}`;
    setAlertPop((prev) => ({ ...prev, isOpen: true, mainText: mainText, subText: t('데이터복구불가') }));
  };

  // 삭제 확인 팝업 - 닫기
  const closeAlertPop = () => {
    setAlertPop((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      {/* 날짜별 삭제 팝업 */}
      <PopupFrame title={t('날짜별삭제')} close={closePopup} leftText="취소" rightText="삭제" leftFunc={closePopup} rightFunc={onClickDelete} disabled={!curDateData?.totalCount}>
        <PopupDateDelSt.ContWrap>
          <PopupDateDelSt.Type $color={selectedFacility?.color}>
            <span className="colorTag"></span>
            <p>{activeCategoryTab?.name}</p>
          </PopupDateDelSt.Type>
          <PopupDateDelSt.Cont>
            <PopupDateDelSt.CalendarArea>
              {/* 캘린더 */}
              <DashboardCalendar workDate={workDate} date={date} setDate={setDate} isSurvey={true} />
            </PopupDateDelSt.CalendarArea>
            <PopupDateDelSt.ListArea>
              <div className="selectedDate">
                <p>{date.format('YYYY/MM/DD')}</p>
                <span className="ico"></span>
              </div>
              <div className="ptList">
                <div className="title">
                  <p>측점명</p>
                  <span className="length">{`총 ${curDateData?.totalCount || '-'}개`}</span>
                </div>
                <ul className="cScroll">
                  {curDateData?.points?.length > 0 && curDateData?.points?.map((ele) => <li key={ele?.pointId}>{ele?.ptNum || '-'}</li>)}
                  {curDateData?.pipes?.length > 0 && curDateData?.pipes?.map((ele) => <li key={ele?.pipeId}>{ele?.endPtNum || '-'}</li>)}
                </ul>
              </div>
            </PopupDateDelSt.ListArea>
          </PopupDateDelSt.Cont>
          <PopupDateDelSt.HelpArea>
            <p className="text">
              ※ 선택된 날짜의 측점 또는 관로에 연결된 항목이 전부 삭제되며, 삭제된 항목은 복구되지 않습니다.
              <br /> 다음 항목을 삭제하시겠습니까?
            </p>
          </PopupDateDelSt.HelpArea>
        </PopupDateDelSt.ContWrap>
      </PopupFrame>

      {/* 삭제 확인 알림창  */}
      {alertPop.isOpen && <ConfirmAlert title={t('날짜별삭제')} close={closeAlertPop} mainText={alertPop.mainText} subText={alertPop.subText} leftText={t('취소')} rightText={t('삭제')} leftFunc={closeAlertPop} rightFunc={deleteData} />}
    </>
  );
}

export default PopupSurveyDateDel;
