import { deleteSurveyPipes, deleteSurveyPipesByDate, getSurveyPipesByDateQuery, getSurveyPipesQuery } from '../pipes';

// 측량성과 등록 - 관 종류별 쿼리 분리, 관로 테이블 조회

export function useSurveyPipes({ info }) {
  const { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum, facility } = info || {};

  return getSurveyPipesQuery({ facility: facility, siteId, pageSize, pageNum, sort, startDate, endDate, ptNum });
}

//파이프 선택삭제
export function useSurveyDeletePipes({ params }) {
  const { pipeIds, siteId, facility } = params || {};
  return deleteSurveyPipes({ siteId, facility, pipeIds });
}

//파이프 날짜삭제
export function useSurveyDeletePipesByDate({ params }) {
  const { date, siteId, facility } = params || {};
  return deleteSurveyPipesByDate({ siteId, facility, date });
}

// 파이프 날짜별 조회
export function useSurveyPipesByDate({ facility, siteId, date, workDate }) {
  return getSurveyPipesByDateQuery({ facility, siteId, date, workDate });
}
