import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationVn from './vn.json';
import translationKo from './ko.json';
import translationEn from './en.json';
import translationId from './id.json';

const resource = {
  vn: {
    translation: translationVn,
  },
  ko: {
    translation: translationKo,
  },
  en: {
    translation: translationEn,
  },
  id: {
    translation: translationId,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lng: 'ko',
    fallbackLng: 'ko',
    // ns: ['translation'],
    // defaultNS: "translation",
    debug: false, // console.log 숨김
    keySeparator: '.', // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
