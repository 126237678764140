import React, { useEffect, useState } from 'react';
import { LoginStyle } from './_style';
import { qsData, request } from '../service/api/common';
import useParamChange from '../hooks/useParamChange';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { siteListState } from 'store/Atoms';
import { SelectProps } from './_interface';
import query from '../hooks/RQuery/utils';
import Table from '../components/atoms/Table';
import { SearchFilterStyle, TableStyle } from '../components/atoms/_style';
import { DashboardStyle, DataFilterStyle, TableListStyle } from '../components/templates/_style';
import Input from '../components/atoms/Input';
import IcoInputSearch from '../assets/images/ico-input-search.svg';
import { useTranslation } from 'react-i18next';

const lang = navigator.language;
const Login = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [param, setParam] = useState({
    id: '',
    password: '',
  });
  const [step, setStep] = useState(1);
  const [siteList, setSiteList] = useRecoilState(siteListState);
  const [word, setWord] = useState('');
  const [type, setType] = useState(false);
  const [saveToken, setSaveToken] = useState('');
  const [onParamChange] = useParamChange(param, setParam);
  const { mutate: mutateLogin } = query.auths.useLogin(param);

  const onLogin = async () => {
    await mutateLogin(param, {
      onSuccess: ({ accessToken }: any) => {
        request('auth')
          .get('v1/member', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: accessToken,
            },
          })
          .then(({ data }) => {
            if (data.data.country === 'VN') {
              i18n.changeLanguage('vn');
            } else if (data.data.country === 'ID') {
              i18n.changeLanguage('id');
            } else if (data.data.country === 'US') {
              i18n.changeLanguage('en');
            }

            sessionStorage.setItem('auth', data.data.auth);
            sessionStorage.setItem('loginId', data.data.loginId);

            if (data.data.auth !== 'ROLE_MASTER') {
              sessionStorage.setItem('solution', data.data.solutions);
              setSaveToken(accessToken);

              if (data.data.siteInfo.length) {
                const newArr: SelectProps[] = [];
                data.data.siteInfo.map((i, index) => {
                  newArr.push({ text: i.siteName, value: i.siteId, active: index === 0, facilityKinds: i.facilityKinds, ownerCompany: i.ownerCompany, projection: i.projection });
                });

                sessionStorage.setItem('siteId', String(newArr[0].value));
                sessionStorage.setItem('projection', String(newArr[0].projection));
                setSiteList(newArr);
                setStep(2);
              } else {
                sessionStorage.setItem('token', accessToken);
                //  window.location.href = '/workplace/register';
              }
            } else {
              window.location.href = '/auth/list';
              sessionStorage.setItem('token', accessToken);
            }
          });
      },
      onError: (e) => {
        console.log(e);
        setError(true);
      },
    });
  };

  //사업장 변경
  const onChangeSite = (id) => {
    setSiteList((prev) => {
      const newArr: SelectProps[] = [];
      prev.map((i) => {
        newArr.push({ ...i, active: id === i.value });
      });

      sessionStorage.setItem('siteId', String(newArr.find((i) => i.value === id)?.value));
      sessionStorage.setItem('projection', String(newArr.find((i) => i.value === id)?.projection));
      return newArr;
    });
  };

  //현장 선택 후 대시보드 이동
  const onMoveDashboard = () => {
    sessionStorage.setItem('token', saveToken);
    window.location.href = '/dashboard';
  };

  const onTypeToggle = () => {
    setType(!type);
  };

  //쿼리스트링으로 해당 정보들이 있을때 자동으로 로그인 및 페이지 이동
  useEffect(() => {
    if (qsData().token) {
      sessionStorage.setItem('token', qsData().token);
      request('auth')
        .get('v1/member', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: qsData().token,
          },
        })
        .then(({ data }) => {
          sessionStorage.setItem('auth', data.data.auth);
          sessionStorage.setItem('solution', data.data.solutions);
          sessionStorage.setItem('siteId', data.data.siteInfo[0].siteId);
          sessionStorage.setItem('projection', data.data.siteInfo[0].projection);
          sessionStorage.setItem('loginId', data.data.loginId);
        });
    }
  }, []);

  return (
    <LoginStyle.Wrap>
      {/* 로그인 */}
      {step === 1 ? (
        <>
          <LoginStyle.Box>
            <LoginStyle.Logo></LoginStyle.Logo>
            {lang.slice(0, 2) !== 'vi' ? <LoginStyle.Title>Digital Twin X 2.0</LoginStyle.Title> : null}
            <LoginStyle.InputWrap className={error ? 'id error' : 'id'}>
              <LoginStyle.Input type={'text'} id={'id'} placeholder={'ID를 입력해주세요'} name={'id'} onChange={onParamChange} />
            </LoginStyle.InputWrap>
            <LoginStyle.InputWrap className={error ? 'password error' : 'password'}>
              <LoginStyle.Input
                type={type ? 'text' : 'password'}
                id={'password'}
                placeholder={'Password'}
                name={'password'}
                onChange={onParamChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    onLogin();
                  }
                }}
              />
              <LoginStyle.ToggleTypeBtn type={'button'} onClick={onTypeToggle}>
                {type ? 'Hide' : 'Show'}
              </LoginStyle.ToggleTypeBtn>
            </LoginStyle.InputWrap>
            <LoginStyle.Msg className={error ? 'error' : undefined}>ID 또는 비밀번호를 다시 확인하세요.</LoginStyle.Msg>
            <LoginStyle.Button type={'button'} onClick={onLogin}>
              Log in
            </LoginStyle.Button>
          </LoginStyle.Box>
          <LoginStyle.CopyRight>
            Copyright 2024. <b>Movements</b> All rights reserved.
          </LoginStyle.CopyRight>
        </>
      ) : null}

      {/*  사업장 선택*/}
      {step === 2 ? (
        <>
          <LoginStyle.SiteTitle>{t('사업장을선택해주세요')}</LoginStyle.SiteTitle>
          <Input
            width={512}
            placeholder={t('사업장,발주처조회')}
            bg={IcoInputSearch}
            onChange={(e) => {
              setWord(e.target.value);
            }}
            isLogin={true}
          />

          <DashboardStyle.DescArea isLogin={true} style={{ zIndex: 1, flex: 'none', marginTop: 8 }}>
            <TableStyle.Wrap>
              <TableListStyle.Cont>
                {siteList.length ? (
                  <Table
                    type={2}
                    width={512}
                    height={'323px'}
                    tbodyHeight={252}
                    tbodyId={'tbody'}
                    colgroup={['50%', 'auto']}
                    isLogin={true}
                    thead={
                      <tr>
                        <th scope={'col'}>{t('사업장')}</th>
                        <th scope={'col'}>{t('발주처')}</th>
                      </tr>
                    }
                    tbody={
                      <>
                        {siteList.filter((i) => i.text.includes(word) || i.ownerCompany?.includes(word)).length ? (
                          siteList
                            .filter((i) => i.text.includes(word) || i.ownerCompany?.includes(word))
                            .map((i) => {
                              return (
                                <tr className={i.active ? 'active' : undefined} key={i.value} onClick={() => onChangeSite(i.value)}>
                                  <td dangerouslySetInnerHTML={{ __html: i.text.replace('.', '<br>') }}></td>
                                  <td>{i.ownerCompany}</td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td rowSpan={2} style={{ height: '100%', color: '#0076FF', fontSize: 14, background: 'none', padding: '30px 0', borderBottom: 0, fontWeight: 500, fontFamily: 'Pretendard' }}>
                              일치하는 사업장이 없습니다.
                            </td>
                          </tr>
                        )}
                      </>
                    }
                  />
                ) : null}
              </TableListStyle.Cont>
            </TableStyle.Wrap>
          </DashboardStyle.DescArea>

          <LoginStyle.Hr />
          <LoginStyle.Button type={'button'} onClick={onMoveDashboard} style={{ zIndex: 1, width: 512, margin: 0 }}>
            {t('사업장선택')}
          </LoginStyle.Button>
        </>
      ) : null}
    </LoginStyle.Wrap>
  );
};

export default Login;
