import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ConfirmProps } from './_interface';
import { ConfirmStyle } from './_style';
import { escKeyModalClose } from '../../util/global';
import { useTranslation } from 'react-i18next';

const Confirm = ({ desc, onClickCancel, onClickSubmit }: ConfirmProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('keydown', (e) => escKeyModalClose(e, () => onClickCancel()));
    return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, () => onClickCancel()));
  }, []);
  return (
    <>
      <ConfirmStyle.Dimmed></ConfirmStyle.Dimmed>
      <ConfirmStyle.Confirm>
        <ConfirmStyle.Cont>{desc}</ConfirmStyle.Cont>
        <ConfirmStyle.Foot>
          <ConfirmStyle.Button type={'button'} onClick={onClickCancel}>
            {t('아니요')}
          </ConfirmStyle.Button>
          <ConfirmStyle.Button type={'button'} onClick={onClickSubmit}>
            {t('예')}
          </ConfirmStyle.Button>
        </ConfirmStyle.Foot>
      </ConfirmStyle.Confirm>
    </>
  );
};

export default Confirm;
