import React, { useEffect, useRef, useState } from 'react';
import PagingTable from '../../atoms/PagingTable';
import { FilterProps } from '../_interface';
import axios from 'axios';
import NoData from '../../atoms/NoData';
import Checkbox from '../../atoms/Checkbox';
import Button from '../../atoms/Button';
import { cellStatus, FILTERLIST } from '../../../util/global';
import { SurveyStyle } from '../_style';
import Select from '../../atoms/Select';
import { CheckboxProps } from '../../atoms/_interface';
import SurveyImageTemp from './SurveyImageTemp';
import SurveyUpload from './SurveyUpload';
import { useSetRecoilState } from 'recoil';
import { tableListPipe, tableListPoint } from '../../../store/surveyAtoms';
import query from 'hooks/RQuery/utils';
import PopupFilter from './popup/PopupFilter';
import { useTranslation } from 'react-i18next';

const SurveyPipeTable = ({ onColumnToggle, activeCategory, activeTab, date, searchTrigger, ptNum, onPopupToggle, siteInfo, firstCall, setFirstCall }) => {
  const siteId = siteInfo.id;
  const { t, i18n } = useTranslation();
  const [filterWidth, setFilterWidth] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const setTableListPipe = useSetRecoilState(tableListPipe);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNoData, setIsNoData] = useState(true);
  const [pageSize, setPageSize] = useState([
    { text: '10', value: 10, active: true },
    { text: '15', value: 15, active: false },
    { text: '20', value: 20, active: false },
  ]);
  const [tableData, setTableData] = useState<any>([]);
  const [sort, setSort] = useState('DESC');

  //관로 컬럼리스트
  const [pipeFilter, setPipeFilter] = useState<FilterProps[]>(FILTERLIST.PIPES);

  //체크박스 배열
  const [checkList, setCheckList] = useState<CheckboxProps[]>([]);

  const [checkAll, setCheckAll] = useState(false);

  //공통코드
  const code = query.codes.useCodes();

  //파이프 리스트
  let params = { siteId, pageSize: pageSize.filter((i) => i.active)[0].value, pageNum: currentPage, sort, startDate: date.from?.format('YYYY-MM-DD'), endDate: date.to?.format('YYYY-MM-DD'), ptNum };
  const pipeList = query.survey.pipes.useSurveyPipes({ info: { ...params, facility: activeCategory } });

  // 체크리스트 갱신마다 recoil 저장
  useEffect(() => {
    if (checkList.length > 0) {
      const newObj: any = {
        points: [],
        pipes: [],
      };
      const checkedRowList = checkList.filter(({ checked }) => checked);
      checkedRowList.map((i) => {
        newObj.pipes.push({
          ptNum: tableData.filter(({ id }) => id === i.id)[0].endCoordinate.ptNum,
          pipeId: tableData.filter(({ id }) => id === i.id)[0].id,
          stCoordinate: tableData.filter(({ id }) => id === i.id)[0].stCoordinate,
          endCoordinate: tableData.filter(({ id }) => id === i.id)[0].endCoordinate,
        });
      });
      setTableListPipe({
        checked: [...checkList.filter((ele) => ele.checked)?.map((ele) => ele?.id)],
        unChecked: [...checkList.filter((ele) => !ele.checked)?.map((ele) => ele?.id)],
        checkedPipeData: newObj,
      });
    }
  }, [checkList]);

  //테이블 데이터 호출
  useEffect(() => {
    if (pipeList?.data) {
      const newArr: CheckboxProps[] = [];
      setTableData(pipeList?.data?.list);
      pipeList?.data?.list.map((i) => {
        newArr.push({ id: i.id, checked: false });
      });
      setCheckList(newArr);
      setTotalPages(pipeList?.data?.page?.totalPages);
    }
  }, [pipeList?.data]);

  // 에러처리
  useEffect(() => {
    if (pipeList?.error) {
      setTableData([]);
    }
  }, [pipeList?.error]);

  //체크박스 클릭시 체크리스트 갱신
  const onCheck = (id) => {
    const newArr = [...checkList];
    newArr.filter((i) => i.id === id)[0].checked = !newArr.filter((i) => i.id === id)[0].checked;
    setCheckList(newArr);
  };

  //th 체크박스 체크이벤트
  const onCheckAll = () => {
    const newArr = [...checkList];
    //테이블 length와 체크된 length가 같을 때 전체 해제
    if (newArr.length === newArr.filter((i) => i.checked).length) {
      newArr.map((i) => {
        i.checked = false;
      });
      setCheckList(newArr);

      //테이블 length와 체크된 length가 다를 때 전체 선택
    } else {
      newArr.map((i) => {
        i.checked = true;
      });
      setCheckList(newArr);
    }
  };

  //select에서 pageSize변경시 액션
  useEffect(() => {
    //현재 페이지가 1이 아닐땐 1페이지로 이동
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [pageSize, activeTab, sort, searchTrigger]);

  useEffect(() => {
    pipeList.refetch();
  }, [searchTrigger]);

  //페이지 내 체크박스 전부 체크됐을때 th 체크박스 체크
  useEffect(() => {
    if (checkList.length) {
      checkList.length === checkList.filter((i) => i.checked).length ? setCheckAll(true) : setCheckAll(false);
    }
  }, [checkList.filter((i) => i.checked).length]);

  //최초 전체측점 데이터 있을 시 테이블 표시, 없을 시 업로드화면 표시
  useEffect(() => {
    if (firstCall) {
      setIsNoData(true);
    } else {
      setIsNoData(false);
    }
  }, [firstCall]);

  //리사이즈 이벤트
  useEffect(() => {
    const func = () => {
      setFilterWidth((document.getElementById('filter') as any).clientWidth);
      console.log(filterWidth);
    };

    window.addEventListener('resize', func);
    return () => window.removeEventListener('resize', func);
  });

  useEffect(() => {
    if (!isNoData) {
      setFilterWidth((document.getElementById('filter') as any).clientWidth);
    }
  }, [isNoData]);
  return (
    <>
      {isNoData ? (
        <SurveyUpload siteInfo={siteInfo} setFirstCall={setFirstCall} />
      ) : (
        <>
          <SurveyStyle.FilterArea id={'filter'}>
            <SurveyStyle.FilterWrap>
              {/* 관로 제외 포인트정보 보이기/숨기기 버튼들 */}
              {pipeFilter.map((i, index) => {
                if (filterWidth > 1525) {
                  if (index < pipeFilter.length - (i18n.language === 'vn' ? 6 : 0)) {
                    return (
                      <SurveyStyle.FilterItem key={index} className={i.active ? 'active filterItem' : 'filterItem'} onClick={() => onColumnToggle(pipeFilter, setPipeFilter, i.key)}>
                        {t(i.name)}
                      </SurveyStyle.FilterItem>
                    );
                  }
                } else if (filterWidth > 1440) {
                  if (index < pipeFilter.length - (i18n.language === 'vn' ? 6 : 1)) {
                    return (
                      <SurveyStyle.FilterItem key={index} className={i.active ? 'active filterItem' : 'filterItem'} onClick={() => onColumnToggle(pipeFilter, setPipeFilter, i.key)}>
                        {t(i.name)}
                      </SurveyStyle.FilterItem>
                    );
                  }
                } else if (filterWidth > 1330) {
                  if (index < pipeFilter.length - (i18n.language === 'vn' ? 7 : 2)) {
                    return (
                      <SurveyStyle.FilterItem key={index} className={i.active ? 'active filterItem' : 'filterItem'} onClick={() => onColumnToggle(pipeFilter, setPipeFilter, i.key)}>
                        {t(i.name)}
                      </SurveyStyle.FilterItem>
                    );
                  }
                } else {
                  if (index < pipeFilter.length - (i18n.language === 'vn' ? 8 : 3)) {
                    return (
                      <SurveyStyle.FilterItem key={index} className={i.active ? 'active filterItem' : 'filterItem'} onClick={() => onColumnToggle(pipeFilter, setPipeFilter, i.key)}>
                        {t(i.name)}
                      </SurveyStyle.FilterItem>
                    );
                  }
                }
              })}
              {!(filterWidth > 1525) || i18n.language === 'vn' ? <PopupFilter onColumnToggle={onColumnToggle} filter={pipeFilter} setFilter={setPipeFilter} isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} /> : null}
            </SurveyStyle.FilterWrap>
            <Select
              option={pageSize}
              width={76}
              label={t('표시갯수')}
              onChange={(e) => {
                const newArr = [...pageSize];
                newArr.map((i) => {
                  i.active = Number((e.target as HTMLLIElement).ariaValueText) === i.value;
                });
                setPageSize(newArr);
              }}
            />
          </SurveyStyle.FilterArea>

          <div style={{ padding: '0 24px', width: 'calc(100% - 48px)', height: 'calc(100% - 102px)', overflow: 'auto' }}>
            {tableData?.length ? (
              <PagingTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                width={2800 - pipeFilter.filter(({ active }) => !active).length * 120}
                caption={'측량정보 테이블'}
                height={'calc(100% - 100px)'}
                thead={
                  <>
                    <tr>
                      <th scope={'col'} rowSpan={2} style={{ width: 60, position: 'sticky', left: 0, zIndex: 21 }}>
                        <Checkbox dark={true} size={18} id={'checkAll'} checked={checkAll} onChange={onCheckAll} />
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ width: 150, position: 'sticky', left: 60, zIndex: 21 }}>
                        <button
                          type={'button'}
                          className={sort === 'ASC' ? 'asc' : 'desc'}
                          onClick={() => {
                            if (sort === 'DESC') {
                              setSort('ASC');
                            } else {
                              setSort('DESC');
                            }
                          }}
                        >
                          {t('관로명')}
                        </button>
                      </th>

                      <th
                        scope={'col'}
                        colSpan={pipeFilter.filter((i) => i.key === 'x' || i.key === 'y' || i.key === 'z').filter((i) => i.active).length}
                        style={{
                          height: 40,
                          display: pipeFilter.filter((i) => i.key === 'x' || i.key === 'y' || i.key === 'z').filter((i) => i.active).length ? 'table-cell' : 'none',
                        }}
                      >
                        {t('시점')}
                      </th>
                      <th
                        scope={'col'}
                        colSpan={pipeFilter.filter((i) => i.key === 'x' || i.key === 'y' || i.key === 'z').filter((i) => i.active).length}
                        style={{
                          height: 40,
                          display: pipeFilter.filter((i) => i.key === 'x' || i.key === 'y' || i.key === 'z').filter((i) => i.active).length ? 'table-cell' : 'none',
                        }}
                        className={'rightBorderNone'}
                      >
                        {t('종점')}
                      </th>
                      <th scope={'col'} rowSpan={2} className={'leftBorder'} style={{ display: cellStatus(pipeFilter, 'avgDepth') }}>
                        {t('평균심도')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'diameter') }}>
                        {t('관경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'distance') }}>
                        {t('연장')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'mop') }}>
                        {t('재질')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'pip') }}>
                        {t('구분')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'stNearImg') }}>
                        {t('시점근경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'stDistantImg') }}>
                        {t('시점원경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'stElNearImg') }}>
                        {t('시점지반고근경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'stElDistantImg') }}>
                        {t('시점지반고원경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'endNearImg') }}>
                        {t('종점근경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'endDistantImg') }}>
                        {t('종점원경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'endElNearImg') }}>
                        {t('종점지반고근경')}
                      </th>
                      <th scope={'col'} rowSpan={2} style={{ display: cellStatus(pipeFilter, 'endElDistantImg') }}>
                        {t('종점지반고원경')}
                      </th>
                      <th scope={'col'} rowSpan={2}>
                        {t('설치년도')}
                      </th>
                    </tr>
                    <tr>
                      <th scope={'col'} style={{ height: 40, borderLeft: 0, display: cellStatus(pipeFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ height: 40, display: cellStatus(pipeFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ height: 40, display: cellStatus(pipeFilter, 'z') }}>
                        Z
                      </th>
                      <th scope={'col'} style={{ height: 40, display: cellStatus(pipeFilter, 'x') }}>
                        X
                      </th>
                      <th scope={'col'} style={{ height: 40, display: cellStatus(pipeFilter, 'y') }}>
                        Y
                      </th>
                      <th scope={'col'} style={{ height: 40, borderRight: 0, display: cellStatus(pipeFilter, 'z') }}>
                        Z
                      </th>
                    </tr>
                  </>
                }
                tbody={
                  <>
                    {tableData?.map((i, index) => {
                      return (
                        <tr key={i.id + '-' + index} onClick={() => onCheck(i.id)}>
                          <td style={{ width: 60, position: 'sticky', left: 0, zIndex: 20 }}>
                            <Checkbox dark={true} size={18} id={i.id} checked={checkList.filter(({ id }) => id === i.id)[0]?.checked} onChange={() => onCheck(i.id)} />
                          </td>
                          <td style={{ width: 150, position: 'sticky', left: 60, zIndex: 20 }}>{i.endCoordinate?.ptNum}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'x') }}>{Number(i.stCoordinate?.x)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'y') }}>{Number(i.stCoordinate?.y)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'z') }}>{Number(i.stCoordinate?.z)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'x') }}>{Number(i.endCoordinate?.x)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'y') }}>{Number(i.endCoordinate?.y)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'z') }}>{Number(i.endCoordinate?.z)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'avgDepth') }}>{Number(i.avgDepth)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'diameter') }}>{i.diameter ? i.diameter : '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'distance') }}>{Number(i.distance)?.toFixed(3) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'mop') }}>{t(code.data?.MOP.filter(({ code }) => code === i.mopCde)[0]?.value) || '-'}</td>
                          <td style={{ display: cellStatus(pipeFilter, 'pip') }}>{t(code.data?.PIP.filter(({ code }) => code === i.pipCde)[0]?.value) || '-'}</td>

                          <td style={{ display: cellStatus(pipeFilter, 'stNearImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.stImages.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.stCoordinate?.ptNum}
                              id={i.stCoordinate?.pointId}
                              typeNum={1}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>
                          <td style={{ display: cellStatus(pipeFilter, 'stDistantImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.stImages.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.stCoordinate?.ptNum}
                              id={i.stCoordinate?.pointId}
                              typeNum={2}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>

                          <td style={{ display: cellStatus(pipeFilter, 'stElNearImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.stImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.stCoordinate?.ptNum}
                              id={i.stCoordinate?.pointId}
                              typeNum={3}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>
                          <td style={{ display: cellStatus(pipeFilter, 'stElDistantImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.stImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.stCoordinate?.ptNum}
                              id={i.stCoordinate?.pointId}
                              typeNum={4}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>

                          <td style={{ display: cellStatus(pipeFilter, 'endNearImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.endImages.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.endCoordinate?.ptNum}
                              id={i.endCoordinate?.pointId}
                              typeNum={5}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>
                          <td style={{ display: cellStatus(pipeFilter, 'endDistantImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.endImages.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.endCoordinate?.ptNum}
                              id={i.endCoordinate?.pointId}
                              typeNum={6}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>

                          <td style={{ display: cellStatus(pipeFilter, 'endElNearImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.endImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.endCoordinate?.ptNum}
                              id={i.endCoordinate?.pointId}
                              typeNum={7}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>
                          <td style={{ display: cellStatus(pipeFilter, 'endElDistantImg') }}>
                            <SurveyImageTemp
                              activeCategory={activeCategory}
                              siteInfo={siteInfo}
                              src={i.endImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageThumbUrl || ''}
                              ptNum={i.endCoordinate?.ptNum}
                              id={i.endCoordinate?.pointId}
                              typeNum={8}
                              refetchApi={pipeList}
                              item={i}
                              isPipe={true}
                            />
                          </td>

                          <td>{i.ymd}</td>
                        </tr>
                      );
                    })}
                  </>
                }
                leftBtnGroup={
                  <>
                    {checkList.filter(({ checked }) => checked).length ? <Button width={80} height={36} fontSize={14} colorType={'greyLineToRed'} text={'선택삭제'} onClick={() => onPopupToggle('pipeSelectDel')} /> : null}
                    <Button width={80} height={36} fontSize={14} colorType={'greyLineToRed'} text={t('날짜별삭제')} onClick={() => onPopupToggle('dateDel')} />
                  </>
                }
              />
            ) : (
              <NoData />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SurveyPipeTable;
