import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImgPopupSt } from './2dMap/_style';

// 취소, 삭제 버튼 확인 알림창
function ConfirmAlert({
  title,
  mainText,
  subText,
  leftText,
  rightText,
  leftFunc,
  rightFunc,
  close,
}: {
  title: string;
  mainText?: string;
  subText?: string;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  close?: () => void;
}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <ImgPopupSt.Dimmed></ImgPopupSt.Dimmed>
      <ImgPopupSt.Wrap $width={480}>
        <ImgPopupSt.Header>
          <p>{title}</p>
          <button className="closeBtn" onClick={close}></button>
        </ImgPopupSt.Header>
        <ImgPopupSt.ContDelete>
          <p className="mainText">{mainText}</p>
          <p className="subText">
            <span className="ico"></span>
            {subText}
          </p>
        </ImgPopupSt.ContDelete>
        <ImgPopupSt.BtnWrapDelete>
          <button onClick={leftFunc}>{leftText}</button>
          <button onClick={rightFunc}>{rightText}</button>
        </ImgPopupSt.BtnWrapDelete>
      </ImgPopupSt.Wrap>
    </>
  );
}

export default ConfirmAlert;
