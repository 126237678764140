import OlCore from '../OlCore';
//
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Select from 'ol/interaction/Select.js';
import { Cluster } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { Collection, Feature, Overlay } from 'ol';
// module
import PointModule from '../Module/PointModule';
// ico
import IcoValve from 'assets/images/ico-map-valve.svg';
import IcoHydrant from 'assets/images/ico-map-hydrant.svg';
import IcoManhole from 'assets/images/ico-map-manhole.svg';
import IcoValveA from 'assets/images/ico-map-valve-active.svg';
import IcoHydrantA from 'assets/images/ico-map-hydrant-active.svg';
import IcoManholeA from 'assets/images/ico-map-manhole-active.svg';
import IcoLocation from 'assets/images/2dMap/ico-location.svg';
import IcoXY from 'assets/images/ico-xy.svg';
import { click, shiftKeyOnly, singleClick } from 'ol/events/condition';
import ImageStyle from 'ol/style/Image';
import { Point } from 'ol/geom';
import getFacilityColor from '../style/getFacilityColor';
import { Extent, boundingExtent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import { getValueOfKeyAsc } from 'util/global';
import { unByKey } from 'ol/Observable';
import { Draw, Snap } from 'ol/interaction';
import IcoPin from '../../../assets/images/smartPin/ico-pin.svg';
import IcoPinA from '../../../assets/images/smartPin/ico-pin-active.svg';

class ObjectMapPoint extends PointModule {
  private initLayerId: string = ''; // 기준점이 되는 레이어
  private wholeExtent: Extent = [0, 0, 0, 0]; // 전체 측점을 포함하는 영역
  public lastPtData: any = {}; // 마지막 시공 pt 데이터

  private xyLayer: VectorLayer<VectorSource>; // xy좌표 이동 레이어

  public pinLayer: VectorLayer<VectorSource>;
  public pointsLayers: VectorLayer<VectorSource>[] = []; // 모든 포인트 레이어 리스트

  private multiClickPit!: Select;
  private multiClickPip!: Select;

  private multiClick!: Select;
  public selectClickPt!: Select;

  private keyDownEvents: any[] = [];
  private keyDownEvent: any = null;
  private keyPressEvent: any = null;
  private pointCursorEvent: any;

  private snapInteractions: Snap[] = [];
  private drawInteractions: Draw[] = [];

  private multiCallback: any;

  // 포인트 종류 리스트
  private pointCodeList = [
    { code: 'PIT001', value: '관로' },
    { code: 'PIT002', value: '분기' },
    { code: 'PIT003', value: '편락관' },
    { code: 'PIT004', value: '관말' },
    { code: 'PIT005', value: '곡관' },
    { code: 'PIT006', value: '밸브' },
    { code: 'PIT007', value: '맨홀' },
    { code: 'PIT008', value: '소화전' },
    { code: 'PIT999', value: '기타' },
  ];

  public pointsTypeList = [
    //  { value: '상수', code: 'WATER', show: true },
    //  { value: '오수', code: 'WASTE', show: true },
    //  { value: '우수', code: 'RAIN', show: true },
    //  { value: '가스', code: 'GAS', show: true },
    //  { value: '통신', code: 'NETWORK', show: true },
    //  { value: '전기', code: 'ELECTRIC', show: true },
    //  { value: '송유', code: 'OIL', show: true },
    //  { value: '난방', code: 'HEAT', show: true },
  ];

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    const facilitys = JSON.parse(sessionStorage.getItem('facilitys') || '');
    this.pointsTypeList = facilitys?.map((ele) => ({ ...ele, show: true }));

    // 좌표이동 표시 레이어
    this.xyLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'xyLayer',
      },
      visible: false,
      zIndex: 5,
    });
    // // 포인트 레이어
    // this.pointLayer = new VectorLayer<VectorSource>({
    //   properties: {
    //     id: 'pointLayer',
    //   },
    //   // minZoom: 18,
    //   visible: true,
    //   zIndex: 4,
    // });

    // 포인트 종류 리스트 맵핑
    this.pointsTypeList.map((ele: any) => {
      // 레이어 생성
      let layer = new VectorLayer<VectorSource>({
        properties: {
          id: ele.code + 'Point',
          value: ele.value,
        },
        visible: ele.show,
        zIndex: 4,
      });
      this.pointsLayers.push(layer);
    });

    this.pinLayer = new VectorLayer({
      properties: {
        id: 'PINPoint',
      },
      visible: true,
      zIndex: 4,
      style: () => {
        return [
          new Style({
            image: new Icon({
              src: IcoPin,
              scale: 0.5,
            }),
          }),
        ];
      },
    });
    this.setLayers([...this.pointsLayers, this.xyLayer, this.pinLayer]);
  }

  /* ------------------ DRAWING ------------------ */

  // 전체 데이터 그리기(포인트 레이어) - 상수,오수,..
  public drawFacilityAll(datas: any) {
    let keys = Object.keys(datas); // facility 배열

    // 초기화
    this.pointsLayers?.map((layer) => {
      layer.getSource()?.clear();
    });

    //facilityType별 레이어에 source 각각 추가
    keys.map((key, idx) => {
      let facility = key;

      // 피쳐 배열 생성
      const features = this.createPointFeatures(datas[key]);
      // 피쳐 벡터 소스
      const source = this.createSource(features);

      // 해당 facility 포인트 레이어
      let targetLayer = this.pointsLayers.find((layer) => layer.get('id') === `${facility}Point`);

      targetLayer?.setSource(source); // 레이어에 벡터 소스 추가
      targetLayer?.setStyle((feature, resolution) => this.onPointStyle(feature)); // 레이어에 스타일 부여

      // 일치하는 [레이어]가 있는 경우 &
      // [데이터]가 있는 경우 해당 layer id 저장 (데이터 있는 마지막 레이어)
      if (targetLayer && datas?.[key]?.length > 0) {
        this.initLayerId = `${facility}Point`;
      }
    });

    // * 뷰 이동 (현장 위치)
    try {
      if (datas?.['ALL']?.length) {
        this.wholeExtent = this.getBoundingExtent(datas?.['ALL']); // 모든 포인트 포함
      } else {
        this.wholeExtent = [0, 0, 0, 0]; // 포인트 데이터 없을때
      }
      this.goToWholeExtent(); // 위치 이동
    } catch (e) {
      console.log(e);
    }

    // 마지막 측점 데이터 저장
    let results = getValueOfKeyAsc(datas?.['ALL'], 'ptNum'); // ptNum 오름차순으로 데이터 정렬
    this.lastPtData = results?.[datas?.['ALL']?.length - 1];
  }

  // 포인트 그리기 - 개별 레이어
  public draw(data: any, category: string, isSurvey = false) {
    //  console.log(data);

    const features = this.createPointFeatures(data);
    // 피쳐 벡터 소스
    const source = this.createSource(features);

    let layerId = `${category}Point`;
    let targetLayer = this.pointsLayers.find((layer) => layer.get('id') === layerId);
    targetLayer?.setSource(source);
    targetLayer?.setStyle((feature, resolution) => this.onPointStyle(feature));

    // 레이어로 이동
    this.moveLayer(layerId, { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
  }

  public drawPin(datas) {
    const features: Feature[] = [];
    datas.map((i) => {
      features.push(
        new Feature({
          geometry: new Point([i.xyCoordinate.y, i.xyCoordinate.x]),
          properties: { ...i, pitCde: 'pin' },
        }),
      );
    });
    const source = this.createSource(features);
    this.pinLayer.setSource(source);
  }

  /* ------------------ EVENT ------------------ */

  // 데이터를 가지고 coord 배열 만들기 &
  // boundingExtent 리턴
  public getBoundingExtent(datas) {
    // 전체 포인트 포함하는 영역 view
    let coords: Coordinate[] = [
      //   [0, 0],
      //   [0, 0],
    ];

    datas?.map((item) => {
      let geometry = [item.y, item.x]; //측점 데이터 좌표들
      coords.push(geometry);
    });

    // 모든 좌표를 Coords에 담은 후, boundingExtent 구하기
    if (coords?.length === datas?.length) {
      return boundingExtent(coords);
    } else {
      return [];
    }
  }

  // 특정 extent로 이동
  public goToWholeExtent() {
    if (this.wholeExtent[0]) {
      this.moveExtent(this.wholeExtent, { duration: 0, maxZoom: 20, padding: [100, 100, 100, 100] });
    } else {
      this.core.moveToInitPosition(); // 대한민국 전체 뷰
      console.log(this.wholeExtent[0], 'wholeExtent가 없습니다.');
    }
  }

  // 특정 레이어로 이동
  public goToLayer() {
    console.log(this.initLayerId);

    if (this.initLayerId) {
      this.moveLayer(this.initLayerId, { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
    } else {
      // this.moveLayer(this.initLayerId, { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
    }
  }

  // 포인트 클릭 이벤트(Select) VMF
  public addClickPoint(callback?: any) {
    this.core.mapInstance.removeInteraction(this.selectClickPt); // 초기화

    this.selectClickPt = new Select({
      condition: click,
      layers: [...this.pointsLayers, this.pinLayer],
      filter: (feature) => {
        let pitCde = feature.getProperties().properties.pitCde;
        const isVMF = pitCde.includes('006') || pitCde.includes('007') || pitCde.includes('008') || pitCde.includes('pin');
        return isVMF;
      },
      style: (feature, resolution) => this.onPointStyleActive(feature),
    });

    this.selectClickPt.on('select', (e) => {
      const targetCollection = e.target.getFeatures().getArray();
      const feature = targetCollection[0];
      if (feature) {
        // 콜백 함수
        callback(feature.getProperties().properties);
      } else {
        //콜백 함수
        callback(null);
      }
    });

    this.core.mapInstance.addInteraction(this.selectClickPt);
  }

  // 포인트 선택 해제
  public selectedClearPt() {
    this.selectClickPt?.getFeatures().clear();
  }

  //마지막 선택한 스마트핀 refetch후 재선택
  public selectAfterRefetch(serial, callback) {
    this.selectClickPt?.getFeatures().push(
      this.pinLayer
        .getSource()!
        .getFeatures()
        .filter((feature) => feature.getProperties().properties.serial === serial)[0],
    );
    callback(
      this.pinLayer
        .getSource()!
        .getFeatures()
        .filter((feature) => feature.getProperties().properties.serial === serial)[0]
        .getProperties().properties,
    );
  }

  /* ------------ MULTIPLE SELECT --------------- */

  // [Draw] point (커서 따라다니는 포인트추가)
  public addDrawPoint() {
    let layer: any = new VectorLayer({}); // 임시 레이어

    let draw = new Draw({
      source: layer.getSource(),
      type: 'Point',
      style: (feature) => this.multiPointSt(feature),
    });
    // 지도에 인터렉션 추가
    this.drawInteractions.push(draw);
    // try~catch
    try {
      for (let draw of this.drawInteractions) {
        this.core.mapInstance.addInteraction(draw);
      }
    } catch (e) {
      console.log(e);
    }
  }
  public removeDrawPoint() {
    try {
      for (let draw of this.drawInteractions) {
        this.core.mapInstance.removeInteraction(draw);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // [Snap] 추가
  public addSnap() {
    //  let interactions: any = [];
    this.pointsLayers.map((layer: any) => {
      if (layer instanceof VectorLayer) {
        // !* 레이어에 source가 있는 경우만 snap 생성
        if (layer.getSource()) {
          // Snap 생성 및 source 추가
          let snap = new Snap({
            source: layer.getSource(),
          });
          // 배열에 snap 추가
          this.snapInteractions.push(snap);
        } else {
          console.log(layer.get('id'), '해당 레이어에 source가 없음.');
        }
      }
    });

    // try~catch
    try {
      for (let snap of this.snapInteractions) {
        this.core.mapInstance.addInteraction(snap);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // [Snap] 삭제
  public removeSnap() {
    try {
      for (let snap of this.snapInteractions) {
        this.core.mapInstance.removeInteraction(snap);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // [key event] 추가 - this.multiClick (addFeature, removeFeature)
  public addKeyEvent() {
    //1) Escape
    const keyDownEvt = (event) => {
      if (event.key === 'Escape') {
        console.log('Escape!');
        // Esc 키로 한단계 취소
        this.multiClick.getFeatures().pop(); // 마지막 항목부터 삭제 -> redoArray에 추가하기
      }
    };
    this.keyDownEvents.push(keyDownEvt); // 이벤트 관리 배열에 추가 1)

    //2) Undo Redo
    const redoArray: Feature[] = [];
    const keyDownEvt2 = (event) => {
      // ctrl + shift + z (재실행)
      if (event.ctrlKey && event.shiftKey && event.keyCode === 90) {
        if (redoArray.length <= 0) {
          console.log('Redo 할 항목이 없음.');
          return;
        }

        const lastFeature = redoArray[redoArray?.length - 1];
        this.multiClick.getFeatures().push(lastFeature); // 선택항목에 다시 추가

        redoArray.pop(); // 관리항목에서 삭제
        console.log('Redooo :)');

        let collection = this.multiClick.getFeatures();
        let selectedFeatures = collection?.getArray();
        this.multiCallback(selectedFeatures);

        return;
      }
      // ctrl + z (실행취소)
      if (event.ctrlKey && event.keyCode === 90) {
        console.log('Undooo :)');
        const length = this.multiClick.getFeatures().getLength();
        const feature = this.multiClick.getFeatures()?.getArray()?.[length - 1]; // 마지막 선택된 포인트 피쳐

        if (feature) {
          redoArray.push(feature);
          this.multiClick.getFeatures().pop(); // 마지막 선택항목 삭제 -> redoArray에 추가하기

          // TODO: callback 함수에 전달
          let collection = this.multiClick.getFeatures();
          let selectedFeatures = collection?.getArray();
          this.multiCallback(selectedFeatures);
        }
        return;
      }
    };
    this.keyDownEvents.push(keyDownEvt2); // 이벤트 관리 배열에 추가 2)

    // 이벤트 관리 배열 -> 전역 이벤트 추가
    for (let keyDownEvent of this.keyDownEvents) {
      document.addEventListener('keydown', keyDownEvent);
    }
  }

  public removeKeyEvent() {
    for (let keyDownEvent of this.keyDownEvents) {
      document.removeEventListener('keydown', keyDownEvent);
    }
    this.keyDownEvents = [];
  }

  // 삭제하기 [ 포인트 파이프 ] 여러개 선택 Select
  public multipleSelectLayers({ pointLayers, pipeLayers, active }: { pointLayers?: any; pipeLayers?: any; active: boolean }) {
    this.core.mapInstance.removeInteraction(this.multiClickPit); // interaction 삭제 후
    this.core.mapInstance.removeInteraction(this.multiClickPip); // interaction 삭제 후

    this.multiClickPit = new Select({
      layers: pointLayers,
      // multi: true,
      toggleCondition: shiftKeyOnly,
      condition: (evt) => {
        if (evt.type === 'click') {
          let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, (feature) => {
            return feature.get('id') === 'point' && feature;
          }); // "point" feature가 있는 영역 선택시에만 true
          return feature ? true : false;
        }
        return false; // 이 외 영역 false
      },
      filter: (feature, layer) => {
        console.log('Pip', this.multiClickPip.getFeatures()?.getArray()?.length);
        if (this.multiClickPip.getFeatures()?.getArray()?.length) {
          return false;
        }
        return true;
      },
      style: (feature) => this.multiPointSt(feature),
    });

    this.multiClickPip = new Select({
      layers: pipeLayers,
      // multi: true, // 겹친경우 다 선택되는 문제
      toggleCondition: shiftKeyOnly,
      condition: (evt) => {
        if (evt.type === 'click') {
          let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, (feature) => {
            return feature.get('id') === 'pipeLine' && feature;
          }); // "pipeLine" feature가 있는 영역 선택시에만 true
          return feature ? true : false;
        }
        return false; // 이 외 영역 false
      },
      filter: (feature, layer) => {
        console.log('pit', this.multiClickPit.getFeatures()?.getArray()?.length);
        if (this.multiClickPit.getFeatures()?.getArray()?.length) {
          return false; // 이미 선택된 포인트가 있을 때
        }
        return true;
      },
      style: (feature) => this.multiSelectStyle(feature),
    });

    //  this.multiClickPit.on('select', (evt) => {
    //    let feature = evt?.selected[0];
    //    let collection = this.multiClickPit.getFeatures();
    //    let selectedFeatures = collection?.getArray();
    //  });

    if (active) {
      console.log('add multiClick');
      this.addKeyEvent(); // 키보드 이벤트
      this.multiClickPit.setActive(true);
      this.multiClickPip.setActive(true);
      this.core.mapInstance.addInteraction(this.multiClickPip); //  interaction 추가
      this.core.mapInstance.addInteraction(this.multiClickPit); // 포인트 interaction 추가 (가장 마지막에 추가)
    } else {
      this.multiClickPit.setActive(false);
      this.multiClickPip.setActive(false);
      this.core.mapInstance.removeInteraction(this.multiClickPip); // interaction 삭제
      this.core.mapInstance.removeInteraction(this.multiClickPit); // interaction 삭제
    }
  }

  // 포인트 여러개 선택 기능
  public multipleSelect(callback?: any) {
    this.multiClick = new Select({
      multi: true,
      filter: function (feature, layer) {
        console.log(feature, layer);
        console.log(layer.get('id'));
        return true;
      },
      condition: click,
      // toggleCondition: click, // Multiple 가능
      toggleCondition: shiftKeyOnly, // Multiple 가능
      layers: this.pointsLayers,
      style: (feature) => this.multiPointSt(feature),
    });

    this.multiClick.on('select', (evt) => {
      let feature = evt?.selected[0];
      let collection = this.multiClick.getFeatures();
      let selectedFeatures = collection?.getArray();

      const isInclude = selectedFeatures?.includes(feature);

      console.log('selected Point:', selectedFeatures);

      if (callback) {
        this.multiCallback = callback;
        callback(selectedFeatures);
      }
    });
  }

  // 포인트 여러개 선택 - 토글
  public onToggleMultiSelect(bool: boolean) {
    if (this.multiClick) {
      this.multiClick.setActive(bool);

      // multiSelect 이벤트 활성화 또는 막기
      if (bool) {
        this.core.mapInstance.removeInteraction(this.multiClick); // 초기화
        console.log('add multiClick');
        this.core.mapInstance.addInteraction(this.multiClick);
        this.addKeyEvent(); // 키보드 이벤트
        this.pointCursor(true); //커서모양 변경
        this.addDrawPoint();
        this.addSnap();
        //
      } else {
        console.log('remove multiClick');
        this.core.mapInstance.removeInteraction(this.multiClick);
        this.removeKeyEvent();
        this.pointCursor(false);
        this.removeDrawPoint();
        this.removeSnap();
        // 선택된게 있다면 클리어
        var selectedFeaturesClick = this.multiClick.getFeatures();
        selectedFeaturesClick.clear();
      }
    }
    // 다른 select 이벤트 활성화 또는 막기
    //  this.toggleSelect(!bool);
  }

  // 기본 클릭 이벤트 반대로 ..
  // [point] | select 이벤트 활성화 또는 막기
  public toggleSelect(bool: boolean) {
    if (bool) {
      this.selectClickPt?.setActive(true);
      this.core.mapInstance.addInteraction(this.selectClickPt);
    } else {
      this.selectClickPt?.setActive(false);
      this.selectClickPt?.getFeatures().clear();
      this.core.mapInstance.removeInteraction(this.selectClickPt);
    }
  }

  // 포인트 레이어에서 피쳐 찾기
  public getFeaturePoint({ layerId, id }: { layerId?: string; id?: string }) {
    // 해당 facility 포인트 레이어
    let targetLayer = this.pointsLayers.find((layer) => layer.get('id') === layerId + 'Point');

    // 해당 레이어의 모든 피쳐
    let features: Feature[] = targetLayer?.getSource()?.getFeatures() || [];
    // 찾은 피쳐값
    let feature = features.find((ele: Feature) => ele?.getProperties()?.properties?.pointId === id);
    return feature;
  }

  // 포인트 선택
  public dynamicSelect(feature) {
    this.selectClickPt?.getFeatures().push(feature);
  }

  // 파이프 자동 선택 기능 (직접클릭x, data로 feature 찾기)
  // data(properties) -> line autoFocus & view Fit
  public async autoSelectLine(data) {
    const { facilityKind, pointId, x, y } = data || {};

    // 해당 파이프 선택
    let feature = await this.getFeaturePoint({ layerId: facilityKind, id: pointId });
    if (feature) {
      this.dynamicSelect(feature);
    }

    // view fit
    const view = this.core.mapInstance?.getView();
    const coord = [y, x, y, x];
    view?.fit(coord, { duration: 0, maxZoom: 23 });
  }

  // 레이어별 토글
  public onToggleLayer(layerId: string) {
    // 측점별 레이어
    this.pointsLayers.map(async (layer: any) => {
      if (layer.get('id') === layerId + 'Point') {
        layer.setVisible(!layer.getVisible());
        //레이어 꺼질떄
        if (!layer.getVisible()) {
          // console.log(this.selectClick.getFeatures());
        }
        //   setTimeout(() => {
        //     layer.setVisible(!layer.getVisible());
        //   }, 10);
      }
    });
  }

  //핀 레이어 토글
  public onTogglePin() {
    this.pinLayer.setVisible(!this.pinLayer.getVisible());
  }

  // 지도 click 이벤트
  public addMapClickEvent() {
    this.core.mapInstance.on('click', async (evt) => {
      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        let properties = feature.getProperties()?.properties;

        // 2개 이상 -> cluster source
        if (properties?.features?.length > 1) {
        }
        return feature;
      });
    });
  }

  // 지도 move 이벤트
  public addMapMoveEvent() {
    this.core.mapInstance.on('pointermove', async (evt) => {
      // 일반 피쳐 감지
      let hoverFeature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        let properties = feature.getProperties().properties;
        let pitCde = properties?.pitCde || '';
        if (pitCde.includes('006') || pitCde.includes('007') || pitCde.includes('008')) {
          return feature;
        } else {
          return null;
        }
      });

      //커서 모양 변경
      if (hoverFeature) {
        document.body.style.cursor = 'pointer';
      } else {
        document.body.style.cursor = 'default';
      }
    });
  }

  // 커서모양 변경 이벤트
  public pointCursor(toggle) {
    const moveEvent = (evt) => {
      try {
        let pixel = evt.pixel;
        // 일반 피쳐 감지
        let hoverFeature = this.core.mapInstance.forEachFeatureAtPixel(pixel, function (feature: any) {
          let properties = feature.getProperties().properties;
          let pitCde = properties?.pitCde || '';
          let isPoint = properties?.pointId || '';
          if (isPoint) {
            return feature;
          } else {
            return null;
          }
        });

        //커서 모양 변경
        if (hoverFeature) {
          document.body.style.cursor = 'pointer';
        } else {
          document.body.style.cursor = 'default';
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (toggle) {
      this.pointCursorEvent = this.core.mapInstance.on('pointermove', moveEvent);
    } else {
      unByKey(this.pointCursorEvent);
    }
  }

  /* ------------------ STYLING ------------------ */

  // 포인트 여러개 선택시 포인트 스타일
  private multiPointSt(feature) {
    const zoom = this.core.mapInstance.getView().getZoom() || 0;
    let properties = feature.getProperties().properties;
    return [
      new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({
            color: '#0eff32',
          }),
          stroke: new Stroke({
            width: 2,
            color: '#ffffff',
          }),
        }),
      }),
    ];
  }

  // 포인트 스타일
  private onPointStyle(feature) {
    const zoom = this.core.mapInstance.getView().getZoom() || 0;
    let properties = feature.getProperties().properties;

    let facilityKind = properties?.facilityKind; // 시설물 종류

    // PIT 코드별 아이콘 이미지 지정
    let pitCde = properties?.pitCde || '';
    let pointIco = pitCde.includes('006') ? IcoValve : pitCde.includes('007') ? IcoManhole : pitCde.includes('008') ? IcoHydrant : pitCde.includes('pin') ? IcoPin : undefined;
    // 아이콘 이미지가 있는 측점 데이터인 경우
    const hasIcon = pitCde.includes('006') || pitCde.includes('007') || pitCde.includes('008') || pitCde.includes('pin');

    // 측점명
    let ptNum = properties?.ptNum || '-';

    let bgStyle: Style = new Style({});
    let myImage: ImageStyle | undefined;
    let myText: Text | undefined;

    // 아이콘 이미지가 있는 측점 데이터인 경우
    if (hasIcon) {
      myImage = new Icon({
        opacity: 1,
        src: pointIco,
      });
    } else {
      // 아이콘 없는 측점 데이터의 경우 측점명을 표시한다. 단, 줌레벨 조건 있음.
      if (zoom > 20) {
        // Active - background style
        bgStyle = new Style({
          image: new Circle({
            radius: 9,
            fill: new Fill({ color: getFacilityColor(facilityKind, 1) }), // 시설물별 색상 적용
          }),
        });
        myImage = new Circle({
          radius: 5,
          fill: new Fill({ color: getFacilityColor(facilityKind, 1) }), // 시설물별 색상 적용
          stroke: new Stroke({ color: '#fff', width: 2 }),
        });
        //   myImage = undefined;
        myText = new Text({
          text: String(ptNum), // 측점명 텍스트
          font: '200 14px Pretendard',
          fill: new Fill({ color: 'rgba(255, 255, 255, 1)' }),
          backgroundFill: new Fill({ color: 'rgba(0, 0, 0, 0.5)' }),

          textAlign: 'start',
          //  textBaseline: 'bottom',
          textBaseline: 'middle',
          offsetX: 10,
        });
      }
    }

    return [
      // bgStyle && bgStyle, // Active - background style
      new Style({
        image: myImage,
        text: myText,
        zIndex: hasIcon ? 2 : 1,
      }),
    ];
  }

  // 포인트 스타일(active)
  private onPointStyleActive(feature) {
    const zoom = this.core.mapInstance.getView().getZoom() || 0;

    let properties = feature.getProperties().properties;

    let facilityKind = properties?.facilityKind; // 시설물 종류

    let pitCde = properties?.pitCde; // 분류 기준 : PIT 코드 (관로,소화전,밸브 등)

    let pointIco = pitCde.includes('006') ? IcoValveA : pitCde.includes('007') ? IcoManholeA : pitCde.includes('008') ? IcoHydrantA : pitCde.includes('pin') ? IcoPinA : undefined;
    let ptNum = properties?.ptNum || '-';

    let myImage: ImageStyle | undefined;
    let myText: Text | undefined;

    // 아이콘 이미지
    if (pitCde.includes('006') || pitCde.includes('007') || pitCde.includes('008') || pitCde.includes('pin')) {
      myImage = new Icon({
        opacity: 1,
        src: pointIco,
        anchor: [0.5, pitCde.includes('pin') ? 46 : 36],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
      });
    } else {
      myImage = new Icon({
        opacity: 1,
        src: IcoLocation,
        anchor: [0.5, 36],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
      });

      if (zoom > 20) {
        myText = new Text({
          text: String(ptNum),
          font: '600 16px Pretendard',
          fill: new Fill({ color: getFacilityColor(facilityKind, 1) }), // 시설물별 색상 적용
          stroke: new Stroke({ color: '#fff', width: 5 }),
          textAlign: 'start',
          textBaseline: 'bottom',
        });
      }
    }

    return new Style({
      image: myImage,
      text: myText,
    });
  }

  // 여러개 선택 라인 스타일(선택 활성화)
  private multiSelectStyle(feature: any) {
    const properties = feature.getProperties().properties;
    const pipeType = properties.layerId;
    const hol = properties.hol;
    const shpCde = properties.shpCde; // SHP001 원형, SHP002 사각
    const diameter = shpCde === 'SHP001' ? Number(properties.diameter) * 100 : shpCde === 'SHP002' ? Number(hol) * 100 * 1000 : 0; // 300
    const resol = this.core.mapInstance.getView().getResolution();

    return [
      new Style({
        stroke: new Stroke({
          width: 4,
          color: getFacilityColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * (0.00001 / resol) : 1,
          color: getFacilityColor(pipeType, 0.4),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * 1.3 * (0.00001 / resol) : 1,
          color: `rgba(255, 0, 0, 0.8)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * 1.3 * (0.00001 / resol) + 10 : 1,
          color: `rgba(255, 255, 255, .1)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
    ];
  }

  /* ------------------ 그 외 함수 ------------------ */
  // 벡터 소스 기반 fit
  public fitVectorSource(source) {
    // 해당 레이어에 fit
    let extent = source?.getExtent();
    console.log(extent);

    this.core.mapInstance.getView().fit(extent, { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
  }

  //측점번호로 측점 검색
  public findFeature(ptNum: string, callback: (prop) => void) {
    // let features = this.pointLayer.getSource()?.getFeatures();
    const features: Feature[] = [];
    this.pointsLayers.map((layer) => {
      layer
        .getSource()
        ?.getFeatures()
        .map((feature) => {
          features.push(feature);
        });
    });

    const thisFeature = features?.filter((i) => i.getProperties().properties.ptNum === ptNum)[0];
    if (thisFeature) {
      this.selectClickPt?.getFeatures().clear(); // select 클리어

      this.selectClickPt?.getFeatures().push(thisFeature); // select 배열에 추가 (acitve)

      callback(thisFeature.getProperties().properties); // 상세창 오픈 콜백함수
      // 화면 Fit
      this.core.mapInstance.getView().fit((thisFeature?.getGeometry() as any).getExtent(), { padding: [0, 0, 0, 0], duration: 400, maxZoom: 23 });
    } else {
      if (ptNum !== '') {
        alert('존재하지 않는 측점번호입니다.');
      }
    }
  }

  //좌표 검색
  public findCoordinates(coordinates, callback) {
    if (coordinates.x && coordinates.y) {
      //토목좌표로 인한 coordinates x,y 순서 변경
      const customFeature = new Feature({
        geometry: new Point([coordinates.y, coordinates.x]),
      });
      const currZoom = this.core.mapInstance.getView().getZoom();
      this.core.mapInstance.getView().fit(customFeature.getGeometry() as any, { padding: [130, 130, 130, 130], duration: 400, maxZoom: currZoom });
      const xySource = this.createSource([customFeature]);
      this.xyLayer.setStyle([
        new Style({
          image: new Icon({
            src: IcoXY,
          }),
        }),
        new Style({
          image: new Circle({
            radius: 48,
            stroke: new Stroke({
              width: 1,
              color: '#FA6767',
            }),
            fill: new Fill({
              color: 'rgba(235, 40, 40, 0.15)',
            }),
          }),
        }),
      ]);
      this.xyLayer.setSource(xySource);
      this.xyLayer.setVisible(true);
    } else {
      //에러 메세지 표시
      callback(true);
    }
  }

  //좌표검색레이어 숨기기
  public hideXyLayer() {
    this.xyLayer.setVisible(false);
  }
}

export default ObjectMapPoint;
