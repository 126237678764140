import { useRef, useState, useEffect } from 'react';
import { ImgPopupSt } from './_style';
import useMutateHook from 'hooks/RQuery/useMutateHook';
import { useTranslation } from 'react-i18next';
import { DragDropBoxSt } from '../drag&drop/_style';
import { fileKeys } from '../../../hooks/RQuery/keys/queryKeys';
import query from '../../../hooks/RQuery/utils';

function ImgAddPopup({
  activeCategory,
  pointId,
  siteInfo,
  ptNum,
  imgNum,
  close,
  refetchApi,
  pitCde,
}: {
  activeCategory: string;
  pointId: string;
  siteInfo: { id: string; name?: string };
  ptNum: string;
  imgNum: number;
  close?: () => void;
  refetchApi?: any;
  pitCde: string;
}) {
  const { t, i18n } = useTranslation();
  const inputRef: any = useRef(null);
  const [isDragging, setIsDragging] = useState(false); //드래그중 스타일

  const [contentImage, setContentImage] = useState<File | any>(null); // 이미지 파일
  const [contentImageUrl, setContentImageUrl] = useState<string | null>(null); // 이미지 주소

  // 상수 측량점 이미지 등록
  const { mutate: postImgMutate } = query.files.useAddImage({
    pointId: pointId,
    siteId: siteInfo.id,
    pitCde: pitCde,
    facilityKind: activeCategory,
    pointImageTypeNum: imgNum,
    file: contentImage,
  });
  //   console.log(pointId);
  //   console.log(ptNum);
  //   console.log(imgNum);

  // 드래그 앤 드롭
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };
  // 드래그 앤 드롭 완료 함수
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    let type = e.dataTransfer.files[0]?.type;
    let accept = ['image/png', 'image/jpg', 'image/jpeg'];
    if (!accept.includes(type)) {
      alert(t('미지원파일'));
      setContentImage(null);
      return;
    }
    //받은 파일 저장
    setContentImage(e.dataTransfer.files[0]);
    //받은 파일 -> 이미지 주소 읽고 저장(썸네일용)
    readImage(e.dataTransfer.files[0]);
  };

  // 파일선택 클릭
  const onClickInput = () => {
    inputRef.current.click();
  };

  // input onchange 이벤트
  const onContentImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setContentImage(e.target.files[0]);
      readImage(e.target.files[0]); // 썸네일용
    }
  };

  // [공통] 받은 파일 이미지 주소 읽고 저장
  const readImage = (image: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setContentImageUrl(String(e.target?.result));
    };
    reader.readAsDataURL(image);
  };

  // 등록 버튼 이벤트
  const onClickAddDone = () => {
    if (contentImage) {
      // ptNum: fileName 변경용
      postImgMutate(
        { pointId: pointId, siteId: siteInfo.id, pitCde: pitCde, facilityKind: activeCategory, pointImageTypeNum: imgNum, file: contentImage },
        {
          onSuccess() {
            if (refetchApi) {
              refetchApi.refetch();
            }

            // 팝업 닫기
            if (close) close();
          },
        },
      );
    } else {
      alert('이미지를 선택하세요');
    }
  };

  return (
    <>
      <ImgPopupSt.Dimmed></ImgPopupSt.Dimmed>
      <ImgPopupSt.Wrap $width={528}>
        <ImgPopupSt.Header>
          <p>{t('사진등록')}</p>
          <button className="closeBtn" onClick={close}>
            <span className="ico"></span>
          </button>
        </ImgPopupSt.Header>
        <ImgPopupSt.ContAdd>
          <DragDropBoxSt.DragDropBox className={isDragging ? 'isDragging' : ''} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
            {contentImageUrl ? (
              <div className="thumbWrap">
                <img src={contentImageUrl || ''} alt="ptImg" />
              </div>
            ) : (
              <>
                <div className="ico"></div>
                <p className="mainText">{t('드래그안내')}</p>
                <p className="or">{t('또는')}</p>
              </>
            )}

            <button className="addFileBtn" onClick={onClickInput}>
              {t('파일선택')}
            </button>
            <input ref={inputRef} id="inputFile" type="file" accept=".jpg,.png,.jpeg" onChange={onContentImageChange} />
          </DragDropBoxSt.DragDropBox>
        </ImgPopupSt.ContAdd>
        <ImgPopupSt.BtnWrapAdd>
          <div className="subText">
            <span className="ico"></span>
            {i18n.language === 'ko' ? (
              <>
                <span className="impact">JPG / PNG {t('파일')}</span>
                {t('추가가능1')}
              </>
            ) : (
              <>
                {t('추가가능1')}&nbsp;
                <span className="impact">JPG / PNG {t('파일')}</span>
                &nbsp;{t('추가가능2')}
              </>
            )}
          </div>
          <div className="btnWrap">
            <button className="delete" onClick={close}>
              {t('취소')}
            </button>
            <button className="add" onClick={onClickAddDone}>
              {t('등록')}
            </button>
          </div>
        </ImgPopupSt.BtnWrapAdd>
      </ImgPopupSt.Wrap>
    </>
  );
}

export default ImgAddPopup;
