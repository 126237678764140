import React, { useState, useEffect } from 'react';

import SearchPtNum from '../../organizations/SearchPtNum';
import ObjectMapPoint from 'maps/wfs/Object/MapPoint';
import ObjectMapPipeLine from 'maps/wfs/Object/MapPipeLine';
import DragBoxInteraction from 'maps/wfs/Interaction/DragBoxInteraction';
import DrawLayer from 'maps/wfs/Layer/DrawLayer';
import EditTools from './EditTools';
import OlCore from 'maps/wfs/OlCore';

function SettingBtn({ isOpen, openPointInfo, point, toggleEditTool, core }: { isOpen: boolean; openPointInfo: () => void; point?: ObjectMapPoint; toggleEditTool?: (active: boolean) => void; core?: OlCore }) {
  return (
    <>
      {/* todo 미구현기능 임시 주석(0823)*/}
      {/* todo 측점검색 left값 원본 : 268 + 72 + 11 : 16 + 72 + 11  (0823) */}
      {/*<div style={{ position: 'absolute', top: 12, left: isOpen ? 268 : 16, zIndex: 9, display: 'flex', alignItems: 'flex-start', transition: '.4s' }}>*/}
      {/*  /!*  /!* 편집 툴 버튼 모음 *!/*!/*/}
      {/*  <EditTools toggleEditTool={toggleEditTool} core={core} />*/}
      {/*</div>*/}
      <div style={{ position: 'absolute', top: 84, left: isOpen ? 268 : 16, zIndex: 9, display: 'flex', alignItems: 'flex-start', transition: '.4s' }}>
        <SearchPtNum point={point} openPointInfo={openPointInfo} />
      </div>
    </>
  );
}

export default SettingBtn;
