import React, { useState } from 'react';
import styled from 'styled-components';
import IcoBuilding from 'assets/images/ico-building.svg';
import IcoFileUpload from 'assets/images/ico-file-upload.svg';
import IcoFileUploadDark from 'assets/images/ico-file-upload-dark.svg';
import IcoFileDownDark from 'assets/images/ico-file-down.svg';
import IcoFileDown from 'assets/images/ico-file-down-light.svg';
import IcoBurger from 'assets/images/ico-burger.svg';
import IcoBurgerDark from 'assets/images/ico-burger-dark.svg';
import { popupUploadSelect } from 'store/popupAtoms';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import PopupDownloadSelect from './popup/PopupDownloadSelect';
import { SurveyHeaderSt } from '../popup/_style';
import { useTranslation } from 'react-i18next';

function SurveyHeader({ onBack, siteInfo }) {
  const { t } = useTranslation();
  const [popSelectState, setPopSelectState] = useRecoilState(popupUploadSelect);
  const [popupStatus, setPopupStatus] = useState({
    download: false,
  });
  const [themeMode] = useRecoilState(themeState);

  const openUploadPopup = () => {
    console.log('click!!');
    setPopSelectState((prev) => ({
      ...prev,
      isOpen: true,
    }));
  };

  const onPopupToggle = (key) => {
    setPopupStatus({ ...popupStatus, [key]: !popupStatus[key] });
  };

  return (
    <>
      {/* 헤더 영역 */}
      <SurveyHeaderSt.Wrap>
        <SurveyHeaderSt.TitleWrap>
          <div className="ico"></div>
          <p className="title">{siteInfo.name}</p>
        </SurveyHeaderSt.TitleWrap>
        <SurveyHeaderSt.BtnWrap>
          <SurveyHeaderSt.Button Ico={themeMode === 'light' ? IcoFileUpload : IcoFileUploadDark} onClick={openUploadPopup}>
            <div className="ico"></div>
            {t('파일업로드')}
          </SurveyHeaderSt.Button>
          <SurveyHeaderSt.Button Ico={themeMode === 'light' ? IcoFileDown : IcoFileDownDark} onClick={() => onPopupToggle('download')}>
            <div className="ico"></div>
            {t('파일다운로드')}
          </SurveyHeaderSt.Button>
          <SurveyHeaderSt.Button Ico={themeMode === 'light' ? IcoBurger : IcoBurgerDark} onClick={onBack}>
            <div className="ico"></div>
            {t('목록가기')}
          </SurveyHeaderSt.Button>
        </SurveyHeaderSt.BtnWrap>
      </SurveyHeaderSt.Wrap>
      {popupStatus.download ? <PopupDownloadSelect onClose={() => onPopupToggle('download')} /> : null}
    </>
  );
}

export default React.memo(SurveyHeader);
