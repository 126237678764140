import React, { useEffect, useRef, useState } from 'react';
import { SelectStyle } from './_style';
import { SelectProps } from './_interface';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import { useTranslation } from 'react-i18next';

const Select = ({ width, height, option = [], onChange, style, value, disabled, name, search, id, labelTrigger, label, placeHolder, max }: SelectProps) => {
  const { t } = useTranslation();
  const comboRef = useRef<HTMLDivElement>(null); //셀렉트 박스 영역
  const listRef = useRef<any>([]);
  const [selected, setSelected] = useState<string>(option.filter(({ active }) => active)[0]?.text); //현재 선택된 옵션의 텍스트
  const [toggle, setToggle] = useState(false); //셀렉트박스 on/off
  const [word, setWord] = useState('');
  const [themeMode] = useRecoilState(themeState);
  const searchRef = useRef(null);
  // 선택한 텍스트로 변경
  const onTextChange = (e: React.MouseEvent) => {
    // const eventTarget = e.target as HTMLLIElement;
    // console.log(eventTarget.innerText);
    // setSelected(eventTarget.innerText);
  };

  useEffect(() => {
    setSelected(option.filter((i) => i.active)[0]?.text);
  }, [option]);

  // useEffect(() => {
  //     if(labelTrigger === 0){
  //         setToggle(false)
  //     }else{
  //         setToggle(true)
  //         setTimeout(() => listRef.current[0].focus())
  //     }
  // },[labelTrigger])

  const onToggle = () => {
    if (!disabled) {
      setToggle(!toggle);
    }
  };
  //외부영역 클릭시 셀렉트박스 닫기
  const handleClickOutSide = (e: MouseEvent) => {
    if (toggle && !comboRef.current?.contains(e.target as Element)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (toggle) document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  const onFocusing = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setToggle(true);
    } else if (e.key === 'ArrowDown') {
      if (toggle) {
        listRef.current[0].focus();
      } else {
        setToggle(true);
      }
    }
  };
  const onNext = (e: any, index: number) => {
    if (e.key === 'ArrowDown') {
      const length = option?.filter(({ text }) => text?.includes(word)).length;
      if (length > index + 1) {
        listRef.current[index + 1].focus();
      }
    } else if (e.key === 'ArrowUp') {
      if (index > 0) {
        listRef.current[index - 1].focus();
      }
    } else if (e.key === 'Enter') {
      onChange?.(e, name);
      onTextChange(e);
      onToggle();
      if (search) {
        (searchRef.current as any).value = '';
        setWord('');
      }
    }
  };

  return (
    <>
      {label ? (
        <SelectStyle.Area>
          <SelectStyle.Label>{label}</SelectStyle.Label>
          <SelectStyle.Wrap ref={comboRef} width={width} style={style} option={option}>
            <SelectStyle.Selected id={id} tabIndex={0} height={height} onClick={onToggle} labelTrigger={labelTrigger} onKeyDown={(e) => onFocusing(e)} option={option} className={toggle ? 'active' : disabled ? 'disabled' : undefined}>
              {placeHolder && !selected ? <span style={{ color: '#94949A' }}>{placeHolder}</span> : null}
              {t(selected)}
            </SelectStyle.Selected>
            <SelectStyle.ListWrap>
              <SelectStyle.List className={themeMode === 'light' ? 'cScroll light' : 'cScroll'}>
                {option
                  .filter(({ value }) => (max ? value !== 3 : true))
                  .map(({ text, value }: any, index) => {
                    return (
                      <SelectStyle.Item
                        key={index}
                        aria-valuetext={value}
                        tabIndex={0}
                        ref={(e: any) => {
                          listRef.current[index] = e;
                        }}
                        onClick={(e) => {
                          onChange?.(e, name);
                          onTextChange(e);
                          onToggle();
                        }}
                        onKeyDown={(e) => onNext(e, index)}
                        onMouseOver={() => {
                          listRef.current[index].focus();
                        }}
                        option={option}
                      >
                        {t(text)}
                      </SelectStyle.Item>
                    );
                  })}
              </SelectStyle.List>
            </SelectStyle.ListWrap>
          </SelectStyle.Wrap>
        </SelectStyle.Area>
      ) : (
        <>
          <SelectStyle.Wrap ref={comboRef} width={width} style={style} option={option}>
            <SelectStyle.Selected id={id} tabIndex={0} height={height} onClick={onToggle} labelTrigger={labelTrigger} onKeyDown={(e) => onFocusing(e)} option={option} className={toggle ? 'active' : disabled ? 'disabled' : undefined}>
              {placeHolder && !selected ? <span style={{ color: '#94949A' }}>{placeHolder}</span> : null}
              {t(selected)}
            </SelectStyle.Selected>
            <SelectStyle.ListWrap>
              <SelectStyle.List className={themeMode === 'light' ? 'cScroll light' : 'cScroll'}>
                {option
                  .filter(({ value }) => (max ? value !== 3 : true))
                  .map(({ text, value }: any, index) => {
                    return (
                      <SelectStyle.Item
                        key={index}
                        aria-valuetext={value}
                        tabIndex={0}
                        ref={(e: any) => {
                          listRef.current[index] = e;
                        }}
                        onClick={(e) => {
                          onChange?.(e, name);
                          onTextChange(e);
                          onToggle();
                        }}
                        onKeyDown={(e) => onNext(e, index)}
                        onMouseOver={() => {
                          listRef.current[index].focus();
                        }}
                        option={option}
                      >
                        {t(text)}
                      </SelectStyle.Item>
                    );
                  })}
              </SelectStyle.List>
            </SelectStyle.ListWrap>
          </SelectStyle.Wrap>
        </>
      )}
    </>
  );
};

export default Select;
