import React, { useEffect, useState } from 'react';
import { FileDownStyle, PopupStyle } from '../components/templates/_style';
import { useRecoilState } from 'recoil';
import { statisticsDateState, themeState } from '../store/Atoms';
import { escKeyModalClose } from '../util/global';
import { TableStyle } from '../components/atoms/_style';
import Table from '../components/atoms/Table';
import ExcelJS from 'exceljs';
import { DateObject } from 'react-multi-date-picker';
import query from '../hooks/RQuery/utils';
interface PipeDiameterLengthListProps {
  code: string;
  diameter: number;
  count: number;
  distance: number;
}

const PopupPipeDiameterLength = ({ onPopupOnOff }: { onPopupOnOff: (key: 'statistics' | 'diameterLength', bool: boolean) => void }) => {
  const [date] = useRecoilState(statisticsDateState);
  const [tableData, setTableData] = useState<PipeDiameterLengthListProps[]>([]);
  const [theme] = useRecoilState(themeState);

  const [sortStatus, setSortStatus] = useState({ key: 'none', sort: 'none' }); //sort 상태

  const list = query.statistics.useStatisticsMopDiameters({ siteId: sessionStorage.getItem('siteId') });

  //th 클릭시 오름차순/내림차순/초기화 순으로 정렬 반복 이벤트. 다른 th 클릭시 해당 컬럼 부터 다시 시작
  const onSort = (key: string) => {
    if (key === sortStatus.key) {
      if (sortStatus.sort === 'none') {
        setSortStatus({ key: key, sort: 'upper' });
      } else if (sortStatus.sort === 'upper') {
        setSortStatus({ key: key, sort: 'lower' });
      } else {
        setSortStatus({ key: 'none', sort: 'none' });
      }
    } else {
      setSortStatus({ key: key, sort: 'upper' });
    }
  };
  useEffect(() => {
    if (list.data) {
      const newArr: PipeDiameterLengthListProps[] = [];
      const data = list.data;
      data.map((i: any) => {
        i.list.map((j) => {
          newArr.push({ code: i.mopCde, diameter: j.diameter, count: j.count, distance: j.distance });
        });
      });
      setTableData(newArr);
    }
  }, [list.data]);

  //esc 닫기
  useEffect(() => {
    window.addEventListener('keydown', (e) => escKeyModalClose(e, () => onPopupOnOff('diameterLength', false)));
    return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, () => onPopupOnOff('diameterLength', false)));
  }, []);

  //엑셀 다운로드
  const onExportExcel = async () => {
    try {
      //console.log(rows);
      const headerWidths = [9, 14, 14];
      // workbook 생성
      const wb = new ExcelJS.Workbook();
      // sheet 생성
      const sheet = wb.addWorksheet('배달 주문 내역');

      // 상단 헤더(TH) 추가
      const headerRow = sheet.addRow(['재질', '관경(mm)', '총 연장(m)']);
      // 헤더의 높이값 지정
      headerRow.height = 30.75;

      // 각 헤더 cell에 스타일 지정
      headerRow.eachCell((cell, colNum) => {
        styleHeaderCell(cell);
        sheet.getColumn(colNum).width = headerWidths[colNum - 1];
      });

      // 각 Data cell에 데이터 삽입 및 스타일 지정
      tableData.forEach((i) => {
        const rowDatas = [i.code, i.diameter, i.distance];
        const appendRow = sheet.addRow(rowDatas);

        appendRow.eachCell((cell, colNum) => {
          styleDataCell(cell);
          if (colNum === 1) {
            cell.font = {
              color: { argb: 'ff1890ff' },
            };
          }
          if (colNum === 3) {
            cell.numFmt = '0.00';
          }
        });
      });

      // 파일 생성
      let promise = [];
      Promise.all(promise).then(() => {
        wb.xlsx.writeBuffer().then((b) => {
          let a = new Blob([b]);
          let url = window.URL.createObjectURL(a);

          let elem = document.createElement('a');
          elem.href = url;
          elem.download = `${new DateObject().format('YYYYMMDD')}-관경별연장.xlsx`;
          elem.click();
          elem.remove();
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  //엑셀 th 스타일 지정
  const styleHeaderCell = (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffebebeb' },
    };
    cell.border = {
      bottom: { style: 'thin', color: { argb: '-100000f' } },
      right: { style: 'thin', color: { argb: '-100000f' } },
    };
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true,
      color: { argb: 'ff252525' },
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
  };

  //엑셀 td 스타일 지정
  const styleDataCell = (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffffff' },
    };
    cell.border = {
      bottom: { style: 'thin', color: { argb: '-100000f' } },
      right: { style: 'thin', color: { argb: '-100000f' } },
    };
    cell.font = {
      name: 'Arial',
      size: 10,
      color: { argb: 'ff252525' },
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
  };
  return (
    <>
      <PopupStyle.Dimmed></PopupStyle.Dimmed>
      <PopupStyle.Popup $width={800} style={{ overflow: 'hidden', maxHeight: 'calc(100% - 224px)' }}>
        <PopupStyle.Head>
          <PopupStyle.Title>관경별 연장</PopupStyle.Title>
          <PopupStyle.CloseBtn type={'button'} onClick={() => onPopupOnOff('diameterLength', false)}></PopupStyle.CloseBtn>
        </PopupStyle.Head>
        <PopupStyle.Cont style={{ background: theme === 'dark' ? '#283142' : '#eef0f8' }}>
          <Table
            caption={'시공 목록'}
            colgroup={['33.3%', '33.3%', 'auto']}
            height={'calc(100% - 138px)'}
            tbodyHeight={400}
            thead={
              <tr>
                <th scope={'col'}>
                  <TableStyle.SortBtn $status={sortStatus} $key={'mopCde'} type={'button'} onClick={() => onSort('mopCde')}>
                    재질
                  </TableStyle.SortBtn>
                </th>
                <th scope={'col'}>
                  <TableStyle.SortBtn $status={sortStatus} $key={'diameter'} type={'button'} onClick={() => onSort('diameter')}>
                    관경(mm)
                  </TableStyle.SortBtn>
                </th>
                <th scope={'col'}>
                  <TableStyle.SortBtn $status={sortStatus} $key={'distance'} type={'button'} onClick={() => onSort('distance')}>
                    총 연장(m)
                  </TableStyle.SortBtn>
                </th>
              </tr>
            }
            tbody={
              <>
                {tableData.map((i: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{i.code}</td>
                      <td>{i.diameter}</td>
                      <td>{Number(i.distance?.toFixed(2))}</td>
                    </tr>
                  );
                })}
              </>
            }
          />
        </PopupStyle.Cont>
        <PopupStyle.Foot>
          <PopupStyle.FootDesc>
            표시 중인 관경별 연장 자료를 엑셀 시트로 저장할 수 있습니다.
            <br />
            파일명은 ‘날짜-관경별연장.xlsx’로 저장됩니다 (예: 20240401-관경별연장.xlsx).
          </PopupStyle.FootDesc>
          <FileDownStyle.Group>
            <FileDownStyle.Button type={'button'} onClick={() => onExportExcel()}>
              엑셀로 저장
            </FileDownStyle.Button>
          </FileDownStyle.Group>
        </PopupStyle.Foot>
      </PopupStyle.Popup>
    </>
  );
};

export default PopupPipeDiameterLength;
