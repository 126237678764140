//설계도면 불러오기
import { useQueries, useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
import { useQueryState } from '../useQueryState';

//
export const useShps = (siteId) => {
  return useQuery({
    queryKey: [...keys.shpKeys.list(siteId)],
    queryFn: () => api.shps.getShp(siteId),
  });
};
