import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PipeInfoModalSt } from './_style';
import IcoClose from 'assets/images/ico-pop-close-light.svg';
import { useSSR, useTranslation } from 'react-i18next';
import HoverTooltip from './HoverTooltip';
import Select from 'components/atoms/Select';
import query from 'hooks/RQuery/utils';
import useInputChange from 'hooks/useInputChange';
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';
import Input from 'components/atoms/Input';

function EditInfoModal({ selectedLines, selectedPoints }) {
  const { t } = useTranslation();

  const [form, setForm, onChange] = useInputChange({ diameter: '' });

  const [selCodes, setSelCodes] = useState({
    facility: '',
    pip: '',
    mop: '',
  });

  const code = query.codes.useCodes();
  const FACILITY = code?.data?.FACILITY; // 관 종류 ㅍ코드
  const PIPE = code?.data?.PIP; // 관 유형 코드
  const MOP = code?.data?.MOP; // 품명 코드

  const facilityOption = useMemo(() => {
    let selCode = selCodes.facility;
    return FACILITY.map((item) => ({ value: item.code, text: item.value, active: selCode === item.code }));
  }, [FACILITY, selCodes.facility]);

  const pipOption = useMemo(() => {
    let selCode = selCodes.pip;
    return PIPE.map((item) => ({ value: item.code, text: item.value, active: selCode === item.code }));
  }, [PIPE, selCodes.pip]);

  const mopOption = useMemo(() => {
    let selCode = selCodes.mop;
    return MOP.map((item) => ({ value: item.code, text: item.value, active: selCode === item.code }));
  }, [MOP, selCodes.mop]);

  // select - onChange
  const onChangeSelect = (e, name) => {
    const value = (e.target as any).ariaValueText;

    setSelCodes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // * 결과값
  console.log(selCodes);
  console.log(form);

  const isPipe = selectedLines?.length > 0;
  const isPoint = selectedPoints?.length > 0;
  return (
    <>
      <PipeInfoModalSt.Wrap>
        <PipeInfoModalSt.Header>
          <div className="titleWrap">
            <p className="title">{isPipe ? '파이프' : isPoint ? '포인트' : ''} 수정</p>
            <span>{'편집할 대상을 선택하세요'}</span>
          </div>
        </PipeInfoModalSt.Header>
        <PipeInfoModalSt.Cont>
          <PipeInfoModalSt.InfoWrap>
            <PipeInfoModalSt.SubInfoWrap>
              <PipeInfoModalSt.SubInfo>
                <li>
                  <p className="title">{t('관종류')}</p>
                  <Select name="facility" width={140} height={30} option={facilityOption} onChange={onChangeSelect} style={{ zIndex: 'inherit' }} />
                </li>
                <li>
                  <p className="title">{t('관유형')}</p>
                  <Select name="pip" width={240} height={30} option={pipOption} onChange={onChangeSelect} style={{ zIndex: 'inherit' }} />
                </li>
                <li>
                  <HoverTooltip type="pip" name="MOP_CDE" title={t('품명')} />
                  <Select name="mop" width={240} height={30} option={mopOption} onChange={onChangeSelect} style={{ zIndex: 'inherit' }} />
                </li>
                <li>
                  <HoverTooltip type="pip" name="DIAMETER" title={t('관경')} />
                  <Input name="diameter" height={30} onChange={onChange} unit="mm" />
                </li>
                <li>
                  <HoverTooltip type="pip" name="DISTANCE" title={t('길이')} />
                  <Input name="distance" height={30} onChange={onChange} unit="m" />
                </li>
                <li>
                  <p className="title">{t('평균심도')}</p>
                  <Input name="avgDepth" height={30} onChange={onChange} unit="m" />
                </li>
              </PipeInfoModalSt.SubInfo>
            </PipeInfoModalSt.SubInfoWrap>

            {/* 선택된 목록 */}
            <div style={{ padding: '40px 24px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', fontWeight: 500 }}>
                <p>관 종류</p>
                <p>시점(측점번호)</p>
                <p>종점(측점번호)</p>
              </div>

              <Listcomponent selectedLines={selectedLines} selectedPoints={selectedPoints} />

              {/* <ParentComponent>
                <Items selectedLines={selectedLines} />
              </ParentComponent> */}
            </div>
          </PipeInfoModalSt.InfoWrap>
        </PipeInfoModalSt.Cont>
      </PipeInfoModalSt.Wrap>
    </>
  );
}

export default EditInfoModal;

const LineList = ({ selectedLines }) => {
  return selectedLines?.map((item) => (
    <li key={item?.id} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <p>{item?.layerId}</p>
      <p>{item?.stCoordinate?.ptNum}</p>
      <p>{item?.endCoordinate?.ptNum}</p>
    </li>
  ));
};

const PointList = ({ selectedPoints }) => {
  console.log(selectedPoints);
  return selectedPoints?.map((item) => (
    <li key={item?.pointId} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <p>{item?.facilityKind}</p>
      <p>{item?.ptNum}</p>
      <p>{''}</p>
    </li>
  ));
};

const Listcomponent = React.memo(({ selectedLines, selectedPoints }: { selectedLines?: any; selectedPoints?: any }) => {
  return (
    <ul style={{ width: '380px', height: 'max-content' }}>
      <LineList selectedLines={selectedLines} />
      <PointList selectedPoints={selectedPoints} />
    </ul>
  );
});

/* 해당구조에서 parent 내에서 re-render해도 children은 무관. */
const ParentComponent = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <ul style={{ width: '380px', height: 'max-content' }}>
      {children}
      <button
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        click
      </button>
    </ul>
  );
};
