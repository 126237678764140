import React from 'react';
import Lottie from 'lottie-react';
import loadingData from '../../assets/loading/data.json';
const Loading = () => {
  return (
    <div style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', zIndex: 999, background: 'rgba(0,0,0,.5)' }}>
      <Lottie animationData={loadingData} loop={true} autoplay={true} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: 150, height: 150 }} />;
    </div>
  );
};

export default Loading;
