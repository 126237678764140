import { request } from 'service/api/common';
import * as apiUrl from './apiUrl';

// 현장 목록 (테이블 필터)
export const getSites = async ({ category, keyword, pageNum }) => {
  const { data } = await request('auth').get(apiUrl.sites.all, { params: { category, keyword, pageNum } });
  return data.data;
};

// 현장별 시공 정보
export const getSiteDetail = async ({ siteId }) => {
  const { data } = await request('auth').get(apiUrl.sites.detail(siteId), { params: {} });
  return data.data;
};

// 현장 등록
export const siteAdd = async (params) => {
  const { data } = await request('auth').post(apiUrl.sites.add, params);
  return data.data;
};
