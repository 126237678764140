import React, { useEffect, useState } from 'react';
import { DashboardStyle } from '../components/templates/_style';
import DashboardInfo from '../components/templates/DashboardInfo';
import DashboardFacilityChart from '../components/templates/DashboardFacilityChart';
import DashboardTotalChart from '../components/templates/DashboardTotalChart';
import DashboardMaterialList from '../components/templates/DashboardMaterialList';
import DashboardCalendar from '../components/templates/DashboardCalendar';
import DashboardTypeChart from '../components/templates/DashboardTypeChart';
import { ConstructionInfoProps, DashboardFacilityDataProps, DashboardTypeDataProps } from './_interface';
import { DateObject } from 'react-multi-date-picker';
import query from 'hooks/RQuery/utils';
import { useRecoilState } from 'recoil';
import { siteListState } from 'store/Atoms';

const Dashboard = () => {
  const [workDate, setWorkDate] = useState([]);
  const [date, setDate] = useState<DateObject>(new DateObject());
  const [typeData, setTypeData] = useState<DashboardTypeDataProps[]>([]); //관별 차트 데이터 저장용 state

  //작업일자 캘린더 표시
  const workDay = query.calendars.useCalendars({ siteId: sessionStorage.getItem('siteId') });

  //관별 차트 데이터 호출 쿼리
  const { data } = query.statistics.useProcessRateByFacilityKind({ siteId: sessionStorage.getItem('siteId'), date: date.format('YYYY-MM-DD') });

  //공통 코드 enum 쿼리
  const code = query.codes.useCodes(); // 코드 조회

  useEffect(() => {
    if (workDay.data) {
      const data = workDay.data;
      setWorkDate(data);
    }
  }, [workDay.data]);

  //차트 데이터 가공 및 저장
  useEffect(() => {
    if (data) {
      const newArr: DashboardTypeDataProps[] = [{ name: 'total', percent: 100, meter: 0 }];
      data?.map((i: any) => {
        let completePercent = typeof i.completePercent === 'number' ? i.completePercent : 0;
        let completeDistance = typeof i.completeDistance === 'number' ? i.completeDistance : 0;
        //공통 코드값과 매핑하여 컬러, 이름으로 저장
        newArr.push({
          name: code.data?.FACILITY.filter(({ code }: { code: string }) => code === i.facilityKind)[0].value,
          percent: Number(completePercent?.toFixed(1)) >= 100 ? 100 : Number(completePercent?.toFixed(1)),
          meter: Number(completeDistance?.toFixed(1)),
          fill: code.data?.FACILITY.filter(({ code }: { code: string }) => code === i.facilityKind)[0].color,
        });
      });
      setTypeData(newArr);
    }
  }, [data]);

  return (
    <DashboardStyle.Contain>
      {/*<input*/}
      {/*  type="file"*/}
      {/*  id="ctrl"*/}
      {/*  webkitdirectory={''}*/}
      {/*  onChange={(e) => {*/}
      {/*    [...e.target.files].map((i) => {*/}
      {/*      console.log(i.webkitRelativePath);*/}
      {/*    });*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<ImageResizeTest />*/}
      {/*<input*/}
      {/*  type={'file'}*/}
      {/*  multiple={true}*/}
      {/*  onChange={(e) => {*/}
      {/*    const { files } = e.target as any;*/}

      {/*    // formData 생성*/}
      {/*    let formdata1: any = new FormData();*/}
      {/*    formdata1.append('siteId', '60010003');*/}

      {/*    for (let i = 0; i < files.length; i++) {*/}
      {/*      formdata1.append('imageFiles', files[i]);*/}
      {/*    }*/}
      {/*    request.post('v1/wtl-joints/images', formdata1, {*/}
      {/*      headers: {*/}
      {/*        'Content-Type': 'multipart/form-data',*/}
      {/*      },*/}
      {/*    });*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<input*/}
      {/*  type={'file'}*/}
      {/*  multiple={true}*/}
      {/*  onChange={async (e) => {*/}
      {/*    const { files } = e.target as any;*/}

      {/*    const req = async () => {*/}
      {/*      for (let int = 0; int <= files.length; int++) {*/}
      {/*        try {*/}
      {/*          await callApi(int);*/}
      {/*        } catch (e) {*/}
      {/*          console.log(e);*/}
      {/*        }*/}
      {/*      }*/}
      {/*    };*/}

      {/*    const callApi = (int: number) => {*/}
      {/*      return new Promise((res, rej) => {*/}
      {/*        let formdata: any = new FormData();*/}
      {/*        formdata.append('siteId', '60010003');*/}
      {/*        formdata.append('imageFiles', files[int]);*/}
      {/*        request*/}
      {/*          .post('v1/wtl-joints/images', formdata, {*/}
      {/*            headers: {*/}
      {/*              'Content-Type': 'multipart/form-data',*/}
      {/*            },*/}
      {/*          })*/}
      {/*          .then(() => {*/}
      {/*            res('성공');*/}
      {/*          })*/}
      {/*          .catch(() => {*/}
      {/*            rej('실패');*/}
      {/*          });*/}
      {/*      });*/}
      {/*    };*/}
      {/*    req();*/}
      {/*    // formData 생성*/}
      {/*  }}*/}
      {/*/>*/}
      <DashboardStyle.Inner>
        <DashboardStyle.Wrap>
          <DashboardStyle.CalendarArea>
            <DashboardCalendar workDate={workDate} date={date} setDate={setDate} />
            <DashboardInfo />
          </DashboardStyle.CalendarArea>
          <DashboardStyle.DataArea>
            <DashboardStyle.ChartArea>
              <DashboardTotalChart date={date} />
              <DashboardTypeChart data={typeData} />
              <DashboardFacilityChart data={typeData} />
            </DashboardStyle.ChartArea>
            <DashboardStyle.TableArea>
              <DashboardMaterialList date={date} />
            </DashboardStyle.TableArea>
          </DashboardStyle.DataArea>
        </DashboardStyle.Wrap>
      </DashboardStyle.Inner>
    </DashboardStyle.Contain>
  );
};

export default Dashboard;
