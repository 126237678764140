import { useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from '../useMutateHook';
import queryKeys, { calendarKeys, pipeKeys } from '../keys/queryKeys';
/* 
   queryKey,
   queryFn,
   그 외 options 설정
*/

export function useSites({ category, keyword, pageNum }) {
  return useQuery({
    queryKey: [...keys.siteKeys.list({ pageNum })],
    queryFn: () => api.sites.getSites({ category, keyword, pageNum }),
  });
}

export function useSiteDetail({ siteId }) {
  return useQuery({
    queryKey: [...keys.siteKeys.detail(siteId)],
    queryFn: () => api.sites.getSiteDetail({ siteId }),

    enabled: !!siteId && siteId !== 'null',
    gcTime: Infinity,
    refetchOnMount: false,
  });
}

export function useSiteAdd(params) {
  return useMutateHook({
    fetchApi: () => api.sites.siteAdd(params),
    invalidQuery: [...keys.siteKeys.all, ...keys.authKeys.all],
  });
}
