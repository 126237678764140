import styled from 'styled-components';
import IcoPopClose from '../../../assets/images/ico-pop-close.svg';
import IcoBuilding from '../../../assets/images/ico-building.svg';

export const PopupStyle = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
  `,
  Popup: styled.div<{ $width?: number }>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: ${(p) => p.theme.popup.cont.shadow};
    border-radius: 4px;
    background: ${(p) => p.theme.popup.cont.bg};
    width: ${(p) => (p.$width ? p.$width + 'px' : '1200px')};

    .thumbSwiper {
      padding: 12px 23px;
      background: #000;

      .swiper-scrollbar {
        opacity: 1;
        height: 6px;
        margin-top: 8px;
        background: #364257;
        border-radius: 50px;

        .swiper-scrollbar-drag {
          height: 6px;
          background: #67758b;
          border-radius: 50px;
        }
      }

      .swiper-slide {
        border-radius: 4px;
        overflow: hidden;

        &.swiper-slide-thumb-active {
          &:after {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 2px solid #0076ff;
            border-radius: 4px;
          }
        }
      }
    }
  `,
  Head: styled.div<{ $height?: number; $align?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    background: ${(p) => p.theme.popup.head.bg};
    padding-left: 24px;
    border-radius: 4px 4px 0 0;
    justify-content: ${(p) => (p.$align === 'left' ? 'flex-start' : 'center')};
    height: ${(p) => (p.$height ? p.$height + 'px' : '56px')};
    border-bottom: 1px solid ${(p) => p.theme.popup.head.border};
  `,
  Title: styled.h4`
    color: ${(p) => p.theme.popup.head.title};
    font-size: 16px;
    font-weight: 600;
  `,
  Inner: styled.div<{ $padding?: string }>`
    padding: ${(p) => (p.$padding ? p.$padding : '16px 24px')};
  `,
  CloseBtn: styled.button<{ $right?: number; $top?: number }>`
    position: absolute;
    transform: ${(p) => (p.$top ? 'none' : 'translateY(-50%)')};
    width: 42px;
    height: 42px;
    right: ${(p) => (p.$right ? p.$right + 'px' : '16px')};
    transition: 0.4s;
    top: ${(p) => (p.$top ? p.$top + 'px' : '50%')};
    background: url(${IcoPopClose}) no-repeat center;
    border-radius: 4px;

    &:hover {
      background-color: ${(p) => p.theme.popup.head.btnHoverColor};
    }

    &:active {
      background-color: ${(p) => p.theme.popup.head.btnActiveColor};
    }
  `,
  Cont: styled.div``,
  ChartArea: styled.div`
    flex: 1;
    background: ${(p) => p.theme.chart.bg};
    border: 1px solid ${(p) => p.theme.chart.border};
    box-sizing: border-box;
    border-radius: 4px;
  `,
  Foot: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    height: 80px;
    border-top: 1px solid ${(p) => p.theme.popup.foot.border};
  `,
  FootDesc: styled.p`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: ${(p) => p.theme.popup.foot.color};

    b {
      color: #0076ff;
      font-size: 12px;
      font-weight: 500;
      margin: 0 2px 0 5px;
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${(p) => p.theme.popup.foot.ico}) no-repeat center;
      margin-right: 10px;
    }
  `,
  ImageTitle: styled.strong`
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 21px;
  `,
  ImageBox: styled.div`
    width: 100%;
    height: 600px;
    text-align: center;
    background: #000;
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    > img {
      height: 100%;
    }
  `,
  ImageList: styled.ul`
    background: #000;
    border-radius: 0 0 4px 4px;
  `,
  ImageItem: styled.div`
    position: relative;
    width: 120px;
    height: 90px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 12px;
    }

    > img {
      height: 100%;
    }
  `,
};

export const SurveyHeaderSt = {
  Wrap: styled.div`
    height: 64px;
    background-color: ${(p) => p.theme.survey.header.bg};
    border-radius: 4px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px 0 #0000001a;
    margin-bottom: 20px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.survey.header.border};
  `,
  TitleWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    > .ico {
      width: 24px;
      height: 24px;
      background: url(${IcoBuilding}) no-repeat center;
    }
    .title {
      color: #0076ff;
      font-size: 18px;
      font-weight: 600;
    }
  `,
  BtnWrap: styled.div`
    display: flex;
    gap: 8px;
  `,
  Button: styled.button<{ Ico?: string }>`
    font-weight: 500;
    font-size: 14px;
    padding: 8px 12px;
    border: 1px solid ${(p) => p.theme.survey.header.btnBorder};
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 6px;
    > .ico {
      width: 24px;
      height: 24px;
      background: ${(p) => `url(${p.Ico}) no-repeat center`};
    }
    color: ${(p) => p.theme.survey.header.btnColor};
    &:hover {
      transition: 0.4s;
      background-color: rgba(72, 128, 255, 0.15);
      border-color: #0076ff;
      color: #0076ff;
      > .ico {
        transition: 0.4s;
        filter: invert(28%) sepia(52%) saturate(3804%) hue-rotate(203deg) brightness(103%) contrast(104%);
      }
    }
  `,
  FileLabel: styled.label<{ Ico?: string }>`
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    padding: 8px 12px;
    border: 1px solid ${(p) => p.theme.survey.header.btnBorder};
    border-radius: 2px;
    display: flex;
    align-items: center;
    gap: 6px;
    > .ico {
      width: 24px;
      height: 24px;
      background: ${(p) => `url(${p.Ico}) no-repeat center`};
    }
    color: ${(p) => p.theme.survey.header.btnColor};
    &:hover {
      transition: 0.4s;
      background-color: rgba(72, 128, 255, 0.15);
      border-color: #0076ff;
      color: #0076ff;
      > .ico {
        transition: 0.4s;
        filter: invert(28%) sepia(52%) saturate(3804%) hue-rotate(203deg) brightness(103%) contrast(104%);
      }
    }
  `,
};
