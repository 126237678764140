import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Table from '../atoms/Table';
import { DashboardStyle, PopupStyle, TableListStyle } from './_style';
import { DashboardMaterialProps } from './_interface';
import PopupStatistics from '../../popups/PopupStatistics';
import useAlert from '../../hooks/useAlert';
import { SelectProps } from '../../pages/_interface';
import { DateObject } from 'react-multi-date-picker';
import { TableStyle } from '../atoms/_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import SkeletonFacilityTable from '../skeleton/SkeletonFacilityTable';
import PopupPipeDiameterLength from '../../popups/PopupPipeDiameterLength';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';
import { sites } from '../../hooks/RQuery/service/apiUrl';

interface MaterialListDataProps {
  code?: string;
  diameter?: number;
  totalCount: number;
  totalDistance?: number;
  facilityKind: string;
  hol?: number | string;
  vel?: number | string;
  type: string;
}

const DashboardMaterialList = ({ date }: { date: DateObject }) => {
  const { t } = useTranslation();
  const [popupState, setPopupState] = useState({
    statistics: false,
    diameterLength: false,
  }); //팝업 on/off

  const [initTableData, setInitTableData] = useState<MaterialListDataProps[]>([]); //테이블 데이터 리스트 sort 초기화 별도 저장
  const [tableData, setTableData] = useState<MaterialListDataProps[]>([]); //테이블 데이터 리스트
  const [facilityOption, setFacilityOption] = useState<SelectProps[]>([]); //관종 리스트
  const [typeOption, setTypeOption] = useState<SelectProps[]>([]); //유형 리스트
  const [codeOption, setCodeOption] = useState<SelectProps[]>([]); //구분 리스트
  const [sortStatus, setSortStatus] = useState({ key: 'none', sort: 'none' }); //sort 상태
  const [themeMode] = useRecoilState(themeState);
  //테이블 데이터 쿼리
  // const list = useStatisticsQuery.useFacilityListQuery(String(sessionStorage.getItem('siteId')), date.format('YYYY-MM-DD'));
  const list = query.statistics.useMaterials({ siteId: sessionStorage.getItem('siteId'), date: date.format('YYYY-MM-DD') });
  //공통 코드 쿼리
  const code = query.codes.useCodes();

  //옵션 변경 이벤트
  const onChangeOption = (code: string, state: SelectProps[], setState: Dispatch<SetStateAction<SelectProps[]>>) => {
    const newArr = [...state];
    newArr.map((i) => {
      i.active = code === i.value;
    });
    setState(newArr);
  };

  //th 클릭시 오름차순/내림차순/초기화 순으로 정렬 반복 이벤트. 다른 th 클릭시 해당 컬럼 부터 다시 시작
  const onSort = (key: string) => {
    if (key === sortStatus.key) {
      if (sortStatus.sort === 'none') {
        setSortStatus({ key: key, sort: 'upper' });
      } else if (sortStatus.sort === 'upper') {
        setSortStatus({ key: key, sort: 'lower' });
      } else {
        setSortStatus({ key: 'none', sort: 'none' });
      }
    } else {
      setSortStatus({ key: key, sort: 'upper' });
    }
  };

  //팝업 on/off 함수
  const onPopupOnOff = (key: 'statistics' | 'diameterLength', bool: boolean) => {
    setPopupState({ ...popupState, [key]: bool });
  };

  //코드에 따른 타입 리턴
  const typeReturn = (code) => {
    if (code.includes('FOR')) {
      return 'MANHOLE';
    } else if (code.includes('PFR')) {
      return 'FIRE';
    } else if (code.includes('PVE')) {
      return 'VALVE';
    } else if (code.includes('PIP')) {
      return 'PIPE';
    }
  };

  //테이블 리스트 호출
  useEffect(() => {
    if (list.data && code.data) {
      const newArr: MaterialListDataProps[] = []; //테이블 데이터 저장
      const newArr2: MaterialListDataProps[] = []; //sort초기화 리스트 별도 저장

      list.data.map((item) => {
        newArr.push({ ...item, type: typeReturn(item.code) });
        newArr2.push({ ...item, type: typeReturn(item.code) });
      });

      //정렬값이 있을 때 데이터 정렬
      if (sortStatus.sort === 'lower') {
        newArr.sort((a: any, b: any) => {
          return a[sortStatus.key] < b[sortStatus.key] ? -1 : a[sortStatus.key] > b[sortStatus.key] ? 1 : 0;
        });
      } else if (sortStatus.sort === 'upper') {
        newArr.sort((a: any, b: any) => {
          return a[sortStatus.key] > b[sortStatus.key] ? -1 : a[sortStatus.key] < b[sortStatus.key] ? 1 : 0;
        });
      }
      setTableData(newArr); //정렬유무 반경하여 리스트 저장
      setInitTableData(newArr2); //sort초기화 리스트 별도 저장
      //관종 리스트
      const newFacility: SelectProps[] = [];
      code.data?.FACILITY.map((i: any, index: number) => {
        if (newArr.filter(({ facilityKind }) => facilityKind === i.code).length) {
          newFacility.push({ value: i.code, text: i.value, active: false });
        }
      });
      if (newFacility.length) {
        newFacility[0].active = true;
      }
      setFacilityOption(newFacility);

      //유형
      const newType: SelectProps[] = [];
      const newArrFilterFacility = newArr.filter((i) => i.facilityKind === newFacility[0].value);
      if (newArrFilterFacility.filter((i) => i.type === 'PIPE').length) {
        newType.push({ text: '파이프', value: 'PIPE', active: false });
      }
      if (newArrFilterFacility.filter((i) => i.type === 'MANHOLE').length) {
        newType.push({ text: '맨홀', value: 'MANHOLE', active: false });
      }
      if (newArrFilterFacility.filter((i) => i.type === 'VALVE').length) {
        newType.push({ text: '밸브', value: 'VALVE', active: false });
      }
      if (newArrFilterFacility.filter((i) => i.type === 'FIRE').length) {
        newType.push({ text: '소화전', value: 'FIRE', active: false });
      }
      if (newType.length) {
        newType[0].active = true;
      }
      setTypeOption(newType);

      //구분
      const newCode: SelectProps[] = [];
      const newArrFilterType = newArr.filter((i) => i.type === newType[0].value);
      //파이프
      code.data?.PIP.map((i: any, index: number) => {
        if (newArrFilterType.filter(({ code }) => code === i.code).length) {
          newCode.push({ value: i.code, text: i.value, active: false });
        }
      });
      //맨홀
      code.data?.FOR.map((i: any, index: number) => {
        if (newArrFilterType.filter(({ code }) => code === i.code).length) {
          newCode.push({ value: i.code, text: i.value, active: false });
        }
      });
      //소화전
      code.data?.PFR.map((i: any, index: number) => {
        if (newArrFilterType.filter(({ code }) => code === i.code).length) {
          newCode.push({ value: i.code, text: i.value, active: false });
        }
      });
      //밸브
      code.data?.PVE.map((i: any, index: number) => {
        if (newArrFilterType.filter(({ code }) => code === i.code).length) {
          newCode.push({ value: i.code, text: i.value, active: false });
        }
      });
      if (newCode.length) {
        newCode[0].active = true;
      }
      setCodeOption(newCode);
    }
  }, [list.data, code.data]);

  //카테고리 선택에 따른 2depth 변경(유형)
  useEffect(() => {
    if (facilityOption.filter(({ active }) => active).length) {
      const newArr: MaterialListDataProps[] = []; //테이블 데이터 저장

      list.data.map((item) => {
        newArr.push({ ...item, type: typeReturn(item.code) });
      });

      //정렬값이 있을 때 데이터 정렬
      if (sortStatus.sort === 'lower') {
        newArr.sort((a: any, b: any) => {
          return a[sortStatus.key] < b[sortStatus.key] ? -1 : a[sortStatus.key] > b[sortStatus.key] ? 1 : 0;
        });
      } else if (sortStatus.sort === 'upper') {
        newArr.sort((a: any, b: any) => {
          return a[sortStatus.key] > b[sortStatus.key] ? -1 : a[sortStatus.key] < b[sortStatus.key] ? 1 : 0;
        });
      }
      setTableData(newArr); //정렬유무 반경하여 리스트 저장

      //유형
      const newType: SelectProps[] = [];
      const newArrFilterFacility = newArr.filter((i) => i.facilityKind === facilityOption.filter(({ active }) => active)[0].value);
      if (newArrFilterFacility.filter((i) => i.type === 'PIPE').length) {
        newType.push({ text: '파이프', value: 'PIPE', active: false });
      }
      if (newArrFilterFacility.filter((i) => i.type === 'MANHOLE').length) {
        newType.push({ text: '맨홀', value: 'MANHOLE', active: false });
      }
      if (newArrFilterFacility.filter((i) => i.type === 'VALVE').length) {
        newType.push({ text: '밸브', value: 'VALVE', active: false });
      }
      if (newArrFilterFacility.filter((i) => i.type === 'FIRE').length) {
        newType.push({ text: '소화전', value: 'FIRE', active: false });
      }
      if (newType.length) {
        newType[0].active = true;
      }
      setTypeOption(newType);
    }
  }, [facilityOption]);

  //카테고리 선택에 따른 3depth 변경(구분)
  useEffect(() => {
    if (typeOption.filter(({ active }) => active).length) {
      const newArr: MaterialListDataProps[] = []; //테이블 데이터 저장

      list.data.map((item) => {
        newArr.push({ ...item, type: typeReturn(item.code) });
      });

      //정렬값이 있을 때 데이터 정렬
      if (sortStatus.sort === 'lower') {
        newArr.sort((a: any, b: any) => {
          return a[sortStatus.key] < b[sortStatus.key] ? -1 : a[sortStatus.key] > b[sortStatus.key] ? 1 : 0;
        });
      } else if (sortStatus.sort === 'upper') {
        newArr.sort((a: any, b: any) => {
          return a[sortStatus.key] > b[sortStatus.key] ? -1 : a[sortStatus.key] < b[sortStatus.key] ? 1 : 0;
        });
      }
      setTableData(newArr); //정렬유무 반경하여 리스트 저장

      //구분
      const newCode: SelectProps[] = [];
      const newArrFilterFacility = newArr.filter((i) => i.facilityKind === facilityOption.filter(({ active }) => active)[0].value);
      const newArrFilterType = newArrFilterFacility.filter((i) => i.type === typeOption.filter(({ active }) => active)[0].value);
      //파이프
      if (typeOption.filter(({ active }) => active)[0].value === 'PIPE') {
        code.data?.PIP.map((i: any, index: number) => {
          if (newArrFilterType.filter(({ code }) => code === i.code).length) {
            newCode.push({ value: i.code, text: i.value, active: false });
          }
        });
        //맨홀
      } else if (typeOption.filter(({ active }) => active)[0].value === 'MANHOLE') {
        code.data?.FOR.map((i: any, index: number) => {
          if (newArrFilterType.filter(({ code }) => code === i.code).length) {
            newCode.push({ value: i.code, text: i.value, active: false });
          }
        });
        //소화전
      } else if (typeOption.filter(({ active }) => active)[0].value === 'FIRE') {
        code.data?.PFR.map((i: any, index: number) => {
          if (newArrFilterType.filter(({ code }) => code === i.code).length) {
            newCode.push({ value: i.code, text: i.value, active: false });
          }
        });
        //밸브
      } else if (typeOption.filter(({ active }) => active)[0].value === 'VALVE') {
        code.data?.PVE.map((i: any, index: number) => {
          if (newArrFilterType.filter(({ code }) => code === i.code).length) {
            newCode.push({ value: i.code, text: i.value, active: false });
          }
        });
      }
      if (newCode.length) {
        newCode[0].active = true;
      }
      setCodeOption(newCode);
    }
  }, [typeOption]);

  //onSort 함수 실행 시 테이블 정렬 트리거
  useEffect(() => {
    const newArr = [...tableData];
    if (sortStatus.sort === 'lower') {
      newArr.sort((a: any, b: any) => {
        return a[sortStatus.key] < b[sortStatus.key] ? -1 : a[sortStatus.key] > b[sortStatus.key] ? 1 : 0;
      });
      setTableData(newArr);
    } else if (sortStatus.sort === 'upper') {
      newArr.sort((a: any, b: any) => {
        return a[sortStatus.key] > b[sortStatus.key] ? -1 : a[sortStatus.key] < b[sortStatus.key] ? 1 : 0;
      });
      setTableData(newArr);
    } else {
      setTableData(initTableData);
    }
  }, [sortStatus]);

  return (
    <>
      <DashboardStyle.SectionBox>
        <SkeletonFacilityTable />
        <DashboardStyle.TitleArea>
          <DashboardStyle.Title>{t('자재목록')}</DashboardStyle.Title>
          {/*<TableListStyle.BtnDiameterLength*/}
          {/*  style={{ margin: '0 8px 0 auto' }}*/}
          {/*  type={'button'}*/}
          {/*  onClick={() => {*/}
          {/*    onPopupOnOff('diameterLength', true);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  관경별 연장 보기*/}
          {/*</TableListStyle.BtnDiameterLength>*/}
          {/*<TableListStyle.BtnStatistic*/}
          {/*  type={'button'}*/}
          {/*  onClick={() => {*/}
          {/*    onPopupOnOff('statistics', true);*/}
          {/*    //alert test*/}
          {/*    // onAlertOpen('alert','전체통계보기', '전체통계보기를 눌렀읍니다.')*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {t('전체통계보기')}*/}
          {/*</TableListStyle.BtnStatistic>*/}
        </DashboardStyle.TitleArea>
        <DashboardStyle.DescArea className={themeMode === 'light' ? 'cScroll light' : 'cScroll'}>
          <TableListStyle.Cont>
            <TableListStyle.SelectArea>
              {/* 관종 */}
              <TableListStyle.SelectWrap>
                <dt>
                  <span>{t('종류')}</span>
                </dt>
                {facilityOption.map((i) => {
                  return (
                    <dd key={i.value} className={i.active ? 'active' : undefined} onClick={() => onChangeOption(String(i.value), facilityOption, setFacilityOption)}>
                      {t(i.text)}
                    </dd>
                  );
                })}
              </TableListStyle.SelectWrap>

              {/* 유형 */}
              <TableListStyle.SelectWrap>
                <dt>
                  <span>{t('유형')}</span>
                </dt>
                {typeOption.map((i) => {
                  return (
                    <dd key={i.value} className={i.active ? 'active' : undefined} onClick={() => onChangeOption(String(i.value), typeOption, setTypeOption)}>
                      {t(i.text)}
                    </dd>
                  );
                })}
              </TableListStyle.SelectWrap>

              {/* 구분 */}
              <TableListStyle.SelectWrap>
                <dt>
                  <span>{t('구분')}</span>
                </dt>
                {codeOption.map((i) => {
                  return (
                    <dd key={i.value} className={i.active ? 'active' : undefined} onClick={() => onChangeOption(String(i.value), codeOption, setCodeOption)}>
                      {t(i.text)}
                    </dd>
                  );
                })}
              </TableListStyle.SelectWrap>
            </TableListStyle.SelectArea>
            <Table
              caption={'자재 목록'}
              colgroup={typeOption.filter(({ active }) => active)[0]?.value === 'PIPE' ? ['33%', '30%', 'auto'] : ['50%', 'auto']}
              height={'calc(100% - 20px)'}
              tbodyHeight={'calc(100% - 60px)'}
              thead={
                <tr>
                  <th scope={'col'}>
                    <TableStyle.SortBtn $status={sortStatus} $key={'diameter'} type={'button'} onClick={() => onSort('diameter')}>
                      {typeOption.filter(({ active }) => active)[0]?.value === 'PIPE' ? t('관경') : t('규격')}(mm)
                    </TableStyle.SortBtn>
                  </th>
                  <th scope={'col'}>
                    <TableStyle.SortBtn $status={sortStatus} $key={'totalCount'} type={'button'} onClick={() => onSort('totalCount')}>
                      {t('전체수량')}(ea)
                    </TableStyle.SortBtn>
                  </th>
                  {typeOption.filter(({ active }) => active)[0]?.value === 'PIPE' ? (
                    <th scope={'col'}>
                      <TableStyle.SortBtn $status={sortStatus} $key={'totalDistance'} type={'button'} onClick={() => onSort('totalDistance')}>
                        {t('관경별물량')}(m)
                      </TableStyle.SortBtn>
                    </th>
                  ) : null}
                </tr>
              }
              tbody={
                <>
                  {tableData
                    .filter(({ facilityKind }) => facilityKind === facilityOption.filter(({ active }) => active)[0]?.value)
                    .filter(({ type }) => type === typeOption.filter(({ active }) => active)[0]?.value)
                    .filter(({ code }) => code === codeOption.filter(({ active }) => active)[0]?.value)
                    .map((i, index) => {
                      return (
                        <tr key={index}>
                          <td>{i.diameter}</td>
                          <td>{i.totalCount.toLocaleString('ko-KR')}</td>
                          {typeOption.filter(({ active }) => active)[0].value === 'PIPE' ? <td>{i.totalDistance?.toLocaleString('ko-KR')}</td> : null}
                        </tr>
                      );
                    })}
                </>
              }
            />
          </TableListStyle.Cont>
        </DashboardStyle.DescArea>
      </DashboardStyle.SectionBox>
      {popupState.statistics ? <PopupStatistics onPopupOnOff={onPopupOnOff} /> : null}
      {popupState.diameterLength ? <PopupPipeDiameterLength onPopupOnOff={onPopupOnOff} /> : null}
    </>
  );
};

export default DashboardMaterialList;
