import styled from 'styled-components';

export const SkeletonStyle = {
  Wrap: styled.div`
    position: absolute;
    z-index: 20;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    @keyframes loading {
      0% {
        background: rgba(0, 0, 0, 0.1);
      }
      50%,
      100% {
        background: rgba(200, 200, 200, 0.3);
      }
    }
    @keyframes loading2 {
      0% {
        border-color: rgba(0, 0, 0, 0.1);
      }
      50%,
      100% {
        border-color: rgba(200, 200, 200, 0.3);
      }
    }

    .skeleton-item {
      position: relative;
      overflow: hidden;
      border-radius: 2px;

      animation: loading 1s infinite alternate linear;
    }
  `,
  Head: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    background: ${(p) => p.theme.dashboard.box.titleArea};
    padding: 0 24px;
  `,
  TitleArea: styled.div`
    width: 100px;
    height: 23px;
  `,
  BtnGroup: styled.div`
    display: flex;
    align-items: center;
    > div:not(:last-child) {
      margin-right: 8px;
    }
  `,
  BtnArea: styled.div`
    width: 145px;
    height: 40px;
  `,
  CalendarBtnArea: styled.div`
    width: 23px;
    height: 23px;
  `,
  Cont: styled.div`
    position: relative;
    height: calc(100% - 52px);
    background: ${(p) => p.theme.dashboard.box.cont};
  `,
  CalendarInner: styled.div`
    padding: 32px 24px 12px;
  `,
  CalendarToday: styled.div`
    position: absolute !important;
    width: 61px;
    height: 18px;
    right: 23px;
    top: 12px;
  `,
  CalendarDayGroup: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  CalendarWeek: styled.div`
    width: 26px;
    height: 13px;
  `,
  CalendarDay: styled.div`
    width: 26px;
    height: 26px;
  `,
  SiteInfoInner: styled.div`
    padding: 25px 24px;
  `,
  SiteInfoRow: styled.div`
    &:not(:last-child) {
      margin-bottom: 23px;
    }
  `,
  SiteInfoName: styled.div`
    width: 42px;
    height: 16px;
    margin-bottom: 8px;
  `,
  SiteInfoValue: styled.div`
    width: 100%;
    height: 16px;
  `,
  TotalRadial: styled.div`
    position: relative;
    min-width: 168px;
    height: 168px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 9px solid rgba(0, 0, 0, 0.1);
    animation: loading2 1s infinite alternate linear;
    margin-right: 32px;
  `,
  PipeRadial: styled.div`
    position: relative;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 9px solid rgba(0, 0, 0, 0.1);
    animation: loading2 1s infinite alternate linear;
  `,
  TotalBarGroup: styled.div`
    &:not(:last-child) {
      margin-bottom: 22px;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }
  `,
};
