import { request } from 'service/api/common';
import * as apiUrl from './apiUrl';

// 대시보드, 작업일보 캘린더
export const getCalendar = async ({ siteId }) => {
  const { data } = await request('d2').get(apiUrl.calendar.all, { params: { siteId } });
  return data.data;
};

// 측량성과 날짜삭제 팝업 내 캘린더(points)
export const getFacilityPointCalendar = async ({ siteId, facilityKind }) => {
  const { data } = await request('d2').get(apiUrl.calendar.facility_point, { params: { siteId, facilityKind } });
  return data.data;
};

// 측량성과 날짜삭제 팝업 내 캘린더(pipes)
export const getFacilityPipeCalendar = async ({ siteId, facilityKind }) => {
  const { data } = await request('d2').get(apiUrl.calendar.facility_pipe, { params: { siteId, facilityKind } });
  return data.data;
};
