import { request, requestFormData } from '../../../service/api/common';
import * as apiUrl from './apiUrl';
import { division, dragImageResize, resizeFile } from '../../../util/imageResizer';
import { showResultPopup } from '../../../components/templates/survey/common.func';
import iconv from 'iconv-lite';
import { Buffer } from 'buffer';

//포인트에 대한 이미지 조회
export const getFileImages = async ({ pointId, facilityKind }) => {
  const { data } = await request('d2').get(apiUrl.files.getImages, { params: { pointId, facilityKind } });
  return data.data;
};

//측량점 이미지 등록
export const addPointImage = async ({ pointId, siteId, pitCde, facilityKind, pointImageTypeNum, file }: { pointId: string; siteId: string; pitCde: string; facilityKind: string; pointImageTypeNum: number; file: File }) => {
  //측량점 이미지 종류
  const pointImageTypes = ['NEAR_IMAGE', 'DISTANT_IMAGE', 'EL_NEAR_IMAGE', 'EL_DISTANT_IMAGE', 'NEAR_IMAGE', 'DISTANT_IMAGE', 'EL_NEAR_IMAGE', 'EL_DISTANT_IMAGE'];
  const pointImageType = pointImageTypes[pointImageTypeNum - 1];

  //파일명 변경 ({ptNum}-1.jpg)
  const { type, name } = file || {};
  let typeSplit = type?.split('/'); // ['image', 'png'];
  // let newName = `${wtlPipePtNum}-${pointImageTypeNum}.${typeSplit[1]}`;
  const newFile = (await resizeFile(file)) as File;
  // formData 생성
  let formdata = new FormData();
  formdata.append('image', newFile);

  const { data } = await requestFormData('d2').post(apiUrl.files.addImage, formdata, { params: { pointId: pointId, siteId: siteId, pitCde: pitCde, facilityKind: facilityKind, pointImageType: pointImageType } });
  return data.data;
};

// 측점, 관로 csv 업로드  TODO 삭제예정
export const postCsvUpload = async ({ csvFile, siteId }) => {
  // formData 생성
  let formdata = new FormData();

  // 파일을 읽는 부분을 Promise로 감쌉니다.
  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  };

  try {
    const text = (await readFileAsText(csvFile)) as any;
    const file = new File([text], csvFile.name, { type: 'text/csv;charset=utf-8;' });
    formdata.append('csvFile', file); // 파일1개

    const { data } = await requestFormData('d2').post(apiUrl.files.addCsv, formdata, {
      params: { siteId: siteId },
      // 파일 업로드 시, progressBar 수치
      onUploadProgress: function (evt: any) {
        var percentComplete = (evt.loaded / evt.total) * 100;

        const progressBar: any = document.getElementById('progress');
        if (progressBar) {
          progressBar.style.width = percentComplete + '%';
        }
      },
    });
    return data;
  } catch (e) {
    console.error('파일을 읽는 중 오류가 발생했습니다:', e);
    throw e;
  }
};

// 측점, 관로 csv 체크
export const postCsvCheckBack = async ({ csvFile, siteId }) => {
  // formData 생성
  let formdata = new FormData();

  // 파일을 읽는 부분을 Promise로 감쌉니다.
  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  };

  const text = (await readFileAsText(csvFile)) as any;
  const file = new File([text], csvFile.name, { type: 'text/csv;charset=utf-8;' });
  formdata.append('csvFile', file); // 파일1개

  const { data } = await requestFormData('d2').post(apiUrl.files.checkCsv, formdata, {
    params: { siteId: siteId },
    // 파일 업로드 시, progressBar 수치
    onUploadProgress: function (evt: any) {
      var percentComplete = (evt.loaded / evt.total) * 100;

      const progressBar: any = document.getElementById('progress');
      if (progressBar) {
        progressBar.style.width = percentComplete + '%';
      }
    },
  });
  //   throw new Error();
  return data;
};

// 측점, 관로 csv 체크
export const postCsvCheck = async ({ csvFile, siteId }) => {
  // formData 생성
  let formdata = new FormData();

  const readFileAsText = (csvFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const cp949Buffer = Buffer.from(arrayBuffer as any);
        const utf8String = iconv.decode(cp949Buffer, 'cp949');
        // Blob을 생성하여 다운로드 링크를 설정
        const utf8Blob = new Blob([utf8String], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(utf8Blob);
        resolve(utf8String);
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(csvFile);
    });
  };

  return readFileAsText(csvFile).then(async (res) => {
    const file = new File([res as any], csvFile.name, { type: 'text/csv;charset=utf-8;' });
    formdata.append('csvFile', file); // 파일1개

    const { data } = await requestFormData('d2').post(apiUrl.files.checkCsv, formdata, {
      params: { siteId: siteId },
      // 파일 업로드 시, progressBar 수치
      onUploadProgress: function (evt: any) {
        var percentComplete = (evt.loaded / evt.total) * 100;

        const progressBar: any = document.getElementById('progress');
        if (progressBar) {
          progressBar.style.width = percentComplete + '%';
        }
      },
    });
    //   throw new Error();
    return data;
  });
};

// 측점, 관로 csv 체크 후 전송
export const postCsvRegistration = async ({ siteId, inspectionKey, overwrite }) => {
  const { data } = await request('d2').post(apiUrl.files.registrationCsv, { siteId, inspectionKey, overwrite });
  return data.data;
};

// 측량점 이미지 삭제
export const deleteImage = async ({ imageId }: { imageId: string }) => {
  const { data } = await request('d2').delete(apiUrl.files.deleteImage(imageId));

  return data;
};

// // 측량점 이미지 업로드
// export const postImages = async ({ siteId, imageList, setPercent, setPercentPopup, resetDnd, refetchSurveyList }) => {
//   // setLoader({ show: true });
//   // CHECK: 로더 표출
//   await dragImageResize(imageList).then((fileList) => {
//     //fileList를 50개 단위로 쪼개기
//     const list = division(fileList, 50);
//     let count = 0;
//
//     let myData: any = {
//       data: {
//         failList: [],
//         failCount: 0,
//         successCount: 0,
//       },
//     };
//
//     let myError: any = null;
//
//     const callApi = (data: File[]) => {
//       return new Promise((resolve, reject) => {
//         // formData 생성
//         let formdata = new FormData();
//
//         data?.map((ele) => {
//           formdata.append('images', ele);
//         });
//
//         requestFormData
//           .post(`v1/files/images`, formdata, { params: { siteId: siteId } })
//           .then(({ data }) => {
//             console.log('serverData', data);
//             resolve('성공');
//             myData = {
//               data: {
//                 failList: [...myData.data.failList, ...data.data.failList],
//                 failCount: myData.data.failCount + data.data.failCount,
//                 successCount: myData.data.successCount + data.data.successCount,
//               },
//             };
//             //  setProgressBar((prev) => ({ ...prev, show: false }));
//           })
//           .catch((e) => {
//             myError = e;
//             reject('실패');
//           })
//           .finally(() => {
//             count = count + 1;
//             setPercent(count / list.length);
//           });
//       });
//     };
//
//     const result = async () => {
//       for (let i = 0; i < list.length; i++) {
//         try {
//           await callApi(list[i]);
//         } catch (e) {
//           console.log(e);
//         }
//       }
//     };
//     result().finally(() => {
//       //100% 보여주기
//       count = count + 1;
//       setPercent(count / list.length);
//
//       //초기화
//       setTimeout(() => {
//         count = 0;
//         setPercent(count);
//         setPercentPopup(false);
//         // 결과 처리 - 팝업
//         showResultPopup({ type: 'image', data: myData, error: myError });
//         // dnd 영역 데이터 초기화
//         refetchSurveyList();
//
//         resetDnd();
//       }, 300);
//
//       // setLoader({ show: false });
//       // 로더 제거
//       // setProgressBar((prev) => ({ ...prev, show: false }));
//     });
//   });
// };

// 측량점 이미지 업로드
// export const postImages = async ({ siteId, imageList, setPercent, setPercentPopup, resetDnd, refetchSurveyList }) => {
//   // setLoader({ show: true });
//   // CHECK: 로더 표출
//   await dragImageResize(imageList).then((fileList) => {
//     //fileList를 50개 단위로 쪼개기
//     const list = division(fileList, 50);
//     let count = 0;
//
//     let myData: any = {
//       data: {
//         failList: [],
//         failCount: 0,
//         successCount: 0,
//       },
//     };
//
//     let myError: any = null;
//
//     const callApi = (data: File[]) => {
//       return new Promise((resolve, reject) => {
//         // formData 생성
//         let formdata = new FormData();
//
//         data?.map((ele) => {
//           formdata.append('images', ele);
//         });
//
//         requestFormData
//           .post(`v1/files/images`, formdata, { params: { siteId: siteId } })
//           .then(({ data }) => {
//             console.log('serverData', data);
//             resolve('성공');
//             myData = {
//               data: {
//                 failList: [...myData.data.failList, ...data.data.failList],
//                 failCount: myData.data.failCount + data.data.failCount,
//                 successCount: myData.data.successCount + data.data.successCount,
//               },
//             };
//             //  setProgressBar((prev) => ({ ...prev, show: false }));
//           })
//           .catch((e) => {
//             myError = e;
//             reject('실패');
//           })
//           .finally(() => {
//             count = count + 1;
//             setPercent(count / list.length);
//           });
//       });
//     };
//
//     const result = async () => {
//       for (let i = 0; i < list.length; i++) {
//         try {
//           await callApi(list[i]);
//         } catch (e) {
//           console.log(e);
//         }
//       }
//     };
//     result().finally(() => {
//       //100% 보여주기
//       count = count + 1;
//       setPercent(count / list.length);
//
//       //초기화
//       setTimeout(() => {
//         count = 0;
//         setPercent(count);
//         setPercentPopup(false);
//         // 결과 처리 - 팝업
//         showResultPopup({ type: 'image', data: myData, error: myError });
//         // dnd 영역 데이터 초기화
//         refetchSurveyList();
//
//         resetDnd();
//       }, 300);
//
//       // setLoader({ show: false });
//       // 로더 제거
//       // setProgressBar((prev) => ({ ...prev, show: false }));
//     });
//   });
// };
//

// 이미지 파일 중복 체크
export const postImageCheck = async ({ siteId, imageList }) => {
  const images = imageList?.map((image) => image.name); // 이미지 파일 이름만 전송

  const { data } = await request('d2').post(apiUrl.files.checkImages, images, { params: { siteId } });
  return data.data;
};

// 이미지 파일 업로드 survey
export const postImages = async ({ siteId, imageList, setPercentPopup, resetDnd, refetchSurveyList }) => {
  let myData: any = {
    data: {
      failList: [],
      failCount: 0,
      successCount: 0,
    },
  };
  let myError = null;

  const callApi = async (data) => {
    return new Promise(async (resolve, reject) => {
      let formdata = new FormData();

      data.forEach((ele) => {
        formdata.append('images', ele);
        console.log(ele);
      });

      requestFormData('d2')
        .post(`v1/survey/files/images`, formdata, { params: { siteId } })
        .then(({ data }) => {
          console.log('serverData', data);
          const { failList, failCount, successCount } = data.data;

          myData = {
            data: {
              failList: [...myData.data.failList, ...(failList || [])],
              failCount: myData.data.failCount + failCount,
              successCount: myData.data.successCount + successCount,
            },
          };
          resolve('성공');
        })
        .catch((e) => {
          console.error('API 요청 실패:', e); // 에러 로그 추가
          myError = e;
          reject('실패');
        });
    });
  };

  const result = async () => {
    const list = division(imageList, 50);
    try {
      console.time('imgUpload');
      // 이미지 50개마다 요청
      for (let i = 0; i < list.length; i++) {
        const resizedList = await dragImageResize(list[i]);
        const response = await callApi(resizedList);
      }
      console.timeEnd('imgUpload');
    } catch (e) {
    } finally {
      // console.log(myData);
    }
    //기존: imgUpload: 105700.41088867188 ms 105.7초 (1.7617 분)
    //수정후: imgUpload: 40010.760009765625 ms 40.0108초
  };

  result().finally(() => {
    setTimeout(() => {
      setPercentPopup(false); // 로딩 삭제
      showResultPopup({ type: 'image', data: myData, error: myError }); // 결과 팝업 오픈
      refetchSurveyList();
      resetDnd();
    }, 300);
  });
};

// csv파일 내보내기
export const getFacilitiesCsv = async ({ siteId, dateFrom, dateTo, facilityKinds }) => {
  const str = facilityKinds.map((i) => `facilityKinds=${i}`).join('&');
  const { data } = await request('d2').get(apiUrl.files.facilities(siteId) + '?' + str, {
    headers: {
      accept: 'application/octet-stream',
    },
    params: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
    responseType: 'blob',
  });

  return data;
};

//사진대지 내보내기
export const getConstructionReportCsv = async ({ siteId, dateFrom, dateTo, facilityKinds }) => {
  const str = facilityKinds.map((i) => `facilityKinds=${i}`).join('&');
  const { data } = await request('d2').get(apiUrl.files.report(siteId) + '?' + str, {
    params: {
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });
  return data;
};
