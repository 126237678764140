import React from 'react';
import { useRecoilValue } from 'recoil';
import { progressState } from 'store/Atoms';

import styled from 'styled-components';

export const LoadingBarSt = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
  `,

  Popup: styled.div`
    padding: 24px;
    box-sizing: border-box;
    width: 480px;
    height: 120px;

    background-color: #fff;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    .fileName {
      color: #0076ff;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
    .subText {
      margin-top: 2px;
      color: #242628;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .progressBar {
      margin-top: 16px;
      width: 100%;
      height: 10px;
      background-color: rgba(72, 128, 255, 0.15);
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px #0076ff33;
      overflow: hidden;
      #progress {
        width: 0; // 0
        height: 100%;
        border-radius: 5px;
        background: linear-gradient(90deg, #bcd0ff 0%, #4880ff 100%);
        line-height: 10px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        animation: progress 4s infinite linear;
        @keyframes progress {
          0% {
            width: 0;
          }
          100% {
            width: 100%;
          }
        }
      }
    }
  `,
};

// 파일 업로드시 로딩바
function LoadingBar() {
  const { fileName, domId } = useRecoilValue(progressState);
  return (
    <>
      <LoadingBarSt.Dimmed></LoadingBarSt.Dimmed>

      <LoadingBarSt.Popup>
        {/* 파일명 */}
        <p className="fileName">{`‘${fileName}’`}</p>
        {/* 안내문구 */}
        <p className="subText">{'파일을 업로드하고 있습니다.'}</p>
        {/* 진행바 progress Bar */}
        <div className="progressBar">
          <div id={domId}></div>
        </div>
      </LoadingBarSt.Popup>
    </>
  );
}

export default LoadingBar;
