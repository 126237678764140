import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';

const SkeletonTotalChart = () => {
  return (
    <SkeletonContainer
      element={
        <div style={{ padding: '22px 24px', height: 'calc(100% - 44px)', display: 'flex', alignItems: 'center' }}>
          <SkeletonStyle.TotalRadial>
            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
              <div className={'skeleton-item'} style={{ width: 42, height: 16, margin: '0 auto 12px' }}></div>
              <div className={'skeleton-item'} style={{ width: 100, height: 34 }}></div>
            </div>
          </SkeletonStyle.TotalRadial>
          <div style={{ flex: 1 }}>
            <SkeletonStyle.TotalBarGroup>
              <div className={'row'}>
                <div className={'skeleton-item'} style={{ width: 54, height: 16 }}></div>
                <div className={'skeleton-item'} style={{ width: 32, height: 16 }}></div>
              </div>
              <div className={'skeleton-item'} style={{ width: '100%', height: 6 }}></div>
            </SkeletonStyle.TotalBarGroup>
            <SkeletonStyle.TotalBarGroup>
              <div className={'row'}>
                <div className={'skeleton-item'} style={{ width: 54, height: 16 }}></div>
                <div className={'skeleton-item'} style={{ width: 32, height: 16 }}></div>
              </div>
              <div className={'skeleton-item'} style={{ width: '100%', height: 6 }}></div>
            </SkeletonStyle.TotalBarGroup>
            <SkeletonStyle.TotalBarGroup>
              <div className={'row'}>
                <div className={'skeleton-item'} style={{ width: 54, height: 16 }}></div>
                <div className={'skeleton-item'} style={{ width: 32, height: 16 }}></div>
              </div>
              <div className={'skeleton-item'} style={{ width: '100%', height: 6 }}></div>
            </SkeletonStyle.TotalBarGroup>
          </div>
        </div>
      }
    />
  );
};

export default SkeletonTotalChart;
