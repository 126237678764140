// point , pipe
export const getFileType = (file: any) => {
  let fileType = '';
  if (file) {
    let type = file.type;
    let name = file.name;
    if (type.startsWith('image/')) fileType = 'image';
    if (name.includes('point.csv')) fileType = 'point';
    if (name.includes('pipe.csv')) fileType = 'pipe';
  }
  return fileType;
};
