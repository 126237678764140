import React, { useRef, useState, useEffect, useCallback } from 'react';
import { SurveyInitSt } from 'pages/_style';
import { useTranslation } from 'react-i18next';

import PopupUpload from 'components/templates/survey/popup/PopupUpload';
import DragDropBoxPage from 'components/templates/drag&drop/DragDropBoxPage';

import { useRecoilState, useResetRecoilState } from 'recoil';
import { progressState } from 'store/Atoms';
import { dragDropBoxPage, popupUpload, popupResultState } from 'store/popupAtoms';
//api
import useMutateHook from 'hooks/RQuery/useMutateHook';
import { showFileCheckPopup, showResultPopup } from './common.func';
import { FILE_TYPES } from 'util/global';
import queryKeys from 'hooks/RQuery/keys/queryKeys';
import query from '../../../hooks/RQuery/utils';

// 데이터 없을때 최초 실행
// DragDropBoxPage
function PinUpload({ siteInfo, setFirstCall }) {
  const siteId = siteInfo?.id || '';
  const { t, i18n } = useTranslation();

  const resetDndPg = useResetRecoilState(dragDropBoxPage);

  //   const [dndState, setdndState] = useRecoilState(dragDropBox);
  const [dndPgState, setdndPgState] = useRecoilState(dragDropBoxPage);
  const [popUploadState, setPopUploadState] = useRecoilState(popupUpload);

  const [progressBar, setProgressBar] = useRecoilState(progressState);

  const { mutate: mutateCsv } = query.smartpin.pin.useUploadPins({ siteId: siteId, csvFile: dndPgState.contentFileArr[0] });

  // csv 전송 (건너뛰기, 덮어쓰기)
  const { mutate: registCsv } = query.files.useRegistrationCsv();

  //   console.log(dndState);
  //   console.log(dndPgState);

  // 초기화
  useEffect(() => {
    resetDndPg();
  }, []);

  // csv 업로드
  const postCsv = async () => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndPgState?.contentFileArr[0]?.name }));
    await mutateCsv(
      { siteId, csvFile: dndPgState.contentFileArr[0] },
      {
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));

          // dnd 영역 데이터 초기화
          resetDndPg();

          // 결과 처리 - 팝업
          showResultPopup({ type: dndPgState.type, data, error });
        },
      },
    );
  };

  // 🔥 유저에게 파일을 받으면, 자동으로 업로드 함수 요청(버튼 클릭 등 없음)
  // 파일 1개
  useEffect(() => {
    // 조건 추가 -
    if (!popUploadState.isOpen) {
      if (dndPgState.contentFileArr[0]) {
        postCsv();
      }
    }
  }, [dndPgState.contentFileArr]);

  const curItem = FILE_TYPES.find((ele) => ele.type === 'point');
  const acceptType = curItem?.acceptType || [];
  const multiple = curItem?.multiple || false;

  //   console.log('result', popUploadState);
  return (
    <>
      {/* 파일업로드 로딩바 */}
      {/* {showLoader && <LoadingBar />} */}

      {/* 업로드 실패 팝업 - 웹에서 체크 결과 */}
      {/* <PopupFail /> */}

      {/* 업로드용 팝업 - DragDropBox 포함 */}
      {/* {popUploadState.isOpen && <PopupUpload siteInfo={siteInfo} />} */}

      {/* 성공 or 실패 팝업 */}
      {/* <PopupResult /> */}

      <SurveyInitSt.PageWrap>
        {/* 공통 헤더 */}
        {/*<SurveyHeader onBack={() => {}} siteInfo={{ id: '', name: '' }} />*/}

        {/* 도움말 있는 버전 및 csv 파일 1개 가능 */}
        <DragDropBoxPage height={420} acceptType={acceptType} mainText={t('업로드안내')} subText={t('업로드안내서브')} multiple={multiple} curItem={curItem} isPin={true} />
      </SurveyInitSt.PageWrap>
    </>
  );
}

export default PinUpload;
