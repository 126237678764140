import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import { useQueryClient } from '@tanstack/react-query';
import query from '../../../hooks/RQuery/utils';
import { DateObject } from 'react-multi-date-picker';
import { OptionProps } from '../../atoms/_interface';
import { FilterProps } from '../_interface';
import { SurveyStyle } from '../_style';

import { SurveyInitSt } from '../../../pages/_style';
import DesignUpload from './DesignUpload';
import { useParams } from 'react-router-dom';
import olCore from '../../../maps/wfs/OlCore';
import OlCore from '../../../maps/wfs/OlCore';
import Design from '../../../maps/wfs/Object/Design';
import { FullScreen } from 'ol/control.js';
import { OSM } from 'ol/source';
import { View } from 'ol';
import styled from 'styled-components';
import Table from '../../atoms/Table';
import PagingTable from '../../atoms/PagingTable';
import { PagingTableStyle } from '../../atoms/_style';
import { useTranslation } from 'react-i18next';
import IcoBurger from '../../../assets/images/ico-burger.svg';
import IcoBurgerDark from '../../../assets/images/ico-burger-dark.svg';
import { SurveyHeaderSt } from '../popup/_style';
import ConfirmAlert from '../ConfirmAlert';

const MapBox = {
  Inner: styled.div`
    height: calc(100% - 67px);
    padding: 22px 24px 45px;
    flex-direction: column;
  `,
  Wrap: styled.div`
    margin-top: 17px;
    display: flex;
    flex: 1;
    width: 100%;

    flex-direction: column;
  `,
  ContInfo: styled.div`
    display: flex;
    height: 56px;
    border: 1px solid ${(p) => p.theme.design.contInfo.border};
    border-radius: 4px;
  `,
  ContLeft: styled.div`
    display: flex;
    align-items: center;
    flex: 1;
  `,
  ContRight: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${(p) => p.theme.design.contInfo.border};
  `,
  ContInner: styled.div`
    padding: 0 24px;
    display: flex;
    align-items: center;
  `,
  LabelText: styled.div`
    display: flex;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      background: rgba(72, 128, 255, 0.14);
      border-radius: 2px;
      padding: 0 8px;
      color: #0076ff;
      font-size: 12px;
      font-weight: 500;
      margin-right: 12px;
    }
    p {
      color: ${(p) => p.theme.design.contInfo.text};
      font-weight: 400;
      font-size: 14px;
    }
    &:not(:last-child) {
      margin-right: 40px;
    }
  `,
};

const DesignContent = ({ siteInfo, categoryTabList, setCategoryTabList }) => {
  const { t } = useTranslation();
  const siteId = String(sessionStorage.getItem('survey_siteId'));
  const facilityKind = categoryTabList.find(({ active }) => active)?.key;
  const { data, isSuccessAll } = query.codes.useCodes(); // 코드 조회
  const design = query.designs.useGetDesignDetail({ siteId: siteInfo.id, facilityKinds: siteInfo.facilityKindList.split(',') });
  const deleteDesign = query.designs.useDeleteDesign({ siteId: siteInfo.id, facilityKind });
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [designData, setDesignData] = useState<Design>();
  const [isObjectReady, setIsObjectReady] = useState(false);
  const [alertPop, setAlertPop] = useState({ isOpen: false, mainText: '', subText: '' });
  // 현재 팝업 - 삭제 버튼 클릭 이벤트
  const onClickDelete = () => {
    //확인 팝업 오픈
    setAlertPop((prev) => ({ ...prev, isOpen: true, mainText: '설계 도면 파일을 삭제하시겠습니까?', subText: t('데이터복구불가') }));
  };
  // 삭제 확인 팝업 - 닫기
  const closeAlertPop = () => {
    setAlertPop((prev) => ({ ...prev, isOpen: false }));
  };
  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const design = new Design(core);

      // ** FullScreen 컨트롤 삭제
      core.mapInstance.getControls().forEach(function (control) {
        if (control instanceof FullScreen) {
          core.mapInstance.removeControl(control);
        }
      });

      setMapInstance(core);
      setDesignData(design);
      setIsObjectReady(true);
    }
  }, []);
  useEffect(() => {
    if (isObjectReady) {
      mapInstance?.getMapInstance().setView(
        new View({
          projection: String(sessionStorage.getItem('survey_projection')),
        }),
      );
      mapInstance?.mapChange(mapInstance!.osmMapSource);
    }
  }, [isObjectReady]);

  //설계도면 그리기(초기 off상태)
  useEffect(() => {
    if (isObjectReady) {
      designData?.draw(design.data);
      setTimeout(() => {
        designData?.showDesign(facilityKind);
      }, 100);
    }
  }, [isObjectReady, design.data]);

  //선택된 탭 설계도면 on
  useEffect(() => {
    if (design.isSuccessAll && categoryTabList.filter(({ active }) => active).length) {
      designData?.showDesign(categoryTabList.find(({ active }) => active).key);
    }
  }, [design.isSuccessAll && categoryTabList.find(({ active }) => active)]);

  //facilityKind 탭 선택(상수/오수/우수/...)
  const onCategoryClick = (state: any, setState: Dispatch<SetStateAction<any>>, key: string) => {
    const newArr = [...state];
    newArr.map((i) => {
      i.active = i.key === key;
    });
    setState(newArr);
  };

  //현장에서 등록 가능한 관종류 탭으로 셋
  useEffect(() => {
    if (isSuccessAll) {
      const newArr: FilterProps[] = [];
      const facilityKindList = siteInfo.facilityKindList.split(',');
      facilityKindList.map((i, index) => {
        newArr.push({ name: data.FACILITY.find(({ code }) => code === i).value, key: i, active: index === 0 });
      });
      setCategoryTabList(newArr);
    }
  }, [isSuccessAll]);
  return (
    <>
      <SurveyStyle.DescArea>
        <SurveyStyle.CategoryArea>
          <SurveyStyle.CategoryWrap>
            {/* 카테고리 리스트*/}
            {categoryTabList.length
              ? categoryTabList?.map((i, index) => {
                  return (
                    <SurveyStyle.CategoryItem key={i.key} className={i.active ? 'active' : undefined} onClick={() => onCategoryClick(categoryTabList, setCategoryTabList, i.key)}>
                      <span></span>
                      {t(i.name)}
                    </SurveyStyle.CategoryItem>
                  );
                })
              : null}
          </SurveyStyle.CategoryWrap>
        </SurveyStyle.CategoryArea>
        <SurveyStyle.DescWrap>
          <MapBox.Inner style={{ display: design.isSuccessAll && design.data[categoryTabList.find(({ active }) => active)?.key]?.lines.length ? 'flex' : 'none' }}>
            <MapBox.ContInfo>
              <MapBox.ContLeft>
                <MapBox.ContInner>
                  <MapBox.LabelText>
                    <span>파일명</span>
                    <p>{design.data[categoryTabList.find(({ active }) => active)?.key]?.fileName}</p>
                  </MapBox.LabelText>
                  <MapBox.LabelText>
                    <span>등록일</span>
                    <p>{design.data[categoryTabList.find(({ active }) => active)?.key]?.createdDate}</p>
                  </MapBox.LabelText>
                </MapBox.ContInner>
              </MapBox.ContLeft>
              <MapBox.ContRight>
                <MapBox.ContInner>
                  <SurveyHeaderSt.Button
                    style={{ marginRight: 8 }}
                    onClick={() => {
                      onClickDelete();
                    }}
                  >
                    {t('설계도면파일삭제')}
                  </SurveyHeaderSt.Button>
                </MapBox.ContInner>
              </MapBox.ContRight>
            </MapBox.ContInfo>
            <MapBox.Wrap>
              <div
                id={'mapContainer'}
                style={{
                  flex: 1,
                }}
              ></div>
            </MapBox.Wrap>
          </MapBox.Inner>

          <SurveyInitSt.PageWrap style={{ display: design.isSuccessAll && design.data[categoryTabList.find(({ active }) => active)?.key]?.lines.length ? 'none' : 'block' }}>
            <DesignUpload height={420} mainText={t('설계도면파일(DXF)을등록해주세요') + '\n' + t('설계도면을시공현황과비교해볼수있습니다')} multiple={false} siteId={siteId} facilityKind={facilityKind} acceptType={['.dxf']} />
          </SurveyInitSt.PageWrap>
        </SurveyStyle.DescWrap>
      </SurveyStyle.DescArea>
      {/* 삭제 확인 알림창  */}
      {alertPop.isOpen && (
        <ConfirmAlert
          title={t('설계 도면 파일 삭제')}
          close={closeAlertPop}
          mainText={alertPop.mainText}
          subText={alertPop.subText}
          leftText={t('취소')}
          rightText={t('삭제')}
          leftFunc={closeAlertPop}
          rightFunc={() => {
            deleteDesign.mutate(
              { siteId: siteInfo.id, facilityKind },
              {
                onSettled: () => {
                  closeAlertPop();
                },
              },
            );
          }}
        />
      )}
    </>
  );
};

export default DesignContent;
