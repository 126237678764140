import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const LegendStyle = {
  Contain: styled.div`
    margin-top: 20px;
  `,
  Wrap: styled.div`
    display: flex;
    align-items: center;
    padding-left: 8px;
  `,
  Title: styled.strong`
    color: ${(p) => p.theme.chart.tooltip.text};
    font-size: 16px;
    font-weight: 600;
  `,
  List: styled.ul`
    display: flex;
    align-items: center;
    margin-left: auto;
  `,
  Item: styled.li<{ $type: string }>`
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.chart.tooltip.text};
    &:not(:last-child) {
      margin-right: 21px;
    }
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-width: 2px;
      border-style: solid;
      margin-right: 8px;
      border-radius: 2px;
      border-color: ${(p) => (p.$type === 'pip01' ? '#0076FF' : p.$type === 'pip02' ? '#49AEFF' : '#B0D1F1')};
      background: ${(p) => (p.$type === 'pip01' ? 'rgba(0, 118, 255, 0.15)' : p.$type === 'pip02' ? 'rgba(73, 174, 255, 0.15)' : 'rgba(231, 243, 255, 0.15)')};
    }
  `,
  Unit: styled.p`
    display: flex;
    justify-content: flex-end;
    margin: 14px 0 8px;
    color: ${(p) => p.theme.chart.tooltip.unit};
    font-size: 12px;
    font-weight: 500;
  `,
};

const CustomLegend = ({ payload, title, unit }: any) => {
  const { t } = useTranslation();
  return (
    <LegendStyle.Contain>
      <LegendStyle.Wrap>
        <LegendStyle.Title>{title}</LegendStyle.Title>
        <LegendStyle.List>
          {payload.map((i: any) => {
            return (
              <LegendStyle.Item key={i.value} $type={i.value.includes('PIP01') ? 'pip01' : i.value.includes('PIP02') ? 'pip02' : 'pip03'}>
                {i.value.includes('PIP01') ? t('직관') : i.value.includes('PIP02') ? t('단관') : t('이형관')} {unit === 'm' ? t('연장') : t('수량')}
              </LegendStyle.Item>
            );
          })}
        </LegendStyle.List>
      </LegendStyle.Wrap>
      <LegendStyle.Unit>
        {t('단위')}({t(unit)})
      </LegendStyle.Unit>
    </LegendStyle.Contain>
  );
};

export default CustomLegend;
