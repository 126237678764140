import React from 'react';
import { MapScaleStyle } from './_style';
import { MapScaleProps } from './_interface';

const MapScale = ({ mapScaleNum, id }: MapScaleProps) => {
  return (
    <MapScaleStyle.Wrap>
      <MapScaleStyle.Label>{mapScaleNum}</MapScaleStyle.Label>
      <MapScaleStyle.Bar id={id}></MapScaleStyle.Bar>
    </MapScaleStyle.Wrap>
  );
};

export default MapScale;
