import React from 'react';
import { LoadingBarSt } from './LoadingBar';

const ImageProgress = ({ width }) => {
  return (
    <>
      <LoadingBarSt.Dimmed></LoadingBarSt.Dimmed>

      <LoadingBarSt.Popup>
        {/* 안내문구 */}
        <p className="subText">{'파일을 업로드하고 있습니다.'}</p>
        {/* 진행바 progress Bar */}
        <div className="progressBar">
          <div id={'progress'}></div>
        </div>
      </LoadingBarSt.Popup>
    </>
  );
};

export default ImageProgress;
