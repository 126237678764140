import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';

const SkeletonFacilityChart = () => {
  return (
    <SkeletonContainer
      element={
        <div style={{ padding: '22px 24px', height: 'calc(100% - 44px)', display: 'flex', alignItems: 'center' }}>
          <SkeletonStyle.TotalRadial style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SkeletonStyle.TotalRadial style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', minWidth: 140, height: 140 }}>
              <SkeletonStyle.TotalRadial style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', minWidth: 112, height: 112 }}></SkeletonStyle.TotalRadial>
            </SkeletonStyle.TotalRadial>
          </SkeletonStyle.TotalRadial>
          <div style={{ flex: 1 }}>
            <SkeletonStyle.TotalBarGroup>
              <div className={'row'}>
                <div className={'skeleton-item'} style={{ width: 64, height: 16 }}></div>
                <div className={'skeleton-item'} style={{ width: 42, height: 16 }}></div>
              </div>
              <div className={'skeleton-item'} style={{ width: '100%', height: 6 }}></div>
            </SkeletonStyle.TotalBarGroup>
            <SkeletonStyle.TotalBarGroup>
              <div className={'row'}>
                <div className={'skeleton-item'} style={{ width: 64, height: 16 }}></div>
                <div className={'skeleton-item'} style={{ width: 42, height: 16 }}></div>
              </div>
              <div className={'skeleton-item'} style={{ width: '100%', height: 6 }}></div>
            </SkeletonStyle.TotalBarGroup>
            <SkeletonStyle.TotalBarGroup>
              <div className={'row'}>
                <div className={'skeleton-item'} style={{ width: 64, height: 16 }}></div>
                <div className={'skeleton-item'} style={{ width: 42, height: 16 }}></div>
              </div>
              <div className={'skeleton-item'} style={{ width: '100%', height: 6 }}></div>
            </SkeletonStyle.TotalBarGroup>
          </div>
        </div>
      }
    />
  );
};

export default SkeletonFacilityChart;
