import { useState, useLayoutEffect } from 'react';
//react-query
import { QueryKey, QueryObserverOptions, UndefinedInitialDataOptions, useQuery, useQueryClient } from '@tanstack/react-query';

// useQuery - Base
// 쿼리로 받아온 데이터를 바로 사용하지 않고 state 저장후 사용시 깜빡임 개선
export function useQueryState(keys: any, fetchApi: any, options?: QueryObserverOptions) {
  //   const { data, isLoading, isSuccess, error, refetch } = useQuery({ queryKey: keys, queryFn: fetchApi, ...options });
  const myQuery = useQuery({ queryKey: keys, queryFn: fetchApi, ...options });
  const [propData, setPropData] = useState<any>(null);

  useLayoutEffect(() => {
    if (myQuery.data) {
      setPropData(myQuery.data);
    }
  }, [myQuery.data]);

  //   return { data: propData, isLoading, isSuccess, error, refetch };
  return { ...myQuery, data: propData };
}

// 예시) query-key 이용하여 데이터 가져오기
export function useGetQueryData(keys: any[]) {
  const queryClient = useQueryClient();
  return queryClient.getQueryData(keys);
}
