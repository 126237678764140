import React, { useEffect, useState } from 'react';
import { PopupStyle } from '../_style';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Scrollbar } from 'swiper/modules';
import { PopupReportImageProps } from '../_interface';
import NoImg from 'assets/images/no-img.svg';
import { escKeyModalClose } from '../../../util/global';
import { useTranslation } from 'react-i18next';

const PopupReportImage = ({ onPopupClose, thumbsSwiper, setThumbsSwiper, stImages, endImages, initialSlide, detailInfo }: PopupReportImageProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.addEventListener('keydown', (e) => escKeyModalClose(e, () => onPopupClose()));
    return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, () => onPopupClose()));
  }, []);
  return (
    <>
      <PopupStyle.Dimmed>
        <PopupStyle.CloseBtn type={'button'} onClick={onPopupClose} $top={20} $right={20} $isDimmed={true}></PopupStyle.CloseBtn>
      </PopupStyle.Dimmed>
      <PopupStyle.Popup $width={800} style={{ boxShadow: 'none', background: 'none' }}>
        <Swiper thumbs={{ swiper: thumbsSwiper }} modules={[Thumbs]} className={'mainSwiper'} initialSlide={initialSlide}>
          <SwiperSlide>
            <PopupStyle.ImageTitle>
              {detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum} / {t('근경')}
            </PopupStyle.ImageTitle>
            <PopupStyle.ImageBox>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE')[0].imageUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? 'auto' : 140 }}
              />
            </PopupStyle.ImageBox>
          </SwiperSlide>
          <SwiperSlide>
            <PopupStyle.ImageTitle>
              {detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum} / {t('원경')}
            </PopupStyle.ImageTitle>
            <PopupStyle.ImageBox>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE')[0].imageUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? 'auto' : 140 }}
              />
            </PopupStyle.ImageBox>
          </SwiperSlide>
          <SwiperSlide>
            <PopupStyle.ImageTitle>
              {detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum} / {t('근경(지반고)')}
            </PopupStyle.ImageTitle>
            <PopupStyle.ImageBox>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE')[0].imageUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? 'auto' : 140 }}
              />
            </PopupStyle.ImageBox>
          </SwiperSlide>
          <SwiperSlide>
            <PopupStyle.ImageTitle>
              {detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum} / {t('원경(지반고)')}
            </PopupStyle.ImageTitle>
            <PopupStyle.ImageBox>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE')[0].imageUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? 'auto' : 140 }}
              />
            </PopupStyle.ImageBox>
          </SwiperSlide>

          {detailInfo.img ? (
            <>
              <SwiperSlide>
                <PopupStyle.ImageTitle>
                  {detailInfo.img.endPointPtNum} / {t('근경')}
                </PopupStyle.ImageTitle>
                <PopupStyle.ImageBox>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE')[0].imageUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? 'auto' : 140 }}
                  />
                </PopupStyle.ImageBox>
              </SwiperSlide>
              <SwiperSlide>
                <PopupStyle.ImageTitle>
                  {detailInfo.img.endPointPtNum} / {t('원경')}
                </PopupStyle.ImageTitle>
                <PopupStyle.ImageBox>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE')[0].imageUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? 'auto' : 140 }}
                  />
                </PopupStyle.ImageBox>
              </SwiperSlide>
              <SwiperSlide>
                <PopupStyle.ImageTitle>
                  {detailInfo.img.endPointPtNum} / {t('근경(지반고)')}
                </PopupStyle.ImageTitle>
                <PopupStyle.ImageBox>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE')[0].imageUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? 'auto' : 140 }}
                  />
                </PopupStyle.ImageBox>
              </SwiperSlide>
              <SwiperSlide>
                <PopupStyle.ImageTitle>
                  {detailInfo.img.endPointPtNum} / {t('원경(지반고)')}
                </PopupStyle.ImageTitle>
                <PopupStyle.ImageBox>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE')[0].imageUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? 'auto' : 140 }}
                  />
                </PopupStyle.ImageBox>
              </SwiperSlide>
            </>
          ) : null}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={12}
          slidesPerView={6}
          watchSlidesProgress={true}
          scrollbar={{
            hide: false,
            draggable: true,
          }}
          modules={[Scrollbar, Thumbs]}
          className={'thumbSwiper'}
        >
          <SwiperSlide>
            <PopupStyle.ImageItem>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE')[0].imageThumbUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? 'auto' : 40 }}
              />
            </PopupStyle.ImageItem>
          </SwiperSlide>
          <SwiperSlide>
            <PopupStyle.ImageItem>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE')[0].imageThumbUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? 'auto' : 40 }}
              />
            </PopupStyle.ImageItem>
          </SwiperSlide>
          <SwiperSlide>
            <PopupStyle.ImageItem>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE')[0].imageThumbUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? 'auto' : 40 }}
              />
            </PopupStyle.ImageItem>
          </SwiperSlide>
          <SwiperSlide>
            <PopupStyle.ImageItem>
              <img
                src={stImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? stImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE')[0].imageThumbUrl : NoImg}
                alt={detailInfo.img ? detailInfo.img.stPointPtNum : detailInfo.ptNum}
                style={{ width: stImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? 'auto' : 40 }}
              />
            </PopupStyle.ImageItem>
          </SwiperSlide>

          {detailInfo.img ? (
            <>
              <SwiperSlide>
                <PopupStyle.ImageItem>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE')[0].imageThumbUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'NEAR_IMAGE').length ? 'auto' : 40 }}
                  />
                </PopupStyle.ImageItem>
              </SwiperSlide>
              <SwiperSlide>
                <PopupStyle.ImageItem>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE')[0].imageThumbUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'DISTANT_IMAGE').length ? 'auto' : 40 }}
                  />
                </PopupStyle.ImageItem>
              </SwiperSlide>
              <SwiperSlide>
                <PopupStyle.ImageItem>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE')[0].imageThumbUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'EL_NEAR_IMAGE').length ? 'auto' : 40 }}
                  />
                </PopupStyle.ImageItem>
              </SwiperSlide>
              <SwiperSlide>
                <PopupStyle.ImageItem>
                  <img
                    src={endImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? endImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE')[0].imageThumbUrl : NoImg}
                    alt={detailInfo.img ? detailInfo.img.endPointPtNum : detailInfo.ptNum}
                    style={{ width: endImages.filter(({ imageType }) => imageType === 'EL_DISTANT_IMAGE').length ? 'auto' : 40 }}
                  />
                </PopupStyle.ImageItem>
              </SwiperSlide>
            </>
          ) : null}
        </Swiper>
      </PopupStyle.Popup>
    </>
  );
};

export default PopupReportImage;
