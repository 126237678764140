import React, { useState, useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { dragDropBox, popupResultState, popupUploadSelect } from 'store/popupAtoms';
import PopupFrame from './PopupFrame';
import Select from 'components/atoms/Select';
import DragDropBox from 'components/templates/drag&drop/DragDropBox';
//api
import useMutateHook from 'hooks/RQuery/useMutateHook';
import { loaderState, progressState } from 'store/Atoms';
import { showFileCheckPopup, showResultPopup } from '../common.func';
import { FILE_TYPES } from 'util/global';

import { requestFormData } from '../../../../service/api/common';
import ImageProgress from '../../../atoms/ImageProgress';
import { useQueryClient } from '@tanstack/react-query';
import queryKeys from 'hooks/RQuery/keys/queryKeys';
import { useTranslation } from 'react-i18next';
import query from '../../../../hooks/RQuery/utils';
import { postCsvRegistration } from 'hooks/RQuery/service/files';
import BasicDragDrop from 'components/templates/drag&drop/BasicDragDrop';

// 상단에서 타입 먼저 선택 후, 파일 업로드 하는 팝업
function PopupUploadSelect({ siteInfo, setFirstCall }) {
  const { t } = useTranslation();
  const siteId = siteInfo?.id || '';
  const resetDnd = useResetRecoilState(dragDropBox);
  const [percent, setPercent] = useState(0);
  const [percentPopup, setPercentPopup] = useState(false);
  const setLoader = useSetRecoilState(loaderState);

  const [dndState, setdndState] = useRecoilState(dragDropBox);
  const [popSelectState, setPopSelectState] = useRecoilState(popupUploadSelect);
  const [popResultState, setPopResultState] = useRecoilState(popupResultState);

  const [progressBar, setProgressBar] = useRecoilState(progressState);

  const [options, setOptions] = useState([
    { active: false, value: 'point', text: '측점 CSV 파일 업로드' },
    { active: false, value: 'pipe', text: '관로 CSV 파일 업로드' },
    { active: false, value: 'image', text: '이미지 파일 업로드' },
  ]);
  const [selected, setSelected] = useState({ value: '', text: '' }); // 선택된 항목

  // csv 파일 체크
  const { mutate: checkCsv } = query.files.useCheckCsv({ siteId: siteId, csvFile: dndState.contentFileArr[0] });

  // csv 전송 (건너뛰기, 덮어쓰기)
  const { mutate: registCsv } = query.files.useRegistrationCsv();

  const refetchSurveyList = () => {
    queryClient.invalidateQueries({ queryKey: [queryKeys.MainKey.SURVEY] });
  };

  // 이미지 파일 체크
  const { mutate: checkImages } = query.files.useCheckImages({ siteId: siteId, imageList: dndState.contentFileArr });

  //이미지 다수 업로드
  const { mutate: postImages } = query.files.useAddImages({ siteId: siteId, imageList: dndState.contentFileArr, setPercentPopup: setPercentPopup, resetDnd: resetDnd, refetchSurveyList: refetchSurveyList });

  const queryClient = useQueryClient();
  const curItem = FILE_TYPES.find((ele) => ele.type === selected.value);
  const acceptType = curItem?.acceptType || [];
  const multiple = curItem?.multiple || false;

  const isImg = selected.text.includes('이미지');
  const leftText = '취소';
  const leftFunc = () => closePopup();
  const rightText = '등록';
  const rightFunc = () => {
    onClickUpload();
  };

  //[등록] 버튼 클릭 이벤트
  const onClickUpload = () => {
    console.log('등록하기', dndState.type, dndState.contentFileArr);

    if (dndState.contentFileArr.length > 0) {
      // csv 파일 (측점, 관로)
      if (dndState.type === 'point' || dndState.type === 'pipe') {
        checkMutateCsv();
      }
      // 이미지
      if (dndState.type === 'image') {
        mutateImg();
      }
    }

    //파일명 에러 처리하기
    // mutate
    // 측점, 관로 csv 파일
    //  if (selected.value === 'point' || selected.value === 'pipe') {
    //    if (dndState.contentFileArr.length > 0) {
    //      if (isBeforeDivideServer) {
    //        mutateCsv();
    //      } else {
    //        checkMutateCsv();
    //      }
    //    }
    //  }
    //  // 이미지 파일
    //  if (selected.value === 'image') {
    //    if (dndState.contentFileArr.length > 0) {
    //      mutateImg();
    //    }
    //  }
  };

  // 이미지 등록
  const mutateImg = async () => {
    // 1) 이미지 체크 진행
    await checkImages(
      {},
      {
        async onSuccess(data: any, variables, context) {
          console.log(data);
          const { duplicateList, failCount, failList, successCount } = data;
          const isDuplicate = duplicateList.length > 0;
          if (!isDuplicate) {
            // 2) 기존처럼 이미지 등록
            uploadImages();
          } else {
            // 3) 건너뛰기&덮어쓰기 팝업 오픈
            showFileCheckPopup({
              type: dndState.type,
              data,
              overwriteFunc: () => uploadImages(), // 2) 기존처럼 이미지 등록
              skipFunc: () => uploadImages(duplicateList), // 중복 데이터를 제거한 파일만 전송
            });
          }
        },
        onError(error, variables, context) {
          console.log(error);
        },
        onSettled(data, error, variables, context) {},
      },
    );

    // [공통] 이미지 파일 필터링(필요시) -> 서버 전송
    const uploadImages = async (duplicateList: string[] = []) => {
      console.log(duplicateList);

      setPercentPopup(true);
      // 이미지 파일 - 필터링 (중복 데이터를 제거한 파일만 전송)
      await setdndState((prev) => {
        let newImgList = [...prev.contentFileArr].filter((file) => !duplicateList.includes(file.name));
        console.log('newImgList', newImgList);

        return duplicateList.length > 0 ? { ...prev, contentFileArr: newImgList } : prev;
      });

      await postImages({}); // 이미지 전송
    };
  };

  // 중복데이터 유무 확인 및 csv파일 서버에 임시 저장 요청
  const checkMutateCsv = async () => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndState?.contentFileArr[0]?.name }));

    await checkCsv(
      { siteId, csvFile: dndState.contentFileArr[0] },
      {
        onSuccess: (data: any, variables, context) => {
          setFirstCall(false);

          console.log(data);
          const response = { ...data?.data };
          const isDuplicate = response.ptNumDuplicateList.length > 0;
          // 중복데이터 없을때 - 기존처럼 csv 서버 전송 후 결과 팝업
          if (!isDuplicate) {
            registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true });
          } else {
            //중복 데이터 있을 때 - 팝업 오픈
            showFileCheckPopup({
              type: dndState.type,
              data,
              overwriteFunc: () => registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true }),
              skipFunc: () => registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: false }),
            });
          }
        },
        onError(error, variables, context) {
          showResultPopup({ type: dndState.type, error });
        },
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));
          // [성공]
          //  if (data) {
          //    console.log(data);
          //    const response = { ...data?.data };
          //    const isDuplicate = response.ptNumDuplicateList.length > 0;
          //    // 중복데이터 없을때 - 기존처럼 csv 서버 전송 후 결과 팝업
          //    if (!isDuplicate) {
          //      registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true });
          //    } else {
          //      //중복 데이터 있을 때 - 팝업 오픈
          //      showFileCheckPopup({ type: dndState.type, data, registMutateCsv });
          //    }
          //  }
          //  // [에러] - 결과 팝업
          //  if (error) {
          //    showResultPopup({ type: dndState.type, data, error });
          //  }
        },
      },
    );
  };

  // 서버에 파일 건너뛰기 또는 덮어쓰기 요청
  const registMutateCsv = async ({ siteId, inspectionKey, overwrite }) => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndState?.contentFileArr[0]?.name }));

    // 서버
    await registCsv(
      { siteId, inspectionKey, overwrite },
      {
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));

          // dnd 영역 데이터 초기화
          resetDnd();

          // 결과 처리 - 팝업
          showResultPopup({ type: dndState.type, data, error });
        },
        onSuccess: () => {
          setFirstCall(false);
        },
      },
    );
  };

  // 파일 종류 select onChange 이벤트
  const onChangeSelect = (e, name) => {
    const { ariaValueText, innerText } = e.currentTarget;

    setOptions((prev) => {
      return prev.map((ele) => (ele?.text === innerText ? { ...ele, active: true } : { ...ele, active: false }));
    });
    setSelected({ value: ariaValueText, text: innerText }); // 선택항목 저장

    resetDnd(); // dnd state 리셋
  };

  // 현재 팝업 닫기
  const closePopup = () => {
    setPopSelectState((prev) => ({
      ...prev,
      isOpen: false,
    }));
    resetDnd();
  };

  return (
    <>
      <PopupFrame title="파일 업로드" close={closePopup} leftText={leftText} rightText={rightText} leftFunc={leftFunc} rightFunc={rightFunc}>
        {/* <Select placeHolder="파일 유형 선택" option={options} onChange={onChangeSelect} /> */}
        <div style={{ marginTop: '7px' }}>
          {/* <DragDropBox acceptType={acceptType} multiple={multiple} curItem={curItem} /> */}

          <BasicDragDrop />
        </div>
      </PopupFrame>
      {percentPopup ? <ImageProgress width={percent} /> : null}
    </>
  );
}

export default PopupUploadSelect;
