import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';

const SkeletonCalendarCont = () => {
  const month = [1, 2, 3, 4, 5, 6];
  const week = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  return (
    <SkeletonContainer
      headerType={'calendar'}
      element={
        <SkeletonStyle.CalendarInner>
          <SkeletonStyle.CalendarToday className={'skeleton-item'}></SkeletonStyle.CalendarToday>
          <SkeletonStyle.CalendarDayGroup style={{ marginTop: 20, marginBottom: 10 }}>
            {week.map((i) => {
              return <SkeletonStyle.CalendarWeek key={i} className={'skeleton-item'}></SkeletonStyle.CalendarWeek>;
            })}
          </SkeletonStyle.CalendarDayGroup>
          {month.map((i) => {
            return (
              <SkeletonStyle.CalendarDayGroup key={i} style={{ marginTop: 6 }}>
                {week.map((j) => {
                  return <SkeletonStyle.CalendarDay key={j} className={'skeleton-item'}></SkeletonStyle.CalendarDay>;
                })}
              </SkeletonStyle.CalendarDayGroup>
            );
          })}
        </SkeletonStyle.CalendarInner>
      }
    />
  );
};

export default SkeletonCalendarCont;
