import { useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
/* 
   queryKey,
   queryFn,
   그 외 options 설정
*/

//대시보드/작업일보 캘린더
export function useCalendars({ siteId }) {
  return useQuery({
    queryKey: [...keys.calendarKeys.all(siteId)],
    queryFn: () => api.calendars.getCalendar({ siteId }),
    enabled: !!siteId && siteId !== 'null',
  });
}

//측량성과 날짜삭제 팝업 캘린더(포인트)
export function useSurveyPoints({ siteId, facilityKind, isPipe }) {
  return useQuery({
    queryKey: [...keys.calendarKeys.surveyPoints({ siteId, facilityKind })],
    queryFn: () => api.calendars.getFacilityPointCalendar({ siteId, facilityKind }),
    enabled: !!siteId && !!facilityKind && isPipe === false,
  });
}

//측량성과 날짜삭제 팝업 캘린더(파이프)
export function useSurveyPipes({ siteId, facilityKind, isPipe }) {
  return useQuery({
    queryKey: [...keys.calendarKeys.surveyPipes({ siteId, facilityKind })],
    queryFn: () => api.calendars.getFacilityPipeCalendar({ siteId, facilityKind }),
    enabled: !!siteId && !!facilityKind && isPipe === true,
  });
}
