/* 
   새로운 api 파일 추가시, import-export 작업 필요
*/
import * as sites from './sites';
import * as codes from './codes';
import * as points from './points';
import * as pipes from './pipes';
import * as workLogs from './workLogs';
import * as files from './files';
import * as statistics from './statistics';
import * as calendars from './calendars';
import * as auths from './auth';
import * as designs from './designs';
import * as smartpin from './smartpin';
import * as shps from './shps';

const api = {
  sites,
  codes,
  points,
  pipes,
  workLogs,
  files,
  statistics,
  calendars,
  auths,
  designs,
  smartpin,
  shps,
};
export default api;
