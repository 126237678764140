import React, { useState } from 'react';
import { DashboardStyle } from './_style';
import { useRecoilState } from 'recoil';
import { constructionInfoState, themeState } from '../../store/Atoms';
import SkeletonSiteInfo from '../skeleton/SkeletonSiteInfo';
import { useTranslation } from 'react-i18next';

const DashboardInfo = () => {
  const { t } = useTranslation();
  const [themeMode] = useRecoilState(themeState);
  const [info] = useRecoilState(constructionInfoState);
  const [boxState, setBoxState] = useState({
    owner: false,
    contractor: false,
    subContractor: false,
  });
  const onBoxOnOff = (str: 'owner' | 'contractor' | 'subContractor') => {
    setBoxState({ ...boxState, [str]: !boxState[str] });
  };
  return (
    <DashboardStyle.SectionBox style={{ flex: 1, marginTop: 16 }}>
      <SkeletonSiteInfo />
      <DashboardStyle.TitleArea>
        <DashboardStyle.Title>{t('시공정보')}</DashboardStyle.Title>
      </DashboardStyle.TitleArea>
      <DashboardStyle.DescArea className={themeMode === 'light' ? 'light' : undefined} style={{ height: 'calc(100% - 52px)' }}>
        <DashboardStyle.DescInner className={'cScroll'}>
          <DashboardStyle.InfoItem>
            <strong>{t('사업명')}</strong>
            <p dangerouslySetInnerHTML={{ __html: info.projectName.replace('.', '<br>') }}>{}</p>
          </DashboardStyle.InfoItem>
          <DashboardStyle.InfoItem>
            <DashboardStyle.InfoTitleRow>
              <strong>{t('발주처')}</strong>
              <button type={'button'} className={boxState.owner ? 'active' : undefined} onClick={() => onBoxOnOff('owner')}>
                <span className={'hidden'}>보이기/숨기기</span>
              </button>
            </DashboardStyle.InfoTitleRow>
            {info.owner.map((i, index) => {
              return (
                <DashboardStyle.InfoBoxGroup key={index} className={boxState.owner ? 'active' : undefined}>
                  <p>{i.companyName}</p>
                  <DashboardStyle.InfoBox className={boxState.owner ? 'active' : undefined}>
                    <DashboardStyle.InfoRow>
                      <b>{t('담당자')}</b>
                      <span>{i.companyManager}</span>
                    </DashboardStyle.InfoRow>
                    <DashboardStyle.InfoRow>
                      <b>{t('연락처')}</b>
                      <ul>
                        <li>{i.officeNumber}</li>
                        <li>{i.phoneNumber}</li>
                      </ul>
                    </DashboardStyle.InfoRow>
                    <DashboardStyle.InfoRow>
                      <b>{t('이메일')}</b>
                      <span>{i.email}</span>
                    </DashboardStyle.InfoRow>
                  </DashboardStyle.InfoBox>
                </DashboardStyle.InfoBoxGroup>
              );
            })}
          </DashboardStyle.InfoItem>
          <DashboardStyle.InfoItem>
            <DashboardStyle.InfoTitleRow>
              <strong>{t('시공사')}</strong>
              <button type={'button'} className={boxState.contractor ? 'active' : undefined} onClick={() => onBoxOnOff('contractor')}>
                <span className={'hidden'}>보이기/숨기기</span>
              </button>
            </DashboardStyle.InfoTitleRow>
            {info.contractor.map((i, index) => {
              return (
                <DashboardStyle.InfoBoxGroup key={index} className={boxState.contractor ? 'active' : undefined}>
                  <p>{i.companyName}</p>
                  <DashboardStyle.InfoBox className={boxState.contractor ? 'active' : undefined}>
                    <DashboardStyle.InfoRow>
                      <b>{t('담당자')}</b>
                      <span>{i.companyManager}</span>
                    </DashboardStyle.InfoRow>
                    <DashboardStyle.InfoRow>
                      <b>{t('연락처')}</b>
                      <ul>
                        <li>{i.officeNumber}</li>
                        <li>{i.phoneNumber}</li>
                      </ul>
                    </DashboardStyle.InfoRow>
                    <DashboardStyle.InfoRow>
                      <b>{t('이메일')}</b>
                      <span>{i.email}</span>
                    </DashboardStyle.InfoRow>
                  </DashboardStyle.InfoBox>
                </DashboardStyle.InfoBoxGroup>
              );
            })}
          </DashboardStyle.InfoItem>
          <DashboardStyle.InfoItem>
            <strong>{t('주소')}</strong>
            <p>{info.address}</p>
          </DashboardStyle.InfoItem>
          <DashboardStyle.InfoItem>
            <DashboardStyle.InfoTitleRow>
              <strong>{t('용역사')}</strong>
              <button type={'button'} className={boxState.subContractor ? 'active' : undefined} onClick={() => onBoxOnOff('subContractor')}>
                <span className={'hidden'}>보이기/숨기기</span>
              </button>
            </DashboardStyle.InfoTitleRow>
            {info.subContractor.map((i, index) => {
              return (
                <DashboardStyle.InfoBoxGroup key={index} className={boxState.subContractor ? 'active' : undefined}>
                  <p>{i.companyName}</p>
                  <DashboardStyle.InfoBox className={boxState.subContractor ? 'active' : undefined}>
                    <DashboardStyle.InfoRow>
                      <b>{t('담당자')}</b>
                      <span>{i.companyManager}</span>
                    </DashboardStyle.InfoRow>
                    <DashboardStyle.InfoRow>
                      <b>{t('연락처')}</b>
                      <ul>
                        <li>{i.officeNumber}</li>
                        <li>{i.phoneNumber}</li>
                      </ul>
                    </DashboardStyle.InfoRow>
                    <DashboardStyle.InfoRow>
                      <b>{t('이메일')}</b>
                      <span>{i.email}</span>
                    </DashboardStyle.InfoRow>
                  </DashboardStyle.InfoBox>
                </DashboardStyle.InfoBoxGroup>
              );
            })}
          </DashboardStyle.InfoItem>
        </DashboardStyle.DescInner>
      </DashboardStyle.DescArea>
    </DashboardStyle.SectionBox>
  );
};

export default DashboardInfo;
