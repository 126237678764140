import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';

const SkeletonPipeChart = () => {
  return (
    <SkeletonContainer
      element={
        <div style={{ padding: '22px 24px', height: 'calc(100% - 44px)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SkeletonStyle.PipeRadial>
            <div className={'skeleton-item'} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: 46, height: 20 }}></div>
            <div className={'skeleton-item'} style={{ position: 'absolute', left: '50%', bottom: '-35px', transform: 'translateX(-50%)', width: 32, height: 16 }}></div>
          </SkeletonStyle.PipeRadial>
          <SkeletonStyle.PipeRadial>
            <div className={'skeleton-item'} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: 46, height: 20 }}></div>
            <div className={'skeleton-item'} style={{ position: 'absolute', left: '50%', bottom: '-35px', transform: 'translateX(-50%)', width: 32, height: 16 }}></div>
          </SkeletonStyle.PipeRadial>
          <SkeletonStyle.PipeRadial>
            <div className={'skeleton-item'} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: 46, height: 20 }}></div>
            <div className={'skeleton-item'} style={{ position: 'absolute', left: '50%', bottom: '-35px', transform: 'translateX(-50%)', width: 32, height: 16 }}></div>
          </SkeletonStyle.PipeRadial>
        </div>
      }
    />
  );
};

export default SkeletonPipeChart;
