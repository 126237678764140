import { useEffect, useLayoutEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import * as keys from 'hooks/RQuery/keys/queryKeys';
import api from 'hooks/RQuery/service';
import { useCallback } from 'react';
import { getPipesOptions, getPipesQuery } from '../pipes';

// 방법 1. 미리 정의해논 파이프 조회 쿼리를 가져와서 (정상)데이터 저장 후 리턴
export function useMapPipes_fixme({ siteId, facilityKinds }) {
  const [propData, setPropData] = useState<any>(null);

  let results: any[] = []; // 쿼리 요청 결과 모음 - error, pending 등 포함
  let object: any = {}; // 리턴할 파이프 데이터 모음

  for (let i = 0; i < facilityKinds.length; i++) {
    let facility = facilityKinds[i].facilityKind; // 'WATER', 'WASTE',..
    let result = getPipesQuery({ siteId, facility }); // 쿼리
    results.push(result); // 관 종류 길이만큼 배열 추가
    object[facility] = result.data || [];
  }

  useLayoutEffect(() => {
    if (object) {
      setPropData(object);
    }
  }, []);

  const isSuccessAll = results.every((result) => result.isSuccess);

  return { data: propData, isSuccessAll };
}

//

// 방법 2.볼 수 있는 관 종류에 따라 쿼리를 여기서 정의해서 요청 후, combine 기능을 이용해서 에러까지 리턴
export function useMapPipes({ siteId, facilityKinds }) {
  let myQueries: any[] = [];

  // 1번 방법
  facilityKinds.map((facility) => {
    let options = getPipesOptions({ siteId, facility });

    myQueries.push({ ...options });
  });

  //   if (facilityKindArr.includes('WATER')) {
  //     myQueries.push({
  //       queryKey: keys.pipeKeys.wtl.all(siteId),
  //       queryFn: () => api.pipes.wtl.getPipes(siteId),
  //     });
  //   }
  //   if (facilityKindArr.includes('WASTE')) {
  //   }
  //   if (facilityKindArr.includes('RAIN')) {
  //   }
  //   if (facilityKindArr.includes('GAS')) {
  //   }
  //   if (facilityKindArr.includes('NETWORK')) {
  //   }
  //   if (facilityKindArr.includes('ELECTRIC')) {
  //   }
  //   if (facilityKindArr.includes('OIL')) {
  //   }
  //   if (facilityKindArr.includes('HEAT')) {
  //   }

  // facility 길이와 쿼리 길이가 같아야 함
  //   console.log(facilityKinds.length, myQueries.length);

  const { data, pending, isSuccessAll, isError, failureCount } = useQueries({
    queries: myQueries,
    combine: useCallback(
      (results) => {
        let newCode: any = {};

        facilityKinds.map((facility, idx) => {
          newCode[facility] = results[idx]?.data || []; // 데이터 새로운 형태로 변경
        });

        // Test: failureCount 의미 확인 필요
        let failureCount = 0;
        results.map((result) => {
          failureCount += result.failureCount;
        });

        return {
          data: newCode,
          //   data: results.map((result) => result.data || []),
          pending: results.some((result) => result.isPending),
          isSuccessAll: results.every((result) => result.isSuccess),
          isError: results.some((result) => result.isError), // 하나라도 에러가 있을때 체크해줌
          failureCount: failureCount, // 하나라도 에러가 있을때 체크?
        };
      },
      [facilityKinds],
    ),
  });

  return { data, pending, isSuccessAll, isError, failureCount };
}

// export function useSurveyMapPipes({ info }) {
//   const { siteId, facility } = info || {};
//   return getPipesQuery({ siteId, facility });
// }
