import { getImageBuffer } from '../../service/api/common';
import Fabric from './ImageTable';

const surveySheet = async (wb, sheet, list) => {
  sheet.getColumn(1).width = 20;
  sheet.getColumn(2).width = 20;
  sheet.getColumn(3).width = 20;
  sheet.getColumn(4).width = 25;
  sheet.getColumn(5).width = 25;
  sheet.getColumn(6).width = 30;
  sheet.getColumn(7).width = 20;
  sheet.getColumn(8).width = 28;
  sheet.getColumn(9).width = 20;
  sheet.getColumn(10).width = 20;
  sheet.getColumn(11).width = 24;
  sheet
    .addRows([
      ['측점명', '좌표(GRS80)', '', '표고(ELE)', '실측번호', '시설물 종류', '재질', '관경', '이격거리', '심도', '측량날짜'],
      ['', 'X(N)', 'Y(E)', '', '', '', '', '', '', '', ''],
    ])
    .forEach((i) => {
      i.eachCell((cell, colNum) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { size: 12, bold: false, color: { argb: '000000' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });
  sheet.mergeCells('B1:C1');
  sheet.mergeCells('A1:A2');
  sheet.mergeCells('D1:D2');
  sheet.mergeCells('E1:E2');
  sheet.mergeCells('F1:F2');
  sheet.mergeCells('G1:G2');
  sheet.mergeCells('H1:H2');
  sheet.mergeCells('I1:I2');
  sheet.mergeCells('J1:J2');
  sheet.mergeCells('K1:K2');
  list.forEach((i, index) => {
    let allRow = sheet.addRows([[index + 1, i.x, i.y, i.z, i.ptNum, i.facilityType, i.mopValue, i.hol && i.vel ? `${i.hol} X ${i.vel}` : i.diameter, i.separationDistance, i.depth ? i.depth : '-', i.ymd]]);
    allRow.forEach((i, index) => {
      i.eachCell((cell, colNum) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { size: 12, bold: false, color: { argb: '000000' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });
  });
  const rowHeights = 16;

  for (let i = 0; i <= list.length + 1; i++) {
    sheet.getRow(i + 1).height = rowHeights;
  }
  // 페이지 설정을 통해 한 페이지에 맞추기
  sheet.pageSetup.paperSize = 9;
  sheet.pageSetup.fitToPage = true;
  sheet.pageSetup.fitToHeight = 15;
  sheet.pageSetup.fitToWidth = 1;
  sheet.pageSetup.horizontalCentered = true; // 수평 가운데 정렬
  sheet.pageSetup.verticalCentered = true; // 수직 가운데 정렬
};

export default surveySheet;
