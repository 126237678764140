import React, { ReactNode, SetStateAction, useEffect, useState } from 'react';
import { PagingTableStyle } from './_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';

interface PagingTableProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
  caption: string;
  colgroup?: (number | string)[];
  totalPages: number;
  thead: ReactNode;
  tbody: ReactNode;
  width?: number;
  height?: string;
  leftBtnGroup?: ReactNode;
}
const PagingTable = ({ currentPage, setCurrentPage, caption, colgroup, totalPages, thead, tbody, width, height, leftBtnGroup }: PagingTableProps) => {
  const [pageArray, setPageArray] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [themeMode] = useRecoilState(themeState);

  useEffect(() => {
    const newArr: number[] = [];
    for (let i = 1; i <= 5; i++) {
      newArr.push(i + pageIndex * 5);
    }
    setPageArray(newArr);
  }, [pageIndex]);
  useEffect(() => {
    if (currentPage === 1) {
      setPageIndex(0);
    }
  }, [currentPage]);
  return (
    <>
      {/* 테이블 */}
      <div style={{ flex: 1, width: '100%', height: height }} className={themeMode === 'light' ? 'cScroll cScrollXY light' : 'cScroll cScrollXY'}>
        <PagingTableStyle.Wrap width={width}>
          <caption>{caption}</caption>
          {colgroup ? (
            <colgroup>
              {colgroup.map((i, index) => {
                return <col key={index} style={{ width: i }} />;
              })}
            </colgroup>
          ) : null}
          <PagingTableStyle.Thead>{thead}</PagingTableStyle.Thead>
          <PagingTableStyle.Tbody>{tbody}</PagingTableStyle.Tbody>
        </PagingTableStyle.Wrap>
      </div>
      <PagingTableStyle.PagiArea>
        {leftBtnGroup ? <PagingTableStyle.LeftBtnGroup>{leftBtnGroup}</PagingTableStyle.LeftBtnGroup> : null}
        <PagingTableStyle.PagiBtn
          disabled={!(pageIndex > 0)}
          className={'first'}
          onClick={() => {
            setPageIndex(0);
            setCurrentPage(1);
          }}
        ></PagingTableStyle.PagiBtn>
        <PagingTableStyle.PagiBtn
          disabled={!(pageIndex > 0)}
          className={'prev'}
          onClick={() => {
            if (pageIndex > 0) {
              setCurrentPage(1 + (pageIndex - 1) * 5);
              setPageIndex(pageIndex - 1);
            }
          }}
        ></PagingTableStyle.PagiBtn>
        <PagingTableStyle.PagiGroup>
          {pageArray.map((i) => {
            if (i <= totalPages)
              return (
                <PagingTableStyle.PagiBtn
                  key={i}
                  className={i === currentPage ? 'active' : undefined}
                  onClick={() => {
                    setCurrentPage(i);
                  }}
                >
                  {i}
                </PagingTableStyle.PagiBtn>
              );
          })}
        </PagingTableStyle.PagiGroup>

        <PagingTableStyle.PagiBtn
          disabled={!(pageIndex < totalPages / 5 - 1)}
          className={'next'}
          onClick={() => {
            if (pageIndex < totalPages / 5 - 1) {
              setCurrentPage(1 + (pageIndex + 1) * 5);
              setPageIndex(pageIndex + 1);
            }
          }}
        ></PagingTableStyle.PagiBtn>
        <PagingTableStyle.PagiBtn
          disabled={!(pageIndex < totalPages / 5 - 1)}
          className={'last'}
          onClick={() => {
            setPageIndex(Math.ceil(totalPages / 5) - 1);
            setCurrentPage(totalPages);
          }}
        ></PagingTableStyle.PagiBtn>
      </PagingTableStyle.PagiArea>
    </>
  );
};

export default PagingTable;
