import { atom } from 'recoil';
import { newPersistAtom } from './Atoms';

export const tableListPoint = atom<{ checked: any[]; unChecked: any[]; checkedPointData: any }>({
  key: 'tableListPoint',
  default: {
    checked: [],
    unChecked: [],
    checkedPointData: {},
  },
  //   effects_UNSTABLE: [newPersistAtom('tableListPoint')], // persist 적용
});

export const tableListPipe = atom<{ checked: any[]; unChecked: any[]; checkedPipeData: any }>({
  key: 'tableListPipe',
  default: {
    checked: [],
    unChecked: [],
    checkedPipeData: {},
  },
  //   effects_UNSTABLE: [newPersistAtom('tableListPipe')], // persist 적용
});
