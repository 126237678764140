import { useRecoilState } from 'recoil';
import { alertState } from '../store/Atoms';

const useAlert = () => {
  const [alertInfo, setAlertInfo] = useRecoilState(alertState);
  const onAlertOpen = (type: 'alert' | 'confirm', title?: string, desc?: string) => {
    setAlertInfo({
      type: type,
      title: title,
      desc: desc,
      active: true,
    });
  };
  const onAlertClose = () => {
    setAlertInfo({ ...alertInfo, active: false });
  };
  return { alertInfo, onAlertOpen, onAlertClose };
};

export default useAlert;
