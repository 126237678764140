// 설계도면
import { request, requestFormData } from '../../../service/api/common';
import * as apiUrl from './apiUrl';

//설계도면 불러오기
export const getDesignDetail = async ({ siteId, facilityKind }) => {
  const { data } = await request('d2').get(apiUrl.design.detail(siteId, facilityKind));
  return data.data;
};

//설계도면 업로드
export const uploadDesign = async ({ siteId, facilityKind, file }) => {
  let formdata = new FormData();
  formdata.append('designFile', file);
  const { data } = await requestFormData('d2').post(apiUrl.design.detail(siteId, facilityKind), formdata);
  return data.data;
};

//설계도면 삭제
export const deleteDesign = async ({ siteId, facilityKind }) => {
  const { data } = await request('d2').delete(apiUrl.design.detail(siteId, facilityKind));
  return data.data;
};
