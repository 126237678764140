import React from 'react';
import { useState } from 'react';
import Checkbox from 'components/atoms/Checkbox';
import { CheckboxProps } from 'components/atoms/_interface';
import styled from 'styled-components';
import IcoPin from 'assets/images/smartPin/ico-pin.svg';
export const LabelCheckboxSt = {
  Wrap: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  LabelWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  Label: styled.div`
    font-weight: 600;
    font-size: 14px;
  `,
  ColorTag: styled.div<{ $color?: string }>`
    width: 12px;
    height: 12px;
    display: ${(p) => (p.$color ? 'block' : 'none')};
    border: 2px solid ${(p) => (p.$color ? p.$color : 'transparent')};
    border-radius: 2px;
    box-sizing: border-box;
    position: relative;
    /* 투명도 배경 */
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${(p) => (p.$color ? p.$color : 'transparent')};
      opacity: 0.15;
    }
  `,
  Pin: styled.div`
    width: 12px;
    height: 12px;
    background: #f2f2f2 url(${IcoPin}) no-repeat center;
    background-size: 85%;
  `,
};

// 관 종류 리스트 + 체크박스
function LabelCheckbox({ id, text, name, onChange, checked, color, isPin, size, style }: CheckboxProps & { color?: string; isPin?: boolean }) {
  return (
    <LabelCheckboxSt.Wrap style={style}>
      <LabelCheckboxSt.LabelWrap>
        {isPin ? <LabelCheckboxSt.Pin /> : null}
        {color ? <LabelCheckboxSt.ColorTag $color={color} /> : null}
        <LabelCheckboxSt.Label>{text}</LabelCheckboxSt.Label>
      </LabelCheckboxSt.LabelWrap>
      <Checkbox id={id} name={name} onChange={onChange} checked={checked} size={size} />
    </LabelCheckboxSt.Wrap>
  );
}

export default LabelCheckbox;
