import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Survey Swiper styles
import { PopupStyle } from './_style';
import NoImg from 'assets/images/no-img.svg';
import { escKeyModalClose } from '../../../util/global';
import { useTranslation } from 'react-i18next';

function LargePinImagePopup({ info, close }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (close) {
      window.addEventListener('keydown', (e) => escKeyModalClose(e, close));
      return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, close));
    }
  }, [close]);
  return (
    <>
      <PopupStyle.Dimmed>
        <PopupStyle.CloseBtn type={'button'} onClick={close} $top={20} $right={20}></PopupStyle.CloseBtn>
      </PopupStyle.Dimmed>
      <PopupStyle.Popup $width={800} style={{ boxShadow: 'none', background: 'none' }}>
        <PopupStyle.ImageTitle>{info?.serial}</PopupStyle.ImageTitle>
        <PopupStyle.ImageBox>
          {info?.image.id ? (
            <img
              src={info.image.orgPath}
              alt={info.serial}
              onError={(e) => {
                e.currentTarget.src = NoImg;
                e.currentTarget.width = 140;
              }}
            />
          ) : null}
        </PopupStyle.ImageBox>
      </PopupStyle.Popup>
    </>
  );
}

export default LargePinImagePopup;
