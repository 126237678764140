import { request } from 'service/api/common';
import * as apiUrl from './apiUrl';

// 작업일보 일일 시공 통계
export const getStatisticsDailyConstruction = async ({ siteId, date }) => {
  const { data } = await request('d2').get(apiUrl.statistics.daily_construction, { params: { siteId: siteId, date: date } });
  return data.data;
};

// 대시보드 - 전체 공정률
export const getProcessRates = async ({ siteId, date }) => {
  const { data } = await request('d2').get(apiUrl.statistics.process_rate, { params: { siteId, date } });
  return data.data;
};

// 대시보드 - 관별 공정률
export const getProcessRateByFacilityKind = async ({ siteId, date }) => {
  const { data } = await request('d2').get(apiUrl.statistics.facilityKind_process_rate, { params: { siteId, date } });
  return data.data;
};

// 대시보드 - 재질/관경별 총 관 수량 및 시공물량 통계
export const getStatisticsMopDiameters = async ({ siteId }) => {
  const { data } = await request('d2').get(apiUrl.statistics.mopCde_diameters, { params: { siteId } });
  return data.data;
};

// 대시보드 - 재질/관경별 총 관 수량 및 시공물량 통계(전체통계 팝업)
export const getStatisticsMopDatesDiameters = async ({ siteId, from, to }) => {
  const { data } = await request('d2').get(apiUrl.statistics.mopCde_dates_diameters, { params: { siteId, from, to } });
  return data.data;
};

// 대시보드 - 자재목록
export const getStatisticsPipCdeDiameters = async ({ siteId, date }) => {
  const { data } = await request('d2').get(apiUrl.statistics.pipCde_diameters, { params: { siteId, date } });
  return data.data;
};
