import React, { useEffect, useState } from 'react';
import Table from '../atoms/Table';
import { FileDownStyle, ReportStyle, TableListStyle } from './_style';
import { DashboardMaterialProps, ReportMaterialListProps } from './_interface';
import { SelectProps } from '../../pages/_interface';
import { TableStyle } from '../atoms/_style';
import ReportPhotoInfo from './ReportPhotoInfo';
import { useRecoilState } from 'recoil';
import { constructionInfoState, loaderState, themeState } from '../../store/Atoms';
import { qsData, request } from '../../service/api/common';
import Select from '../atoms/Select';
import SkeletonConstructionTable from '../skeleton/SkeletonConstructionTable';
import ExcelJS from 'exceljs';
import { useTranslation } from 'react-i18next';
import { convertDateFormat, facilityKindByUrlCode, onExportExcel } from '../../util/global';
import query from 'hooks/RQuery/utils';
import { DateObject } from 'react-multi-date-picker';
import PopupExportDateSelect from '../../popups/PopupExportDateSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import NoData from '../atoms/NoData';

const ReportMaterialList = ({ workDate, date, onActivePipe, state }: ReportMaterialListProps) => {
  const { t } = useTranslation();

  //테이블 데이터 리스트
  const [pipeTable, setPipeTable] = useState<any>([]);
  const [pointTable, setPointTable] = useState<any>([]);
  //테이블 데이터 리스트(정렬 초기화)
  const [initPipeTable, setInitPipeTable] = useState<any>([]);
  const [initPointTable, setInitPointTable] = useState<any>([]);

  //유형
  const [type, setType] = useState<SelectProps[]>([
    { value: 'point', text: '측점', active: true },
    { value: 'pipe', text: '파이프', active: false },
  ]); //관 유형 리스트
  const [sortStatus, setSortStatus] = useState({ key: 'none', sort: 'none' }); //sort 상태
  const [tableLoad, setTableLoad] = useState(0);
  const [paramLoad, setParamLoad] = useState(false);
  const [dailyConstructionData, setDailyConstructionData] = useState<any>({
    dailyConstructionDistance: 0,
  });
  const [exportDate, setExportDate] = useState({
    from: new DateObject(),
    to: new DateObject(),
  });

  const [popupState, setPopupState] = useState(false);

  const [detailInfo, setDetailInfo] = useState<any>({
    facilityKind: 'WASTE',
    pitCde: 'PIT001',
    pointId: '',
  });
  const [winSize, setWinSize] = useState<number | undefined>(undefined);
  const [selectType, setSelectType] = useState('point');
  const [info] = useRecoilState(constructionInfoState);
  const [loader, setLoader] = useRecoilState(loaderState);
  const [themeMode] = useRecoilState(themeState);

  const navigate = useNavigate();
  const location = useLocation();
  //작업일보 데이터 쿼리
  const pipeList = query.workLogs.usePipes({ siteId: String(sessionStorage.getItem('siteId')), date: date.format('YYYY-MM-DD') });
  const pointList = query.workLogs.usePoints({ siteId: String(sessionStorage.getItem('siteId')), date: date.format('YYYY-MM-DD') });

  //공통 코드 쿼리
  const code = query.codes.useCodes();

  //일일 시공 통계 쿼리
  const dailyData = query.statistics.useStatisticsDailyConstruction({ siteId: String(sessionStorage.getItem('siteId')), date: date.format('YYYY-MM-DD') });

  //작업일보 내보내기 데이터 쿼리
  const exportData = query.workLogs.useExport({ siteId: String(sessionStorage.getItem('siteId')), dateFrom: exportDate.from.format('YYYY-MM-DD'), dateTo: exportDate.to.format('YYYY-MM-DD') });

  const { data: ptDetailData } = query.map.points.useMapPointDetail({ pointInfo: { facilityKind: detailInfo.facilityKind, pitCde: detailInfo.pitCde, pointId: detailInfo.pointId } });
  //유형 변경 이벤트
  const onChangeCategory = (code: string) => {
    const newArr = [...type];
    newArr.map((i) => {
      i.active = code === i.value;
    });
    setType(newArr);
  };
  //내보내기 기간 초기값(시공 시작일 ~ 마지막 시공일)
  useEffect(() => {
    if (workDate.length) {
      setExportDate({
        from: new DateObject(workDate[workDate.length - 1]),
        to: new DateObject(workDate[0]),
      });
    }
  }, [workDate]);

  //th 클릭시 오름차순/내림차순/초기화 순으로 정렬 반복 이벤트. 다른 th 클릭시 해당 컬럼 부터 다시 시작
  const onSort = (key: string) => {
    if (key === sortStatus.key) {
      if (sortStatus.sort === 'none') {
        setSortStatus({ key: key, sort: 'upper' });
      } else if (sortStatus.sort === 'upper') {
        setSortStatus({ key: key, sort: 'lower' });
      } else {
        setSortStatus({ key: 'none', sort: 'none' });
      }
    } else {
      setSortStatus({ key: key, sort: 'upper' });
    }
  };

  //시공목록 항목 클릭시 이벤트
  const onTdClick = (row: any) => {
    if (type.filter(({ active }) => active)[0].value === 'pipe') {
      const newArr = [...pipeTable];
      const newArr2 = [...initPipeTable];
      newArr.map((i) => {
        i.active = row.pipeId === i.pipeId;
      });
      newArr2.map((i) => {
        i.active = row.pipeId === i.pipeId;
      });
      setPipeTable(newArr);
      setInitPipeTable(newArr2);
      setDetailInfo({ ...row, type: 'pipe' });
    } else {
      const newArr = [...pointTable];
      const newArr2 = [...initPointTable];
      newArr.map((i) => {
        i.active = row.pointId === i.pointId;
      });
      newArr2.map((i) => {
        i.active = row.pointId === i.pointId;
      });
      setPointTable(newArr);
      setInitPointTable(newArr2);
      setDetailInfo({ ...row, type: 'point' });
    }
    //시점, 종점, 파이프마커 추가
    onActivePipe(row);
    setSelectType(String(type.filter(({ active }) => active)[0].value));
  };
  //팝업 닫기
  const onPopupToggle = () => {
    setPopupState(!popupState);
  };

  //엑셀 내보내기
  const onExcelExport = async () => {
    await exportData.refetch().then(({ data }) => {
      onExportExcel(code.data, data, workDate, { st: exportDate.from.format('YYYY-MM-DD'), end: exportDate.to.format('YYYY-MM-DD') }, setLoader);
    });
    onPopupToggle();
  };
  useEffect(() => {
    if (exportData.isSuccess) {
      setTimeout(() => {
        setLoader({ show: true });
      }, 1000);
    }
  }, [exportData.isSuccess]);

  //측점, 파이프 초기 호출 후 state 저장
  useEffect(() => {
    if (pointList.data && pipeList.data) {
      const newArr: any = [];
      const newArr2: any = [];
      const newArr3: any = [];
      const newArr4: any = [];

      pointList.data.map((i) => {
        newArr.push({ ...i, active: false });
        newArr2.push({ ...i, active: false });
      });
      pipeList.data.map((i) => {
        newArr3.push({ ...i, active: false });
        newArr4.push({ ...i, active: false });
      });

      setPointTable(newArr);
      setInitPointTable(newArr2);
      setPipeTable(newArr3);
      setInitPipeTable(newArr4);

      setTableLoad(tableLoad + 1);
    }
  }, [pointList.data, pipeList.data]);

  //일일 시공길이 계산
  useEffect(() => {
    if (pointTable) {
      let distance = 0;
      const newArr: any = [];
      pointTable.map((i) => {
        if (i.pitCde === 'PIT004' || i.pitCde === 'PIT005' || i.pitCde === 'PIT006' || i.pitCde === 'PIT007' || i.pitCde === 'PIT008') {
          newArr.filter((j) => j.pitCde === i.pitCde).length
            ? (newArr.find((j) => j.pitCde === i.pitCde).count = newArr.find((j) => j.pitCde === i.pitCde).count + 1)
            : newArr.push({ pitCde: i.pitCde, count: 1, text: code.data.PIT.find(({ code }) => code === i.pitCde).value });
        }
      });
      if (pipeTable) {
        pipeTable.map((i) => {
          distance = distance + Number(i.distance);
          if (i.pipCde !== 'PIP003' && i.pipCde !== 'PIP004') {
            newArr.filter((j) => j.pipCde === i.pipCde).length
              ? (newArr.find((j) => j.pipCde === i.pipCde).count = newArr.find((j) => j.pipCde === i.pipCde).count + 1)
              : newArr.push({ pipCde: i.pipCde, count: 1, text: code.data.PIP.find(({ code }) => code === i.pipCde).value });
          }
        });
      }

      setDailyConstructionData({
        count: newArr,
        distance: distance,
      });
    }
  }, [pipeTable, pointTable]);
  //onSort 함수 실행 시 테이블 정렬 트리거
  useEffect(() => {
    const newArr = [...pointTable];
    const newArr2 = [...pipeTable];

    if (sortStatus.sort === 'lower') {
      newArr.sort((a: any, b: any) => {
        if (sortStatus.key.split('.').length > 1) {
          return a[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]] < b[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]]
            ? -1
            : a[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]] > b[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]]
              ? 1
              : 0;
        } else {
          return a[sortStatus.key] < b[sortStatus.key] ? -1 : a[sortStatus.key] > b[sortStatus.key] ? 1 : 0;
        }
      });
      newArr2.sort((a: any, b: any) => {
        return a[sortStatus.key] < b[sortStatus.key] ? -1 : a[sortStatus.key] > b[sortStatus.key] ? 1 : 0;
      });
      setPipeTable(newArr2);
      setPointTable(newArr);
    } else if (sortStatus.sort === 'upper') {
      newArr.sort((a: any, b: any) => {
        if (sortStatus.key.split('.').length > 1) {
          return a[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]] > b[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]]
            ? -1
            : a[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]] < b[sortStatus.key.split('.')[0]][sortStatus.key.split('.')[1]]
              ? 1
              : 0;
        } else {
          return a[sortStatus.key] > b[sortStatus.key] ? -1 : a[sortStatus.key] < b[sortStatus.key] ? 1 : 0;
        }
      });
      newArr2.sort((a: any, b: any) => {
        return a[sortStatus.key] > b[sortStatus.key] ? -1 : a[sortStatus.key] < b[sortStatus.key] ? 1 : 0;
      });
      setPipeTable(newArr2);
      setPointTable(newArr);
    } else {
      setPipeTable(initPipeTable);
      setPointTable(initPointTable);
    }
  }, [sortStatus]);

  //측점, 파이프 테이블 저장 이후 맨 위 데이터 클릭
  useEffect(() => {
    if (tableLoad) {
      if (pointTable.length && type.filter(({ active }) => active)[0].value === 'point') {
        onTdClick(pointTable[0]);
      }
      if (pipeTable.length && type.filter(({ active }) => active)[0].value === 'pipe') {
        onTdClick(pipeTable[0]);
      }
    }
  }, [type, tableLoad]);

  //2D/3D 지도에서 넘어온 값 있는지 체크
  useEffect(() => {
    if (pipeTable.length || pointTable.length) {
      if (state || qsData().id) {
        setParamLoad(true);
      }
    }
  }, [pipeTable.length || pointTable.length]);

  //2D / 3D 지도에서 넘어온 값이 있을 때
  useEffect(() => {
    if (paramLoad) {
      if (qsData().id) {
        onChangeCategory(qsData().type);
        setTimeout(() => {
          // onTdClick(tableData.filter((i) => i.id === qsData().id)[0]);
          if (qsData().type === 'pipe') {
            onTdClick(pipeTable.filter((i) => i.pipeId === qsData().id)[0]);
          } else {
            onTdClick(pointTable.filter((i) => i.pointId === qsData().id)[0]);
          }
          setTimeout(() => {
            (document.getElementById('materialList') as any).scrollTo({
              top: (document.querySelector('tr.active') as any).offsetTop + 1,
              behavior: 'smooth',
            });
          });
        }, 100);
      } else if (state) {
        onChangeCategory(state.type);
        setTimeout(() => {
          if (state.type === 'pipe') {
            onTdClick(pipeTable.filter((i) => i.pipeId === state.id)[0]);
          } else {
            onTdClick(pointTable.filter((i) => i.pointId === state.id)[0]);
          }
          setTimeout(() => {
            (document.getElementById('materialList') as any).scrollTo({
              top: (document.querySelector('tr.active') as any).offsetTop + 1,
              behavior: 'smooth',
            });
            navigate(location.pathname, { state: null });
          });
        }, 100);
      }
    }
  }, [paramLoad]);

  useEffect(() => {
    if (ptDetailData) {
      setDetailInfo({ ...detailInfo, ...ptDetailData });
    }
  }, [ptDetailData, selectType]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWinSize(window.innerWidth);
      };

      // resize 이벤트가 발생할 때 handleResize 함수가 실행되도록 한다.
      window.addEventListener('resize', handleResize);

      // 초기값을 설정할 수 있도록 handleResize 함수를 한 번 실행시킨다.
      handleResize();

      // 이벤트 리스너를 제거하여 이벤트 리스너가 리사이즈될 때마다 계속해서 생겨나지 않도록 처리한다. (clean up)
      return () => window.removeEventListener('resize', handleResize);
    } else {
      return () =>
        window.removeEventListener('resize', () => {
          return null;
        });
    }
  });

  //캘린더 선택시 유형 측점탭 선택
  useEffect(() => {
    const newArr = [...type];
    newArr.map((i) => {
      i.active = i.value === 'point';
    });
    setType(newArr);
  }, [date]);

  return (
    <>
      <ReportStyle.SectionBox>
        <SkeletonConstructionTable winSize={winSize} />
        <ReportStyle.TitleArea>
          <ReportStyle.TitleGroup>
            <ReportStyle.Title>{t('시공목록')}</ReportStyle.Title>
            <ReportStyle.Date>{convertDateFormat(date.format('YYYY-MM-DD'), t)}</ReportStyle.Date>
          </ReportStyle.TitleGroup>

          <FileDownStyle.Group>
            {workDate.length ? (
              <>
                <FileDownStyle.Projection>
                  {t('좌표계')} : {info.projection}
                </FileDownStyle.Projection>
                {/*<FileDownStyle.Button type={'button'} $type={2}>*/}
                {/*  {t('PDF로저장')}*/}
                {/*</FileDownStyle.Button>*/}
                <FileDownStyle.Button type={'button'} $type={2} onClick={onPopupToggle}>
                  {t('엑셀로저장')}
                </FileDownStyle.Button>
              </>
            ) : null}
          </FileDownStyle.Group>
        </ReportStyle.TitleArea>
        <ReportStyle.DescArea className={themeMode === 'light' ? 'cScroll light' : 'cScroll'}>
          <TableListStyle.Cont>
            {workDate.length ? (
              <TableListStyle.SelectArea className={'w1920'}>
                <TableListStyle.SelectWrap>
                  <dt>
                    <span>{t('유형')}</span>
                  </dt>
                  {type.map((i) => {
                    if (pipeTable.length) {
                      return (
                        <dd key={i.value} className={i.active ? 'active' : undefined} onClick={() => onChangeCategory(String(i.value))}>
                          {t(i.text)}
                        </dd>
                      );
                    } else if (i.value === 'point') {
                      return (
                        <dd key={i.value} className={i.active ? 'active' : undefined} onClick={() => onChangeCategory(String(i.value))}>
                          {t(i.text)}
                        </dd>
                      );
                    }
                  })}
                </TableListStyle.SelectWrap>
              </TableListStyle.SelectArea>
            ) : null}

            {workDate.length ? (
              <>
                <TableListStyle.TableContainer>
                  {workDate.length ? (
                    <TableListStyle.SelectArea className={'w1600'}>
                      <Select
                        option={pipeTable.length ? type : type.filter(({ value }) => value === 'point')}
                        width={132}
                        height={36}
                        style={{ marginRight: 10 }}
                        onChange={(e) => {
                          const value = (e.target as any).ariaValueText;
                          onChangeCategory(value);
                        }}
                      />
                    </TableListStyle.SelectArea>
                  ) : null}

                  <Table
                    caption={'시공 목록'}
                    colgroup={['16.6%', '16.6%', '16.6%', '16.6%', '16.6%', 'auto']}
                    height={Number(winSize) <= 1660 ? 'calc(100% - 243px)' : 'calc(100% - 194px)'}
                    tbodyHeight={'calc(100% - 80px)'}
                    tbodyId={'materialList'}
                    thead={
                      type.filter(({ active }) => active)[0].value === 'point' ? (
                        <tr>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'facilityKind'} type={'button'} onClick={() => onSort('facilityKind')}>
                              {t('관종')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'ptNum'} type={'button'} onClick={() => onSort('ptNum')}>
                              {t('측점명')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'coordinate.x'} type={'button'} onClick={() => onSort('coordinate.x')}>
                              {t('X')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'coordinate.y'} type={'button'} onClick={() => onSort('coordinate.y')}>
                              {t('Y')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'coordinate.z'} type={'button'} onClick={() => onSort('coordinate.z')}>
                              {t('Z')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'pitCde'} type={'button'} onClick={() => onSort('pitCde')}>
                              {t('구분')}
                            </TableStyle.SortBtn>
                          </th>
                        </tr>
                      ) : (
                        <tr>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'facilityKind'} type={'button'} onClick={() => onSort('facilityKind')}>
                              {t('관종')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'mopCde'} type={'button'} onClick={() => onSort('mopCde')}>
                              {t('재질')}
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'diameter'} type={'button'} onClick={() => onSort('diameter')}>
                              {t('관경')}(mm)
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'distance'} type={'button'} onClick={() => onSort('distance')}>
                              {t('길이')}(m)
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'depth'} type={'button'} onClick={() => onSort('depth')}>
                              {t('관상고')}(m)
                            </TableStyle.SortBtn>
                          </th>
                          <th scope={'col'}>
                            <TableStyle.SortBtn $status={sortStatus} $key={'pipCde'} type={'button'} onClick={() => onSort('pipCde')}>
                              {t('구분')}
                            </TableStyle.SortBtn>
                          </th>
                        </tr>
                      )
                    }
                    tbody={
                      <>
                        {type.filter(({ active }) => active)[0].value === 'point'
                          ? pointTable?.map((i) => {
                              return (
                                <tr key={i.pointId} onClick={() => !i.active && onTdClick(i)} className={i.active ? 'active' : undefined}>
                                  <td>{t(code.data.FACILITY.filter(({ code }) => code === i.facilityKind)[0].value)}</td>
                                  <td>{i.ptNum}</td>
                                  <td>{Number(i.coordinate.x?.toFixed(3)) || '-'}</td>
                                  <td>{Number(i.coordinate.y?.toFixed(3)) || '-'}</td>
                                  <td>{Number(i.coordinate.z?.toFixed(3)) || '-'}</td>
                                  <td>{t(code.data.PIT.filter(({ code }) => code === i.pitCde)[0].value)}</td>
                                </tr>
                              );
                            })
                          : pipeTable?.map((i) => {
                              return (
                                <tr key={i.pipeId} onClick={() => !i.active && onTdClick(i)} className={i.active ? 'active' : undefined}>
                                  <td>{t(code.data.FACILITY.filter(({ code }) => code === i.facilityKind)[0].value)}</td>
                                  <td>{t(code.data.MOP.filter(({ code }) => code === i.mopCde)[0].value)}</td>
                                  <td>{i.diameter}</td>
                                  <td>{Number(i.distance?.toFixed(3)) || '-'}</td>
                                  <td>{Number(i.depth?.toFixed(3)) || '-'}</td>
                                  <td>{t(code.data.PIP.filter(({ code }) => code === i.pipCde)[0].value)}</td>
                                </tr>
                              );
                            })}
                      </>
                    }
                  />
                  <TableListStyle.DailyArea>
                    <TableListStyle.DailyBox>
                      <TableListStyle.DailyTitle>{t('일일관수량')}</TableListStyle.DailyTitle>
                      <TableListStyle.DailyCont className={'cScroll'}>
                        <TableListStyle.DailyList>
                          {/* 관로 */}
                          <>
                            {dailyConstructionData?.count?.map((i) => {
                              return (
                                <TableListStyle.DailyItem key={i.text}>
                                  <strong>{t(i.text)}</strong>
                                  <span>
                                    {i.count} {t('개')}
                                  </span>
                                </TableListStyle.DailyItem>
                              );
                            })}
                          </>
                        </TableListStyle.DailyList>
                      </TableListStyle.DailyCont>
                    </TableListStyle.DailyBox>
                    <TableListStyle.DailyBox>
                      <TableListStyle.DailyTitle>{t('일일시공길이')}</TableListStyle.DailyTitle>
                      <TableListStyle.DailyCont>
                        <TableListStyle.DailyList>
                          <TableListStyle.DailyItem>
                            <span style={{ color: '#4379EE' }}>{Number(dailyConstructionData?.distance?.toFixed(1)) || '-'}m</span>
                          </TableListStyle.DailyItem>
                        </TableListStyle.DailyList>
                      </TableListStyle.DailyCont>
                    </TableListStyle.DailyBox>
                  </TableListStyle.DailyArea>
                </TableListStyle.TableContainer>

                <ReportPhotoInfo detailInfo={detailInfo} selectType={selectType} />
              </>
            ) : (
              <NoData style={{ flex: 1 }} text={'등록된 측량성과가 없습니다.'} />
            )}
          </TableListStyle.Cont>
        </ReportStyle.DescArea>
      </ReportStyle.SectionBox>
      {popupState ? <PopupExportDateSelect workDate={workDate} date={exportDate} setDate={setExportDate} onClose={onPopupToggle} onExport={onExcelExport} /> : null}
    </>
  );
};

export default ReportMaterialList;
