import React, { Fragment, useEffect, useState } from 'react';
import query from '../../hooks/RQuery/utils';
import useInputChange from '../../hooks/useInputChange';
import { DataFilterStyle, SurveyStyle } from '../../components/templates/_style';
import LabelInput, { LabelForm } from '../../components/atoms/LabelInput';
import Checkbox from '../../components/atoms/Checkbox';
import { useRecoilState } from 'recoil';
import { siteListState } from '../../store/Atoms';
import ManageContent, { Dash, ManageContentStepProps } from '../../components/templates/ManageContent';
import { confirmOneBtn } from '../../store/popupAtoms';
import ConfirmOneBtn from '../../components/templates/ConfirmOneBtn';
import { useNavigate } from 'react-router-dom';
import { WorkPlaceStyle } from './WorkPlaceRegister';
import { CheckboxStyle } from '../../components/atoms/_style';
import { SurveyHeaderSt } from '../../components/templates/popup/_style';

const initForm = {
  id: '',
  password: '',
  verify: '',
  name: '',
  auth: '',
};

interface SitesProps {
  siteId: string;
  facilityKind: ('WATER' | 'WASTE' | 'RAIN' | 'GAS' | 'NETWORK' | 'ELECTRIC' | 'OIL' | 'HEAT')[];
}
const UserRegister = ({ userType }) => {
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn);
  const codes = query.codes.useCodes();
  //메뉴(step형태)
  const [step, setStep] = useState<ManageContentStepProps[]>([{ num: 1, pass: true, text: '기본 정보' }]);
  const [formOrigin, setFormOrigin, onChangeOrigin] = useInputChange(initForm);
  const [siteList, setSiteList] = useRecoilState(siteListState);
  const [siteForm, setSiteForm] = useState<any>([]);
  const [siteOrigin, setSiteOrigin] = useState<any>([]);
  const [duplicate, setDuplicate] = useState({
    init: true,
    state: true,
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const navigate = useNavigate();
  const userRole = userType === 'ROLE_MANAGER' ? '매니저' : userType === 'ROLE_USER' ? '사용자' : '';
  const checkDuplicate = query.auths.useCheckDuplicate(formOrigin.id);
  const { mutate: addUser } = query.auths.useAddUser({
    id: formOrigin.id,
    password: formOrigin.password,
    name: formOrigin.name,
    auth: userType,
    sites: siteOrigin,
  });
  //로그인한 계정으로 유저생성이 가능한 현장과 관종리스트를 보여줌
  useEffect(() => {
    if (siteList.length) {
      const newArr: any = [];
      siteList.map((i) => {
        const facilities: any = [];
        i.facilityKinds?.map((j) => {
          facilities.push({ facilityKind: j, checked: false });
        });
        newArr.push({ text: i.text, id: i.value, checked: false, facilityKinds: facilities });
      });
      setSiteForm(newArr);
    }
  }, [siteList.length]);

  //현장 체크 state변경
  const onChangeSite = (site) => {
    setSiteForm((prev) => {
      return prev.map((j) => {
        return {
          ...j,
          checked: site.id === j.id ? !j.checked : j.checked,
        };
      });
    });
  };

  //관종 체크 state변경
  const onChangeFacilityCheck = (site, siteRow) => {
    const newArr = [...siteForm];
    newArr.map((row) => {
      if (row.id === site.id) {
        row.facilityKinds.find(({ facilityKind }) => facilityKind === siteRow.facilityKind).checked = !row.facilityKinds.find(({ facilityKind }) => facilityKind === siteRow.facilityKind).checked;
      }
    });
    setSiteForm(newArr);
  };

  const closeConfirm = () => {
    setConfirmOneBtn((prev) => ({ ...prev, isOpen: false }));
    submitSuccess && navigate('../list');
  };
  const onSubmit = async () => {
    const newArr: any = [];
    siteForm.map((i) => {
      if (i.checked) {
        const facilities: any = [];
        i.facilityKinds.map((j) => {
          if (j.checked) {
            facilities.push(j.facilityKind);
          }
        });
        newArr.push({ siteId: i.id, facilityKind: facilities });
      }
    });
    setSiteOrigin(newArr);
    await addUser(
      {
        id: formOrigin.id,
        password: formOrigin.password,
        name: formOrigin.name,
        auth: formOrigin.auth,
        sites: newArr,
      },
      {
        onSuccess: (data: any) => {
          setSubmitSuccess(true);
          setConfirmOneBtn((prev) => ({ ...prev, desc: data, isOpen: true }));
        },
        onError: (err: any) => {
          setSubmitSuccess(false);
          setConfirmOneBtn((prev) => ({ ...prev, desc: err.response.data.errorMsg, isOpen: true }));
        },
      },
    );
  };

  return (
    <>
      <ManageContent
        title={userRole + ' 등록'}
        step={step}
        listPath={'/workplace/list'}
        naviRight={
          <DataFilterStyle.BtnSearch type={'button'} onClick={onSubmit} disabled={formOrigin.password !== formOrigin.verify || !formOrigin.password.length || !formOrigin.id || duplicate.state || duplicate.init}>
            등록하기
          </DataFilterStyle.BtnSearch>
        }
        content={
          <>
            <LabelInput
              name="id"
              label="ID"
              onChange={(e) => {
                onChangeOrigin(e);
                setDuplicate({ init: true, state: true });
              }}
              value={formOrigin?.id}
              width={400}
              style={{ marginBottom: 12 }}
              required={true}
              rightBtn={
                <SurveyHeaderSt.Button
                  type={'button'}
                  style={{ width: 80, height: 40, marginLeft: 10 }}
                  onClick={() => {
                    checkDuplicate.refetch().then(({ data }) => {
                      if (data) {
                        setConfirmOneBtn((prev) => ({ ...prev, desc: '이미 등록된 ID 입니다.', isOpen: true }));
                      } else {
                        setConfirmOneBtn((prev) => ({ ...prev, desc: '사용 가능한 ID 입니다.', isOpen: true }));
                      }
                      setDuplicate({ init: false, state: data });
                    });
                  }}
                  disabled={!formOrigin?.id}
                >
                  중복 확인
                </SurveyHeaderSt.Button>
              }
            />
            <LabelInput name="password" label="비밀번호 입력" type={'password'} onChange={onChangeOrigin} value={formOrigin?.password} width={400} style={{ marginBottom: 12 }} required={true} />
            <LabelInput name="verify" label="비밀번호 확인" type={'password'} onChange={onChangeOrigin} value={formOrigin?.verify} width={400} style={{ marginBottom: 12 }} required={true} />
            <LabelInput name="name" label="이름" onChange={onChangeOrigin} value={formOrigin?.name} width={400} required={true} />
            <Dash />
            <LabelForm.Wrap style={{ alignItems: 'flex-start' }}>
              <LabelForm.Label>담당 사업장</LabelForm.Label>
              <div>
                <p style={{ color: '#0076FF', fontSize: 14, fontWeight: 500, display: 'block', marginBottom: 16 }}>※ 담당 사업장 및 사업장에서 관장할 관종을 각각 1개 이상 선택합니다.</p>
                <WorkPlaceStyle.FacilityArea>
                  <WorkPlaceStyle.FacilityColumn>
                    {siteForm.map((i) => {
                      return (
                        <WorkPlaceStyle.FacilityItem key={i.id} style={{ width: 900 }}>
                          <WorkPlaceStyle.FacilityCheckArea style={{ width: 42 }}>
                            <Checkbox name={'site'} id={i.id} checked={i.checked} onChange={() => onChangeSite(i)} />
                          </WorkPlaceStyle.FacilityCheckArea>
                          <WorkPlaceStyle.FacilityRowGroup>
                            <WorkPlaceStyle.FacilityRow>
                              <WorkPlaceStyle.SiteName>{i.text}</WorkPlaceStyle.SiteName>
                            </WorkPlaceStyle.FacilityRow>

                            <WorkPlaceStyle.FacilityRow>
                              <div style={{ display: 'flex' }}>
                                {i.facilityKinds?.map((j, index) => {
                                  return (
                                    <CheckboxStyle.Wrap style={{ marginRight: 20 }} key={index}>
                                      <CheckboxStyle.Input id={i.id + '_' + j.facilityKind} className={'dark'} type={'checkbox'} checked={j.checked} onChange={() => onChangeFacilityCheck(i, j)} disabled={!i.checked} />
                                      <CheckboxStyle.Label htmlFor={i.id + '_' + j.facilityKind} size={18}></CheckboxStyle.Label>
                                      <span
                                        style={{
                                          width: 12,
                                          height: 12,
                                          boxSizing: 'border-box',
                                          borderRadius: 2,
                                          border: `2px solid ${codes.data.FACILITY.find(({ code }) => j.facilityKind === code).color}`,
                                          margin: '0 10px 0 12px',
                                        }}
                                      ></span>
                                      <CheckboxStyle.Text htmlFor={i.id + '_' + j.facilityKind}>{codes.data.FACILITY.find(({ code }) => j.facilityKind === code).value}</CheckboxStyle.Text>
                                    </CheckboxStyle.Wrap>
                                  );
                                })}
                              </div>
                            </WorkPlaceStyle.FacilityRow>
                          </WorkPlaceStyle.FacilityRowGroup>
                        </WorkPlaceStyle.FacilityItem>
                      );
                    })}
                  </WorkPlaceStyle.FacilityColumn>
                </WorkPlaceStyle.FacilityArea>
              </div>
            </LabelForm.Wrap>

            {/*<DataFilterStyle.BtnSearch type={'button'} onClick={() => navigate('/account/list')}>*/}
            {/*  목록*/}
            {/*</DataFilterStyle.BtnSearch>*/}
          </>
        }
      />
      {confirmOneBtnState.isOpen && <ConfirmOneBtn desc={confirmOneBtnState.desc || ''} onClickCancel={closeConfirm} onClickSubmit={() => {}} />}
    </>
  );
};

export default UserRegister;
