import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterProps } from '../../components/templates/_interface';
import { SurveyStyle } from '../../components/templates/_style';
import DesignHeader from '../../components/templates/design/DesignHeader';
import DesignContent from '../../components/templates/design/DesignContent';
import query from '../../hooks/RQuery/utils';
import { useTranslation } from 'react-i18next';

const DesignRegister = () => {
  const param = useParams();
  const navigate = useNavigate();
  // 관종 탭(상수, 오수, 우수,...)
  const [categoryTabList, setCategoryTabList] = useState<FilterProps[]>([]);

  //현재 페이지의 siteId, siteName, 관종 리스트 저장
  const [siteInfo, setSiteInfo] = useState({
    id: '',
    name: '',
    facilityKindList: '',
  });
  const [dxfFile, setDxfFile] = useState<File>();
  const { mutate: mutateDesign } = query.designs.useUploadDesign({ siteId: siteInfo.id, facilityKind: categoryTabList.find(({ active }) => active)?.key, file: dxfFile });

  //목록가기
  const onBack = () => {
    navigate('/design/list');
  };

  useEffect(() => {
    setSiteInfo({
      id: param.id || String(sessionStorage.getItem('survey_siteId')),
      name: param.name || String(sessionStorage.getItem('survey_siteName')),
      facilityKindList: param.facilityKindList || String(sessionStorage.getItem('survey_facilityKindList')),
    });
  }, []);

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    setDxfFile(file);
    mutateDesign({ siteId: siteInfo.id, facilityKind: categoryTabList.find(({ active }) => active)!.key, file: file });
  };

  return (
    <>
      {siteInfo.id ? (
        <SurveyStyle.Contain>
          <SurveyStyle.Inner>
            {/* 공통헤더 */}
            <DesignHeader onBack={onBack} siteInfo={siteInfo} onChangeFile={onChangeFile} />
            <SurveyStyle.Wrap style={{ height: 'calc(100% - 84px)' }}>
              <DesignContent siteInfo={siteInfo} categoryTabList={categoryTabList} setCategoryTabList={setCategoryTabList} />
            </SurveyStyle.Wrap>
          </SurveyStyle.Inner>
        </SurveyStyle.Contain>
      ) : null}
    </>
  );
};

export default DesignRegister;
