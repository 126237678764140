import { useQuery, useQueries } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';

// 코드 조회
export function useCodes() {
  // code 키로 맵핑
  const keyArray = Object.keys(keys.codeKeys); // PIT, PIP,..

  const { data, pending, isSuccessAll } = useQueries({
    queries: keyArray.map((key) => {
      let type = keys.codeKeys[key]?.[1]; // 'pit-cdes',..
      return { queryKey: keys.codeKeys[key], queryFn: () => api.codes.getCodes(type), gcTime: Infinity, refetchOnMount: false };
    }),
    combine: (results) => {
      let newCode: any = {};
      keyArray.map((key, idx) => {
        newCode[key] = results[idx].data?.data; // 데이터 새로운 형태로 변경
      });
      return {
        data: newCode,
        //   data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
        isSuccessAll: results.every((result) => result.isSuccess),
      };
    },
  });

  return { data, pending, isSuccessAll };
}

// (툴팁) 코드 조회
export function useCodesTooltip() {
  const keyArray = Object.keys(keys.codeTooltipKeys);

  const { data, pending, isSuccessAll } = useQueries({
    queries: keyArray.map((key) => {
      let type = keys.codeTooltipKeys[key]?.[1]; // 'pipe-tooltips', 'point-tooltips'
      return { queryKey: keys.codeTooltipKeys[key], queryFn: () => api.codes.getCodes(type), gcTime: Infinity, refetchOnMount: false };
    }),
    combine: (results) => {
      // console.log(results);
      let newCode: any = {};
      keyArray.map((key, idx) => {
        newCode[key] = results[idx].data?.data; // 데이터 새로운 형태로 변경
      });

      return {
        data: newCode,
        pending: results.some((result) => result.isPending),
        isSuccessAll: results.every((result) => result.isSuccess),
      };
    },
  });

  return { data, pending, isSuccessAll };
}
