import styled from 'styled-components';

import IcoAlertCircle from 'assets/images/ico-alert-circle.svg';
import IcoClose from 'assets/images/ico-pop-close-light.svg';
import IcoCheckCircle from 'assets/images/ico-check-circle.svg';
import IcoFailCircle from 'assets/images/ico-fail-circle.svg';
import IcoInfo from 'assets/images/ico-information.svg';
import IcoFileThumb from 'assets/images/ico-file-thumb.svg';
import IcoCloseRed from 'assets/images/ico-close-red.svg';

export const PopupFrameSt = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  `,
  Popup: styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    min-width: 528px;
    min-height: 387px;
  `,
  HeaderWrap: styled.div`
    height: 56px;
    background-color: ${(p) => p.theme.popup.head.bg};
    border-radius: 4px 4px 0 0;
    position: relative;
    .title {
      color: ${(p) => p.theme.popup.head.title};
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .closeBtn {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 42px;
      height: 42px;
      background: url(${(p) => p.theme.popup.head.icoClose}) no-repeat center;
      border-radius: 4px;
      &:hover {
        transition: 0.4s;
        background-color: rgba(144, 144, 144, 0.35);
      }
    }
  `,
  ContWrap: styled.div`
    padding: 20px 24px;
    background-color: ${(p) => p.theme.imgModal.bg};
    border: 1px solid ${(p) => p.theme.imgModal.border};
  `,
  Footer: styled.div`
    padding: 16px 24px;
    background-color: ${(p) => p.theme.imgModal.bg};
    border: 1px solid ${(p) => p.theme.imgModal.border};
    border-top: 0;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.full {
      padding: 0;
    }
    > .helpText {
      display: flex;
      align-items: center;
      gap: 10px;
      > .ico {
        width: 24px;
        height: 24px;
        background: url(${IcoInfo}) no-repeat center;
      }
      > .text {
        color: #797a7c;
        > span {
          color: #0076ff;
        }
      }
    }
  `,
  BtnWrap: styled.div`
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    button {
      min-width: 112px;
      padding: 12px 24px;
      box-sizing: border-box;
      color: ${(p) => p.theme.popup.language.color};
      background-color: ${(p) => p.theme.imgModal.foot.btnBg};
      border: 1px solid ${(p) => p.theme.imgModal.foot.btnBd};
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transition: 0.4s;
        color: #0076ff;
        border-color: #0076ff;
        background-color: rgba(72, 128, 255, 0.15);
        box-shadow: 0px 4px 4px 0px #0076ff33;
        &:disabled {
          color: ${(p) => p.theme.popup.language.color};
          background-color: ${(p) => p.theme.imgModal.foot.btnBg};
          border: 1px solid ${(p) => p.theme.imgModal.foot.btnBd};
          box-shadow: none;
          cursor: auto;
          opacity: 0.5;
        }
      }
      &:disabled {
        opacity: 0.5;
      }
      &.red {
        &:hover {
          color: #f01a1a;
          border-color: #f01a1a;
          background-color: #f01a1a26;
          box-shadow: 0px 4px 4px 0px #f01a1a33;
        }
      }
    }
    &.full {
      margin-left: 0;
      width: 100%;
      padding: 0;
      button {
        width: 100%;
        border: 0;
        border-radius: 0 0 4px 4px;
        background-color: ${(p) => p.theme.imgModal.foot.btnFullBg};
        &:hover {
          transition: 0.4s;
          color: #0076ff;
          border-color: #0076ff;
          background-color: rgba(72, 128, 255, 0.15);
          box-shadow: none;
        }
      }
    }
  `,
};

export const SuccessContSt = {
  Wrap: styled.div``,
  Header: styled.div`
    height: 56px;
    background-color: #0076ff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
    .number {
      padding: 7.5px 10px;
      border-radius: 16px;
      background-color: #fff;
      color: #0076ff;
      font-size: 14px;
      font-weight: 500;
    }
  `,
  Cont: styled.div`
    padding: 30px 0;
    border: 1px solid #0076ff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .ico {
      width: 32px;
      height: 32px;
      background: url(${IcoCheckCircle}) no-repeat center;
    }
    .textWrap {
      text-align: center;
      line-height: 26px;
      .main {
        color: ${(p) => p.theme.popup.cont.color};
        font-weight: 500;
        font-size: 16px;
      }
      .sub {
        color: #0076ff;
        font-weight: 500;
        font-size: 16px;
      }
    }
  `,
};

export const FailedContSt = {
  Wrap: styled.div``,

  Cont: styled.div`
    padding: 30px 0;
    border: 1px solid #f01a1a;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .ico {
      width: 32px;
      height: 32px;
      background: url(${IcoFailCircle}) no-repeat center;
      &.alert {
        background: url(${IcoAlertCircle}) no-repeat center;
      }
    }
    .textWrap {
      text-align: center;
      line-height: 26px;
      .main {
        color: ${(p) => p.theme.popup.cont.color};
        font-weight: 500;
        font-size: 16px;
      }
      .sub {
        color: #f01a1a;
        font-weight: 500;
        font-size: 16px;
      }
    }
  `,
  ImgCont: styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    .success {
      height: 56px;
      background-color: #0076ff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
      .number {
        padding: 7.5px 10px;
        border-radius: 16px;
        background-color: #fff;
        color: #0076ff;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .fail {
      height: 56px;
      background-color: #f01a1a;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
      .number {
        padding: 7.5px 10px;
        border-radius: 16px;
        background-color: #fff;
        color: #f01a1a;
        font-size: 14px;
        font-weight: 500;
      }
    }
  `,
  ErrorCont: styled.div`
    margin-top: 6px;
    width: 100%;
    padding: 8.5px 16px;
    box-sizing: border-box;
    background: #f01a1a14;
    border: 1px solid #f01a1a;
    border-radius: 8px;
    > .listWrap {
      max-height: 129px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      li {
        height: 17px;
        display: flex;
        align-items: center;
        color: #f01a1a;
        .id {
          min-width: 113px;
        }
        .msg {
        }
      }
    }
  `,
};

// 웹 체크 - 업로드 할 수 없는 파일입니다.
// [측량성과등록] 용 설명 포함
export const PopupFailSt = {
  Wrap: styled.div`
    /* padding: 0 24px; */
  `,
  Cont: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    > .mainText {
      margin-top: 14px;
      font-weight: 400;
      font-size: 16px;
      color: ${(p) => p.theme.confirm.color};
    }
    > .thumbWrap {
      margin-top: 36px;
      width: 100%;
      padding: 20px 20px;
      box-sizing: border-box;
      background: #f01a1a14;
      border: 1px solid #f01a1a;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .icoTextWrap {
        display: flex;
        align-items: center;
        gap: 16px;
        > .icoWrap {
          padding: 12px;
          background: #f01a1a26;
          border: 1px solid #f01a1a4d;
          border-radius: 4px;
          > .ico {
            width: 32px;
            height: 32.8px;
            background: url(${IcoFileThumb}) no-repeat center;
          }
        }
        > .fileText {
          color: #f01a1a;
          font-weight: 400;
          font-size: 14px;
        }
      }
      > .closeBtn {
        width: 40px;
        height: 40px;
        background: url(${IcoCloseRed}) no-repeat center;
      }
    }
    > .helpBoxWrap {
      margin-top: 8px;
      width: 100%;
      padding: 14px 20px;
      box-sizing: border-box;
      background: ${(p) => p.theme.popup.head.bg};
      border: 1px solid ${(p) => p.theme.popup.head.border};
      border-radius: 8px;
      > .helpText {
        white-space: break-spaces;
        line-height: 22px;
        font-weight: 400;
        font-size: 14px;
        color: ${(p) => p.theme.confirm.color};
      }
    }
  `,
};
