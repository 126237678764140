import React from 'react';
import styled from 'styled-components';
import { TabProps } from './_interface';
import { TabStyle } from './_style';
import { useTranslation } from 'react-i18next';

const TabContainer = ({ list, onChangeTab }: { list: TabProps[]; onChangeTab: (name: string) => void }) => {
  const { t } = useTranslation();
  return (
    <TabStyle.Wrap>
      {list.map((i) => {
        return (
          <TabStyle.Item key={i.name} className={i.active ? 'active' : undefined} onClick={() => onChangeTab(i.name)}>
            {t(i.name)}
          </TabStyle.Item>
        );
      })}
    </TabStyle.Wrap>
  );
};

export default TabContainer;
