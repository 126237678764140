import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { onExportPhotoRegister } from './exportPhotoRegister';

const ImageTable = ({ title, setIsReady, reportList, setReportList, date }) => {
  const [count, setCount] = useState<number>(0);

  // 이미지 로드 완료 시 상태 업데이트
  const handleImageLoad = async (ptNum, index) => {
    const newArr = [...reportList];

    const element = document.getElementsByClassName('reportItem') as any;
    const canvas = await html2canvas(element[index], {
      useCORS: true,
    });
    newArr.filter((i) => i.ptNum === ptNum)[0].transImage = canvas.toDataURL('image/jpeg');
    setReportList(newArr);
    setCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (reportList.length && count === reportList.filter((i) => !!i.distanceImage).length) {
      onExportPhotoRegister(reportList, title, date).then(() => {
        setIsReady(false);
      });
    }
  }, [count]);

  return (
    <div>
      {reportList.map((i, index) => {
        return (
          <div key={index} className={'reportItem'} style={{ position: 'fixed', left: -9999, top: -9999, backgroundColor: 'white', width: 700, height: 500 }}>
            {!!i.distanceImage ? (
              <>
                <img
                  src={i.distanceImage}
                  onLoad={() => handleImageLoad(i.ptNum, index)} // 이미지 로드 완료 시 처리
                  crossOrigin="anonymous" // CORS 문제 해결을 위한 속성 추가
                  style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}
                />

                {/* 테이블 */}
                <table style={{ position: 'absolute', left: 0, bottom: 0 }} className={'cellTable'}>
                  <tbody>
                    <tr>
                      <th>공사명</th>
                      <td>{title}</td>
                    </tr>
                    <tr>
                      <th>시공날짜</th>
                      <td>{i.ymd}</td>
                    </tr>
                    <tr>
                      <th>시설물 종류</th>
                      <td>{i.facilityType}</td>
                    </tr>
                    <tr>
                      <th>시설물 관종</th>
                      <td>{i.mopValue}</td>
                    </tr>
                    <tr>
                      <th>관경</th>
                      <td>{i.diameter}</td>
                    </tr>
                    <tr>
                      <th>측점번호</th>
                      <td>{i.ptNum}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ImageTable;
