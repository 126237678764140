function hexToRgba(hex, alpha = 1) {
  // #을 제거합니다.
  hex = hex?.replace('#', '');

  // 3자리 16진수 색상 값인 경우 6자리로 변환합니다.
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // 16진수 색상 값을 정수로 변환합니다.
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // RGBA 문자열을 반환합니다.
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// 파이프 색상 코드 - 서버 facility code
function getFacilityColor(pipeType: string, alpha: number) {
  const facilitys = JSON.parse(sessionStorage.getItem('facilitys') || '');
  const target = facilitys.find((ele) => pipeType.includes(ele.code));

  return hexToRgba(target?.color, alpha);
}

export default getFacilityColor;

/* not use */
//   switch (pipeType) {
//     case 'WATER':
//       color = `rgba(0, 118, 255,${alpha})`;
//       break;
//     case 'WASTE':
//       color = `rgba(147, 36, 216,${alpha})`;
//       break;
//     case 'RAIN':
//       color = `rgba(248, 54, 229, ${alpha})`;
//       break;
//     case 'GAS':
//       color = `rgba(231, 234, 70, ${alpha})`;
//       break;
//     case 'NETWORK':
//       color = `rgba(38, 203, 35, ${alpha})`;
//       break;
//     case 'ELECTRIC':
//       color = `rgba(246, 51, 51, ${alpha})`;
//       break;
//     case 'OIL':
//       color = `rgba(140, 83, 50, ${alpha})`;
//       break;
//     case 'HEAT':
//       color = `rgba(221, 110, 6, ${alpha})`;
//       break;
//     case 'ACTIVE':
//       color = `rgba(243, 30, 30, ${alpha})`;
//       break;
//   }
