// Json 데이터를 Csv 파일로 내려받기
export const exportCsvFile = (jsonData, fileName) => {
  // JSON 데이터 생성 (더미 데이터 사용)
  //   const jsonData = [
  //     { name: 'John', age: 30, city: 'New York' },
  //     { name: 'Alice', age: 25, city: 'Los Angeles' },
  //     { name: 'Bob', age: 35, city: 'Chicago' },
  //   ];

  if (jsonData) {
    const column = Object.keys(jsonData[0]).toString(); // Name,Age,City
    // CSV 문자열 생성
    //  const csvContent = 'data:text/csv;charset=utf-8,' + `${column}\n` + jsonData.map((row) => Object.values(row).join(',')).join('\n');
    //  const encodedUri = encodeURI(csvContent);
    //  link.setAttribute('href', encodedUri);

    const csvData = `${column}\n` + jsonData.map((row) => Object.values(row).join(',')).join('\n');

    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    // CSV 파일 다운로드
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName || 'data.csv');
    document.body.appendChild(link); // 다운로드 링크를 DOM에 추가
    link.click(); // 다운로드 클릭
  }
};

export const exportTxtFile = (message, fileName) => {
  if (message) {
    // const jsonString = JSON.stringify(jsonData, null, 2); // JSON 데이터를 문자열로 변환

    // 파일 생성
    const element = document.createElement('a');
    const file = new Blob([message], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = fileName || 'data.txt';
    document.body.appendChild(element); // 다운로드 링크를 DOM에 추가
    element.click(); // 다운로드 클릭
  }
};
