import axios from 'axios';

export const qsData = () => {
  const arr = window.location.search
    .replace('?', '')
    .split('&')
    .filter((i) => i !== '');
  let obj: any = {};
  arr.map((i, index) => {
    obj = { ...obj, [i.split('=')[0]]: i.split('=')[1] };
  });
  return obj;
};

export const request = (base) =>
  axios.create({
    //  baseURL: '/d2',
    baseURL: '/api/' + base,
    headers: {
      'Content-Type': 'application/json',
      Authorization: qsData().token ? qsData().token : sessionStorage.getItem('token'),
      //  'Accept-Language': ''
    },
  });

// form-data 전송용 request
export const requestFormData = (base) =>
  axios.create({
    //  baseURL: '/d2',
    baseURL: '/api/' + base,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: qsData().token ? qsData().token : sessionStorage.getItem('token'),
    },
  });

//test
export const getTodos = async (params?: any) => {
  let { id } = params || {};
  let filter = id ? '/' + id : '';
  console.log(params);

  const response = await axios.get(`https://jsonplaceholder.typicode.com/comments`, { params: params });
  if (response.status === 404) {
    throw new Response('Not Found', { status: 404 });
  }
  return response?.data;
  // throw new Response("Not Found", { status: 404, statusText: "error test.." });
};

export const getImageBuffer = async (imageUrl: string) => {
  try {
    const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer',
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', imageUrl);
    console.error('Error fetching image:', error);
    throw error;
  }
};

// 측량성과등록 페이지 - 현장 등록
export const postSurveyAddSite = async (params) => {
  console.log('params', params);
  const { data } = await request('auth').post(`v1/sites`, params);
  return data.data;
};

// TEST: 공통 axios GET 함수
export const getAxiosData = async ({ url, params }) => {
  const { data } = await request('d2').get(url, { params });
  return data.data;
};
