//엑셀 다운로드
import JSZip from 'jszip';
import ExcelJS from 'exceljs';
import pipeFunc from '../excelReport/pipeSheet';
import holeFunc from '../excelReport/holeSheet';
import valveFunc from '../excelReport/valveSheet';
import statisticsFunc from '../excelReport/statisticsFunc';
import photoSheet from './photoSheet';
import surveySheet from './surveySheet';

export const onExportPhotoRegister = async (reportData, title, date) => {
  try {
    // workbook 생성
    const wb = new ExcelJS.Workbook();
    // sheet 생성
    const survey = wb.addWorksheet('측량성과');
    const photoRegisterSheet = wb.addWorksheet('사진대지');
    const boardSheet = wb.addWorksheet('보드판');

    await photoSheet(wb, photoRegisterSheet, reportData, title);
    await surveySheet(wb, survey, reportData);

    const b = await wb.xlsx.writeBuffer();
    const a = { date: '2020', blob: new Blob([b], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) };

    const url = window.URL.createObjectURL(a.blob);
    const down = document.createElement('a');
    down.href = url;
    down.download = `사진대지 ${date.from}-${date.to}.xlsx`;
    down.click();
    down.remove();
    window.URL.revokeObjectURL(url);

    console.log(a);
  } catch (error) {
    console.log(error);
  }
};
