import { useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from '../useMutateHook';
import queryKeys, { calendarKeys, fileKeys, pointKeys } from '../keys/queryKeys';
/* 
   queryKey,
   queryFn,
   그 외 options 설정
*/

export function useImages({ pointId, facilityKind }) {
  return useQuery({
    queryKey: [...keys.fileKeys.image, pointId],
    queryFn: () => api.files.getFileImages({ pointId, facilityKind }),
    enabled: !!pointId,
    gcTime: 0,
  });
}

export function useAddImage({ pointId, siteId, pitCde, facilityKind, pointImageTypeNum, file }) {
  return useMutateHook({
    fetchApi: () => api.files.addPointImage({ pointId, siteId, pitCde, facilityKind, pointImageTypeNum, file }),
    invalidQuery: [[...fileKeys.image, pointId]],
  });
}

// 측점, 관로 csv 업로드  TODO 삭제예정
export function useAddCsv({ siteId, csvFile }) {
  return useMutateHook({
    fetchApi: () => api.files.postCsvUpload({ siteId, csvFile }),
    invalidQuery: [[queryKeys.MainKey.SURVEY]],
  });
}

// 측점, 관로 csv 체크
export function useCheckCsv({ siteId, csvFile }) {
  return useMutateHook({
    fetchApi: () => api.files.postCsvCheck({ siteId, csvFile }),
  });
}

// 측점, 관로 csv 체크 후 전송
export function useRegistrationCsv() {
  return useMutateHook({
    fetchApi: ({ siteId, inspectionKey, overwrite }) => api.files.postCsvRegistration({ siteId, inspectionKey, overwrite }),
    invalidQuery: [[queryKeys.MainKey.SURVEY]],
  });
}

// 측량점 이미지 삭제
export function useDeleteImage({ imageId }) {
  return useMutateHook({
    fetchApi: () => api.files.deleteImage({ imageId }),
    invalidQuery: [[...fileKeys.image]],
  });
}

// 측량점 이미지 업로드 체크
export function useCheckImages({ siteId, imageList }) {
  return useMutateHook({
    fetchApi: () => api.files.postImageCheck({ siteId, imageList }),
  });
}

// 측량점 이미지 업로드
export function useAddImages({ siteId, imageList, setPercentPopup, resetDnd, refetchSurveyList }) {
  return useMutateHook({
    fetchApi: () => api.files.postImages({ siteId, imageList, setPercentPopup, resetDnd, refetchSurveyList }),
    invalidQuery: [...queryKeys.MainKey.SURVEY],
  });
}

//csv파일 내보내기
export function useFacilitiesFile({ siteId, dateFrom, dateTo, facilityKinds }) {
  const facilityList: string[] = [];
  facilityKinds.map((i) => {
    facilityList.push(i.value);
  });
  return useMutateHook({
    fetchApi: () => api.files.getFacilitiesCsv({ siteId, dateFrom, dateTo, facilityKinds: facilityList }),
  });
}

//사진대지 내보내기
export function useReport({ siteId, dateFrom, dateTo, facilityKinds }) {
  const facilityList: string[] = [];
  facilityKinds.map((i) => {
    facilityList.push(i.value);
  });
  return useQuery({
    queryKey: [...keys.fileKeys.report],
    queryFn: () => api.files.getConstructionReportCsv({ siteId, dateFrom, dateTo, facilityKinds: facilityList }),
    enabled: false,
  });
}
