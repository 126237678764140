import { useQuery } from '@tanstack/react-query';
import { getHoles, getPipes } from '../../service/smartpin';
import queryKeys from '../../keys/queryKeys';

//스마트핀 맨홀 리스트 호출
export const useHoles = ({ siteId }) => {
  return useQuery({
    queryKey: [[...queryKeys.smartpinKeys.hole({ siteId })]],
    queryFn: () => getHoles({ siteId }),
    enabled: !!siteId,
  });
};
