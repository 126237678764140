import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import olCore from '../../maps/wfs/OlCore';
import OlCore from '../../maps/wfs/OlCore';
import { OSM, XYZ } from 'ol/source';
import { DashboardStyle, ReportStyle } from './_style';
import { DateObject } from 'react-multi-date-picker';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import ReportPipeLineLayer from '../../maps/wfs/Object/ReportPipeLineLayer';
import { FullScreen } from 'ol/control.js';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import SkeletonReportMap from '../skeleton/SkeletonReportMap';
import Toolbar from './2dMap/Toolbar';
import query from '../../hooks/RQuery/utils';
import { useTranslation } from 'react-i18next';

const ReportMap = ({ date, pipeLayer, setPipeLayer }: { date: DateObject; pipeLayer?: ReportPipeLineLayer; setPipeLayer?: Dispatch<SetStateAction<ReportPipeLineLayer | undefined>> }) => {
  const { t } = useTranslation();
  const [mapInstance, setMapInstance] = useState<olCore>();
  const [isObjectReady, setIsObjectReady] = useState(false);
  const [mapType, setMapType] = useState<{ name: string; active: boolean; source: OSM | XYZ }[]>([]);
  const [showLegend, setShowLegend] = useState(false);
  const mapData = query.workLogs.usePipes({ siteId: String(sessionStorage.getItem('siteId')), date: date.format('YYYY-MM-DD') });
  const codes = query.codes.useCodes();

  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const pipeLayer = new ReportPipeLineLayer(core);
      setMapInstance(core);
      if (pipeLayer && setPipeLayer) {
        setPipeLayer(pipeLayer);
      }
      setIsObjectReady(true);
    }
  }, []);

  //관종별 Draw
  useEffect(() => {
    if (mapData.data && codes.isSuccessAll) {
      if (pipeLayer) {
        codes.data.FACILITY.map((i) => {
          pipeLayer.draw(
            mapData.data.filter(({ facilityKind }) => facilityKind === i.code),
            i.code,
          );
        });
      }
    }
  }, [mapData.data, pipeLayer, date, codes.isSuccessAll]);

  useEffect(() => {
    if (isObjectReady) {
      setMapType([
        { name: 'Map', active: false, source: mapInstance!.osmMapSource },
        { name: 'Satellite', active: true, source: mapInstance!.googleMapSource },
      ]);
      mapInstance
        ?.getMapInstance()
        .getControls()
        .getArray()
        .filter((i) => i instanceof FullScreen)[0]
        .changed();
    }
  }, [isObjectReady]);

  //지도 변경
  const onMapTileChange = (source: XYZ | OSM) => {
    mapInstance?.mapChange(source);
    const newArr: { name: string; active: boolean; source: OSM | XYZ }[] = [];
    mapType.map((i) => {
      newArr.push({ ...i, active: i.source === source });
    });
    setMapType(newArr);
  };

  // 뷰 처음 위치로 가기
  const goInitPosition = () => {
    //  mapInstance?.moveToInitPosition();
    mapInstance?.moveToLayer(mapInstance?.getLayerFromName('WATER') as VectorLayer<VectorSource>, { padding: [80, 80, 80, 80], duration: 400 });
  };
  return (
    <ReportStyle.SectionBox style={{ flex: 1, marginTop: 16 }}>
      <SkeletonReportMap />
      <ReportStyle.TitleArea>
        <ReportStyle.Title>{t('작업지도')}</ReportStyle.Title>
      </ReportStyle.TitleArea>
      <ReportStyle.DescArea>
        <div id={'mapContainer'} style={{ position: 'relative', width: '100%', height: '100%' }}>
          {showLegend ? <ReportStyle.MapLegend></ReportStyle.MapLegend> : null}

          <ReportStyle.MapSelect>
            {mapType.map((i) => {
              return (
                <button type={'button'} key={i.name} className={i.active ? 'active' : undefined} onClick={() => onMapTileChange(i.source)}>
                  {i.name}
                </button>
              );
            })}
          </ReportStyle.MapSelect>

          {/* 초기 위치 버튼 */}
          <Toolbar onClick={goInitPosition} style={{ bottom: 106, right: 10, top: 'auto' }} />
        </div>
      </ReportStyle.DescArea>
    </ReportStyle.SectionBox>
  );
};

export default ReportMap;
