import React, { useEffect, useState } from 'react';
import { FileDownStyle, PopupStyle } from '../components/templates/_style';
import { Bar, BarChart, Brush, CartesianGrid, Cell, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import DateFilter from '../components/templates/DateFilter';
import { DateObject } from 'react-multi-date-picker';
import { RangeDateProps } from './_interface';
import CustomTooltip from '../components/templates/chart/CustomTooltip';
import CustomLegend from '../components/templates/chart/CustomLegend';
import { TabProps } from '../components/templates/_interface';
import TabContainer from '../components/templates/TabContainer';
import { useRecoilState } from 'recoil';
import { statisticsDateState, themeState } from '../store/Atoms';
import { escKeyModalClose } from '../util/global';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';

const PopupStatistics = ({ onPopupOnOff }: { onPopupOnOff: (key: 'statistics' | 'diameterLength', bool: boolean) => void }) => {
  const { t } = useTranslation();
  const [workDate, setWorkDate] = useState([]);
  const [date] = useRecoilState(statisticsDateState);
  const [theme] = useRecoilState(themeState);
  const statistics = query.statistics.useStatisticsMopDatesDiameters({ siteId: sessionStorage.getItem('siteId'), from: date.from.format('YYYY-MM-DD'), to: date.to.format('YYYY-MM-DD') });
  // 코드 조회
  const code = query.codes.useCodes();
  const [allData, setAllData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [tabList, setTabList] = useState<TabProps[]>([]);
  const workDay = query.calendars.useCalendars({ siteId: sessionStorage.getItem('siteId') });

  useEffect(() => {
    if (workDay.data) {
      setWorkDate(workDay.data);
    }
  }, [workDay.data]);
  useEffect(() => {
    if (statistics.isSuccess && code.isSuccessAll) {
      const data = statistics.data;

      //탭 추가
      const newTabList: TabProps[] = [];
      newTabList.push({ name: '전체', active: true });
      // newTabList.push({name:'PC',active:false}) //todo 삭제예정( 더미데이터 테스트용 )
      data.map((i: any) => {
        i.mopCde !== 'ALL' && newTabList.push({ name: i.mopCde, active: false });
      });
      setTabList(newTabList);

      //전체 데이터
      const newAllData: any = [];
      data
        .filter(({ mopCde }: { mopCde: string }) => mopCde === 'ALL')
        .map((i: any) => {
          i.list.map((j: any) => {
            let newItem = { date: j.date };
            j.list.map((k: any) => {
              newItem = {
                ...newItem,
                [code.data?.PIP.filter(({ code }: { code: string }) => code === k.pipCde)[0].code + 'Distance']: k.distance,
                [code.data?.PIP.filter(({ code }: { code: string }) => code === k.pipCde)[0].code + 'Count']: k.count,
              };
            });
            newAllData.push(newItem);
          });
        });
      setAllData(newAllData);
      console.log(newAllData);

      //탭별 데이터
      const newTabData: any = [];
      data
        .filter(({ mopCde }: { mopCde: string }) => mopCde !== 'ALL')
        .map((i: any) => {
          i.list.map((j: any) => {
            j.list.map((k: any) => {
              let newItem = { date: j.date, mopCde: i.mopCde, diameter: k.diameter };
              k.list.map((l: any) => {
                newItem = {
                  ...newItem,
                  [code.data?.PIP.filter(({ code }: { code: string }) => code === l.pipCde)[0].code + 'Distance']: l.distance,
                  [code.data?.PIP.filter(({ code }: { code: string }) => code === l.pipCde)[0].code + 'Count']: l.count,
                };
              });
              newTabData.push(newItem);
            });
          });
        });
      setTabData(newTabData);
    }
  }, [statistics.data]);

  //esc 닫기
  useEffect(() => {
    window.addEventListener('keydown', (e) => escKeyModalClose(e, () => onPopupOnOff('statistics', false)));
    return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, () => onPopupOnOff('statistics', false)));
  }, []);

  //조회버튼 클릭
  const onSearch = () => {
    statistics.refetch().then(() => {
      console.log(tabData);
    });
  };

  //탭 변경
  const onChangeTab = (name: string) => {
    const newArr: TabProps[] = [];
    tabList.map((i) => {
      newArr.push({ ...i, active: i.name === name });
    });
    setTabList(newArr);
  };

  return (
    <>
      <PopupStyle.Dimmed></PopupStyle.Dimmed>
      <PopupStyle.Popup>
        <PopupStyle.Head>
          <PopupStyle.Title>{t('전체통계')}</PopupStyle.Title>
          <PopupStyle.CloseBtn type={'button'} onClick={() => onPopupOnOff('statistics', false)}></PopupStyle.CloseBtn>
        </PopupStyle.Head>
        <PopupStyle.Cont style={{ background: theme === 'dark' ? '#283142' : '#eef0f8' }}>
          <PopupStyle.Inner>
            <DateFilter workDate={workDate} onSearch={onSearch} />
            {tabList.length ? <TabContainer list={tabList} onChangeTab={onChangeTab} /> : null}
            {tabList.length && tabList.filter(({ active }) => active)[0].name === '전체' ? (
              <>
                <PopupStyle.ChartArea style={{ marginBottom: 12 }}>
                  <ResponsiveContainer width={'100%'} height={269}>
                    <BarChart
                      data={allData}
                      stackOffset="sign"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={6}
                    >
                      <CartesianGrid strokeDasharray="0" stroke={theme === 'dark' ? '#313D4F' : '#D4DDE9'} vertical={false} />
                      <XAxis
                        dataKey="date"
                        stroke={theme === 'dark' ? '#586A87' : '#9099A7'}
                        fontSize={12}
                        fontWeight={500}
                        fontFamily={'Pretendard'}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(i) => {
                          return i.slice(5, 10).replace('-', '/');
                        }}
                      />
                      <YAxis unit={t('개')} tickLine={false} stroke={theme === 'dark' ? '#586A87' : '#9099A7'} axisLine={false} fontSize={12} fontWeight={500} fontFamily={'Pretendard'} />
                      <Tooltip cursor={{ fill: 'rgba(0, 118, 255, 0.15)' }} content={<CustomTooltip unit={'개'} all={true} />} />
                      <Brush startIndex={0} endIndex={allData.length < 20 ? undefined : 20} height={20} fill={'none'} />
                      <Legend verticalAlign={'top'} align={'right'} content={<CustomLegend title={t('관수량통계')} unit={'개'} />} />

                      {/*<ReferenceLine y={0} stroke="#000" />*/}
                      <Bar dataKey="PIP001Count" fill="#0076FF" stackId="stack" radius={10} />
                      <Bar dataKey="PIP002Count" fill="#49AEFF" stackId="stack" radius={10} />
                      <Bar dataKey="PIP003Count" fill="#E7F3FF" stackId="stack" radius={10} />
                    </BarChart>
                  </ResponsiveContainer>
                </PopupStyle.ChartArea>
                <PopupStyle.ChartArea>
                  <ResponsiveContainer width={'100%'} height={269}>
                    <BarChart
                      data={allData}
                      stackOffset="sign"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={6}
                    >
                      <CartesianGrid strokeDasharray="0" stroke={theme === 'dark' ? '#313D4F' : '#D4DDE9'} vertical={false} />
                      <XAxis
                        dataKey="date"
                        stroke={theme === 'dark' ? '#586A87' : '#9099A7'}
                        fontSize={12}
                        fontWeight={500}
                        fontFamily={'Pretendard'}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(i) => {
                          return i.slice(5, 10).replace('-', '/');
                        }}
                      />
                      <YAxis unit={'m'} tickLine={false} stroke={theme === 'dark' ? '#586A87' : '#9099A7'} axisLine={false} fontSize={12} fontWeight={500} fontFamily={'Pretendart'} />
                      <Tooltip cursor={{ fill: 'rgba(0, 118, 255, 0.15)' }} content={<CustomTooltip unit={'m'} all={true} />} />
                      <Brush startIndex={0} endIndex={allData.length < 20 ? undefined : 20} height={20} fill={'none'} />
                      <Legend verticalAlign={'top'} align={'right'} content={<CustomLegend title={t('시공물량통계')} unit={'m'} />} />

                      {/*<ReferenceLine y={0} stroke="#000" />*/}
                      <Bar dataKey="PIP001Distance" fill="#0076FF" stackId="stack" radius={10} />
                      <Bar dataKey="PIP002Distance" fill="#49AEFF" stackId="stack" radius={10} />
                      <Bar dataKey="PIP003Distance" fill="#E7F3FF" stackId="stack" radius={10} />
                    </BarChart>
                  </ResponsiveContainer>
                </PopupStyle.ChartArea>
              </>
            ) : (
              <>
                <PopupStyle.ChartArea style={{ marginBottom: 12 }}>
                  <ResponsiveContainer width={'100%'} height={269}>
                    <BarChart
                      data={tabData.filter(({ mopCde }) => mopCde === tabList.filter(({ active }) => active)[0]?.name)}
                      stackOffset="sign"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={6}
                      barCategoryGap={0}
                    >
                      <CartesianGrid strokeDasharray="0" stroke={theme === 'dark' ? '#313D4F' : '#D4DDE9'} vertical={false} />
                      <XAxis dataKey={'diameter'} height={20} stroke={theme === 'dark' ? '#586A87' : '#9099A7'} fontSize={12} fontWeight={500} fontFamily={'Pretendard'} axisLine={false} tickLine={false} />
                      <XAxis
                        dataKey={'date'}
                        height={20}
                        stroke={theme === 'dark' ? '#586A87' : '#9099A7'}
                        xAxisId={1}
                        interval={0}
                        fontSize={12}
                        fontWeight={500}
                        fontFamily={'Pretendard'}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(i) => {
                          return i.slice(5, 10).replace('-', '/');
                        }}
                      />
                      <YAxis unit={t('개')} tickLine={false} stroke={'#586A87'} axisLine={false} fontSize={12} fontWeight={500} fontFamily={'Pretendart'} />
                      <Tooltip cursor={{ fill: 'rgba(0, 118, 255, 0.15)' }} content={<CustomTooltip unit={'개'} />} />
                      <Brush startIndex={0} endIndex={allData.length < 20 ? undefined : 20} height={20} fill={'none'} />

                      {/*<Brush startIndex={0} endIndex={dummy.length-4}/>*/}
                      <Legend verticalAlign={'top'} align={'right'} content={<CustomLegend title={t('관수량통계')} unit={t('개')} />} />

                      {/*<ReferenceLine y={0} stroke="#000" />*/}
                      <Bar dataKey="PIP001Count" fill="#0076FF" stackId="stack1" radius={10} xAxisId={1} />
                      <Bar dataKey="PIP002Count" fill="#49AEFF" stackId="stack1" radius={10} xAxisId={1} />
                      <Bar dataKey="PIP003Count" fill="#E7F3FF" stackId="stack1" radius={10} xAxisId={1} />
                    </BarChart>
                  </ResponsiveContainer>
                </PopupStyle.ChartArea>
                <PopupStyle.ChartArea>
                  <ResponsiveContainer width={'100%'} height={269}>
                    <BarChart
                      data={tabData.filter(({ mopCde }) => mopCde === tabList.filter(({ active }) => active)[0]?.name)}
                      stackOffset="sign"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={6}
                      barCategoryGap={0}
                    >
                      <CartesianGrid strokeDasharray="0" stroke={theme === 'dark' ? '#313D4F' : '#D4DDE9'} vertical={false} />
                      <XAxis dataKey={'diameter'} height={20} stroke={theme === 'dark' ? '#586A87' : '#9099A7'} fontSize={12} fontWeight={500} fontFamily={'Pretendard'} axisLine={false} tickLine={false} />
                      <XAxis
                        dataKey={'date'}
                        height={20}
                        stroke={theme === 'dark' ? '#586A87' : '#9099A7'}
                        xAxisId={1}
                        interval={0}
                        fontSize={12}
                        fontWeight={500}
                        fontFamily={'Pretendard'}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(i) => {
                          return i.slice(5, 10).replace('-', '/');
                        }}
                      />
                      <YAxis unit={'m'} tickLine={false} stroke={'#586A87'} axisLine={false} fontSize={12} fontWeight={500} fontFamily={'Pretendard'} />
                      <Tooltip cursor={{ fill: 'rgba(0, 118, 255, 0.15)' }} content={<CustomTooltip unit={'m'} />} />
                      <Brush startIndex={0} endIndex={allData.length < 20 ? undefined : 20} height={20} fill={'none'} />

                      {/*<Brush startIndex={0} endIndex={dummy.length-4}/>*/}
                      <Legend verticalAlign={'top'} align={'right'} content={<CustomLegend title={t('시공물량통계')} unit={'m'} />} />

                      {/*<ReferenceLine y={0} stroke="#000" />*/}
                      <Bar dataKey="PIP001Distance" fill="#0076FF" stackId="stack1" radius={10} xAxisId={1} />
                      <Bar dataKey="PIP002Distance" fill="#49AEFF" stackId="stack1" radius={10} xAxisId={1} />
                      <Bar dataKey="PIP003Distance" fill="#E7F3FF" stackId="stack1" radius={10} xAxisId={1} />
                    </BarChart>
                  </ResponsiveContainer>
                </PopupStyle.ChartArea>
              </>
            )}

            {/*<PopupStyle.ChartArea style={{marginBottom:12}}>*/}
            {/*    <ResponsiveContainer width={'100%'} height={269}>*/}
            {/*        <BarChart*/}
            {/*            data={dummy}*/}
            {/*            stackOffset="sign"*/}
            {/*            margin={{*/}
            {/*                top: 5,*/}
            {/*                right: 30,*/}
            {/*                left: 20,*/}
            {/*                bottom: 5,*/}
            {/*            }}*/}
            {/*            barSize={6}*/}
            {/*            barCategoryGap={0}*/}

            {/*        >*/}

            {/*            <CartesianGrid strokeDasharray="0" stroke={'#313D4F'} vertical={false}/>*/}
            {/*            <XAxis dataKey={'diameter'}*/}
            {/*                   stroke={'#586A87'}*/}
            {/*                   fontSize={12} fontWeight={500} fontFamily={'Pretendard'}*/}
            {/*                   axisLine={false}*/}
            {/*                   tickLine={false}*/}
            {/*            />*/}
            {/*            <XAxis dataKey={'date'}*/}
            {/*                   height={20}*/}
            {/*                   domain={[dummy[0].date, dummy[dummy.length-1].date]}*/}
            {/*                   xAxisId={1}*/}
            {/*                   interval={0}*/}
            {/*                   tickFormatter={formatXAxis}*/}

            {/*            />*/}
            {/*            <YAxis unit={'개'} tickLine={false} stroke={'#586A87'} axisLine={false}*/}
            {/*                   fontSize={12} fontWeight={500} fontFamily={'Pretendart'}/>*/}
            {/*            <Tooltip*/}
            {/*                cursor={{fill:'rgba(0, 118, 255, 0.15)'}}*/}
            {/*                content={<CustomTooltip unit={'개'}/>}*/}
            {/*            />*/}
            {/*            /!*<Brush startIndex={0} endIndex={dummy.length-4}/>*!/*/}
            {/*            <Legend verticalAlign={'top'} align={'right'}*/}
            {/*                    content={<CustomLegend title={'관 수량 통계'} unit={'개'}/>}*/}
            {/*            />*/}

            {/*            /!*<ReferenceLine y={0} stroke="#000" />*!/*/}
            {/*            <Bar dataKey="PIP01_300_Count" fill="#0076FF" stackId="stack1" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP02_300_Count" fill="#49AEFF" stackId="stack1" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP03_300_Count" fill="#E7F3FF" stackId="stack1" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP01_400_Count" fill="#0076FF" stackId="stack2" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP02_400_Count" fill="#49AEFF" stackId="stack2" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP03_400_Count" fill="#E7F3FF" stackId="stack2" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP01_500_Count" fill="#0076FF" stackId="stack3" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP02_500_Count" fill="#49AEFF" stackId="stack3" radius={10} xAxisId={1}/>*/}
            {/*            <Bar dataKey="PIP03_500_Count" fill="#E7F3FF" stackId="stack3" radius={10} xAxisId={1}/>*/}
            {/*        </BarChart>*/}
            {/*    </ResponsiveContainer>*/}
            {/*</PopupStyle.ChartArea>*/}
          </PopupStyle.Inner>
        </PopupStyle.Cont>
        <PopupStyle.Foot>
          <PopupStyle.FootDesc>
            <p>
              {t('통계저장안내1')}
              <b>{t('통계저장안내2')}</b>
              {t('통계저장안내3')}
            </p>
          </PopupStyle.FootDesc>
          <FileDownStyle.Group>
            <FileDownStyle.Button type={'button'} onClick={() => alert('준비중')}>
              {t('PDF로저장')}
            </FileDownStyle.Button>
            <FileDownStyle.Button type={'button'} onClick={() => alert('준비중')}>
              {t('엑셀로저장')}
            </FileDownStyle.Button>
          </FileDownStyle.Group>
        </PopupStyle.Foot>
      </PopupStyle.Popup>
    </>
  );
};

export default PopupStatistics;
