import OlCore from '../OlCore';
import PipeLineModule from '../Module/PipeLineModule';
import getFacilityColor from '../style/getFacilityColor';
//ol
import { Feature } from 'ol';
import { OSM, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style, Circle, Icon } from 'ol/style.js';
import Select from 'ol/interaction/Select.js';
import { click, shiftKeyOnly, altKeyOnly } from 'ol/events/condition';
import { Point } from 'ol/geom';
import { unByKey } from 'ol/Observable';
//icon
import IcoStartPoint from 'assets/images/2dMap/ico-start-point-map.svg';
import IcoStartPointA from 'assets/images/2dMap/ico-start-point-map-active.svg';
import IcoEndPoint from 'assets/images/2dMap/ico-end-point-map.svg';
import IcoEndPointA from 'assets/images/2dMap/ico-end-point-map-active.svg';
import IcoLocation from 'assets/images/2dMap/ico-location.svg';
import { RESOLUTION_TO_PROJ } from '../../../util/global';
import ShpModule from '../Module/ShpModule';

class ObjectMapShp extends ShpModule {
  public pipeLayers: VectorLayer<VectorSource>[] = []; // 파이프 라인 레이어
  private pipeSource!: VectorSource;

  public pipeTypeList = [];

  // 버퍼 여부
  public buffer: boolean = true;

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    const facilitys = JSON.parse(sessionStorage.getItem('facilitys') || '');
    this.pipeTypeList = facilitys?.map((ele) => ({ ...ele, show: true }));

    // 파이프 라인 레이어
    this.pipeTypeList.map((ele: any) => {
      const layer = new VectorLayer<VectorSource>({
        properties: {
          id: ele.code,
          value: ele.value,
        },
        visible: ele.show,
        zIndex: 3,
        renderBuffer: 100,
      });
      this.pipeLayers.push(layer);
    });

    this.setLayers([...this.pipeLayers]);
  }
  public drawPipe(datas) {
    const features = this.createPipeFeatures(datas.water.pipe, 'WATER');
    this.pipeSource = this.createSource(features);
    this.pipeLayers.map((i) => {
      i.setSource(this.pipeSource);
    });
  }
}

export default ObjectMapShp;
