import { PopupStyle } from '../components/templates/_style';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import ExportDate from '../components/templates/ExportDate';
import { PopupFrameSt } from '../components/templates/survey/popup/_style';
import DateFilter from '../components/templates/DateFilter';

const PopupExportDateSelect = ({ workDate, date, setDate, onClose, onExport }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <PopupStyle.Dimmed></PopupStyle.Dimmed>
      <PopupStyle.Popup $width={531}>
        <PopupStyle.Head>
          <PopupStyle.Title>{t('EXCEL 저장')}</PopupStyle.Title>
          <PopupStyle.CloseBtn type={'button'} onClick={onClose}></PopupStyle.CloseBtn>
        </PopupStyle.Head>
        <PopupStyle.Cont>
          <PopupStyle.Inner>
            <ExportDate workDate={workDate} date={date} setDate={setDate} />
          </PopupStyle.Inner>
        </PopupStyle.Cont>
        <PopupStyle.Foot style={{ height: 72 }}>
          <PopupFrameSt.BtnWrap>
            <button type={'button'} onClick={onClose}>
              {t('취소')}
            </button>
            <button type={'button'} onClick={onExport}>
              {t('저장')}
            </button>
          </PopupFrameSt.BtnWrap>
        </PopupStyle.Foot>
      </PopupStyle.Popup>
    </>
  );
};

export default PopupExportDateSelect;
