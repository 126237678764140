import React from 'react';
import { ButtonStyle } from './_style';
import { ButtonProps } from './_interface';

const Button = ({ width, height, colorType, fontSize, text, onClick, style, ico, icoHover, icoPosition, icoSize, disabled, radius }: ButtonProps) => {
  return (
    <ButtonStyle.Wrap type={'button'} width={width} height={height} colorType={colorType} disabled={disabled} radius={radius} onClick={onClick} style={style} ico={ico} icoHover={icoHover} icoPosition={icoPosition} icoSize={icoSize}>
      <ButtonStyle.Text fontSize={fontSize} colorType={colorType}>
        {text}
      </ButtonStyle.Text>
    </ButtonStyle.Wrap>
  );
};

export default Button;
