import React from 'react';
import { CheckboxStyle } from './_style';
import { CheckboxProps } from './_interface';

const Checkbox = ({ id, text, name, type, defaultChecked, checked, style, onChange, size, dark, disabled }: CheckboxProps) => {
  return (
    <CheckboxStyle.Wrap style={style} size={size}>
      <CheckboxStyle.Input id={id} className={dark ? 'dark' : undefined} name={name} type={type === 'radio' ? 'radio' : 'checkbox'} defaultChecked={defaultChecked} checked={checked} onChange={onChange} disabled={disabled} />
      <CheckboxStyle.Label htmlFor={id} type={type} text={text} size={size}>
        {text}
      </CheckboxStyle.Label>
    </CheckboxStyle.Wrap>
  );
};

export default Checkbox;
