import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';

const SkeletonSiteInfo = () => {
  return (
    <SkeletonContainer
      element={
        <SkeletonStyle.SiteInfoInner>
          <SkeletonStyle.SiteInfoRow>
            <SkeletonStyle.SiteInfoName className={'skeleton-item'}></SkeletonStyle.SiteInfoName>
            <SkeletonStyle.SiteInfoValue className={'skeleton-item'}></SkeletonStyle.SiteInfoValue>
          </SkeletonStyle.SiteInfoRow>
          <SkeletonStyle.SiteInfoRow>
            <SkeletonStyle.SiteInfoName className={'skeleton-item'}></SkeletonStyle.SiteInfoName>
            <SkeletonStyle.SiteInfoValue className={'skeleton-item'}></SkeletonStyle.SiteInfoValue>
          </SkeletonStyle.SiteInfoRow>
          <SkeletonStyle.SiteInfoRow>
            <SkeletonStyle.SiteInfoName className={'skeleton-item'}></SkeletonStyle.SiteInfoName>
            <SkeletonStyle.SiteInfoValue className={'skeleton-item'}></SkeletonStyle.SiteInfoValue>
            <SkeletonStyle.SiteInfoValue className={'skeleton-item'} style={{ marginTop: 4 }}></SkeletonStyle.SiteInfoValue>
          </SkeletonStyle.SiteInfoRow>
          <SkeletonStyle.SiteInfoRow>
            <SkeletonStyle.SiteInfoName className={'skeleton-item'}></SkeletonStyle.SiteInfoName>
            <SkeletonStyle.SiteInfoValue className={'skeleton-item'}></SkeletonStyle.SiteInfoValue>
            <SkeletonStyle.SiteInfoValue className={'skeleton-item'} style={{ marginTop: 4 }}></SkeletonStyle.SiteInfoValue>
          </SkeletonStyle.SiteInfoRow>
        </SkeletonStyle.SiteInfoInner>
      }
    />
  );
};

export default SkeletonSiteInfo;
