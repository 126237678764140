import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { siteListState, themeState } from '../../store/Atoms';
import styled from 'styled-components';
import { SelectStyle } from '../atoms/_style';
import { SelectProps } from '../../pages/_interface';
import IcoSiteSearch from '../../assets/images/ico-site-search.svg';
import Input from '../atoms/Input';
import IcoInputSearch from '../../assets/images/ico-input-search.svg';
import NoData from '../atoms/NoData';
import { useTranslation } from 'react-i18next';

const SearchSiteStyle = {
  ...SelectStyle,
  Item: styled(SelectStyle.Item)`
    display: flex;
    align-items: center;
    height: 64px;
    > div {
      display: flex;
      flex-direction: column;
      strong {
        line-height: 14px;
        font-size: 14px;
        color: ${(p) => p.theme.select.item.color};
        font-family: Pretendard;
        font-weight: 500;
        margin-bottom: 6px;
      }
      p {
        line-height: 14px;

        color: #a0aec0;
        font-family: Pretendard;
        font-weight: 400;
        font-size: 14px;
      }
    }

    &:before {
      content: '';
      width: 32px;
      height: 32px;
      margin-right: 12px;
      background: url(${IcoSiteSearch}) no-repeat center;
    }
    &:hover {
      > div {
        strong,
        p {
          color: #0076ff;
        }
      }
    }
  `,
  InputBox: styled.div`
    padding: 12px 16px;
    background: ${(p) => p.theme.select.search.bg};
  `,
};

const SearchSite = () => {
  const { t } = useTranslation();
  const [listShow, setListShow] = useState(false);
  const [themeMode] = useRecoilState(themeState);
  const comboRef = useRef<HTMLDivElement>(null); //셀렉트 박스 영역

  const [siteList, setSiteList] = useRecoilState(siteListState);
  const [word, setWord] = useState('');
  const selected = siteList.find((i) => i.active)?.text;

  const onChangeSelect = (val) => {
    setSiteList((prev) => {
      const newArr: SelectProps[] = [];
      prev.map((i) => {
        newArr.push({ ...i, active: val === i.value });

        sessionStorage.setItem('siteId', String(newArr.find((i) => i.value === val)?.value));
        sessionStorage.setItem('projection', String(newArr.find((i) => i.value === val)?.projection));
      });

      return newArr;
    });
  };
  //외부영역 클릭시 셀렉트박스 닫기
  const handleClickOutSide = (e: MouseEvent) => {
    if (listShow && !comboRef.current?.contains(e.target as Element)) {
      setListShow(false);
    }
  };

  useEffect(() => {
    if (listShow) document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  });

  return (
    <SearchSiteStyle.Area ref={comboRef}>
      <SearchSiteStyle.Wrap option={siteList} width={420}>
        <SearchSiteStyle.Selected option={siteList} onClick={() => setListShow(!listShow)} className={listShow ? 'active' : undefined}>
          {selected}
        </SearchSiteStyle.Selected>
        <SearchSiteStyle.ListWrap style={{ display: listShow ? 'block' : 'none' }}>
          <SearchSiteStyle.InputBox>
            <Input
              width={'full'}
              placeholder={t('사업장,발주처조회')}
              bg={IcoInputSearch}
              onChange={(e) => {
                setWord(e.target.value);
              }}
            />
          </SearchSiteStyle.InputBox>
          <SearchSiteStyle.List className={themeMode === 'light' ? 'cScroll light' : 'cScroll'}>
            {siteList.filter((i) => i.text.includes(word) || i.ownerCompany!.includes(word)).length ? (
              siteList
                .filter((i) => i.text.includes(word) || i.ownerCompany!.includes(word))
                .map(({ text, value, ownerCompany }: any, index) => {
                  return (
                    <SearchSiteStyle.Item
                      key={index}
                      aria-valuetext={value}
                      tabIndex={0}
                      onClick={(e) => {
                        onChangeSelect(value);
                        setListShow(false);
                      }}
                      option={siteList}
                    >
                      <div>
                        <strong>{text}</strong>
                        <p>{ownerCompany}</p>
                      </div>
                    </SearchSiteStyle.Item>
                  );
                })
            ) : (
              <li>
                <NoData style={{ margin: '20px 0' }} />
              </li>
            )}
          </SearchSiteStyle.List>
        </SearchSiteStyle.ListWrap>
      </SearchSiteStyle.Wrap>
    </SearchSiteStyle.Area>
  );
};

export default SearchSite;
