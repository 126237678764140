import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PagingTableStyle, TableStyle } from '../../atoms/_style';
import Table from '../../atoms/Table';
import { ContAddBtn, DataFilterStyle, SurveyStyle } from '../_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import PagingTable from '../../atoms/PagingTable';
import SearchFilter from '../../atoms/SearchFilter';
import { OptionProps } from '../../atoms/_interface';
import NoData from '../../atoms/NoData';
import query from 'hooks/RQuery/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// 사업장 목록
const WorkPlaceList = ({ onSelectSite }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [themeMode] = useRecoilState(themeState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState<any>([]);
  const [option, setOption] = useState<OptionProps[]>([
    { text: '전체', value: 'ALL', active: true },
    { text: '사업장', value: 'SITE_NAME', active: false },
    { text: '발주처', value: 'OWNER', active: false },
    { text: '주소', value: 'ADDRESS', active: false },
  ]);
  const [keyword, setKeyword] = useState('');

  const list = query.sites.useSites({ pageNum: currentPage, category: String(option.filter((i) => i.active)[0].value), keyword: keyword });

  //기간 선택 변경
  const onChange = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    // onParamChange(e)
    const value = (e.target as any).ariaValueText;
    const newArr: OptionProps[] = [];
    option.map((i) => {
      newArr.push({ ...i, active: value === i.value });
    });
    setOption(newArr);
  };

  //검색어 변경
  const onChangeWord = (e) => {
    setKeyword(e.target.value);
  };

  //검색버튼 클릭
  const onSearch = async () => {
    await setCurrentPage(1);
    list.refetch();
  };

  //api 호출시 테이블 데이터 변경
  useEffect(() => {
    if (list.data) {
      setTableData(list.data.siteInfo);
      setTotalPages(list.data.page.totalPages);
    }
  }, [list.data]);

  useEffect(() => {
    list.refetch();
  }, [currentPage]);

  return (
    <SurveyStyle.DescArea>
      <SearchFilter option={option} onChange={onChange} onChangeWord={onChangeWord} onSearch={onSearch} placeholder={t('사업장,발주처,주소로검색')} />
      {tableData?.length ? (
        <>
          <PagingTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            caption={'현장 리스트'}
            colgroup={['27%', '27%', 'auto']}
            totalPages={totalPages}
            height={'calc(100% - 147px)'}
            thead={
              <tr className={'txtLeft'}>
                <th scope={'col'}>{t('사업장')}</th>
                <th scope={'col'}>{t('발주처')}</th>
                <th scope={'col'}>{t('주소')}</th>
              </tr>
            }
            tbody={
              <>
                {tableData?.map((i, index) => {
                  return (
                    <tr className={'txtLeft'} key={index} onClick={() => onSelectSite(i.siteId)}>
                      <td>
                        <PagingTableStyle.SiteName className={'txtLeft'}>{i.siteName}</PagingTableStyle.SiteName>
                      </td>
                      <td>{i.owner}</td>
                      <td>{i.address}</td>
                    </tr>
                  );
                })}
              </>
            }
            leftBtnGroup={
              <ContAddBtn type={'button'} onClick={() => navigate('/workplace/register')}>
                {t('사업장등록')}
              </ContAddBtn>
            }
          />
        </>
      ) : (
        <>
          <ContAddBtn type={'button'} onClick={() => navigate('/workplace/register')} style={{ width: '100%' }}>
            {t('사업장등록')}
          </ContAddBtn>
          <NoData style={{ height: 'auto' }} />
        </>
      )}
    </SurveyStyle.DescArea>
  );
};

export default WorkPlaceList;
