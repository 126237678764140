import React, { useRef, useState, useEffect, useCallback } from 'react';
import { DragDropBoxSt } from './_style';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { confirmOneBtn, dragDropBoxPage, popupFailState } from 'store/popupAtoms';
import { SurveyInitSt } from '../_style';
import { getFileType } from './common.func';
import { FILE_TYPES_PROPS } from 'util/global';

// 페이지 내의 dnd ( Not for popup )
// multiple = false
//
function DragDropBoxPage({ height, acceptType, multiple, mainText, subText, curItem, isPin }: { height?: number; acceptType?: string[]; multiple?: boolean; mainText?: string; subText?: string; curItem?: FILE_TYPES_PROPS; isPin?: boolean }) {
  const { t, i18n } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [dndPgState, setdndPgState] = useRecoilState(dragDropBoxPage);
  const [popFail, setPopFail] = useRecoilState(popupFailState); // 미지원파일 에러 팝업
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn); // 경고문구 팝업
  const [isDragging, setIsDragging] = useState(false); //드래그중 스타일

  const [contentFileUrl, setContentFileUrl] = useState<string | null>(null); // 파일 주소
  const [helpStatus, setHelpStatus] = useState(false);

  //   console.log('Page / DnD state:', dndState.contentFile);

  // input element 초기화 (연속으로 같은 value 인식 못하는 부분 해결)
  const clearInput = () => {
    if (inputRef?.current) inputRef.current.value = '';
  };

  // 파일선택 버튼 클릭
  const onClickInput = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };
  // 드래그 앤 드롭
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };

  // state 저장 함수 (drag,input 공통)
  const setDndState = (files) => {
    // 유효성 체크후 탈락시 - 파일 거부
    if (!isValidFile(files[0])) {
      if (isPin) {
        if (files.length > 1) {
          setConfirmOneBtn((prev) => ({
            ...prev,
            isOpen: true,
            desc: '파일을 여러개 업로드 할 수 없습니다.',
          }));
          return;
        }
        // 파일 1개일때
        if (files?.length === 1) {
          // 유효성 체크 통과시 - 저장
          //global
          setdndPgState((prev) => ({
            ...prev,
            type: getFileType(files[0]),
            contentFileArr: [...files],
          }));

          //받은 파일 -> 이미지 주소 읽고 저장(썸네일용)
          readImage(files[0]);
        }
      }
      return;
    } else {
      // 파일을 여러개 업로드 할 수 없습니다.
      if (files.length > 1) {
        setConfirmOneBtn((prev) => ({
          ...prev,
          isOpen: true,
          desc: '파일을 여러개 업로드 할 수 없습니다.',
        }));
        return;
      }
      // 파일 1개일때
      if (files?.length === 1) {
        // 유효성 체크 통과시 - 저장
        //global
        setdndPgState((prev) => ({
          ...prev,
          type: getFileType(files[0]),
          contentFileArr: [...files],
        }));

        //받은 파일 -> 이미지 주소 읽고 저장(썸네일용)
        readImage(files[0]);
      }
    }
  };

  // 1) 드래그 앤 드롭 완료 함수
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    let files = e.dataTransfer.files || [];
    // state 저장
    setDndState(files);

    // 초기화
    clearInput();
  };

  // 2) input onchange 이벤트 완료 함수
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //  const { files } = e.target;
    //  console.log(files);

    let files = e.target.files || [];
    // state 저장
    setDndState(files);
    // 초기화
    clearInput();
  };

  // [공통] 파일 거르기
  const isValidFile = (file: any) => {
    let type = file?.type; // 'image/jpeg', 'text/csv' ...
    let name = file?.name; // point.csv , pipe.csv ...

    let accept = acceptType; //['text/csv']
    // 1) 확장자 불일치
    if (!accept?.includes(type)) {
      setPopFail((prev) => ({
        ...prev,
        isOpen: true,
        fileName: name,
      }));

      return false;
    }

    // 2) 확장자는 일치하지만, 파일명 형식 안맞을때
    // point, pipe 파일명에 없을때 처리
    if (getFileType(file) !== 'point' && getFileType(file) !== 'pipe') {
      setPopFail((prev) => ({
        ...prev,
        isOpen: true,
        fileName: name,
      }));
      return false;
    }

    // 측점 or 관로 중 특정 타입을 선택했을때 파일명과 비교
    if (curItem) {
      if (curItem?.type !== getFileType(file)) {
        setConfirmOneBtn((prev) => ({
          ...prev,
          isOpen: true,
          desc: `${curItem?.text} 파일을 선택하세요.`,
        }));
        return false;
      }
    }

    return true;
  };

  // [공통] 받은 파일 이미지 주소 읽고 저장
  const readImage = (image: File) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      //global
      setdndPgState((prev) => ({
        ...prev,
        contentFileUrl: String(e.target?.result),
      }));

      //local
      setContentFileUrl(String(e.target?.result));
    };
    reader.readAsDataURL(image);
  };

  return (
    <DragDropBoxSt.DragDropBox $height={height} className={`${isDragging && 'isDragging'} `} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
      {/* 도움말 시작 */}
      <SurveyInitSt.HelpArea>
        <SurveyInitSt.HelpBtn
          type={'button'}
          onClick={() => {
            setHelpStatus(true);
          }}
        >
          도움말
        </SurveyInitSt.HelpBtn>
        <SurveyInitSt.HelpBox className={helpStatus ? 'active' : undefined}>
          <SurveyInitSt.HelpBoxHead>
            도움말
            <SurveyInitSt.Close
              type={'button'}
              onClick={() => {
                setHelpStatus(false);
              }}
            >
              <span className={'hidden'}>닫기</span>
            </SurveyInitSt.Close>
          </SurveyInitSt.HelpBoxHead>
          <SurveyInitSt.HelpBoxCont>
            <p>측점 및 관로 파일의 경우, 오토캐드 맵에서 무브먼츠에서 개발한 전용 플러그인으로 출력한 측점 파일(point.csv)과 관로 파일(pipe.csv)만 업로드할 수 있습니다.</p>
            <hr />
            <p>사진 파일은 JPG, PNG 파일만 인식하며, 파일명 규칙은 다음과 같습니다.</p>
            <SurveyInitSt.BlueList>
              <SurveyInitSt.BlueItem>측점명-1: 관로 근경 사진</SurveyInitSt.BlueItem>
              <SurveyInitSt.BlueItem>측점명-2: 관로 원경 사진</SurveyInitSt.BlueItem>
              <SurveyInitSt.BlueItem>측점명-3: 지반고 근경 사진</SurveyInitSt.BlueItem>
              <SurveyInitSt.BlueItem>
                측점명-4: 지반고 원경 사진
                <br /> ex) 20240403-001-2.jpg: 관로 원경 사진
              </SurveyInitSt.BlueItem>
            </SurveyInitSt.BlueList>
            <hr />
            <p>사진 파일은 측점 및 관로가 등록된 이후에 추가할 수 있습니다. 사진만 먼저 업로드할 수는 없습니다.</p>
          </SurveyInitSt.HelpBoxCont>
        </SurveyInitSt.HelpBox>
      </SurveyInitSt.HelpArea>
      {/* 도움말 끝 */}

      <>
        <div className="ico"></div>
        <p className="mainText">{mainText ? mainText : '드래그 & 드롭하여 파일을 업로드 하세요'}</p>
        <p className="subText">{subText ? subText : ''}</p>
        <p className="or">{t('또는')}</p>
      </>

      <button className="addFileBtn" onClick={onClickInput}>
        {t('파일선택')}
      </button>
      <input ref={inputRef} id="inputFile" type="file" accept={acceptType?.toString()} onChange={onInputChange} style={{ display: 'none' }} multiple={multiple} />
    </DragDropBoxSt.DragDropBox>
  );
}

export default DragDropBoxPage;
