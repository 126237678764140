import OlCore from '../OlCore';
import PipeLineModule from '../Module/PipeLineModule';
import getFacilityColor from '../style/getFacilityColor';
//ol
import { Feature } from 'ol';
import { OSM, XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style, Circle, Icon } from 'ol/style.js';
import Select from 'ol/interaction/Select.js';
import { click, shiftKeyOnly, altKeyOnly } from 'ol/events/condition';
import { Point } from 'ol/geom';
import { unByKey } from 'ol/Observable';
//icon
import IcoStartPoint from 'assets/images/2dMap/ico-start-point-map.svg';
import IcoStartPointA from 'assets/images/2dMap/ico-start-point-map-active.svg';
import IcoEndPoint from 'assets/images/2dMap/ico-end-point-map.svg';
import IcoEndPointA from 'assets/images/2dMap/ico-end-point-map-active.svg';
import IcoLocation from 'assets/images/2dMap/ico-location.svg';
import { RESOLUTION_TO_PROJ } from '../../../util/global';

interface CoordinateProps {
  pointId: string;
  coordinate: {
    x: number;
    y: number;
    z: number;
  };
  ptNum?: string;
  depth?: any;
}

class ObjectMapPipeLine extends PipeLineModule {
  public pipeLayers: VectorLayer<VectorSource>[] = []; // 파이프 라인 레이어
  private pointLayers: VectorLayer<VectorSource>[] = []; // 시점 종점 레이어
  private activePointLayer!: VectorLayer<VectorSource>; // 활성화 마커 레이어
  private pointSingleLayer!: VectorLayer<VectorSource>; // 측량성과등록 삭제팝업 레이어

  private multiClick!: Select;
  public selectClick!: Select;
  private selectClickPt!: Select;

  private selectedPipeArr: any[] = []; //복수 선택된 파이프 피쳐 배열

  public pointList = [{ style: 'startPt' }, { style: 'endPt' }];

  public pipeTypeList = [
    //  { value: '상수', code: 'WATER', show: true },
    //  { value: '오수', code: 'WASTE', show: true },
    //  { value: '우수', code: 'RAIN', show: true },
    //  { value: '가스', code: 'GAS', show: true },
    //  { value: '통신', code: 'NETWORK', show: true },
    //  { value: '전기', code: 'ELECTRIC', show: true },
    //  { value: '송유', code: 'OIL', show: true },
    //  { value: '난방', code: 'HEAT', show: true },
  ];

  // 버퍼 여부
  public buffer: boolean = true;

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    const facilitys = JSON.parse(sessionStorage.getItem('facilitys') || '');
    this.pipeTypeList = facilitys?.map((ele) => ({ ...ele, show: true }));

    this.pointList.map((ele: any) => {
      const layer = new VectorLayer<VectorSource>({
        properties: {
          id: ele.style + 'Layer',
        },
        visible: true,
        zIndex: 5,
        style: this.defaultStyle(ele.style),
      });
      this.pointLayers.push(layer);
    });
    // 선택된 항목 마커 레이어
    this.activePointLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'activePtLayer',
      },
      visible: true,
      zIndex: 6,
      style: this.defaultStyle('activePt'),
    });
    // 파이프 라인 레이어
    this.pipeTypeList.map((ele: any) => {
      const layer = new VectorLayer<VectorSource>({
        properties: {
          id: ele.code,
          value: ele.value,
        },
        visible: ele.show,
        zIndex: 3,
        style: this.defaultStyle('line'),
        renderBuffer: 100,
      });
      layer.set('selectable', true);
      this.pipeLayers.push(layer);
    });

    //측량성과등록 삭제팝업 레이어
    this.pointSingleLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'PointSingleLayer',
      },
      visible: true,
      zIndex: 4,
    });

    this.setLayers([...this.pipeLayers, ...this.pointLayers, this.activePointLayer, this.pointSingleLayer]);
  }

  // 스타일(기본)
  private defaultStyle(type?: 'startPt' | 'endPt' | 'activePt' | 'line') {
    if (type === 'activePt') {
      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: IcoLocation,
            anchor: [0.5, 36],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
          }),
        }),
      ];
    }
    if (type === 'startPt') {
      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: IcoStartPoint,
            // rotation: 45,
            // anchor: [0.5, -1],
            // anchorXUnits: 'fraction',
            // anchorYUnits: 'pixels',
          }),
        }),
      ];
    }
    if (type === 'endPt') {
      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: IcoEndPoint,
            // anchor: [0.5, 30],
            // anchorXUnits: 'fraction',
            // anchorYUnits: 'pixels',
          }),
        }),
      ];
    }

    return [
      new Style({
        stroke: new Stroke({
          width: 4,
          color: '#478dff',
          lineCap: 'round',
        }),
      }),
    ];
  }

  // 포인트 active 스타일
  private pointActiveStyle(feature: any) {
    const type = feature?.getProperties()?.properties.style;
    const isActive = feature.getProperties().properties.active;
    //  console.log('type:', type);

    let icoActive: any = IcoStartPointA;
    switch (type) {
      case 'startPt':
        icoActive = IcoStartPointA;
        break;
      case 'endPt':
        icoActive = IcoEndPointA;
        break;
    }

    return [
      new Style({
        image: new Icon({
          opacity: 1,
          src: icoActive,
        }),
      }),
    ];
  }

  // [파이프] 버퍼 해제 스타일
  private offStyle(feature: any) {
    const pipeType = feature.getProperties().properties.layerId;
    return [
      new Style({
        stroke: new Stroke({
          width: 2,
          color: getFacilityColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
    ];
  }

  // [파이프] 버퍼 적용된 스타일
  private onStyle(feature: any) {
    const properties = feature.getProperties().properties;
    const pipeType = properties.layerId;
    const hol = properties.hol;
    const shpCde = properties.shpCde; // SHP001 원형, SHP002 사각
    const diameter = shpCde === 'SHP001' ? Number(properties.diameter) * 100 : shpCde === 'SHP002' ? Number(hol) * 100 * 1000 : 0; // 300
    const resol = this.core.mapInstance.getView().getResolution();
    const projection = sessionStorage.getItem('projection');

    //  console.log(pipeType, diameter);

    return [
      new Style({
        stroke: new Stroke({
          width: 2,
          color: getFacilityColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * (RESOLUTION_TO_PROJ(projection) / resol) : 1,
          color: getFacilityColor(pipeType, 0.6),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
    ];
  }

  // 파이프 스타일 Active
  private pipeActiveStyle(feature: any) {
    const properties = feature.getProperties().properties;
    const pipeType = properties.layerId;
    const hol = properties.hol;
    const shpCde = properties.shpCde; // SHP001 원형, SHP002 사각
    const diameter = shpCde === 'SHP001' ? Number(properties.diameter) * 100 : shpCde === 'SHP002' ? Number(hol) * 100 * 1000 : 0; // 300
    const resol = this.core.mapInstance.getView().getResolution();
    const projection = sessionStorage.getItem('projection');

    return [
      new Style({
        stroke: new Stroke({
          width: 4,
          color: getFacilityColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * (RESOLUTION_TO_PROJ(projection) / resol) : 1,
          color: getFacilityColor(pipeType, 0.4),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
      new Style({
        //   fill: new Fill({
        //     color: getFacilityColor(pipeType, 0.4),
        //   }),
        stroke: new Stroke({
          width: resol ? diameter * 1.3 * (RESOLUTION_TO_PROJ(projection) / resol) : 1,

          color: `rgba(255, 255, 255, .8)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * 1.3 * (RESOLUTION_TO_PROJ(projection) / resol) + 10 : 1,
          color: `rgba(255, 255, 255, .1)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
    ];
  }

  // 여러개 선택 라인 스타일(선택 활성화)
  private multiSelectStyle(feature: any) {
    const properties = feature.getProperties().properties;
    const pipeType = properties.layerId;
    const hol = properties.hol;
    const shpCde = properties.shpCde; // SHP001 원형, SHP002 사각
    const diameter = shpCde === 'SHP001' ? Number(properties.diameter) * 100 : shpCde === 'SHP002' ? Number(hol) * 100 * 1000 : 0; // 300
    const resol = this.core.mapInstance.getView().getResolution();
    const projection = sessionStorage.getItem('projection');

    return [
      new Style({
        stroke: new Stroke({
          width: 4,
          color: getFacilityColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * (RESOLUTION_TO_PROJ(projection) / resol) : 1,
          color: getFacilityColor(pipeType, 0.4),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * 1.3 * (RESOLUTION_TO_PROJ(projection) / resol) : 1,
          color: `rgba(255, 0, 0, 0.8)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? diameter * 1.3 * (RESOLUTION_TO_PROJ(projection) / resol) + 10 : 1,
          color: `rgba(255, 255, 255, .1)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
    ];
  }

  //------ 포인트 관련 시작 ---------

  // 🔥 stCoordinate, endCoordinate 형태 변환
  // 좌표는 coordinate 키로 묶어주기
  private getCoord(data): CoordinateProps {
    return { pointId: data?.pointId, coordinate: { x: data.x, y: data.y, z: data?.z }, depth: data?.depth, ptNum: data?.ptNum };
  }

  // 두 좌표 중심점 구하기
  public getMidPoint(stCoord: { x: any; y: any }, endCoord: { x: any; y: any }) {
    const start: any = [stCoord?.x, stCoord?.y];
    const end: any = [endCoord?.x, endCoord?.y];
    const mid_point = ([x1, y1], [x2, y2]) => [(x1 + x2) / 2, (y1 + y2) / 2];
    const result = mid_point(start, end);
    return {
      pointId: '',
      coordinate: {
        x: result[0],
        y: result[1],
        z: 0,
      },
    };
  }

  // 시점, 종점 피쳐 만들기
  private createPointFeature(data: CoordinateProps, properties?: any) {
    const feature = new Feature({
      id: data?.pointId ? 'startendPt' : 'activePt',
      geometry: new Point([data?.coordinate.y, data?.coordinate.x]),
      properties: {
        pointId: data?.pointId,
        ...properties,
      },
    });

    return feature;
  }

  // 시점 종점 마커 그리기 (파이프 선택시)
  public drawPoint(selectedProperties: any) {
    const { stCoordinate, endCoordinate, facilityKind } = selectedProperties;

    let feature: any = null;
    this.pointLayers.map((layer) => {
      switch (layer.get('id')) {
        case 'startPtLayer':
          feature = this.createPointFeature(this.getCoord(stCoordinate), { style: 'startPt', ...stCoordinate, facilityKind });
          break;
        case 'endPtLayer':
          feature = this.createPointFeature(this.getCoord(endCoordinate), { style: 'endPt', ...endCoordinate, facilityKind });
          break;
      }
      const source = this.createSource([feature]);
      layer.setSource(source);
      layer.setVisible(true);
    });
  }

  // 활성화 마커 그리기 (처음)
  public drawActivePoint(selectedProperties: any) {
    const { stCoordinate, endCoordinate } = selectedProperties;

    const midPoint = this.getMidPoint(this.getCoord(stCoordinate).coordinate, this.getCoord(endCoordinate).coordinate);
    const midFeature = this.createPointFeature(midPoint, { style: 'activePt' });
    const sourceMid = this.createSource([midFeature]);

    this.activePointLayer.setSource(sourceMid);
    this.activePointLayer.setVisible(true);

    //  console.log(midPoint);
  }

  // 활성화 마커 위치 변경 (새로운 클릭)
  public redrawActivePoint(stCoord: { x: number; y: number }, endCoord?: { x: number; y: number }) {
    // active 마커
    let activePtFeature = this.activePointLayer.getSource()?.getFeatures()?.[0];
    // 새로운 위치
    let newCoord = { x: 0, y: 0 };
    // 포인트 데이터일때
    if (stCoord) {
      newCoord = { ...stCoord };
    }
    // 파이프 데이터일때 (가운데 위치)
    if (endCoord) {
      const midPoint = this.getMidPoint(stCoord, endCoord);
      newCoord = { ...midPoint.coordinate };
    }
    // active 마커 위치 변경
    activePtFeature?.setGeometry(new Point([newCoord.y, newCoord.x]));
  }

  // 시점 종점 포인트 클릭 이벤트(Select)
  public addClickPoint(callback?: any) {
    this.core.mapInstance.removeInteraction(this.selectClickPt); // 초기화

    this.selectClickPt = new Select({
      condition: click,
      layers: this.pointLayers,
      style: (feature) => this.pointActiveStyle(feature),
    });

    this.selectClickPt.on('select', (e) => {
      const targetCollection = e.target.getFeatures().getArray();
      if (targetCollection.length) {
        // 콜백 함수
        callback(targetCollection[0].getProperties().properties);
      } else {
        //콜백 함수
        callback(null);
      }
    });

    this.core.mapInstance.addInteraction(this.selectClickPt);
  }

  public mapChange() {
    this.core.mapInstance.getAllLayers()[0].setSource(new OSM());
    this.moveLayer('PointSingleLayer', { padding: [130, 130, 130, 130], duration: 400 });
  }

  public mapChangePipe() {
    this.core.mapInstance.getAllLayers()[0].setSource(new OSM());
    this.moveLayer('WATER', { padding: [130, 130, 130, 130], duration: 400 });
  }

  // 측량성과등록 삭제팝업 지도 포인트
  public drawPointSingleBySurvey(data: any, isSurvey = false) {
    const { coordinate, pointId, ptNum } = data;
    let feature: any = [];

    data.map((i) => {
      feature.push(this.createPointFeature(i, { style: 'startPt', ...i }));
    });

    // feature = this.createPointFeature(data, { style: 'startPt', ...data }); // coord, properties
    const source = this.createSource(feature);

    this.pointSingleLayer.setStyle((feature) => {
      const isActive = feature.getProperties().properties.active;
      return [
        new Style({
          image: new Icon({
            opacity: 1,
            src: isActive ? IcoStartPoint : IcoStartPointA,
            // rotation: 45,
            // anchor: [0.5, -1],
            // anchorXUnits: 'fraction',
            // anchorYUnits: 'pixels',
          }),
          zIndex: isActive ? 4 : 3,
        }),
      ];
    });
    this.pointSingleLayer.setSource(source);

    if (!isSurvey) {
      // 해당 레이어로 이동
      this.moveLayer('PointSingleLayer', { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
    }
  }

  //측량성과등록 지도 내 포인트 클릭시 state변경
  public selectPointBySurvey(setCheckPoint, setCheckNeighborPipe) {
    this.core.mapInstance.on('click', (e) => {
      const feature = this.core.mapInstance.forEachFeatureAtPixel(e.pixel, (feature) => {
        return feature;
      });
      console.log(feature);
    });
  }

  //------ 포인트 관련 끝 ---------

  /* ------------------ DRAWING ------------------ */

  // 전체 데이터 그리기(파이프 라인 레이어) - 상수,오수,..
  public drawFacilityAll(datas: any) {
    let keys = Object.keys(datas); // facility 배열

    // 초기화
    this.pipeLayers?.map((layer) => {
      layer.getSource()?.clear();
    });

    keys.map((key) => {
      let layerId = key;
      const features = this.createPipeLineFeatures(datas[key], layerId);
      const source = this.createSource(features);

      this.pipeLayers.map((layer: any) => {
        if (layer.get('id') === layerId) {
          layer.setSource(source);

          // 레이어에 스타일 부여
          layer.setStyle((feature: Feature) => {
            return this.onStyle(feature);
          });
        }
      });
      // 해당 레이어로 이동 -> 마지막 facility
      // this.moveLayer(layerId, { padding: [130, 130, 130, 130], duration: 400 });
    });
  }

  // 데이터 그리기(파이프 라인 레이어) - 개별 레이어
  public draw(datas: any, layerId: string, isSurvey = false) {
    const features = this.createPipeLineFeatures(datas, layerId);

    const source = this.createSource(features);

    this.pipeLayers.map((layer: any) => {
      if (layer.get('id') === layerId) {
        layer.setSource(source);

        // 레이어에 스타일 부여
        layer.setStyle((feature: Feature) => {
          return this.onStyle(feature);
        });
      }
    });

    // 2.5d survey 공통사용
    if (!isSurvey) {
      // 해당 레이어로 이동
      this.moveLayer(layerId, { padding: [130, 130, 130, 130], duration: 400 });
    }
  }

  /* ------------------ EVENT ------------------ */

  // 지도 클릭 이벤트
  public addMapClickEvent() {
    this.core.mapInstance.on('click', (evt: any) => {
      let clickedPosition = evt.coordinate;
      // console.log(clickedPosition); // 클릭한 좌표

      //선택한 피쳐
      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        return feature;
      });

      if (feature) {
        let id = feature.get('id'); // point, pipeLine 일 때
        let properties = feature?.getProperties().properties; //피쳐 좌표 가져오기

        // 피쳐 종류에 따라 다른 이벤트 부여
        if (id === 'activePt') {
          return;
        }
        if (id === 'startendPt') {
          this.redrawActivePoint(this.getCoord(properties).coordinate);
        }
        if (id === 'pipeLine') {
          this.redrawActivePoint(this.getCoord(properties?.stCoordinate).coordinate, this.getCoord(properties?.endCoordinate).coordinate);
        }
      }
    });
  }

  // 파이프 레이어 클릭 이벤트
  public addClick(callback?: any) {
    this.core.mapInstance.removeInteraction(this.selectClick); // 초기화

    this.selectClick = new Select({
      // condition: click,
      condition: (e) => {
        // 선택된 포인트 객체가 있다면 click이 안되도록 처리
        let selectedPt = this.selectClickPt?.getFeatures()?.getArray() || [];
        return click(e) && selectedPt?.length === 0;
      },
      layers: this.pipeLayers,
      style: (feature) => this.pipeActiveStyle(feature),
      filter: (feature, layer) => {
        return true;
      },
    });

    this.selectClick.on('select', (e) => {
      const targetCollection = e.target.getFeatures().getArray();
      if (targetCollection.length) {
        // 선택한 파이프 데이터 사용
        const selectedProperties = targetCollection[0].getProperties().properties;
        // 콜백 함수
        callback(selectedProperties);
        // 시점 종점 포인트 표시
        this.drawPoint(selectedProperties);
        // 활성화 마커 표시 FIXME:
        this.drawActivePoint(selectedProperties);
      } else {
        //콜백 함수
        callback(null); // 상세창 닫기
        this.selectedClear(); // 파이프 선택 해제
        // 시점 종점 포인트 감추기
        //   this.pointLayer.setVisible(false);
        this.pointLayers.map((layer) => layer.setVisible(false));
      }
    });

    this.core.mapInstance.addInteraction(this.selectClick);
  }

  // 포인트 선택 해제
  public selectedClearPt() {
    this.selectClickPt.getFeatures().clear();
  }
  // 파이프 선택 해제
  public selectedClear() {
    this.selectClick.getFeatures().clear();

    // 시점 종점 포인트 감추기
    this.pointLayers.map((layer) => layer.setVisible(false));
    this.activePointLayer.setVisible(false);
  }

  // 레이어별 토글
  public onToggleLayer(layerId: string) {
    // 관로 레이어
    this.pipeLayers.map(async (layer: any) => {
      if (layer.get('id') === layerId) {
        layer.setVisible(!layer.getVisible());
        //레이어 꺼질떄
        if (!layer.getVisible()) {
          // console.log(this.selectClick.getFeatures());
        }
        //   setTimeout(() => {
        //     layer.setVisible(!layer.getVisible());
        //   }, 10);
      }
    });
    //TODO: 밸브 레이어 등등 추가 필요
  }

  // 배관 직경 표현(diameter) 토글
  public onToggleBuffer(bool: boolean) {
    this.pipeLayers.map((layer: any) => {
      layer.setStyle((feature) => {
        return bool ? this.onStyle(feature) : this.offStyle(feature);
      });
    });
  }

  // 파이프 레이어에서 피쳐 찾기
  public getFeaturePipe({ layerId, id }: { layerId?: string; id?: string }) {
    let feature;

    this.pipeLayers.map((layer: any) => {
      if (layer.get('id') === layerId) {
        let features: Feature[] = layer?.getSource()?.getFeatures() || [];
        features?.map((ele: Feature) => {
          if (ele?.getProperties()?.properties?.id === id) {
            feature = ele;
          }
        });
      }
    });

    return feature;
  }

  // Select 객체 - 선택할 피쳐 넣기(지도 직접클릭 x)
  public dynamicSelect(feature: Feature) {
    this.selectClick?.getFeatures()?.push(feature);
  }

  // 파이프 자동 선택 기능 (직접클릭x, data로 feature 찾기)
  // data(properties) -> line autoFocus & view Fit
  public async autoSelectLine(data) {
    const { facilityKind, id, stCoordinate, endCoordinate } = data || {};

    this.drawPoint(data); // 시점,종점 마커 표시 (this.selectClick 동일)
    this.drawActivePoint(data); //active 마커 표시 (this.selectClick 동일)

    // 해당 파이프 선택
    let feature = await this.getFeaturePipe({ layerId: facilityKind, id });
    if (feature) {
      this.dynamicSelect(feature);
    }

    // View fit
    let midCoord = await this.getMidPoint(stCoordinate, endCoordinate)?.coordinate;
    if (midCoord?.x && midCoord?.y) {
      const view = this.core.mapInstance?.getView();
      const coord = [midCoord?.y, midCoord?.x, midCoord?.y, midCoord?.x];
      view?.fit(coord, { duration: 0, maxZoom: 23 });
    }
  }

  /* ------------------ MULTI SELECT ------------------ */

  // 파이프 여러개 선택 기능(test)
  public multipleSelect_test(bool: boolean) {
    const myCallback = (e) => {
      this.core.mapInstance.forEachFeatureAtPixel(e.pixel, (feature: any, layer) => {
        const selIndex = this.selectedPipeArr?.indexOf(feature); // 유무 체크
        if (feature.get('id') === 'pipeLine') {
          if (selIndex < 0) {
            this.selectedPipeArr.push(feature); // 배열에 피쳐 추가
            feature.setStyle(this.multiSelectStyle(feature)); // 스타일 변경
          } else {
            this.selectedPipeArr?.splice(selIndex, 1);
            feature.setStyle(undefined);
          }
        }
      });
      console.log(this.selectedPipeArr);
      // if (this.selectedPipeArr.length > 0) {
      //   this.selectedPipeArr.map((feature) => {
      //     feature.setStyle(this.selectStyle2(feature));
      //   });
      // }
    };
    let eventKey;
    if (bool) {
      // 이벤트 추가
      eventKey = this.core.mapInstance.on('singleclick', myCallback);
    } else {
      // console.log('remove');
      new unByKey(eventKey);
      // 이벤트 삭제
      // this.core.mapInstance.removeEventListener('singleclick', myCallback);
      // 선택된 항목 있을 때
      if (this.selectedPipeArr.length > 0) {
        this.selectedPipeArr.map((feature) => {
          feature.setStyle(undefined); // 스타일 초기화
        });
      }
      this.selectedPipeArr = []; // 관리 배열 초기화
    }
  }

  // 파이프 여러개 선택 기능
  public multipleSelect(callback?: any) {
    this.multiClick = new Select({
      condition: click,
      // toggleCondition: shiftKeyOnly,
      toggleCondition: click,
      // removeCondition: altKeyOnly,
      layers: this.pipeLayers, // 상수, 하수..
      style: (feature) => this.multiSelectStyle(feature),
    });

    this.multiClick.on('select', (evt) => {
      let feature = evt?.selected[0];
      let collection = this.multiClick.getFeatures();
      let selectedFeatures = collection?.getArray();

      const isInclude = selectedFeatures?.includes(feature);

      console.log('selected Line:', selectedFeatures);

      callback && callback(selectedFeatures);
    });
  }

  // 파이프 여러개 선택 - 토글
  public onToggleMultiSelect(bool: boolean) {
    if (this.multiClick) {
      // multiSelect 이벤트 활성화 또는 막기
      if (bool) {
        this.core.mapInstance.removeInteraction(this.multiClick);
        console.log('add multiClick');
        this.multiClick.setActive(true);
        this.core.mapInstance.addInteraction(this.multiClick);
        //
      } else {
        console.log('remove multiClick');
        this.multiClick.setActive(false);
        this.core.mapInstance.removeInteraction(this.multiClick);
        // 선택된게 있다면 클리어
        var selectedFeaturesClick = this.multiClick.getFeatures();
        selectedFeaturesClick.clear();
      }
    }

    // 다른 select 이벤트 활성화 또는 막기
    //  this.toggleSelect(!bool);
  }

  // [line, point] | select 이벤트 활성화 또는 막기
  public toggleSelect(bool: boolean) {
    if (bool) {
      this.selectClick.setActive(true);
      this.core.mapInstance.addInteraction(this.selectClick);
      this.core.mapInstance.addInteraction(this.selectClickPt);
    } else {
      this.selectClick.setActive(false);
      this.selectClick.getFeatures().clear();
      this.selectClickPt.getFeatures().clear();
      this.core.mapInstance.removeInteraction(this.selectClick);
      this.core.mapInstance.removeInteraction(this.selectClickPt);
    }
  }
}

export default ObjectMapPipeLine;
