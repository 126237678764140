import React, { useEffect, useState } from 'react';
import { CompanyStyle, DataFilterStyle, SurveyStyle } from '../../components/templates/_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import query from '../../hooks/RQuery/utils';
import PagingTable from '../../components/atoms/PagingTable';
import WorkPlaceList from '../../components/templates/admin/WorkPlaceList';
import CompanyList from '../../components/templates/superAdmin/CompanyList';

const Company = () => {
  const navigate = useNavigate();
  //고객사 리스트에서 고객 선택
  const onSelectCompany = (id) => {
    navigate(`/auth/detail/${id}/info`);
  };

  return (
    <SurveyStyle.Contain>
      <SurveyStyle.Inner>
        <SurveyStyle.Wrap style={{ height: '100%' }}>
          {/* step1 */}
          <CompanyList onSelectCompany={onSelectCompany} />
        </SurveyStyle.Wrap>
      </SurveyStyle.Inner>
    </SurveyStyle.Contain>
  );
};

export default Company;
