import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import * as keys from '../../keys/queryKeys';
import api from '../../service';
import useMutateHook from '../../useMutateHook';
import queryKeys, { calendarKeys, pipeKeys } from '../../keys/queryKeys';

/* 전기 - elt */

// 2d 지도 - 옵션
export function getPipesOptions({ siteId }) {
  let options = {
    queryKey: [...keys.pipeKeys.elt.all(siteId)],
    queryFn: () => api.pipes.elt.getPipes({ siteId }),
    enabled: !!siteId,
  };

  return options;
}

// 2d 지도 - 쿼리
export function usePipes({ siteId }) {
  return useQuery(getPipesOptions({ siteId }));
}

//측량성과 테이블
export function useSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) {
  return useQuery({
    queryKey: [...keys.pipeKeys.elt.survey.all({ siteId, pageSize, pageNum, sort })],
    queryFn: () => api.pipes.elt.getSurveyPipes({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }),
    enabled: !!siteId,
  });
}

//측량성과 선택 삭제
export function useSurveyDeletePipes({ siteId, pipeIds }) {
  return useMutateHook({
    fetchApi: () => api.pipes.elt.deleteSurveyPipes({ siteId, pipeIds }),
    invalidQuery: [[...calendarKeys.surveyPipes({ siteId })], [...pipeKeys.elt.common, 'list', { siteId }], [queryKeys.MainKey.SURVEY]],
  });
}

//측량성과 날짜 삭제
export function useSurveyDeletePipesByDate({ siteId, date }) {
  return useMutateHook({
    fetchApi: () => api.pipes.elt.deleteSurveyPipesByDate({ siteId, date }),
    invalidQuery: [[...calendarKeys.surveyPoints({ siteId })], [...calendarKeys.surveyPipes({ siteId })], [...pipeKeys.elt.common, 'list', { siteId }], [queryKeys.MainKey.SURVEY]],
  });
}

//측량성과 날짜별 파이프 데이터 조회
export function useSurveyPipesByDate({ siteId, date, workDate }) {
  return useQuery({
    queryKey: [...keys.pipeKeys.elt.survey.date({ siteId, date, workDate })],
    queryFn: () => api.pipes.elt.getSurveyPipesByDate({ siteId, date }),
    enabled: !!date,
  });
}
