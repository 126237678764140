import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableStyle } from '../../atoms/_style';
import Table from '../../atoms/Table';
import { SurveyStyle } from '../_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import PagingTable from '../../atoms/PagingTable';
import Select from '../../atoms/Select';
import Input from '../../atoms/Input';
import IcoInputSearch from '../../../assets/images/ico-input-search.svg';
import { FilterProps } from '../_interface';
import SurveyPipeTable from './SurveyPipeTable';
import DateFilter from '../DateFilter';
import ListFilter from './ListFilter';

import { OptionProps } from '../../atoms/_interface';
import { DateObject } from 'react-multi-date-picker';
import query from 'hooks/RQuery/utils';
import SurveyTable from './SurveyTable';
import queryKeys from '../../../hooks/RQuery/keys/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface SurveyDataListProps {
  onPopupToggle: (key: string) => void;
  siteInfo: { id: string; name: string; facilityKindList: string };
  categoryTabList: { name: string; key: string; active: boolean }[];
  setCategoryTabList: Dispatch<SetStateAction<{ name: string; key: string; active: boolean }[]>>;
  tabList: any;
  setTabList: Dispatch<SetStateAction<any>>;
  firstCall: boolean;
  setFirstCall: Dispatch<SetStateAction<boolean>>;
}

//측량성과등록 - 카테고리 + 테이블 영역
const SurveyDataList = ({ onPopupToggle, siteInfo, categoryTabList, setCategoryTabList, tabList, setTabList, firstCall, setFirstCall }: SurveyDataListProps) => {
  const [themeMode] = useRecoilState(themeState);
  const queryClient = useQueryClient();
  // const workDay = query.calendars.useCalendars({ siteId: sessionStorage.getItem('siteId') });
  const { t } = useTranslation();
  //관종별 포인트 캘린더
  const workDay = query.calendars.useSurveyPoints({ siteId: siteInfo.id, facilityKind: categoryTabList.filter(({ active }) => active)[0]?.key, isPipe: false });

  const [workDate, setWorkDate] = useState([]);

  const [date, setDate] = useState<{ from?: DateObject; to?: DateObject }>({
    from: undefined,
    to: undefined,
  });
  const [option, setOption] = useState<OptionProps[]>([
    { text: '전체', value: 1, active: true },
    { text: '최근1개월', value: 2, active: false },
    { text: '최근3개월', value: 3, active: false },
    { text: '직접입력', value: 4, active: false },
  ]);
  const [word, setWord] = useState('');
  const [ptNum, setPtNum] = useState('');
  const { data, isSuccessAll } = query.codes.useCodes(); // 코드 조회
  const [categoryIndex, setCategoryIndex] = useState(0);
  //카테고리별 탭

  const [searchTrigger, setSearchTrigger] = useState(1);

  //type = 'single' 일때 컬럼 보이기/숨기기,  type 없을 땐 탭 기능(전체측점/연결부/맨홀/밸브/...)
  const onFilterClick = (state: any, setState: Dispatch<SetStateAction<any>>, key: string, facilityKind: string) => {
    const newObj: any = { ...state };
    console.log(key);
    newObj[facilityKind].map((i) => {
      i.active = i.key === key;
    });

    setState(newObj);
  };

  //facilityKind 탭 선택(상수/오수/우수/...)
  const onCategoryClick = (state: any, setState: Dispatch<SetStateAction<any>>, key: string) => {
    const newArr = [...state];
    console.log(newArr);
    newArr.map((i) => {
      i.active = i.key === key;
    });
    setState(newArr);
  };

  const onColumnToggle = (state: any, setState: Dispatch<SetStateAction<any>>, key: string) => {
    const newArr = [...state];
    newArr.filter((i) => i.key === key)[0].active = !newArr.filter((i) => i.key === key)[0].active;
    setState(newArr);
  };

  useEffect(() => {
    if (workDay.data) {
      const data = workDay.data;
      setWorkDate(data);
    }
  }, [workDay.data]);

  const onSearch = () => {
    // queryClient.invalidateQueries({ queryKey: [queryKeys.MainKey.POINT] });
    setSearchTrigger(searchTrigger + 1);
  };

  const refetchSurveyList = () => {};

  //현장에서 등록 가능한 관종류 탭으로 셋
  useEffect(() => {
    if (isSuccessAll) {
      const newArr: FilterProps[] = [];
      const facilityKindList = siteInfo.facilityKindList.split(',');
      facilityKindList.map((i, index) => {
        newArr.push({ name: data.FACILITY.find(({ code }) => code === i).value, key: i, active: index === 0 });
      });
      setCategoryTabList(newArr);
    }
  }, [isSuccessAll]);

  return (
    <SurveyStyle.DescArea>
      <SurveyStyle.CategoryArea>
        <SurveyStyle.CategoryWrap>
          {/* 카테고리 리스트*/}
          {categoryTabList.length
            ? categoryTabList?.map((i, index) => {
                if (index >= categoryIndex && index <= categoryIndex + 3) {
                  return (
                    <SurveyStyle.CategoryItem key={i.key} className={i.active ? 'active' : undefined} onClick={() => onCategoryClick(categoryTabList, setCategoryTabList, i.key)}>
                      <span></span>
                      {t(i.name)}
                    </SurveyStyle.CategoryItem>
                  );
                }
              })
            : null}
        </SurveyStyle.CategoryWrap>
        <SurveyStyle.CategoryBtnGroup>
          {categoryIndex > 0 ? (
            <SurveyStyle.CategoryBtn
              type={'button'}
              className={'prev'}
              onClick={() => {
                setCategoryIndex(categoryIndex - 1);
              }}
            >
              <span className={'hidden'}>이전</span>{' '}
            </SurveyStyle.CategoryBtn>
          ) : null}
          {categoryIndex < categoryTabList.length - 4 ? (
            <SurveyStyle.CategoryBtn
              type={'button'}
              className={'next'}
              onClick={() => {
                setCategoryIndex(categoryIndex + 1);
              }}
            >
              <span className={'hidden'}>다음</span>{' '}
            </SurveyStyle.CategoryBtn>
          ) : null}
        </SurveyStyle.CategoryBtnGroup>
        <ListFilter workDate={workDate} onSearch={() => onSearch()} date={date} setDate={setDate} option={option} setOption={setOption} />
      </SurveyStyle.CategoryArea>
      <SurveyStyle.DescWrap>
        <SurveyStyle.TableArea>
          <>
            <SurveyStyle.TabArea>
              <SurveyStyle.BtnMap type={'button'} onClick={() => onPopupToggle('map')}></SurveyStyle.BtnMap>
              <SurveyStyle.TabWrap>
                {/* 탭 리스트*/}
                {categoryTabList.length
                  ? tabList[categoryTabList.filter(({ active }) => active)[0]?.key]?.map((i) => {
                      return (
                        <SurveyStyle.TabItem key={i.key} className={i.active ? 'active' : undefined} onClick={() => onFilterClick(tabList, setTabList, i.key, categoryTabList.filter(({ active }) => active)[0].key)}>
                          {t(i.name)}
                        </SurveyStyle.TabItem>
                      );
                    })
                  : null}
              </SurveyStyle.TabWrap>
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                <Input width={260} placeholder={t('측점/관로명을입력하세요')} bg={IcoInputSearch} onChange={(e) => setWord(e.target.value)} />
                <SurveyStyle.SearchBtn
                  type={'button'}
                  onClick={() => {
                    setPtNum(word);
                    onSearch();
                  }}
                >
                  <span className={'hidden'}>검색</span>
                </SurveyStyle.SearchBtn>
              </div>
            </SurveyStyle.TabArea>

            {categoryTabList.length ? (
              <SurveyTable
                onColumnToggle={onColumnToggle}
                siteInfo={siteInfo}
                activeCategory={categoryTabList.filter(({ active }) => active)[0].key}
                activeTab={tabList[categoryTabList.filter(({ active }) => active)[0].key]?.filter(({ active }) => active)[0].key}
                date={date}
                searchTrigger={searchTrigger}
                ptNum={ptNum}
                onPopupToggle={onPopupToggle}
                firstCall={firstCall}
                setFirstCall={setFirstCall}
                tabList={tabList[categoryTabList.filter(({ active }) => active)[0].key]}
              />
            ) : null}
          </>
        </SurveyStyle.TableArea>
      </SurveyStyle.DescWrap>
    </SurveyStyle.DescArea>
  );
};

export default SurveyDataList;
