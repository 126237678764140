import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import query from '../../hooks/RQuery/utils';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import LabelInput from '../../components/atoms/LabelInput';
import ManageContent, { ManageContentMenuProps } from '../../components/templates/ManageContent';
import styled from 'styled-components';
import IcoEdit from '../../assets/images/superAdmin/ico-edit.svg';

const MemberListStyle = {
  Wrap: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  Card: styled.div`
    margin: 6px;
    border-radius: 12px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.company.card.border};
    background: ${(p) => p.theme.company.card.bg};
    width: 262px;
    height: 289px;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Image: styled.div`
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-sizing: border-box;
    background: ${(p) => p.theme.company.card.img} url(${(p) => p.theme.company.card.dummy}) no-repeat center;
    margin-bottom: 32px;
  `,
  Edit: styled.button`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.company.card.edit.border};
    border-radius: 50%;
    background: url(${(p) => p.theme.company.card.edit.icon}) no-repeat center;
    background-color: ${(p) => p.theme.company.card.edit.bg};
    &:hover {
      background: #4880ff url(${IcoEdit}) no-repeat center;
      border: 1px solid transparent;
    }
  `,
  Name: styled.strong`
    color: ${(p) => p.theme.company.card.name};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 13px;
  `,
  Role: styled.p`
    color: ${(p) => p.theme.company.card.text};
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 9px;
    span {
      margin-left: 6px;
      &.admin {
        color: #f93c65;
      }
      &.manager {
        color: #4880ff;
      }
      &.user {
        color: #00b69b;
      }
    }
  `,
  Email: styled.p`
    font-weight: 500;
    font-size: 14px;
    color: ${(p) => p.theme.company.card.text};
  `,
};

const CompanyDetail = () => {
  const [themeMode] = useRecoilState(themeState);
  const { t } = useTranslation();
  const { id } = useParams();
  const { data } = query.auths.useCompanyDetail(id);
  const location = useLocation();
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    companyName: '',
    address: '',
    createdDate: '',
    memberList: [],
  });
  const menu: ManageContentMenuProps[] = [
    { key: 'info', pathName: `/auth/detail/${id}/info`, text: '기본 정보' },
    { key: 'members', pathName: `/auth/detail/${id}/members`, text: '계정 목록' },
  ];

  useEffect(() => {
    if (data) {
      setInfo({
        companyName: data.companyName,
        address: data.address,
        createdDate: data.createdDate,
        memberList: data.memberList,
      });
    }
  }, [data]);

  return (
    <ManageContent
      title={'고객사 상세정보'}
      menu={menu}
      listPath={'/auth/list'}
      content={
        location.pathname.includes('members') ? (
          <MemberListStyle.Wrap>
            {info.memberList.map((i: any, index: number) => {
              return (
                <MemberListStyle.Card key={index}>
                  <MemberListStyle.Image>
                    <MemberListStyle.Edit type={'button'}>
                      <span className={'hidden'}>편집</span>
                    </MemberListStyle.Edit>
                  </MemberListStyle.Image>
                  <MemberListStyle.Name>{i.name}</MemberListStyle.Name>
                  <MemberListStyle.Role>
                    권한<span className={i.auth === 'ROLE_ADMIN' ? 'admin' : i.auth === 'ROLE_MANAGER' ? 'manager' : i.auth === 'ROLE_USER' ? 'user' : undefined}>{i.auth.replace('ROLE_', '')}</span>
                  </MemberListStyle.Role>
                  <MemberListStyle.Email>{i.loginId}</MemberListStyle.Email>
                </MemberListStyle.Card>
              );
            })}
          </MemberListStyle.Wrap>
        ) : (
          <>
            <LabelInput label={'회사명'} id={'companyName'} value={info.companyName} width={400} style={{ marginBottom: 12 }} readOnly={true} />
            <LabelInput label={'주소'} id={'address'} value={info.address} width={400} style={{ marginBottom: 12 }} readOnly={true} />
            <LabelInput label={'생성일'} id={'createdDate'} value={info.createdDate.replace('T', ' ').split('.')[0]} width={400} readOnly={true} />
          </>
        )
      }
    />
  );
};

export default CompanyDetail;
