import React from 'react';
import styled from 'styled-components';
import IcoNoData from 'assets/images/ico-no-data.svg';
import { useTranslation } from 'react-i18next';
const NoDataStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 158px;
    img {
      width: 40px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      color: #767676;
      margin-top: 20px;
    }
  `,
};
const NoData = ({ style, text }: { style?: React.CSSProperties; text?: string }) => {
  const { t } = useTranslation();
  return (
    <NoDataStyle.Wrap style={style}>
      <img src={IcoNoData} alt={text ? text : t('일치하는검색결과가없습니다')} />
      <p>{text ? text : t('일치하는검색결과가없습니다')}</p>
    </NoDataStyle.Wrap>
  );
};

export default NoData;
