import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReportPDFSt } from './_style';
import LogoPdf from 'assets/images/logo_pdf.png';
import Img1 from 'assets/images/img_1.jpg';
import Img2 from 'assets/images/img_2.jpg';
/*
   [pdf 파일 다운로드 테스트]
   html2canvas : html 요소를 이미지로 캡쳐
   jspdf : 이미지를 pdf 형태로 다운로드 가능
*/
function ExportPdf(props) {
  // 결과 리포트 pdf 파일로 저장
  const printToPDF = async () => {
    const pdf = new jsPDF('p', 'mm', 'a4');

    const addPageContent = async (element: any) => {
      const canvas = await html2canvas(element, { scale: 4 }); // 선명도 up
      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 210; //190?
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pageHeight = imgWidth * 1.414; //210 * 1.414 =  296.94
      const margin = 0; //왼쪽 여백
      let position = 0;
      let heightLeft = imgHeight;

      // 1.
      // pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight);
      // pdf.addPage();

      // 2.
      pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
      pdf.addPage(); //빈페이지 추가

      // 예시 코드
      // // 첫 페이지 출력
      // pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      // heightLeft -= pageHeight;

      // // 한 페이지 이상일 경우 루프 돌면서 출력
      // while (heightLeft >= 20) {
      //   position = heightLeft - imgHeight;
      //   pdf.addPage();
      //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      //   heightLeft -= pageHeight;
      // }
    };

    const reportContents = [document.getElementById('report-contents-0'), document.getElementById('report-contents-1'), document.getElementById('report-contents-2')];

    for (const reportContent of reportContents) {
      if (reportContent) {
        await addPageContent(reportContent);
      }
    }
    pdf.save('test_report.pdf'); //파일추출 (파일명 입력)
  };

  return (
    <>
      <button onClick={printToPDF}>pdf 추출하기</button>
      <ReportPDFSt.Wrap id="pdf-contents-wrap">
        <ReportPDFSt.Page id="report-contents-0">
          <div className="outLine">
            <div className="content_site">
              <ul>
                <li>
                  <span className="subTit">작업기간 : </span>
                  <span className="subCont">{'2023-01-01 ~ 2023-12-12'}</span>
                </li>
              </ul>
            </div>
            <div className="title">시설물 통계</div>
            <ReportPDFSt.TableWrap>
              <ReportPDFSt.Table>
                <div className="item">
                  <div className="tHead">
                    <div className=" tr">
                      <div className="th">관로 수량(EA)</div>
                    </div>
                  </div>
                  <div className="tHead">
                    <div className=" tr">
                      <div className="th">직관</div>
                      <div className="th">단관</div>
                      <div className="th">이형관</div>
                    </div>
                  </div>
                  <div className="tBody">
                    <div className="tr">
                      <div className="td">13</div>
                      <div className="td">2</div>
                      <div className="td">0</div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tHead">
                    <div className=" tr">
                      <div className="th">관로 연장(m)</div>
                    </div>
                  </div>
                  <div className="tHead">
                    <div className=" tr">
                      <div className="th">직관</div>
                      <div className="th">단관</div>
                      <div className="th">이형관</div>
                    </div>
                  </div>
                  <div className="tBody">
                    <div className="tr">
                      <div className="td">32.511</div>
                      <div className="td">2.513</div>
                      <div className="td">0</div>
                    </div>
                  </div>
                </div>
              </ReportPDFSt.Table>

              <ReportPDFSt.Table>
                <div className="item">
                  <div className="tHead">
                    <div className=" tr">
                      <div className="th">밸브 수량(EA)</div>
                    </div>
                  </div>
                  <div className="tBody">
                    <div className=" tr">
                      <div className="td">제수 밸브</div>
                      <div className="td">12</div>
                    </div>
                    <div className="tr">
                      <div className="td">이토 밸브</div>
                      <div className="td">12</div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tHead">
                    <div className="tr">
                      <div className="th">사각 맨홀 수량(EA)</div>
                    </div>
                  </div>
                  <div className="tBody">
                    <div className="tr">
                      <div className="td">상수</div>
                      <div className="td">12</div>
                    </div>
                    <div className="tr">
                      <div className="td">오수</div>
                      <div className="td">12</div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="tHead">
                    <div className="tr">
                      <div className="th">원형 맨홀 수량(EA)</div>
                    </div>
                  </div>
                  <div className="tBody">
                    <div className="tr">
                      <div className="td">상수</div>
                      <div className="td">12</div>
                    </div>
                    <div className="tr">
                      <div className="td">오수</div>
                      <div className="td">12</div>
                    </div>
                  </div>
                </div>
              </ReportPDFSt.Table>
            </ReportPDFSt.TableWrap>
            <div className="logoWrap">
              <img className="logo" src={LogoPdf} alt="logo" />
            </div>
          </div>
        </ReportPDFSt.Page>
        <ReportPDFSt.Page id="report-contents-1">
          <div className="outLine">
            <div className="title">작업일보</div>
            <div className="content_site">
              <ul>
                <li>
                  <span className="subTit">데이터 No : </span>
                  <span className="subCont">{1}</span>
                </li>
                <li>
                  <span className="subTit">공사명 : </span>
                  <span className="subCont">부산에코델타시티 2-3공구 지하시설물 GIS DB 구축용역</span>
                </li>
                <li>
                  <span className="subTit">공사기간 : </span>
                  <span className="subCont">2020-01-06 ~ 2020-12-31</span>
                </li>
                <li>
                  <span className="subTit">작업일자 : </span>
                  <span className="subCont">2021-06-23</span>
                </li>
                <li>
                  <span className="subTit">현장주소 : </span>
                  <span className="subCont">부산 강서구 명지동 3170-18</span>
                </li>
              </ul>
              <ul>
                <li>
                  <span className="subTit"></span>
                  <span className="subCont"></span>
                </li>
                <li>
                  <span className="subTit">발주처 : </span>
                  <span className="subCont">수자원공사</span>
                </li>
                <li>
                  <span className="subTit">시공사 : </span>
                  <span className="subCont">현대건설</span>
                </li>
                <li>
                  <span className="subTit">용역사 : </span>
                  <span className="subCont">주식회사 무브먼츠</span>
                </li>
                <li>
                  <span className="subTit">관측자 : </span>
                  <span className="subCont">함동관</span>
                </li>
              </ul>
            </div>
            <div className="content_item">
              <div className="titleWrap">
                <span className="subTit">작업내용 : </span>
                <span className="subCont">관로 시공</span>
              </div>
              <div className="contWrap">
                <ul>
                  <li>
                    <span>측점 번호</span>
                    <span>{'231016-001'}</span>
                  </li>
                  <li>
                    <span>시설물 구분</span>
                    <span>{'우수'}</span>
                  </li>
                  <li>
                    <span>시설물 재질</span>
                    <span>PE</span>
                  </li>
                  <li>
                    <span>관경</span>
                    <span>{300}mm</span>
                  </li>
                  <li>
                    <span>관로 연장</span>
                    <span>{7.73}m</span>
                  </li>
                  <li>
                    <span>관 유형</span>
                    <span>{'단관'}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span>시점 좌표(X)</span>
                    <span>{281772.41}</span>
                  </li>
                  <li>
                    <span>시점 좌표(Y)</span>
                    <span>{191710.977}</span>
                  </li>
                  <li>
                    <span>시점 관상고(Z)</span>
                    <span>{3.015}</span>
                  </li>
                  <li>
                    <span>종점 좌표(X)</span>
                    <span>{281772.41}</span>
                  </li>
                  <li>
                    <span>종점 좌표(Y)</span>
                    <span>{191710.977}</span>
                  </li>
                  <li>
                    <span>종점 관상고(Z)</span>
                    <span>{3.015}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="content_img">
              <div className="titleWrap">
                <span className="subTit">작업사진</span>
                <span className="subCont"></span>
              </div>
              <div className="contWrap">
                <div className="imgItem">
                  <div className="imgWrap">
                    <img src={Img1} alt="site_thum_1" />
                  </div>
                  <p className="imgTit">근경</p>
                </div>
                <div className="imgItem">
                  <div className="imgWrap">
                    <img src={Img2} alt="site_thum_1" />
                  </div>
                  <p className="imgTit">원경</p>
                </div>
              </div>
            </div>
            <div className="logoWrap">
              <img className="logo" src={LogoPdf} alt="logo" />
            </div>
          </div>
        </ReportPDFSt.Page>
        <ReportPDFSt.Page id="report-contents-2">content_2</ReportPDFSt.Page>
      </ReportPDFSt.Wrap>
    </>
  );
}

export default ExportPdf;
