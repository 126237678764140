import React from 'react';
import { SurveyStyle } from '../../components/templates/_style';
import SurveySiteList from '../../components/templates/survey/SurveySiteList';
import { useNavigate } from 'react-router-dom';
import WorkPlaceList from '../../components/templates/admin/WorkPlaceList';
import UserList from '../../components/templates/admin/UserList';

const User = ({ userType }) => {
  const navigate = useNavigate();
  //현장 리스트에서 현장 선택
  const onSelectSite = (id: string, name: string, facilityKindList: string[]) => {
    // let str = '';
    // facilityKindList.forEach((i, index) => {
    //   return (str = index !== facilityKindList.length - 1 ? str + i + ',' : str + i);
    // });
    // sessionStorage.setItem('survey_siteId', id);
    // sessionStorage.setItem('survey_siteName', name);
    // sessionStorage.setItem('survey_facilityKindList', str);
    // navigate('/survey/detail/' + id + '/' + name + '/' + str);
  };

  return (
    <SurveyStyle.Contain>
      <SurveyStyle.Inner>
        <SurveyStyle.Wrap style={{ height: '100%' }}>
          {/* step1 */}
          <UserList userType={userType} onSelectSite={onSelectSite} />
        </SurveyStyle.Wrap>
      </SurveyStyle.Inner>
    </SurveyStyle.Contain>
  );
};

export default User;
