import React, { forwardRef } from 'react';
import { InputStyle } from './_style';
import { InputProps } from './_interface';

const Input = forwardRef(({ width, height, type, id, name, component, onChange, placeholder, bg, style, error, errMsg, onKeyPress, value, disabled, min, max, unit, readOnly, isLogin }: InputProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <InputStyle.Wrap width={width} height={height} unit={unit}>
      <InputStyle.Input
        id={id}
        name={name}
        type={type ? type : 'text'}
        component={component}
        onChange={onChange}
        placeholder={placeholder}
        bg={bg}
        error={error}
        style={style}
        ref={ref}
        onKeyDown={onKeyPress}
        defaultValue={value}
        disabled={disabled}
        min={min}
        max={max}
        readOnly={readOnly}
        isLogin={isLogin}
      />
      {component ? component : null}
      {error && <InputStyle.ErrMsg>{errMsg}</InputStyle.ErrMsg>}
      {unit && <p className="unit">{unit}</p>}
    </InputStyle.Wrap>
  );
});

export default Input;
