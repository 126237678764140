import React, { useState } from 'react';
import styled from 'styled-components';
import IcoBuilding from 'assets/images/ico-building.svg';
import IcoFileAdd from 'assets/images/ico-file-add.svg';
import IcoFileAddDark from 'assets/images/ico-file-add-dark.svg';
import IcoFileDownDark from 'assets/images/ico-file-down.svg';
import IcoFileDown from 'assets/images/ico-file-down-hover.svg';
import IcoBurger from 'assets/images/ico-burger.svg';
import IcoBurgerDark from 'assets/images/ico-burger-dark.svg';
import { popupUploadSelect } from 'store/popupAtoms';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import { SurveyHeaderSt } from '../popup/_style';
import { useTranslation } from 'react-i18next';

function DesignHeader({ onBack, siteInfo, onChangeFile }) {
  const { t } = useTranslation();
  const [popSelectState, setPopSelectState] = useRecoilState(popupUploadSelect);
  const [popupStatus, setPopupStatus] = useState({
    download: false,
  });
  const [themeMode] = useRecoilState(themeState);

  const onPopupToggle = (key) => {
    setPopupStatus({ ...popupStatus, [key]: !popupStatus[key] });
  };

  return (
    <>
      {/* 헤더 영역 */}
      <SurveyHeaderSt.Wrap>
        <SurveyHeaderSt.TitleWrap>
          <div className="ico"></div>
          <p className="title">{siteInfo.name}</p>
        </SurveyHeaderSt.TitleWrap>
        <SurveyHeaderSt.BtnWrap>
          <SurveyHeaderSt.FileLabel htmlFor={'changeDxf'} Ico={themeMode === 'light' ? IcoFileAdd : IcoFileAddDark}>
            <div className="ico"></div>
            {t('설계도면파일등록')}
          </SurveyHeaderSt.FileLabel>
          <input type={'file'} id={'changeDxf'} onChange={onChangeFile} style={{ display: 'none' }} accept={'.dxf'} />
          <SurveyHeaderSt.Button Ico={themeMode === 'light' ? IcoBurger : IcoBurgerDark} onClick={onBack}>
            <div className="ico"></div>
            {t('목록가기')}
          </SurveyHeaderSt.Button>
        </SurveyHeaderSt.BtnWrap>
      </SurveyHeaderSt.Wrap>
    </>
  );
}

export default React.memo(DesignHeader);
