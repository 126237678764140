import React, { useState, useEffect, useMemo } from 'react';
import { PipeInfoModalSt } from './_style';
import NoImg from 'assets/images/no-img.svg';
import LargeImagePopup from '../popup/LargeImagePopup';
import ImgDeletePopup from './ImgDeletePopup';
import ImgAddPopup from './ImgAddPopup';
import { manholeImgList, pointImgList, vmfImgList } from '../../../util/global';
import { useTranslation } from 'react-i18next';
import LargePinImagePopup from '../popup/LargePinImagePopup';
import PinImgDeletePopup from './PinImgDeletePopup';
import PinImgAddPopup from './PinImgAddPopup';
import query from '../../../hooks/RQuery/utils';

// 지도 - 포인트 | 파이프 상세창에서 이미지 썸네일 리스트
function PinImageInfo({ info, isTable, pinData }) {
  const { t } = useTranslation();
  const [openLarge, setOpenLarge] = useState(false); // 사진 크게보기
  const [openDelete, setOpenDelete] = useState(false); // 사진 삭제 팝업
  const [openAddPop, setOpenAdd] = useState(false); // 사진 등록 팝업
  const [imgName, setImgName] = useState('');
  // 이미지 크게보기 이벤트
  const onClickImg = () => {
    setOpenLarge(true); // 크게보기 열기
  };
  // 이미지 삭제 이벤트
  const onClickDelete = () => {
    setOpenDelete(true);
    //
    // const fullString = `${pointImgList[num - 1]?.fullValue} 사진`;
    // const fileName = getImageName(num);
    // setImgName(`${fullString}(${fileName})`);
  };

  //이미지 추가 이벤트
  const onClickAdd = () => {
    // setImgNum(num);
    setOpenAdd(true);
  };
  return (
    <>
      {/* 이미지 크게보기 */}
      {openLarge && <LargePinImagePopup info={info} close={() => setOpenLarge(false)} />}

      {/*/!* 삭제하기 팝업 *!/*/}
      {openDelete && <PinImgDeletePopup info={info} close={() => setOpenDelete(false)} refetchApi={pinData} />}

      {/*/!* 사진 등록 팝업 *!/*/}
      {openAddPop && <PinImgAddPopup refetchApi={pinData} info={info} siteInfo={{ id: String(sessionStorage.getItem('siteId')) }} close={() => setOpenAdd(false)} />}

      {/* 기본 상세창에서 썸네일 목록 */}
      <PipeInfoModalSt.ImgWrap style={isTable ? { margin: '0 auto', justifyContent: 'center' } : undefined}>
        <PipeInfoModalSt.ImgItem>
          {/* 기존 이미지가 있을 때 */}
          {info?.image?.id ? (
            <>
              {/* hover시 아이템 */}
              <div className="hoverText">
                <div className="textWrap">
                  <p className="text">{info.serial}</p>
                  {/*<span className="tooltip-content">{t(ele?.value)}</span>*/}
                </div>
                {/* 크게보기, 삭제 버튼 */}
                <div className="btnWrap">
                  <button className="icoView" onClick={() => onClickImg()}></button>
                  <button className="icoDelete" onClick={() => onClickDelete()}></button>
                </div>
              </div>
              {/* 기존 이미지 */}
              <img
                src={info?.image?.thumbPath || ''}
                alt={info?.serial}
                onError={(e) => {
                  e.currentTarget.src = NoImg;
                }}
              />
            </>
          ) : (
            <>
              {/* 기존 이미지 없을떄 */}
              {/* hover시 아이템 */}
              <div className="hoverText">
                <div className="textWrap">
                  <p className="text">{info.serial}</p>
                  {/*<span className="tooltip-content">{t(ele?.value)}</span>*/}
                </div>
                {/* 이미지 추가 */}
                <div className="btnWrap">
                  <button className="icoAdd" onClick={() => onClickAdd()}></button>
                </div>
              </div>
              <div className="addImg">
                <span className="ico"></span>
              </div>
            </>
          )}
        </PipeInfoModalSt.ImgItem>
      </PipeInfoModalSt.ImgWrap>
    </>
  );
}

export default PinImageInfo;
