import { View, Map, Overlay, Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { XYZ, OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { FitOptions } from 'ol/View';
import { Control, ScaleLine } from 'ol/control';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import { defaults as defaultInteractions, DragPan } from 'ol/interaction';
import { Image as ImageLayer } from 'ol/layer';
import Static from 'ol/source/ImageStatic.js';
import query from 'hooks/RQuery/utils';
import { Style, Circle, Stroke, Fill } from 'ol/style';

//코어를 담당하는 mapInstance Viewer객체
//Viewr객체의 기능
class OlCore {
  public mapInstance: Map;
  public viewer: View;

  private url = 'https://github.com/sonak22';
  private key = '9D1217A3-966D-373B-8A56-3A3E159690D7';
  private layer = 'Hybrid'; // Base, midnight, Hybrid(위성지도 + 하이브리드), Satellite(jpeg)
  private tileMatrix = '';
  private tileType = 'png'; //png

  // vworld 기본지도
  private vworldBaseLayer = new TileLayer({
    source: new XYZ({ url: `https://api.vworld.kr/req/wmts/1.0.0/${this.key}/${this.layer}/{z}/{y}/{x}.png` }),
    properties: { name: 'base-vworld-base' },
    //  minZoom: 5,
    //  maxZoom: 19,
    zIndex: 2,
    preload: Infinity,
  });

  // vworld 위성지도
  private vworldSatelliteLayer = new TileLayer({
    source: new XYZ({ url: `https://api.vworld.kr/req/wmts/1.0.0/${this.key}/Satellite/{z}/{y}/{x}.jpeg` }),
    properties: { name: 'base-vworld-satellite' },
    zIndex: 2,
    preload: Infinity,
  });

  private SATELLITE_URL = 'http://mt0.google.com/vt/lyrs=s&hl=ko&x={x}&y={y}&z={z}';

  public googleMapSource = new XYZ({ url: this.SATELLITE_URL });
  public osmMapSource = new OSM();
  public map4dMapSource = new XYZ({ url: `https://rtile.map4d.vn/all/2d/{z}/{x}/{y}.png`, maxZoom: 19 });
  // 구글 위성 지도
  public googleMapLayer = new TileLayer({
    source: this.googleMapSource,
    properties: { name: 'google-map-satellite' },
    zIndex: 1,
    preload: Infinity,
  });

  // 기본 2D 지도
  public osmMapLayer = new TileLayer({
    source: this.osmMapSource,
    properties: { name: 'osm-map' },
    zIndex: 1,
    preload: Infinity,
  });

  public map4dMapLayer = new TileLayer({
    source: this.map4dMapSource,
    properties: { name: 'base-map4d' },
    zIndex: 2,
    preload: Infinity,
  });
  // private selectMapSource: XYZ | OSM = this.googleMapSource
  //지도 초기화
  constructor() {
    let projection = sessionStorage?.getItem('projection') || '';
    // 해당 현장정보 -  지도 좌표계
    //  let projection = sessionStorage?.getItem('projection') || '';
    let siteProj = projection ? projection : 'EPSG:4326';
    console.log('siteProjection:', siteProj);

    //  Vector layer
    var vector = new VectorLayer({
      source: new VectorSource(),
      style: function (f) {
        return new Style({
          image: new Circle({
            radius: 5,
            stroke: new Stroke({ width: 1.5, color: f.get('color') || [255, 128, 0] }),
            fill: new Fill({ color: (f.get('color') || [255, 128, 0]).concat([0.3]) }),
          }),
          stroke: new Stroke({ width: 2.5, color: f.get('color') || [255, 128, 0] }),
          fill: new Fill({ color: (f.get('color') || [255, 128, 0]).concat([0.3]) }),
        });
      },
    });

    this.mapInstance = new Map({
      controls: defaultControls().extend([new FullScreen()]),
      target: 'mapContainer',
      layers: [
        //   this.osmMapLayer,
        this.googleMapLayer,
        vector,
      ],
      view: new View({
        // maxZoom: 20, //줌 확대 제한
        // minZoom:11,
        // zoom: 11.5, //초기 줌레벨 지정
        //   projection: 'EPSG:4326',
        projection: projection,
      }),
      // interactions: defaultInteractions({ keyboard: false }),
    });
    this.viewer = this.mapInstance.getView();

    this.mapInstance.addControl(
      new ScaleLine({
        target: document.getElementById('scaleBar') as any,
      }),
    );
  }

  public moveToInitPosition() {
    const extent = [-6845.4311040807515, 79859.89282919432, 503435.0599430465, 662059.1802722721];
    const wgs84Coord = [128, 36];
    // 대한민국 전체 extent
    const extent_5187 = [109834.88454304817, 378507.1710007871, 109834.88454304817, 378507.1710007871];

    //  let center = [410807.187, 288216.97];
    // this.viewer.setCenter(center);
    // this.viewer.setZoom(7);
    this.viewer.fit(extent_5187, { duration: 0, maxZoom: 8 });
  }

  //해당 레이어로 화면이동
  public moveToLayer(layer?: VectorLayer<VectorSource>, option?: FitOptions) {
    if (layer?.getSource()?.getExtent()[0] !== Infinity) {
      layer && this.viewer.fit(layer.getSource()?.getExtent() || [], option);
    }
  }

  public getLayerFromName(layerId: string) {
    if (this.mapInstance.getAllLayers().filter((i) => i.get('id') === layerId).length) {
      return this.mapInstance.getAllLayers().filter((i) => i.get('id') === layerId)[0];
    }
  }

  public moveToFeature(feature?: Feature, option?: FitOptions) {
    if ((feature?.getGeometry() as any).getExtent() !== Infinity) {
      feature && this.viewer.fit(feature.getGeometry()?.getExtent() || [], option);
    }
  }
  public getMapInstance() {
    return this.mapInstance;
  }

  //지도 이동 후 스케일바 조절
  public onMoveEnd(callback: () => void) {
    this.mapInstance.on('moveend', () => {
      callback();
    });
  }

  public getOverlay(id: string) {
    return this.mapInstance
      .getOverlays()
      .getArray()
      .find((i: Overlay) => i.getOptions().id === id);
  }

  public mapChange(source: XYZ | OSM) {
    this.mapInstance.getAllLayers()[0].setSource(source);
  }
}

export default OlCore;
