import React, { SetStateAction, useState } from 'react';
import { ConfirmStyle, LanguageStyle, PopupStyle } from '../components/templates/_style';
import { useTranslation } from 'react-i18next';

interface LanguagePopupProps {
  onClickCancel: (key: 'language') => void;
  onClickSubmit: () => void;
  language: { key: string; name: string; active: boolean }[];
  setLanguage: React.Dispatch<SetStateAction<{ key: string; name: string; active: boolean }[]>>;
}
const PopupLanguage = ({ onClickCancel, onClickSubmit, language, setLanguage }: LanguagePopupProps) => {
  const { t, i18n } = useTranslation();

  const onChangeLanguage = (key: string) => {
    const newArr = [...language];
    newArr.map((i) => {
      i.active = key === i.key;
    });
    setLanguage(newArr);
  };
  return (
    <>
      <PopupStyle.Dimmed></PopupStyle.Dimmed>
      <PopupStyle.Popup $width={248}>
        <PopupStyle.Head $height={48} $align={'left'}>
          <PopupStyle.Title>{t('언어변경')}</PopupStyle.Title>
          <PopupStyle.CloseBtn $right={12} type={'button'} onClick={() => onClickCancel('language')}></PopupStyle.CloseBtn>
        </PopupStyle.Head>
        <PopupStyle.Cont>
          <PopupStyle.Inner $padding={'20px 24px'}>
            <LanguageStyle.List>
              {language.map((i) => {
                return (
                  <LanguageStyle.Item key={i.key} className={i.active ? 'active' : undefined} onClick={() => onChangeLanguage(i.key)}>
                    {i.name}
                  </LanguageStyle.Item>
                );
              })}
            </LanguageStyle.List>
          </PopupStyle.Inner>
          <ConfirmStyle.Button
            type={'button'}
            onClick={() => {
              i18n.changeLanguage(language.filter(({ active }) => active)[0].key);
              onClickSubmit();
            }}
            style={{ width: '100%' }}
          >
            {t('확인')}
          </ConfirmStyle.Button>
        </PopupStyle.Cont>
      </PopupStyle.Popup>
    </>
  );
};

export default PopupLanguage;
