import React, { useRef, useState, useEffect, useCallback } from 'react';
import { SurveyInitSt } from 'pages/_style';
import { useTranslation } from 'react-i18next';

import PopupUpload from 'components/templates/survey/popup/PopupUpload';
import DragDropBoxPage from 'components/templates/drag&drop/DragDropBoxPage';

import { useRecoilState, useResetRecoilState } from 'recoil';
import { progressState } from 'store/Atoms';
import { dragDropBoxPage, popupUpload, popupResultState } from 'store/popupAtoms';
//api
import useMutateHook from 'hooks/RQuery/useMutateHook';
import { showFileCheckPopup, showResultPopup } from './common.func';
import { FILE_TYPES } from 'util/global';
import queryKeys from 'hooks/RQuery/keys/queryKeys';
import query from '../../../hooks/RQuery/utils';

// 데이터 없을때 최초 실행
// DragDropBoxPage
function SurveyUpload({ siteInfo, setFirstCall }) {
  const siteId = siteInfo?.id || '';
  const { t, i18n } = useTranslation();

  const resetDndPg = useResetRecoilState(dragDropBoxPage);

  //   const [dndState, setdndState] = useRecoilState(dragDropBox);
  const [dndPgState, setdndPgState] = useRecoilState(dragDropBoxPage);
  const [popUploadState, setPopUploadState] = useRecoilState(popupUpload);

  const [progressBar, setProgressBar] = useRecoilState(progressState);

  const { mutate: checkCsv } = query.files.useCheckCsv({ siteId: siteId, csvFile: dndPgState.contentFileArr[0] });

  // csv 전송 (건너뛰기, 덮어쓰기)
  const { mutate: registCsv } = query.files.useRegistrationCsv();

  //   console.log(dndState);
  //   console.log(dndPgState);

  // 초기화
  useEffect(() => {
    resetDndPg();
  }, []);

  // 중복데이터 유무 확인 및 csv파일 서버에 임시 저장 요청
  const checkMutateCsv = async () => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndPgState?.contentFileArr[0]?.name }));

    await checkCsv(
      { siteId, csvFile: dndPgState.contentFileArr[0] },
      {
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));

          // [성공]
          if (data) {
            const response = { ...data?.data };
            const isDuplicate = response.ptNumDuplicateList.length > 0;
            // 중복데이터 없을때 - 기존처럼 csv 서버 전송 후 결과 팝업
            if (!isDuplicate) {
              registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true });
            } else {
              //중복 데이터 있을 때 - 팝업 오픈
              showFileCheckPopup({
                type: dndPgState.type,
                data,
                overwriteFunc: () => registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true }),
                skipFunc: () => registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: false }),
              });
            }
          }
          // [에러] - 결과 팝업
          if (error) {
            showResultPopup({ type: dndPgState.type, data, error });
          }
        },
        onSuccess: () => {
          setFirstCall(false);
        },
      },
    );
  };

  // 서버에 파일 건너뛰기 또는 덮어쓰기 요청
  const registMutateCsv = async ({ siteId, inspectionKey, overwrite }) => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndPgState?.contentFileArr[0]?.name }));

    // 서버
    await registCsv(
      { siteId, inspectionKey, overwrite },
      {
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));

          // dnd 영역 데이터 초기화
          resetDndPg();

          // 결과 처리 - 팝업
          showResultPopup({ type: dndPgState.type, data, error });
        },
        onSuccess: () => {
          setFirstCall(false);
        },
      },
    );
  };

  // 🔥 유저에게 파일을 받으면, 자동으로 업로드 함수 요청(버튼 클릭 등 없음)
  // 파일 1개
  useEffect(() => {
    // 조건 추가 -
    if (!popUploadState.isOpen) {
      if (dndPgState.contentFileArr[0]) {
        checkMutateCsv();
      }
    }
  }, [dndPgState.contentFileArr]);

  const curItem = FILE_TYPES.find((ele) => ele.type === 'point');
  const acceptType = curItem?.acceptType || [];
  const multiple = curItem?.multiple || false;

  //   console.log('result', popUploadState);
  return (
    <>
      {/* 파일업로드 로딩바 */}
      {/* {showLoader && <LoadingBar />} */}

      {/* 업로드 실패 팝업 - 웹에서 체크 결과 */}
      {/* <PopupFail /> */}

      {/* 업로드용 팝업 - DragDropBox 포함 */}
      {/* {popUploadState.isOpen && <PopupUpload siteInfo={siteInfo} />} */}

      {/* 성공 or 실패 팝업 */}
      {/* <PopupResult /> */}

      <SurveyInitSt.PageWrap>
        {/* 공통 헤더 */}
        {/*<SurveyHeader onBack={() => {}} siteInfo={{ id: '', name: '' }} />*/}

        {/* 도움말 있는 버전 및 csv 파일 1개 가능 */}
        <DragDropBoxPage height={420} acceptType={acceptType} mainText={t('업로드안내')} subText={t('업로드안내서브')} multiple={multiple} curItem={curItem} />
      </SurveyInitSt.PageWrap>
    </>
  );
}

export default SurveyUpload;
