import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';

const SkeletonReportMap = () => {
  return (
    <SkeletonContainer
      element={
        <>
          <div style={{ position: 'absolute', left: 10, top: 12, width: 142, height: 36 }} className={'skeleton-item'}></div>
          <div style={{ position: 'absolute', right: 10, top: 10, width: 36, height: 36 }} className={'skeleton-item'}></div>
          <div style={{ position: 'absolute', right: 10, bottom: 10, width: 36, height: 36 }} className={'skeleton-item'}></div>
          <div style={{ position: 'absolute', right: 10, bottom: 48, width: 36, height: 36 }} className={'skeleton-item'}></div>
          <div style={{ position: 'absolute', right: 10, bottom: 106, width: 36, height: 36 }} className={'skeleton-item'}></div>
        </>
      }
    />
  );
};

export default SkeletonReportMap;
