import { request } from 'service/api/common';
import * as apiUrl from './apiUrl';

// 파이프 (관로)
const mainUrl = apiUrl.pipes;

// 상수
const wtlUrl = mainUrl.wtl;
export const wtl = {
  // 2d map (all)
  getPipes: async ({ siteId }) => {
    const { data } = await request('d2').get(wtlUrl.all, { params: { siteId } });
    return data.data;
  },
  // 측량성과등록 페이지
  getSurveyPipes: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wtlUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  //선택 삭제
  deleteSurveyPipes: async ({ siteId, pipeIds }) => {
    const { data } = await request('d2').delete(wtlUrl.survey.all, {
      params: { siteId, pipeIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(wtlUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  getSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(wtlUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 오수
const wstUrl = mainUrl.wst;
export const wst = {
  // 2d map (all)
  getPipes: async ({ siteId }) => {
    const { data } = await request('d2').get(wstUrl.all, { params: { siteId } });
    return data.data;
  },
  // 측량성과등록 페이지
  getSurveyPipes: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(wstUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  //선택 삭제
  deleteSurveyPipes: async ({ siteId, pipeIds }) => {
    const { data } = await request('d2').delete(wstUrl.survey.all, {
      params: { siteId, pipeIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(wstUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  getSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(wstUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 우수
const rnwUrl = mainUrl.rnw;
export const rnw = {
  // 2d map (all)
  getPipes: async ({ siteId }) => {
    const { data } = await request('d2').get(rnwUrl.all, { params: { siteId } });
    return data.data;
  },
  // 측량성과등록 페이지
  getSurveyPipes: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(rnwUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  //선택 삭제
  deleteSurveyPipes: async ({ siteId, pipeIds }) => {
    const { data } = await request('d2').delete(rnwUrl.survey.all, {
      params: { siteId, pipeIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(rnwUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  getSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(rnwUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 전기
const eltUrl = mainUrl.elt;
export const elt = {
  // 2d map (all)
  getPipes: async ({ siteId }) => {
    const { data } = await request('d2').get(eltUrl.all, { params: { siteId } });
    return data.data;
  },
  // 측량성과등록 페이지
  getSurveyPipes: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(eltUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  //선택 삭제
  deleteSurveyPipes: async ({ siteId, pipeIds }) => {
    const { data } = await request('d2').delete(eltUrl.survey.all, {
      params: { siteId, pipeIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(eltUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  getSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(eltUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 가스
const gasUrl = mainUrl.gas;
export const gas = {
  // 2d map (all)
  getPipes: async ({ siteId }) => {
    const { data } = await request('d2').get(gasUrl.all, { params: { siteId } });
    return data.data;
  },
  // 측량성과등록 페이지
  getSurveyPipes: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(gasUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  //선택 삭제
  deleteSurveyPipes: async ({ siteId, pipeIds }) => {
    const { data } = await request('d2').delete(gasUrl.survey.all, {
      params: { siteId, pipeIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(gasUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  getSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(gasUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 통신
const ntwUrl = mainUrl.ntw;
export const ntw = {
  // 2d map (all)
  getPipes: async ({ siteId }) => {
    const { data } = await request('d2').get(ntwUrl.all, { params: { siteId } });
    return data.data;
  },
  // 측량성과등록 페이지
  getSurveyPipes: async ({ siteId, pageSize, pageNum, sort, startDate, endDate, ptNum }) => {
    const { data } = await request('d2').get(ntwUrl.survey.all, { params: { siteId, pageSize, pageNum, sort, startDate, endDate, ptNum } });
    return data.data;
  },
  //선택 삭제
  deleteSurveyPipes: async ({ siteId, pipeIds }) => {
    const { data } = await request('d2').delete(ntwUrl.survey.all, {
      params: { siteId, pipeIds },
      paramsSerializer: {
        indexes: null,
      },
    });
    return data.data;
  },
  //날짜 삭제
  deleteSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').delete(ntwUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
  getSurveyPipesByDate: async ({ siteId, date }) => {
    const { data } = await request('d2').get(ntwUrl.survey.date, {
      params: { siteId, date },
    });
    return data.data;
  },
};

// 오수
export const any = {};
