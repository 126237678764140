import React, { useState } from 'react';
import styled from 'styled-components';
import PopupFrame from 'components/templates/survey/popup/PopupFrame';
import { useTranslation } from 'react-i18next';
import SurveyDelMap from 'components/templates/survey/SurveyDelMap';
import ConfirmAlert from 'components/templates/ConfirmAlert';
import { useRecoilValue } from 'recoil';
import { tableListPipe, tableListPoint } from 'store/surveyAtoms';
import { PopupSelectDelSt } from './PopupSurveySelectDel';
import query from '../hooks/RQuery/utils';

// 선택된 항목 삭제 팝업
function PopupSurveyPipeSelectDel({ onClose, siteInfo, activeCategory }: { onClose: (key: string) => void; activeCategory: string; siteInfo?: { id: string; name: string } }) {
  const siteId = siteInfo?.id || '';
  const { t, i18n } = useTranslation();

  const tableListPipeState = useRecoilValue(tableListPipe); // 파이프 테이블 체크리스트
  const [alertPop, setAlertPop] = useState({ isOpen: false, mainText: '', subText: '' });

  //선택한 파이프 삭제
  const { mutate: deletePipesByChecked } = query.survey.pipes.useSurveyDeletePipes({ params: { siteId: siteId, facility: activeCategory, pipeIds: [...tableListPipeState?.checked] } });

  // 현재 팝업 - 닫기
  const closePopup = () => {
    onClose('pipeSelectDel');
  };
  console.log(tableListPipeState);
  // 현재 팝업 - 삭제 버튼 클릭 이벤트
  const onClickDelete = () => {
    //확인 팝업 오픈
    const dataName = '관로 데이터';
    const mainText = i18n.language === 'ko' ? `${dataName}${t('데이터삭제여부')}` : `${t('데이터삭제여부')}${dataName}`;
    setAlertPop((prev) => ({ ...prev, isOpen: true, mainText: mainText, subText: t('데이터복구불가') }));
  };

  // 삭제 확인 팝업 - 닫기
  const closeAlertPop = () => {
    setAlertPop((prev) => ({ ...prev, isOpen: false }));
  };
  //체크한 항목, 연계된 파이프 삭제
  const onRemoveChecked = async () => {
    await deletePipesByChecked(
      { siteId, wtlPipeIds: [...tableListPipeState?.checked] },
      {
        onSettled(data, error, variables, context) {
          console.log(data, error);
          closeAlertPop(); //삭제 확인 팝업 - 닫기
          closePopup();
        },
      },
    );
  };
  return (
    <>
      <PopupFrame title={t('삭제')} close={closePopup} leftText="취소" rightText="삭제" leftFunc={closePopup} rightFunc={onClickDelete}>
        <PopupSelectDelSt.HelpArea>
          <p className="text">
            ※ 선택된 관로가 전부 삭제되며, 삭제된 항목은 복구되지 않습니다.
            <br />
            다음 항목을 삭제하시겠습니까?
          </p>
        </PopupSelectDelSt.HelpArea>
        <PopupSelectDelSt.ContWrap>
          <PopupSelectDelSt.MapArea>
            {/* 지도영역 */}
            <SurveyDelMap bboxData={tableListPipeState.checkedPipeData} />
          </PopupSelectDelSt.MapArea>
          <PopupSelectDelSt.ListArea>
            <div className="titleWrap">
              <div className="column">구분</div>
              <div className="column">측점명</div>
            </div>
            <div className="contWrap">
              <ul className="cScroll">
                {tableListPipeState.checkedPipeData.pipes?.map((ele, idx) => (
                  <li key={idx}>
                    <div>파이프</div>
                    <div>{ele.ptNum}</div>
                  </li>
                ))}
              </ul>
            </div>
          </PopupSelectDelSt.ListArea>
        </PopupSelectDelSt.ContWrap>
      </PopupFrame>

      {/* 삭제 확인 알림창  */}
      {alertPop.isOpen && <ConfirmAlert title={t('삭제')} close={closeAlertPop} mainText={alertPop.mainText} subText={alertPop.subText} leftText={t('취소')} rightText={t('삭제')} leftFunc={closeAlertPop} rightFunc={onRemoveChecked} />}
    </>
  );
}

export default PopupSurveyPipeSelectDel;
