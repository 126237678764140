import { RefObject } from 'react';
import { atom } from 'recoil';

export interface DragDropProps {
  accept?: string[];
  type?: any;
  contentFile: File | null;
  contentFileArr: File[] | [];
  contentFileUrl: string;
  mainText: string;
  subText: string;
  inputRef: RefObject<HTMLInputElement> | null;
  inputId: string;
}

// 드래그 앤 드롭 박스 컴포넌트
export const dragDropBox = atom<DragDropProps>({
  key: 'dragDropBox',
  default: {
    accept: [],
    type: '',
    contentFileArr: [],
    contentFile: null,
    contentFileUrl: '',
    mainText: '',
    subText: '',
    inputRef: null,
    inputId: '',
  },
  //Cannot freeze 발생시 옵션
  dangerouslyAllowMutability: true, // RefObject 저장 (원래 read-only만 가능)
});

// 드래그 앤 드롭 박스 컴포넌트 (for page) init
export const dragDropBoxPage = atom<DragDropProps>({
  key: 'dragDropBoxPage',
  default: {
    type: '',
    contentFile: null,
    contentFileUrl: '',
    contentFileArr: [],
    mainText: '',
    subText: '',
    inputRef: null,
    inputId: '',
  },
  //Cannot freeze 발생시 옵션
  dangerouslyAllowMutability: true, // RefObject 저장 (원래 read-only만 가능)
});

// -------------------------

export interface UploadProps {
  isOpen?: boolean;
  type: 'point' | 'pipe' | 'image';
  title?: string;
}

// 파일 업로드 팝업 (dnd 포함)
export const popupUploadSelect = atom<UploadProps>({
  key: 'popupUploadSelect',
  default: {
    isOpen: false,
    title: '',
    type: 'point',
  },
});

// -------------------------

export interface UploadProps {
  isOpen?: boolean;
  type: 'point' | 'pipe' | 'image';
  title?: string;
}

// 파일 업로드 팝업 (dnd 포함)
export const popupUpload = atom<UploadProps>({
  key: 'popupUpload',
  default: {
    isOpen: false,
    title: '',
    type: 'point',
  },
});

// -------------------------

export interface UploadResultProps {
  done?: boolean;
  isSuccess?: boolean;
  isDuplicate?: boolean;
  type: 'point' | 'pipe' | 'image';
  title?: string;
  leftText?: string;
  rightText?: string;
  leftFunc?: any;
  rightFunc?: any;
  close?: () => void;
  response?: any;
}

// 파일 업로드 후 결과 팝업
export const popupResultState = atom<UploadResultProps>({
  key: 'popupResultState',
  default: {
    done: false, // 오픈 유무
    isSuccess: false, // 성공,에러 유무 (파란색,빨간색 디자인)
    isDuplicate: false, // 중복데이터 에러일경우
    type: 'point',
    title: '',
    rightText: '',
    rightFunc: () => {},
    leftText: '',
    leftFunc: () => {},
    close: () => {},
    response: {},
  },
});

// -------------------------

export const popupFailState = atom({
  key: 'popupFailState',
  default: {
    isOpen: false,
    fileName: '',
    noDescription: false,
  },
});

export const confirmOneBtn = atom({
  key: 'confirmOneBtn',
  default: {
    isOpen: false,
    desc: '',
  },
});

/* TEST: 현장 등록 팝업 ( survey 측량성과등록 화면 ) */
export const popupAddSite = atom({
  key: 'popupAddSite',
  default: {
    isOpen: false,
  },
});
