import React, { ReactNode, useEffect } from 'react';
import Input from './Input';

import styled from 'styled-components';
import { InputProps } from './_interface';

// label input
export type LabelFormProps = InputProps & {
  labelSize?: number;
  labelWidth?: number;
  label?: string;
  align?: 'top';
  labelPos?: 'top';
  gap?: number;
  required?: boolean;
  siteName?: string;
  defaultValues?: any;
  defaultValue?: any;
  fontSize?: any;
  rightBtn?: ReactNode;
  beforeIcon?: ReactNode;
  regax?: RegExp;
  regaxMessage?: string;
};

export const LabelForm = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
  `,
  Label: styled.label<{ required?: boolean; labelSize?: number; labelWidth?: number }>`
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.container.title};
    /* width: 100%; */
    /* width: max-content; */
    min-width: ${(p) => (p.labelWidth ? p.labelWidth + 'px' : '140px')};
    font-size: ${(p) => (p.labelSize ? p.labelSize + 'px' : '14px')};
    font-weight: 500;
    ${(p) =>
      p.required &&
      `
      &:after {
      content: '*';
      margin-left:4px;
      color:#4880FF;
    }`}
  `,
};

const LabelInput = ({ labelWidth, id, name, type, width, height, value, onChange, component, label, disabled, defaultValue, placeholder, rightBtn, style, readOnly, required, beforeIcon, labelSize, regax, regaxMessage }: LabelFormProps) => {
  return (
    <>
      <LabelForm.Wrap style={style}>
        {beforeIcon}
        <LabelForm.Label htmlFor={id} required={required} labelSize={labelSize} labelWidth={labelWidth}>
          {label}
        </LabelForm.Label>
        <Input id={id} name={name} type={type} width={width ? width : 'full'} height={height} onChange={onChange} component={component} disabled={disabled} value={value} placeholder={placeholder} readOnly={readOnly} />
        {!!rightBtn && rightBtn}
      </LabelForm.Wrap>
    </>
  );
};

export default LabelInput;
