import React, { useEffect, useState } from 'react';
import { RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { DashboardStyle } from './_style';
import BarChart from '../atoms/BarChart';
import { DashboardTypeDataProps } from '../../pages/_interface';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import { DateObject } from 'react-multi-date-picker';
import SkeletonTotalChart from '../skeleton/SkeletonTotalChart';
import { useTranslation } from 'react-i18next';
import query from '../../hooks/RQuery/utils';

const DashboardTotalChart = ({ date }: { date: DateObject }) => {
  const { t } = useTranslation();
  //전체 공정률 초기값
  const [data, setData] = useState<DashboardTypeDataProps[]>([
    { name: 'total', percent: 100, meter: 0, fill: '#323D4E' },
    { name: '시공률', percent: 0, meter: 0, fill: '#0076FF' },
    { name: '잔여작업량', percent: 0, meter: 0, fill: '#49AEFF' },
    { name: '누적작업거리', percent: 0, meter: 0, value: 0, fill: '#A5CDF5' },
  ]);
  const [themeMode] = useRecoilState(themeState);
  const [totalData, setTotalData] = useState<DashboardTypeDataProps[]>([]); //100% 기준 대비 시공률 계산을 위한 별도 state

  //전체 공정률 데이터 쿼리
  const process = query.statistics.useProcessRate({ siteId: String(sessionStorage.getItem('siteId')), date: date.format('YYYY-MM-DD') });

  //전체 공정률 데이터 setState
  useEffect(() => {
    if (process.data) {
      const propData = process.data;
      const newArr = [...data];
      let completePercent = typeof propData.completePercent === 'number' ? propData.completePercent : 0;
      let remainPercent = typeof propData.remainPercent === 'number' ? propData.remainPercent : 0;
      let completeDistance = typeof propData.completeDistance === 'number' ? propData.completeDistance : 0;
      newArr[1].percent = Number(completePercent?.toFixed(1));
      newArr[2].percent = Number(remainPercent?.toFixed(1));
      newArr[3].percent = Number(completePercent?.toFixed(1));
      newArr[3].value = Number(completeDistance?.toFixed(1));
      setData(newArr);
      console.log(newArr);
    }
  }, [process.data]);

  //원형 그래프(시공률) 표현 데이터 지정
  useEffect(() => {
    setTotalData(data.filter((i) => i.name === '시공률' || i.name === 'total'));
  }, []);

  return (
    <DashboardStyle.SectionBox>
      <SkeletonTotalChart />
      <DashboardStyle.TitleArea>
        <DashboardStyle.Title>{t('전체공정률')}(%)</DashboardStyle.Title>
      </DashboardStyle.TitleArea>
      <DashboardStyle.DescArea>
        <DashboardStyle.DescInner>
          <DashboardStyle.ChartFlex>
            <DashboardStyle.ChartBox style={{ width: 168, height: 168, marginRight: 32 }}>
              <DashboardStyle.ChartTotal>
                <strong>{t('시공률')}</strong>
                <p>
                  {data.filter((i) => i.name === '시공률')[0].percent}
                  <span>%</span>
                </p>
              </DashboardStyle.ChartTotal>
              <ResponsiveContainer width={'100%'} height={'100%'}>
                <RadialBarChart cx="50%" cy="50%" innerRadius="100%" outerRadius="100%" barSize={8} data={totalData} startAngle={270} endAngle={-90}>
                  {/*<defs>*/}
                  {/*    <linearGradient id={`colorUv`} x1='50%' y1='0' x2='50%' y2='100%' spreadMethod='reflect'>*/}
                  {/*        <stop offset='0' stopColor='#eee' />*/}
                  {/*        <stop offset='1' stopColor='blue' />*/}
                  {/*    </linearGradient>*/}
                  {/*</defs>*/}
                  <RadialBar cornerRadius={50} dataKey="percent" background={{ fill: themeMode === 'dark' ? '#323D4E' : '#E2E4ED' }}>
                    {/*{*/}
                    {/*    data.map((i,index) => {*/}
                    {/*        return <Cell*/}
                    {/*            key={index}*/}
                    {/*            // fill={'url(#colorUv)'}*/}
                    {/*        />*/}
                    {/*    })*/}
                    {/*}*/}
                  </RadialBar>

                  {/*<Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={style} />*/}
                </RadialBarChart>
              </ResponsiveContainer>
            </DashboardStyle.ChartBox>
            <div style={{ flex: 1 }}>
              <BarChart percent={data[1].percent} label={t(data[1].name)} color={data[1].fill} />
              <BarChart percent={data[2].percent} label={t(data[2].name)} color={data[2].fill} />
              <BarChart percent={data[1].percent} value={Number(data[3].value).toLocaleString('ko-KR') + 'm'} label={t(data[3].name)} color={data[3].fill} />
            </div>
          </DashboardStyle.ChartFlex>
        </DashboardStyle.DescInner>
      </DashboardStyle.DescArea>
    </DashboardStyle.SectionBox>
  );
};

export default DashboardTotalChart;
