import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import * as keys from 'hooks/RQuery/keys/queryKeys';
import api from 'hooks/RQuery/service';
import { getPointDetailQuery, getPointsOptions } from '../points';

// 현장별로 볼 수 있는
// facilityKinds 배열에 있는 측점들 조회
// 방법2. useQuries
export function useMapPoints({ siteId, facilityKinds }) {
  //
  let myQueries: any[] = [];

  // 1번 방법
  facilityKinds.map((facility) => {
    let options = getPointsOptions({ siteId, facility });

    myQueries.push({ ...options });
  });

  // 2번 방법
  //   if (facilityKindArr.includes('WATER')) {
  //  myQueries.push({
  //    queryKey: keys.pointKeys.wtl.all(siteId),
  //    queryFn: () => api.points.wtl.getPoints({ siteId }),
  //  });
  //  }
  //  if (facilityKindArr.includes('WASTE')) {
  //  }
  //  if (facilityKindArr.includes('RAIN')) {
  //  }
  //  if (facilityKindArr.includes('GAS')) {
  //  }
  //  if (facilityKindArr.includes('NETWORK')) {
  //  }
  //  if (facilityKindArr.includes('ELECTRIC')) {
  //  }
  //  if (facilityKindArr.includes('OIL')) {
  //  }
  //  if (facilityKindArr.includes('HEAT')) {
  //  }

  // facility 길이와 쿼리 길이가 같아야 함
  //   console.log(facilityKindArr.length, myQueries.length);

  const { data, pending, isSuccessAll, isError, failureCount } = useQueries({
    queries: myQueries,
    combine: useCallback(
      (results) => {
        let newCode: any = {};
        let pointsAll: any[] = [];

        facilityKinds.map((facility, idx) => {
          newCode[facility] = results[idx]?.data; // 시설물별 구분

          // 모든 측점 데이터 합친 배열 리턴
          if (results[idx]?.data?.length > 0) {
            pointsAll.push(...results[idx]?.data);
          }
        });
        newCode['ALL'] = pointsAll; // 모든 측점 데이터 한 배열
        //   console.log(newCode);
        //   console.log(pointsAll);

        // Test: failureCount 의미 확인 필요
        let failureCount = 0;
        results.map((result) => {
          failureCount += result.failureCount;
        });

        return {
          data: newCode,
          //   data: results.map((result) => result.data || []),
          pending: results.some((result) => result.isPending),
          isSuccessAll: results.every((result) => result.isSuccess),
          isError: results.some((result) => result.isError), // 하나라도 에러가 있을때 체크해줌
          failureCount: failureCount, // 하나라도 에러가 있을때 체크?
        };
      },
      [facilityKinds],
    ),
  });

  return { data, pending, isSuccessAll, isError, failureCount };
}

// 관 종류별로 쿼리 분리, 포인트 상세 조회
export function useMapPointDetail({ pointInfo }) {
  const { facilityKind, pitCde, pointId } = pointInfo || {};
  const type = pitCde === 'PIT006' ? 'valves' : pitCde === 'PIT007' ? 'holes' : pitCde === 'PIT008' ? 'fires' : 'joints';

  // facility : 상수, 오수, 우수 등을 구분
  return getPointDetailQuery({ facility: facilityKind, type, pointId });
}
