import { useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from '../useMutateHook';
import { addCompany, addUser, login } from '../service/auth';
/* 
   queryKey,
   queryFn,
   그 외 options 설정
*/

//로그인
export function useLogin({ id, password }) {
  return useMutateHook({
    fetchApi: () => login({ id, password }),
  });
}

//슈퍼어드민 - 회사 목록보기
export function useCompanies({ currentPage, keyword, category }) {
  return useQuery({
    queryKey: [...keys.authKeys.companies.all({ pageNum: currentPage })],
    queryFn: () => api.auths.getCompanies({ pageNum: currentPage, keyword, category }),
  });
}

//슈퍼어드민 - 회사 상세정보
export function useCompanyDetail(companyId) {
  return useQuery({
    queryKey: [...keys.authKeys.companies.detail(companyId)],
    queryFn: () => api.auths.getCompanyDetail(companyId),
  });
}

//슈퍼어드민 - 회사+관리자 추가
export function useAddCompany({ companyName, address, id, password, name, registration, contract, solutionSaveDto }) {
  return useMutateHook({
    fetchApi: () => addCompany({ companyName, address, id, password, name, registration, contract, solutionSaveDto }),
  });
}

//아이디 중복체크
export function useCheckDuplicate(loginId) {
  return useQuery({
    queryKey: [...keys.authKeys.duplicate],
    queryFn: () => api.auths.getCheckDuplicate(loginId),
    enabled: false,
  });
}

//하위 사용자 추가
export function useAddUser({ id, password, name, auth, sites }) {
  return useMutateHook({
    fetchApi: () => addUser({ id, password, name, auth, sites }),
    invalidQuery: [], //todo: 사용자리스트 쿼리키 추가예정
  });
}

//사용자 리스트
export function useMembers({ pageSize, pageNum, sort, auth }) {
  return useQuery({
    queryKey: [...keys.authKeys.memberList({ pageSize, pageNum, sort, auth })],
    queryFn: () => api.auths.getMembers({ pageSize, pageNum, sort, auth }),
  });
}

//로그인한 계정 정보
export function useMyInfo() {
  return useQuery({
    queryKey: [...keys.authKeys.all],
    queryFn: () => api.auths.getMyInfo(),
  });
}
