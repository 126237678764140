import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import query from 'hooks/RQuery/utils';
import { useNavigate } from 'react-router-dom';
import LabelInput, { LabelForm } from '../../components/atoms/LabelInput';
import useInputChange from '../../hooks/useInputChange';
import ManageContent, { ManageContentStepProps } from '../../components/templates/ManageContent';
import { DataFilterStyle } from '../../components/templates/_style';
import ConfirmOneBtn from '../../components/templates/ConfirmOneBtn';
import { confirmOneBtn } from '../../store/popupAtoms';
import LabelCheckbox from '../../components/templates/2dMap/LabelCheckbox';
import Checkbox from '../../components/atoms/Checkbox';
import Input from '../../components/atoms/Input';
import { WorkPlaceStyle } from '../admin/WorkPlaceRegister';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { MonthsForLocale } from '../../util/global';
import { useTranslation } from 'react-i18next';
import { SurveyHeaderSt } from '../../components/templates/popup/_style';

interface SolutionProps {
  solution: string;
  checked: boolean;
  contractStart: string;
  contractEnd: string;
}

const initForm = {
  companyName: '',
  address: '',
  id: '',
  password: '',
  verify: '',
  name: '',
  registration: '',
  contract: '',
};

// 고객사 추가 화면
const CompanyRegister = () => {
  const [themeMode] = useRecoilState(themeState);
  const { t, i18n } = useTranslation();
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn);
  const monthsKo = MonthsForLocale(i18n.language === 'ko' ? 'ko' : 'en', 'short');

  //메뉴(step형태)
  const [step, setStep] = useState<ManageContentStepProps[]>([
    { num: 1, pass: true, text: '기본 정보' },
    // { num: 2, pass: false, text: '사용 서비스' },
    // { num: 3, pass: false, text: '사용 기간' },
    // { num: 4, pass: false, text: '관리자 정보' },
  ]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const passArr = step.filter(({ pass }) => pass);
  const [duplicate, setDuplicate] = useState({
    init: true,
    state: true,
  });
  const navigate = useNavigate();
  const [solutionList, setSolutionList] = useState<SolutionProps[]>([]);
  const [formOrigin, setFormOrigin, onChangeOrigin] = useInputChange(initForm);
  const checkDuplicate = query.auths.useCheckDuplicate(formOrigin.id);
  const { mutate: mutateAddSite, isSuccess } = query.auths.useAddCompany({
    companyName: formOrigin.companyName,
    address: formOrigin.address,
    id: formOrigin.id,
    password: formOrigin.password,
    name: formOrigin.name,
    registration: formOrigin.registration,
    contract: formOrigin.contract,
    solutionSaveDto: solutionList.filter(({ checked }) => checked),
  });

  const codes = query.codes.useCodes();

  //solution 코드 가공
  useEffect(() => {
    if (codes.isSuccessAll) {
      const newArr: SolutionProps[] = [];
      codes.data.SOLUTION.map((i) => {
        newArr.push({ solution: i, checked: false, contractStart: '', contractEnd: '' });
      });
      setSolutionList(newArr);
    }
  }, [codes.isSuccessAll]);

  const onChangeCheck = (solution) => {
    const newArr = [...solutionList];
    newArr.find((i) => i.solution === solution)!.checked = !newArr.find((i) => i.solution === solution)!.checked;
    setSolutionList(newArr);
  };

  const onChangeContractDate = (e, solution, dateKind) => {
    const newArr = [...solutionList];
    if (dateKind === 'contractStart') {
      newArr.find((i) => i.solution === solution)!.contractStart = (e as DateObject).format('YYYY-MM-DD');
    } else {
      newArr.find((i) => i.solution === solution)!.contractEnd = (e as DateObject).format('YYYY-MM-DD');
    }
    setSolutionList(newArr);
  };

  const onSubmit = async () => {
    await mutateAddSite(
      { ...formOrigin, ...solutionList },
      {
        onSuccess: (data: any) => {
          setSubmitSuccess(true);
          setConfirmOneBtn((prev) => ({ ...prev, desc: data, isOpen: true }));
        },
        onError: (e: any) => {
          setConfirmOneBtn((prev) => ({ ...prev, desc: e.data.errorMsg, isOpen: true }));
        },
      },
    );
  };

  const closeConfirm = () => {
    setConfirmOneBtn((prev) => ({ ...prev, isOpen: false }));
    submitSuccess && navigate('/auth/list');
  };
  const onTabClick = (index) => {
    const newArr = [...step];
    newArr.map((i, idx) => {
      i.pass = index >= idx;
    });
    setStep(newArr);
  };
  return (
    <>
      <ManageContent
        title={'고객사 추가'}
        step={step}
        listPath={'/auth/list'}
        naviRight={
          <DataFilterStyle.BtnSearch type={'button'} onClick={onSubmit} style={{ width: 100, height: 40 }} disabled={formOrigin.password !== formOrigin.verify || !formOrigin.password.length || !formOrigin.id || duplicate.state || duplicate.init}>
            등록하기
          </DataFilterStyle.BtnSearch>
        }
        content={
          <>
            {passArr[passArr.length - 1].num === 4 ? (
              //4번탭
              <div>4</div>
            ) : passArr[passArr.length - 1].num === 3 ? (
              //3번탭
              <div>3</div>
            ) : passArr[passArr.length - 1].num === 2 ? (
              //2번탭
              <div>2</div>
            ) : passArr[passArr.length - 1].num === 1 ? (
              //1번탭
              <>
                <LabelInput name="companyName" label="회사명" onChange={onChangeOrigin} value={formOrigin?.companyName} width={400} style={{ marginBottom: 12 }} required={true} />
                <LabelInput name="address" label="주소" onChange={onChangeOrigin} value={formOrigin?.address} width={400} style={{ marginBottom: 12 }} required={true} />
                <LabelInput
                  name="id"
                  label="ID"
                  onChange={(e) => {
                    onChangeOrigin(e);
                    setDuplicate({ init: true, state: true });
                  }}
                  value={formOrigin?.id}
                  width={400}
                  style={{ marginBottom: 12 }}
                  required={true}
                  rightBtn={
                    <SurveyHeaderSt.Button
                      type={'button'}
                      style={{ width: 80, height: 40, marginLeft: 10 }}
                      disabled={!formOrigin?.id}
                      onClick={() => {
                        checkDuplicate.refetch().then(({ data }) => {
                          if (data) {
                            setConfirmOneBtn((prev) => ({ ...prev, desc: '이미 등록된 ID 입니다.', isOpen: true }));
                          } else {
                            setConfirmOneBtn((prev) => ({ ...prev, desc: '사용 가능한 ID 입니다.', isOpen: true }));
                          }
                          setDuplicate({ init: false, state: data });
                        });
                      }}
                    >
                      중복 확인
                    </SurveyHeaderSt.Button>
                  }
                />
                <LabelInput name="password" label="비밀번호 입력" type={'password'} onChange={onChangeOrigin} value={formOrigin?.password} width={400} required={true} style={{ marginBottom: 12 }} />
                <LabelInput name="verify" label="비밀번호 확인" type={'password'} onChange={onChangeOrigin} value={formOrigin?.verify} width={400} required={true} style={{ marginBottom: 12 }} />
                <LabelInput name="name" label="이름" onChange={onChangeOrigin} value={formOrigin?.name} width={400} required={true} style={{ marginBottom: 12 }} />
                <LabelForm.Wrap style={{ alignItems: 'flex-start' }}>
                  <LabelForm.Label required={true} style={{ paddingTop: 10 }}>
                    이용 서비스
                  </LabelForm.Label>
                  <LabelForm.Wrap style={{ display: 'flex', flexDirection: 'column' }}>
                    {solutionList.map((i) => {
                      return (
                        <div key={i.solution}>
                          <WorkPlaceStyle.FacilityItem style={{ width: 500 }}>
                            <WorkPlaceStyle.FacilityCheckArea style={{ width: 120, justifyContent: 'flex-start', paddingLeft: 10 }}>
                              <Checkbox
                                // type="radio"
                                id={i.solution}
                                name={i.solution}
                                text={i.solution}
                                // dark={true}
                                size={18}
                                // checked={}
                                onChange={() => onChangeCheck(i.solution)}
                              />
                            </WorkPlaceStyle.FacilityCheckArea>
                            <LabelForm.Wrap style={{ flex: 1, justifyContent: 'center' }}>
                              <DataFilterStyle.Group className={themeMode === 'light' ? 'light' : undefined}>
                                <DatePicker
                                  style={{ width: 160 }}
                                  placeholder={'계약 시작일'}
                                  disabled={!i.checked}
                                  months={monthsKo}
                                  headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
                                  monthYearSeparator=" "
                                  weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                  onChange={(e) => onChangeContractDate(e, i.solution, 'contractStart')}
                                  maxDate={i.contractEnd}
                                />
                                <span style={{ margin: '0 10px' }}>~</span>
                                <DatePicker
                                  style={{ width: 160 }}
                                  placeholder={'계약 종료일'}
                                  disabled={!i.checked}
                                  months={monthsKo}
                                  headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
                                  monthYearSeparator=" "
                                  weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                                  onChange={(e) => onChangeContractDate(e, i.solution, 'contractEnd')}
                                  minDate={i.contractStart}
                                />
                              </DataFilterStyle.Group>
                            </LabelForm.Wrap>
                          </WorkPlaceStyle.FacilityItem>
                        </div>
                      );
                    })}
                  </LabelForm.Wrap>
                </LabelForm.Wrap>
              </>
            ) : null}
            {/*<button*/}
            {/*  type={'button'}*/}
            {/*  onClick={() => {*/}
            {/*    const newArr = [...step];*/}
            {/*    const passArr = newArr.filter(({ pass }) => pass);*/}
            {/*    if (passArr.length > 1) {*/}
            {/*      passArr[passArr.length - 1].pass = false;*/}
            {/*    }*/}
            {/*    setStep(newArr);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  이전*/}
            {/*</button>*/}
            {/*<button*/}
            {/*  type={'button'}*/}
            {/*  onClick={() => {*/}
            {/*    const newArr = [...step];*/}
            {/*    const passArr = newArr.filter(({ pass }) => pass);*/}
            {/*    if (newArr.length > passArr.length) {*/}
            {/*      newArr[passArr.length].pass = true;*/}
            {/*    }*/}
            {/*    setStep(newArr);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  다음*/}
            {/*</button>*/}
          </>
        }
        onTabClick={onTabClick}
      />
      {confirmOneBtnState.isOpen && <ConfirmOneBtn desc={confirmOneBtnState.desc || ''} onClickCancel={closeConfirm} onClickSubmit={() => {}} />}
    </>
  );
};

export default CompanyRegister;
