import { FILE_TYPES } from 'util/global';

import { getRecoil, setRecoil } from 'recoil-nexus';
import { popupResultState, popupUpload, popupUploadSelect } from 'store/popupAtoms';
import { exportCsvFile, exportTxtFile } from 'util/exportFile';
import { postCsvRegistration } from '../../../hooks/RQuery/service/files';
import useMutateHook from '../../../hooks/RQuery/useMutateHook';

// popup Upload Select 팝업 닫기
function closePopSelect() {
  const popSelectState = getRecoil(popupUploadSelect);
  setRecoil(popupUploadSelect, {
    ...popSelectState,
    isOpen: false,
  });
}

// 업로드용 팝업 열기 (계속해서~ 파일업로드)
function openPopUpload(props) {
  const popUploadState = getRecoil(popupUpload);
  setRecoil(popupUpload, {
    ...popUploadState,
    ...props,
    isOpen: true,
  });
}

// 결과 팝업 닫기
function closePopResult() {
  const popResultState = getRecoil(popupResultState);
  setRecoil(popupResultState, {
    ...popResultState,
    done: false, // 닫기
  });
}

// 결과 팝업 열기
function openPopResult(props) {
  const popResultState = getRecoil(popupResultState);
  setRecoil(popupResultState, {
    ...popResultState,
    ...props, // 팝업 내용
    done: true, // 열기
  });
}

// mutate 결과값을 받아서 처리 후 result 팝업을 오픈한다.
export const showResultPopup = ({ type, data, error }: { type?: string; data?: any; error?: any }) => {
  console.log('Show Result Popup', data);
  // 고려할 사항: 측점, 관로, 이미지
  // result : 성공 / 실패(서버에러)
  // type = img의 경우 3가지 결과 (성공 / 일부성공(200, failCount) / 실패(서버에러))

  // mutate 결과값을 받아서 처리
  let response, result;

  if (data) {
    result = true;
    response = { ...(data?.data || data) };
  }
  if (error) {
    result = false;
    const errorRespon = error?.response?.data;
    response = { ...errorRespon?.data, errorMsg: errorRespon?.errorMsg };
  }
  // [이미지]는 일부 실패일떄, status 200으로 내려와서 error값이 없음
  if (type === 'image' && response.failCount * 1 > 0) {
    result = false;
    response = { ...data?.data };
    console.log('please here');
  }

  // state 정리
  //   const curItem = FILE_TYPES.find((ele) => ele.type === type);
  const curIdx = FILE_TYPES.findIndex((ele) => ele.type === type);
  const nextType = FILE_TYPES[curIdx + 1]?.type;

  const popTitle = result ? '파일 업로드' : '파일 업로드 실패';
  const rightText = '닫기';
  const rightFunc = closePopResult;

  // 왼쪽 버튼 텍스트
  const leftText = (): string => {
    if (type === 'image') {
      return result ? '' : '결과 파일 다운로드';
    }
    if (type === 'point' || type === 'pipe') {
      return result ? '파일 업로드' : '결과 파일 다운로드';
    }
    return '';
  };

  // 왼쪽 버튼 이벤트
  const leftFunc = () => {
    //성공시
    if (result) {
      if (leftText() === '파일 업로드') {
        closePopResult(); // 현재 "결과" 팝업 닫기
        closePopSelect(); // "PopupUploadSelect" 팝업 닫기 (열려있는 경우,)
        openPopUpload({
          type: nextType, // next step (🔥파일 개수 multiple 유무 확인)
        });
      }
      return;
    } else {
      // 실패시 -> 결과 파일 다운로드
      // 1) failList - csv파일
      const failList = response?.failList || [];
      if (failList?.length > 0) {
        exportCsvFile(failList, `${type}_failList.csv`);
        return;
      }
      // 2) errorMsg - txt파일
      if (response?.errorMsg) {
        exportTxtFile(response?.errorMsg, `${type}_fail.txt`);
      }
    }
  };

  // 결과 팝업 props
  const resultState = {
    type: type, // point, pipe, image
    isSuccess: result, // 성공,에러
    isDuplicate: false, // 중복 데이터체크용 아님
    response: { ...response }, // 서버 결과값 같이 전달 ( 성공 실패 개수 , 에러 문구 등 )
    title: popTitle, // 헤더 타이틀
    leftText: leftText(), //하단 버튼 왼쪽
    rightText: rightText, //하단 버튼 오른쪽
    leftFunc: leftFunc, // 파일업로드 버튼(성공시) or 결과 파일 다운로드 (실패시)
    rightFunc: rightFunc,
    close: closePopResult,
  };

  // 결과 팝업 오픈
  openPopResult(resultState);
};

// 중복 데이터 있을 때 결과 팝업
// 건너뛰기, 덮어쓰기 유무 확인 팝업
export const showFileCheckPopup = ({ type, data, registMutateCsv }) => {
  console.log('Show Check Popup', data);

  const siteId = sessionStorage.getItem('siteId');

  let response = { ...data?.data };
  const { inspectionKey, ptNumDuplicateList } = response;

  let result = false; // 디자인 : 에러 화면

  const popTitle = '파일 업로드';
  const rightText = '덮어쓰기';
  const rightFunc = () => {
    registMutateCsv({ siteId, inspectionKey, overwrite: true });
  };
  const leftText = '건너뛰기';
  const leftFunc = () => {
    registMutateCsv({ siteId, inspectionKey, overwrite: false });
  };

  // 결과 팝업 props
  const resultState = {
    type: type, // point, pipe, image
    isSuccess: result, // 성공,에러 (디자인)
    isDuplicate: true, // 중복데이터 유무
    response: { ...response }, // 서버 결과값 같이 전달 ( 성공 실패 개수 , 에러 문구 등 )
    title: popTitle, // 헤더 타이틀
    leftText: leftText, //하단 버튼 왼쪽
    rightText: rightText, //하단 버튼 오른쪽
    leftFunc: leftFunc, // 파일업로드 버튼(성공시) or 결과 파일 다운로드 (실패시)
    rightFunc: rightFunc,
    close: closePopResult,
  };

  // 결과 팝업 오픈
  openPopResult(resultState);
};
