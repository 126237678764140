import { useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
/* 
   queryKey,
   queryFn,
   그 외 options 설정
*/

//작업일보 - 일일 시공 통계
export function useStatisticsDailyConstruction({ siteId, date }) {
  return useQuery({
    queryKey: [...keys.statisticsKeys.dailyConstruction, date, siteId],
    queryFn: () => api.statistics.getStatisticsDailyConstruction({ siteId, date }),
    enabled: !!siteId && siteId !== 'null',
  });
}

//대시보드 전체 공정률
export function useProcessRate({ siteId, date }) {
  return useQuery({
    queryKey: [...keys.statisticsKeys.processRate, date, siteId],
    queryFn: () => api.statistics.getProcessRates({ siteId, date }),
    enabled: !!siteId && siteId !== 'null',
  });
}

//대시보드 관별 공정률
export function useProcessRateByFacilityKind({ siteId, date }) {
  return useQuery({
    queryKey: [...keys.statisticsKeys.facilityKindProcessRate, date, siteId],
    queryFn: () => api.statistics.getProcessRateByFacilityKind({ siteId, date }),
    enabled: !!siteId,
  });
}

//대시보드 재질/관경 별 총 관 수량 및 시공물량(m) 통계
export function useStatisticsMopDiameters({ siteId }) {
  return useQuery({
    queryKey: [...keys.statisticsKeys.mopCdeDiameters, siteId],
    queryFn: () => api.statistics.getStatisticsMopDiameters({ siteId }),
    enabled: !!siteId,
  });
}

//대시보드 재질/일자/관경 별  관 수량 및 시공물량(m) 통계
export function useStatisticsMopDatesDiameters({ siteId, from, to }) {
  return useQuery({
    queryKey: [...keys.statisticsKeys.mopCdeDatesDiameters, siteId],
    queryFn: () => api.statistics.getStatisticsMopDatesDiameters({ siteId, from, to }),
    enabled: !!siteId,
  });
}

//대시보드 - 자재목록
export function useMaterials({ siteId, date }) {
  return useQuery({
    queryKey: [...keys.statisticsKeys.pipCdeDiameters, date, siteId],
    queryFn: () => api.statistics.getStatisticsPipCdeDiameters({ siteId, date }),
    enabled: !!siteId,
  });
}
