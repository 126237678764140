import React, { useEffect, useState } from 'react';
import { ReportStyle } from '../components/templates/_style';
import ReportCalendar from '../components/templates/ReportCalendar';
import ReportMap from '../components/templates/ReportMap';
import { DateObject } from 'react-multi-date-picker';
import ReportMaterialList from '../components/templates/ReportMaterialList';
import ReportPipeLineLayer from '../maps/wfs/Object/ReportPipeLineLayer';
import { useLocation } from 'react-router-dom';
import { qsData } from '../service/api/common';
import query from '../hooks/RQuery/utils';

const Report = () => {
  const [date, setDate] = useState<DateObject>(new DateObject());

  const [workDate, setWorkDate] = useState([]);
  const [pipeLayer, setPipeLayer] = useState<ReportPipeLineLayer>();
  const workDay = query.calendars.useCalendars({ siteId: String(sessionStorage.getItem('siteId')) });
  const { state } = useLocation();

  //시공일 리스트 호출 후 마지막 시공일 선택
  useEffect(() => {
    if (workDay.data) {
      const data = workDay.data;
      setWorkDate(data);
      if (qsData().ymd) {
        setDate(new DateObject(qsData().ymd));
      } else if (state) {
        setDate(new DateObject(state.ymd));
      } else {
        setDate(new DateObject(data[0]));
      }
    }
  }, [workDay.data]);

  //시공 목록에서 파이프 선택시 지도에 해당 파이프 위치 및 시점, 종점 표시
  const onActivePipe = (feature) => {
    pipeLayer?.addPoints(feature);
  };
  return (
    <ReportStyle.Contain>
      <ReportStyle.Inner>
        <ReportStyle.Wrap>
          <ReportStyle.CalendarArea>
            <ReportCalendar workDate={workDate} date={date} setDate={setDate} />
            <ReportMap date={date} pipeLayer={pipeLayer} setPipeLayer={setPipeLayer} />
          </ReportStyle.CalendarArea>
          <ReportStyle.TableArea>
            <ReportMaterialList workDate={workDate} date={date} onActivePipe={onActivePipe} state={state} pipeLayer={pipeLayer} />
          </ReportStyle.TableArea>
        </ReportStyle.Wrap>
      </ReportStyle.Inner>
    </ReportStyle.Contain>
  );
};

export default Report;
