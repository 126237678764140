import { useQuery } from '@tanstack/react-query';
import { getPipes } from '../../service/smartpin';
import queryKeys from '../../keys/queryKeys';

//스마트핀 파이프 리스트 호출
export const usePipes = ({ siteId }) => {
  return useQuery({
    queryKey: [[...queryKeys.smartpinKeys.pipe({ siteId })]],
    queryFn: () => getPipes({ siteId }),
    enabled: !!siteId,
  });
};
