import styled from 'styled-components';
import Logo from '../assets/images/logo.svg';
import LogoVn from '../assets/images/logo-vn.png';
import IcoId from '../assets/images/ico-login-id.svg';
import IcoIdFocus from '../assets/images/ico-login-id-focus.svg';
import IcoPassword from '../assets/images/ico-login-password.svg';
import IcoPasswordFocus from '../assets/images/ico-login-password-focus.svg';
import IcoSiteSelect from '../assets/images/ico-login-site.svg';

const lang = navigator.language;
export const LoginStyle = {
  Wrap: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #020202, #015efe, #01b0f0, #02eec5);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    @keyframes gradient {
      0% {
        background-position: 0 0;
      }
      25% {
        background-position: 0 100%;
      }
      50% {
        background-position: 100% 100%;
      }
      75% {
        background-position: 100% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 200%;
      height: 200%;
      background: radial-gradient(rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
      animation: move 15s ease infinite;
      @keyframes move {
        0% {
          left: 33%;
          top: 33%;
        }
        25% {
          left: 66%;
          top: 33%;
        }
        50% {
          left: 66%;
          top: 66%;
        }
        75% {
          left: 66%;
          top: 33%;
        }
        100% {
          left: 33%;
          top: 33%;
        }
      }
    }
  `,
  Box: styled.div`
    position: relative;
    z-index: 10;
    width: 320px;
    height: 420px;
    padding: 0 50px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
  `,
  CopyRight: styled.p`
    position: relative;
    z-index: 10;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    margin-top: 30px;
    letter-spacing: 0.5px;
    b {
      color: #4880ff;
    }
  `,
  Logo: styled.div`
    width: ${() => (lang.slice(0, 2) === 'vi' ? '84px' : '78px')};
    height: ${() => (lang.slice(0, 2) === 'vi' ? '45px' : '26px')};
    background: url(${() => (lang.slice(0, 2) === 'vi' ? LogoVn : Logo)}) no-repeat center;
    background-size: cover;
    margin: 52px auto 18px;
  `,
  Title: styled.strong`
    font-size: 14px;
    color: #a0aec0;
    font-weight: 500;
    display: block;
    text-align: center;
    margin-bottom: 38px;
    letter-spacing: 0.5px;
  `,
  InputWrap: styled.div`
    position: relative;
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    transition: 0.4s;
    &.error {
      border-color: #f32427;
    }
    &:focus-within {
      border-color: #0076ff;
      &.error {
        border-color: #f32427;
      }
    }
    &:before {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.4s;
    }
    &.id {
      &:before {
        background-image: url(${IcoId});
      }
      &:focus-within {
        &:before {
          background-image: url(${IcoIdFocus});
        }
      }
    }
    &.password {
      margin-top: 8px;
      &:before {
        background-image: url(${IcoPassword});
      }
      &:focus-within {
        &:before {
          background-image: url(${IcoPasswordFocus});
        }
      }
    }
  `,
  ToggleTypeBtn: styled.button`
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #8e8ea9;
    font-size: 14px;
    font-weight: 500;
  `,
  Input: styled.input`
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    padding-left: 52px;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    &::placeholder {
      color: #8e8ea9;
    }
    &:focus {
      outline: none;
    }
  `,
  Msg: styled.p`
    height: 0;
    transition: 0.4s;
    overflow: hidden;
    color: #f32427;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    &.error {
      height: 17px;
    }
  `,
  Button: styled.button`
    width: 100%;
    height: 56px;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: #4379ee;
    margin-top: 28px;
    transition: 0.4s;
    &:hover {
      background: #6b96f3;
    }
    &:active {
      background: #2961d8;
    }
  `,
  Hr: styled.hr`
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.8);
    border-bottom: 0;
    width: 512px;
    margin: 20px 0;
  `,
  SiteTitle: styled.h1`
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
    align-items: center;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    font-family: Pretendard;
    z-index: 10;
    &:before {
      content: '';
      width: 64px;
      height: 64px;
      margin-bottom: 16px;
      background: url(${IcoSiteSelect}) no-repeat center;
    }
  `,
};

export const NotFoundSt = {
  Wrap: styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${(p) => p.theme.notFound.bg};
    color: ${(p) => p.theme.notFound.color};
    padding-top: 220px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  ImgWrap: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    //404
    > .textImg {
      position: absolute;
      top: 96px;
    }
    // 배경파일 이미지
    &::after {
      content: '';
      width: 160px;
      height: 148px;
      display: block;
      background: ${(p) => `url(${p.theme.notFound.bgImg})`};
    }
  `,
  TextWrap: styled.div`
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    > .main {
      font-weight: 500;
      font-size: 32px;
    }
    > .sub {
      display: inline-block;
      text-align: center;
      line-height: 26px;
      font-weight: 500;
      font-size: 16px;
    }
  `,
  BackBtn: styled.button`
    margin-top: 38px;
    width: 143px;
    height: 40px;
    background-color: #4379ee;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    &:hover {
      transition: 0.4s;
      background-color: #6b96f3;
    }
  `,
};

// 측량성과등록 _ 초기화면
export const SurveyInitSt = {
  PageWrap: styled.div`
    padding: 20px 40px;
    .header {
      height: 64px;
      background-color: #eef0f8;
      color: #0076ff;
      display: flex;
      align-items: center;
      padding: 0 40px;
      font-size: 18px;
      font-weight: 600;
    }
  `,
};
