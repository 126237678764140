import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PagingTableStyle, TableStyle } from '../../atoms/_style';
import Table from '../../atoms/Table';
import { ContAddBtn, DataFilterStyle, SurveyStyle } from '../_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../../store/Atoms';
import PagingTable from '../../atoms/PagingTable';
import SearchFilter from '../../atoms/SearchFilter';
import { OptionProps } from '../../atoms/_interface';
import NoData from '../../atoms/NoData';
import query from 'hooks/RQuery/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// 매니저 목록
const UserList = ({ userType, onSelectSite }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [themeMode] = useRecoilState(themeState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState<any>([]);

  const list = query.auths.useMembers({ pageNum: currentPage, pageSize: 10, auth: userType, sort: 'DESC' });

  //api 호출시 테이블 데이터 변경
  useEffect(() => {
    if (list.data) {
      setTableData(list.data.memberInfo);
      setTotalPages(list.data.page.totalPages);
    }
  }, [list.data]);

  useEffect(() => {
    list.refetch();
  }, [currentPage]);

  return (
    <SurveyStyle.DescArea>
      {tableData?.length ? (
        <PagingTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          caption={'사용자 리스트'}
          colgroup={['27%', '27%', 'auto']}
          totalPages={totalPages}
          height={'calc(100% - 147px)'}
          thead={
            <tr className={'txtLeft'}>
              <th scope={'col'}>ID</th>
              <th scope={'col'}>{t('이름')}</th>
              <th scope={'col'}>{t('생성일')}</th>
            </tr>
          }
          tbody={
            <>
              {tableData?.map((i, index) => {
                return (
                  <tr className={'txtLeft'} key={index} onClick={() => onSelectSite(i.siteId, i.siteName, i.facilityKindList)}>
                    <td>{i.loginId}</td>
                    <td>{i.name}</td>
                    <td>{i.createdDate.replace('T', ' ').split('.')[0]}</td>
                  </tr>
                );
              })}
            </>
          }
          leftBtnGroup={
            <ContAddBtn type={'button'} onClick={() => navigate(`/${userType === 'ROLE_MANAGER' ? 'manager' : 'user'}/register`)}>
              {userType === 'ROLE_MANAGER' ? t('매니저추가') : t('사용자추가')}
            </ContAddBtn>
          }
        />
      ) : (
        <>
          <ContAddBtn type={'button'} onClick={() => navigate(`/${userType === 'ROLE_MANAGER' ? 'manager' : 'user'}/register`)} style={{ width: '100%' }}>
            {userType === 'ROLE_MANAGER' ? t('매니저추가') : t('사용자추가')}
          </ContAddBtn>
          <NoData style={{ height: 'auto' }} />
        </>
      )}
    </SurveyStyle.DescArea>
  );
};

export default UserList;
