import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Survey Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Pagination, Navigation, Thumbs, Scrollbar } from 'swiper/modules';
import { PopupStyle } from './_style';
import NoImg from 'assets/images/no-img.svg';
import { escKeyModalClose } from '../../../util/global';
import { useTranslation } from 'react-i18next';

function LargeImagePopup({ pointImgList, imgData, initNum, close, isPipe }: { pointImgList?: any[]; imgData?: any; initNum?: number; close?: () => void; isPipe?: boolean }) {
  const { t } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  useEffect(() => {
    if (close) {
      window.addEventListener('keydown', (e) => escKeyModalClose(e, close));
      return () => window.removeEventListener('keydown', (e) => escKeyModalClose(e, close));
    }
  }, [close]);
  return (
    <>
      <PopupStyle.Dimmed>
        <PopupStyle.CloseBtn type={'button'} onClick={close} $top={20} $right={20}></PopupStyle.CloseBtn>
      </PopupStyle.Dimmed>
      <PopupStyle.Popup $width={800} style={{ boxShadow: 'none', background: 'none' }}>
        <Swiper thumbs={{ swiper: thumbsSwiper }} modules={[Thumbs]} className={'mainSwiper'} initialSlide={initNum}>
          {pointImgList?.map((ele, index) => (
            <SwiperSlide key={'largeImg' + ele?.num}>
              <PopupStyle.ImageTitle>
                {imgData?.ptNum} / {t(ele.value.replace(' ', ''))}
              </PopupStyle.ImageTitle>
              <PopupStyle.ImageBox>
                {Object.keys(imgData).filter((i) => i === 'item').length ? (
                  <img
                    src={
                      isPipe
                        ? imgData?.item[index < 4 ? 'stImages' : 'endImages'].filter((i) => i.imageType === ele.imageType)[0]?.imageThumbUrl.replace('_thumbnail', '') || ''
                        : imgData?.item.images?.filter((i) => i.imageType === ele.imageType)[0]?.imageThumbUrl.replace('_thumbnail', '') || ''
                    }
                    alt={t(ele?.value)}
                    onError={(e) => {
                      e.currentTarget.src = NoImg;
                      e.currentTarget.width = 140;
                    }}
                  />
                ) : (
                  <img
                    src={imgData?.data.filter((i) => i.imageType === ele.imageType)[0]?.imageUrl || ''}
                    alt={t(ele?.value)}
                    onError={(e) => {
                      e.currentTarget.src = NoImg;
                      e.currentTarget.width = 140;
                    }}
                  />
                )}
              </PopupStyle.ImageBox>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={12}
          slidesPerView={6}
          watchSlidesProgress={true}
          scrollbar={{
            hide: false,
            draggable: true,
          }}
          modules={[Scrollbar, Thumbs]}
          className={'thumbSwiper'}
          initialSlide={initNum}
        >
          {pointImgList?.map((ele, index) => (
            <SwiperSlide key={'largeImgThumb' + ele?.num}>
              <PopupStyle.ImageItem>
                {/* {imgData?.[ele?.code_thumb] && ( */}
                {Object.keys(imgData).filter((i) => i === 'item').length ? (
                  <img
                    src={isPipe ? imgData?.item[index < 4 ? 'stImages' : 'endImages'].filter((i) => i.imageType === ele.imageType)[0]?.imageThumbUrl || '' : imgData?.item.images?.filter((i) => i.imageType === ele.imageType)[0]?.imageThumbUrl || ''}
                    alt={t(ele?.value)}
                    onError={(e) => {
                      e.currentTarget.src = NoImg;
                      e.currentTarget.width = 40;
                    }}
                  />
                ) : (
                  <img
                    src={imgData?.data.filter((i) => i.imageType === ele.imageType)[0]?.imageThumbUrl || ''}
                    alt={t(ele?.value)}
                    onError={(e) => {
                      e.currentTarget.src = NoImg;
                      e.currentTarget.width = 40;
                    }}
                  />
                )}
                {/* )} */}
              </PopupStyle.ImageItem>
            </SwiperSlide>
          ))}
        </Swiper>
      </PopupStyle.Popup>
    </>
  );
}

export default LargeImagePopup;
