import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TooltipStyle = {
  Wrap: styled.div`
    background: ${(p) => p.theme.chart.tooltip.bg};
    border-radius: 4px;
    padding: 10px;
    width: 150px;
  `,
  Label: styled.p`
    color: ${(p) => p.theme.chart.tooltip.text};
    font-size: 12px;
    font-weight: 500;
  `,
  List: styled.ul`
    margin-top: 10px;
  `,
  Item: styled.li<{ $type: string }>`
    display: flex;
    align-items: center;
    height: 12px;
    color: ${(p) => p.theme.chart.tooltip.text};
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      border-width: 2px;
      border-style: solid;
      margin-right: 8px;
      border-radius: 2px;
      border-color: ${(p) => (p.$type === 'pip01' ? '#0076FF' : p.$type === 'pip02' ? '#49AEFF' : '#B0D1F1')};
      background: ${(p) => (p.$type === 'pip01' ? 'rgba(0, 118, 255, 0.15)' : p.$type === 'pip02' ? 'rgba(73, 174, 255, 0.15)' : 'rgba(231, 243, 255, 0.15)')};
    }
    span {
      margin-left: auto;
    }
  `,
};

const CustomTooltip = ({ active, payload, label, unit, all }: any) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <TooltipStyle.Wrap className="custom-tooltip">
        {all ? (
          <TooltipStyle.Label className="label">{label}</TooltipStyle.Label>
        ) : (
          <>
            <TooltipStyle.Label className="label">{payload[0].payload.date}</TooltipStyle.Label>
            <TooltipStyle.Label>
              {payload[0].payload.mopCde}/{label}mm {unit === 'm' ? t('길이') : t('수량')}
            </TooltipStyle.Label>
          </>
        )}
        <TooltipStyle.List>
          {/*{*/}
          {/*    payload.map((i:any,index:number) => {*/}
          {/*        console.log(payload)*/}
          {/*        return (*/}
          {/*            <>*/}
          {/*                <TooltipStyle.Item*/}
          {/*                    $type={i.name.includes('PIP01') ? 'pip01' : i.name.includes('PIP02') ? 'pip02' : "pip03"}*/}
          {/*                >*/}
          {/*                    {i.name.includes('PIP01') ? '직관' : i.name.includes('PIP02') ? '단관' : "이형관"}<span>{i.value}{unit}</span>*/}
          {/*                </TooltipStyle.Item>*/}
          {/*            </>*/}
          {/*        )*/}
          {/*})}*/}
          {payload.length >= 1 ? (
            <TooltipStyle.Item $type={payload[0].dataKey.includes('PIP01') ? 'pip01' : payload[0].dataKey.includes('PIP02') ? 'pip02' : 'pip03'}>
              {payload[0].dataKey.includes('PIP01') ? t('직관') : payload[0].dataKey.includes('PIP02') ? t('단관') : t('이형관')}
              <span>
                {payload[0].value}
                {t(unit)}
              </span>
            </TooltipStyle.Item>
          ) : null}
          {payload.length >= 2 ? (
            <TooltipStyle.Item $type={payload[1].dataKey.includes('PIP01') ? 'pip01' : payload[1].dataKey.includes('PIP02') ? 'pip02' : 'pip03'}>
              {payload[1].dataKey.includes('PIP01') ? t('직관') : payload[1].dataKey.includes('PIP02') ? t('단관') : t('이형관')}
              <span>
                {payload[1].value}
                {t(unit)}
              </span>
            </TooltipStyle.Item>
          ) : null}
          {payload.length >= 3 ? (
            <TooltipStyle.Item $type={payload[2].dataKey.includes('PIP01') ? 'pip01' : payload[2].dataKey.includes('PIP02') ? 'pip02' : 'pip03'}>
              {payload[2].dataKey.includes('PIP01') ? t('직관') : payload[2].dataKey.includes('PIP02') ? t('단관') : t('이형관')}
              <span>
                {payload[2].value}
                {unit}
              </span>
            </TooltipStyle.Item>
          ) : null}
        </TooltipStyle.List>
      </TooltipStyle.Wrap>
    );
  }
  return null;
};

export default CustomTooltip;
