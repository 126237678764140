import React from 'react';
import { SkeletonStyle } from './_style';
import useLoading from '../../hooks/useLoading';

interface SkeletonProps {
  headerType?: 'calendar' | 'table';
  element?: React.ReactElement;
}

const SkeletonContainer = ({ headerType, element }: SkeletonProps) => {
  const [isLoading] = useLoading();
  return (
    <>
      {isLoading ? (
        <SkeletonStyle.Wrap>
          <SkeletonStyle.Head>
            {headerType === 'calendar' ? (
              <>
                <SkeletonStyle.CalendarBtnArea className={'skeleton-item'}></SkeletonStyle.CalendarBtnArea>
                <SkeletonStyle.TitleArea className={'skeleton-item'}></SkeletonStyle.TitleArea>
                <SkeletonStyle.CalendarBtnArea className={'skeleton-item'}></SkeletonStyle.CalendarBtnArea>
              </>
            ) : headerType === 'table' ? (
              <>
                <SkeletonStyle.TitleArea className={'skeleton-item'}></SkeletonStyle.TitleArea>
                <SkeletonStyle.BtnGroup>
                  <SkeletonStyle.BtnArea className={'skeleton-item'}></SkeletonStyle.BtnArea>
                  <SkeletonStyle.BtnArea className={'skeleton-item'}></SkeletonStyle.BtnArea>
                </SkeletonStyle.BtnGroup>
              </>
            ) : (
              <SkeletonStyle.TitleArea className={'skeleton-item'}></SkeletonStyle.TitleArea>
            )}
          </SkeletonStyle.Head>
          <SkeletonStyle.Cont>{element ? element : null}</SkeletonStyle.Cont>
        </SkeletonStyle.Wrap>
      ) : null}
    </>
  );
};

export default SkeletonContainer;
