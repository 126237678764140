import React from 'react';
import { SkeletonStyle } from './_style';
import SkeletonContainer from './SkeletonContainer';
import { DashboardStyle, TableListStyle } from '../templates/_style';
import Table from '../atoms/Table';
import { TableStyle } from '../atoms/_style';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';

const SkeletonFacilityTable = () => {
  const [themeMode] = useRecoilState(themeState);
  return (
    <SkeletonContainer
      headerType={'table'}
      element={
        <>
          <DashboardStyle.DescArea className={themeMode === 'light' ? 'cScroll light' : 'cScroll'} style={{ height: '100%' }}>
            <TableListStyle.Cont>
              <TableListStyle.SelectArea>
                <TableListStyle.SelectWrap>
                  <dt>
                    <div className={'skeleton-item'} style={{ width: 42, height: 16 }}></div>
                  </dt>
                  <dd className={'skeleton-item'} style={{ width: 160, height: 36 }}></dd>
                  <dd className={'skeleton-item'} style={{ width: 105, height: 36, marginTop: 10 }}></dd>
                </TableListStyle.SelectWrap>
                <TableListStyle.SelectWrap>
                  <dt>
                    {' '}
                    <div className={'skeleton-item'} style={{ width: 42, height: 16 }}></div>
                  </dt>
                  <dd className={'skeleton-item'} style={{ width: 160, height: 36 }}></dd>
                  <dd className={'skeleton-item'} style={{ width: 105, height: 36, marginTop: 10 }}></dd>
                  <dd className={'skeleton-item'} style={{ width: 160, height: 36, marginTop: 10 }}></dd>
                  <dd className={'skeleton-item'} style={{ width: 160, height: 36, marginTop: 10 }}></dd>
                </TableListStyle.SelectWrap>
              </TableListStyle.SelectArea>
              <Table
                caption={'자재 목록'}
                colgroup={['33%', '30%', 'auto']}
                height={'calc(100% - 20px)'}
                tbodyHeight={'calc(100% - 60px)'}
                thead={
                  <tr>
                    <th scope={'col'}>
                      <div className={'skeleton-item'} style={{ width: 57, height: 16, margin: '0 auto' }}></div>
                    </th>
                    <th scope={'col'}>
                      <div className={'skeleton-item'} style={{ width: 75, height: 16, margin: '0 auto' }}></div>
                    </th>
                    <th scope={'col'}>
                      <div className={'skeleton-item'} style={{ width: 85, height: 16, margin: '0 auto' }}></div>
                    </th>
                  </tr>
                }
                tbody={
                  <>
                    <tr>
                      <td colSpan={3}>
                        <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className={'skeleton-item'} style={{ width: '100%', height: 48 }}></div>
                      </td>
                    </tr>
                  </>
                }
              />
            </TableListStyle.Cont>
          </DashboardStyle.DescArea>
        </>
      }
    />
  );
};

export default SkeletonFacilityTable;
