import Logo from '../src/assets/images/logo.svg';
import LogoLight from '../src/assets/images/logo-light.svg';
import IcoSiteInfo from '../src/assets/images/ico-site-info.svg';
import IcoSiteInfoLight from '../src/assets/images/ico-site-info-light.svg';
import IcoSort from '../src/assets/images/ico-sort.svg';
import IcoSortLight from '../src/assets/images/ico-sort-light.svg';
import IcoStatistics from '../src/assets/images/ico-total-statistics.svg';
import IcoStatisticsLight from '../src/assets/images/ico-total-statistics-light.svg';
import IcoMenuShow from '../src/assets/images/ico-menu-show.svg';
import IcoMenuShowLight from '../src/assets/images/ico-menu-show-light.svg';
import IcoMenuHide from '../src/assets/images/ico-menu-hide.svg';
import IcoMenuHideLight from '../src/assets/images/ico-menu-hide-light.svg';
import IcoPopClose from '../src/assets/images/ico-pop-close.svg';
import IcoPopCloseLight from '../src/assets/images/ico-pop-close-light.svg';
import IcoPopInfo from '../src/assets/images/ico-information.svg';
import IcoPopInfoLight from '../src/assets/images/ico-information-light.svg';
import IcoFileDown from '../src/assets/images/ico-file-down.svg';
import IcoFileDownLight from '../src/assets/images/ico-file-down-light.svg';
import IcoSelectUp from '../src/assets/images/ico-select-up.svg';
import IcoSelectUpLight from '../src/assets/images/ico-select-up-light.svg';
import IcoDiameterLength from '../src/assets/images/ico-diameter-length.svg';
import IcoDiameterLengthLight from '../src/assets/images/ico-diameter-length-light.svg';
import IcoCheckbox from 'assets/images/ico-checkbox.svg';
import IcoCheckboxDark from 'assets/images/ico-checkbox-dark.svg';
import IcoCheckboxA from 'assets/images/ico-checkbox-active.svg';
import IcoCheckboxDarkA from 'assets/images/ico-checkbox-dark-active.svg';
import IcoMore from 'assets/images/2dMap/ico-more.svg';
import IcoMoreDark from 'assets/images/2dMap/ico-more-dark.svg';
import IcoMoreA from 'assets/images/2dMap/ico-more-active.svg';
import IcoContractShow from 'assets/images/ico-construct-info-show.svg';
import IcoContractShowLight from 'assets/images/ico-construct-info-show-light.svg';
import IcoContractHide from 'assets/images/ico-construct-info-hide.svg';
import IcoContractHideLight from 'assets/images/ico-construct-info-hide-light.svg';
import bgShadow from 'assets/images/2dMap/bg-shadow.svg';
import bgShadowDark from 'assets/images/2dMap/bg-shadow-dark.svg';
import arrow from 'assets/images/2dMap/ico-arrow-left.svg';
import arrowDark from 'assets/images/2dMap/ico-arrow-left-dark.svg';
import arrowA from 'assets/images/2dMap/ico-arrow-left-active.svg';
import IcoFile from 'assets/images/ico-file.svg';
import IcoFileDark from 'assets/images/ico-file-dark.svg';
import IcoCategory from 'assets/images/ico-survey-category.svg';
import IcoCategoryDark from 'assets/images/ico-survey-category-dark.svg';
import IcoSurveyTabIcon from './assets/images/ico-survey-tab-icon.svg';
import IcoSurveyTabIconDark from './assets/images/ico-survey-tab-icon-dark.svg';
import IcoCalendar from 'assets/images/ico-calendar.svg';
import IcoCalendarW from 'assets/images/ico-calendar-white.svg';
import IcoManageAdd from 'assets/images/ico-manage-add.svg';
import IcoManageAddLight from 'assets/images/ico-manage-add-light.svg';
import IcoEdit from 'assets/images/superAdmin/ico-edit.svg';
import IcoEditW from 'assets/images/superAdmin/ico-edit-light.svg';
import IcoDummy from 'assets/images/superAdmin/ico-dummy.svg';
import IcoDummyW from 'assets/images/superAdmin/ico-dummy-light.svg';
export const light = {
  header: {
    wrap: '#fff',
    border: '#e0e0e0',
    logoImg: LogoLight,
    menuItem: '#242628',
    bottomText: '#797A7C',
    bottomBtn: '#242628',
    toggle: { show: IcoMenuShowLight, hide: IcoMenuHideLight },
  },
  container: {
    contain: '#F5F6FA',
    titleBorder: '#E0E0E0',
    title: '#242628;',
    version: '#242628',
    infoBtn: { ico: IcoSiteInfoLight, color: '#242628', border: '#D5D5D5' },
  },
  dashboard: {
    box: { border: '#DFDFDF', titleArea: '#F1F4F9', title: '#242628', cont: '#FFFFFF' },
    info: {
      title: '#797A7C',
      desc: '#242628',
      contBox: '#f4f7ff',
      contLabel: '#797a7c',
      contText: '#242628',
      icoShow: IcoContractShowLight,
      icoHide: IcoContractHideLight,
    },
    totalChart: { title: '#797A7C', percent: '#242628' },
    barChart: { label: '#797A7C', back: '#E2E4ED', percent: '#242628' },
    filter: {
      dt: '#242628',
      border: '#E0E0E0',
      dd: '#242628',
      ddActive: '#4880FF',
      bg: 'rgba(72, 128, 255, 0.15)',
      after: '#E0E0E0',
    },
    table: {
      diameter: IcoDiameterLengthLight,
      statistics: IcoStatisticsLight,
      border: '#DFDFDF',
      th: '#242628',
      td: '#242628',
      tdBg: '#F5F5F9',
      tdBottom: '#fff',
      sort: IcoSortLight,
    },
  },
  confirm: {
    bg: '#fff',
    color: '#242628',
    btnBg: '#F5F5F9',
    btnSubmitBg: '#E4ECFF',
    btnActive: '#C6D4F6',
    btnColor: '#242628',
    btnSubmitColor: '#0076FF',
    btnHoverColor: '#0076FF',
    border: '#DFDFDF',
  },
  popup: {
    head: {
      bg: '#F1F4F9',
      title: '#242628',
      border: '#DFDFDF',
      icoClose: IcoPopCloseLight,
      btnHoverColor: 'rgba(144, 144, 144, 0.35)',
      btnActiveColor: 'rgba(144, 144, 144, 0.15)',
    },
    cont: { shadow: 'none', bg: '#fff', color: '#242628' },
    foot: {
      border: '#DFDFDF',
      color: '#797A7C',
      ico: IcoPopInfoLight,
      bg: '#EEF0F8',
    },
    language: {
      bg: '#F5F5F9',
      color: '#242628',
      before: '#D2DAE8',
      activeBg: 'rgba(72, 128, 255, 0.15)',
    },
  },
  dataFilter: {
    bg: '#F1F4F9',
    border: '#DFDFDF',
    text: '#797A7C',
    tabColor: '#797A7C',
    tabActiveColor: '#242628',
  },
  chart: {
    border: '#D5D5D5',
    bg: '#fff',
    tooltip: {
      bg: 'rgba(255, 255, 255, 0.7)',
      text: '#242628',
      unit: '#9099A7',
    },
  },
  fileDown: {
    border: '#D5D5D5',
    bg: '#F1F4F9',
    color: '#242628',
    ico: IcoFileDownLight,
  },
  select: {
    selected: {
      color: '#242628',
      border: '#D5D5D5',
      bg: '#fff',
      activeIco: IcoSelectUpLight,
      activeBg: '#fff',
      activeBorder: '#0076ff',
      labelBorder: 'transparent',
    },
    item: {
      color: '#242628',
      bgHover: 'rgba(0, 118, 255, 0.15)',
      colorHover: '#0076FF',
    },
    search: {
      bg: '#D9EAFF',
    },
  },
  map: {
    setting: {
      color: '#242628',
      border: '#dfdfdf',
      bg: '#fff',
      bgImg: bgShadow,
      closeBtn: {
        border: '#dfdfdf',
        bg: '#fff',
        ico: arrow,
        icoA: arrowA,
        bgA: '#cadaf3',
      },
      tab: {
        bg: '#ECEDF4',
        color: '#9095AC',
        bgA: 'rgba(72, 127, 255, 0.15)',
      },
      divider: {
        bg: '#DFDFDF',
      },
      jobId: {
        bg: '#f5f5f9',
        border: '#DFDFDF',
      },
    },
    infoModal: {
      color: '#242628',
      bg: 'rgb(255, 255, 255, 0.88)',
      border: '#DFDFDF',
      titleBd: '#B8B8B8',
      icoMore: IcoMore,
      icoMoreA: IcoMoreA,
      btnBg: '#F5F6FA',
      btnBorder: '#D5D5D5',
      imgBg: 'rgb(235, 236, 240)',
      // closeBtn: "#242628",
      closeBtn: 'invert(8%) sepia(2%) saturate(2670%) hue-rotate(170deg) brightness(92%) contrast(83%)',
      closeBtnBg: '#CEDAF2',
      // closeBtnHover: '#0076FF',
      closeBtnHover: 'invert(46%) sepia(98%) saturate(5727%) hue-rotate(203deg) brightness(104%) contrast(103%)',
      subInfoBg: '#F6F8FD',
    },
    moveCont: {
      bg: '#fff',
      border: '#DFDFDF',
      hr: '#DFDFDF',
      input: {
        bg: '#fff',
        label: '#111',
        border: '#d5d5d5',
      },
      btn: {
        border: '#D5D5D5',
        bg: '#F1F4F9',
        color: '#242628',
        hoverBorder: '#0076FF',
        hoverColor: '#0076FF',
        hoverBg: '#D8E3FA',
      },
    },
  },
  // 사진 등록, 사진 삭제 모달 공통
  imgModal: {
    head: {
      bg: '#F1F4F9',
      color: '#242628',
      btn: IcoPopCloseLight,
      btnHover: 'rgba(144, 144, 144, 0.15)',
      btnActive: 'rgba(144, 144, 144, 0.25)',
    },
    bg: '#fff',
    border: '#DFDFDF',
    infoIco: IcoPopInfoLight,
    infoTextColor: '#767676',
    impactColor: '#0076FF',
    foot: {
      bg: '#fff',
      border: '#DFDFDF',
      btnBg: '#F1F4F9',
      btnBd: '#D5D5D5',
      btnFullBg: '#DFDFDF',
    },
  },
  imgAddModal: {
    boxBg: '#E5F1FF',
    boxHoverBg: '#daebff',
    btnWrapBg: '#fff',
    btnBg: '#F1F4F9',
    btnBdr: '#D5D5D5',
  },
  imgDelModal: {
    btnBg: '#F5F5F9',
    btnHoverBg: '#E4ECFF',
    btnHoverColor: '#0076FF',
  },
  drag_drop: {
    boxBg: '#E5F1FF',
    boxHoverBg: '#daebff',
    subTextColor: '#222',
  },
  helpModal: {
    box: {
      bg: '#fff',
      border: '#d5d5d5',
    },
    header: {
      bg: '#fafbff',
      border: '#d5d5d5',
      color: '#000',
    },
    cont: {
      bg: '#fff',
      border: '#dfdfdf',
      color: '#242628',
    },
  },
  delModal: {
    color: '#242628',
    mapBd: '#D9D9D9',
    list: {
      border: '#D5D5D5',
      divider: '#E0E0E0',
      header: {
        bg: '#FAFBFF',
      },
      cont: {
        bg: '#FFFFFF',
      },
    },
  },
  delModalDate: {
    color: '#242628',
    border: '#D5D5D5',
    type: {
      bg: '#FAFBFF',
    },
    date: {
      bg: '#ffffff',
      ico: IcoCalendar,
    },
    list: {
      header: {
        bg: '#FAFBFF',
      },
      cont: {
        bg: '#ffffff',
      },
    },
  },
  notFound: {
    bg: '#EEF0F8',
    color: '#242628',
    bgImg: IcoFile,
  },
  checkbox: {
    ico: IcoCheckbox,
    activeIco: IcoCheckboxA,
  },
  report: {
    text: '#242628',
    photoGroupBg: '#EEF0F8',
    infoHead: '#F4F7FF',
    photoBox: '#fff',
    shadow: 'rgba(0,0,0,.05)',
    pagination: '#BBC4D4',
    saveBtn: '#D5D5D5',
    null: '#CCD0DE',
  },
  button: {
    type1: {
      default: {
        border: '#D5D5D5',
        bg: '#F1F4F9',
        color: '#242628',
      },
      active: { border: '#0076FF', bg: 'rgba(72, 128, 255, 0.5)', color: '#242628' },
      disabled: { border: '#D5D5D5', bg: '#E8EBF1', color: '#9095AC' },
    },
    type2: {
      disabled: { bg: '#E8EBF1', color: '#9095AC' },
    },
    greyLineToRed: {
      bg: '#fff',
      border: '#D5D5D5',
      color: '#242628',
      hoverBorder: '#F01A1A',
      hoverBg: 'rgba(240, 26, 26, 0.15)',
      hoverColor: '#f01a1a',
      hoverShadow: 'rgba(240, 26, 26, 0.2)',
    },
  },
  survey: {
    bg: '#fff',
    filter: '#f1f4f9',
    border: '#dfdfdf',
    text: '#797a7c',
    header: {
      border: '#dfdfdf',
      bg: '#fff',
      btnBorder: '#d5d5d5',
      btnColor: '#242628',
    },
    category: {
      area: {
        border: '#d9d9d9',
      },
      item: {
        bg: '#faf9fe',
        border: '#dfdfdf',
        color: '#767676',
        activeBg: '#fff',
        ico: IcoCategory,
      },
      btn: {
        border: '#d5d5d5',
        bg: '#fafbfd',
        arrow: '#202224',
      },
    },
    tab: { border: '#d9d9d9', color: '#767676', activeColor: '#242628', ico: IcoSurveyTabIcon },
    tabFilter: { border: '#979797', color: '#767676' },
    popFilter: {
      ico: '#94949A',
      icoActive: '#4379EE',
      bg: '#fff',
      shadow: '0px 13px 61px 0px rgba(169, 169, 169, 0.37)',
    },
  },
  input: {
    bg: '#fff',
    border: '#d5d5d5',
    color: '#242628',
  },
  pagingTable: {
    table: {
      thBg: '#fafbff',
      thColor: '#242628',
      thBgType2: '#eff3ff',
      tdBg: '#fff',
      tdColor: '#242628',
      border: '#d5d5d5',
      hoverTdBg: '#f5f7ff',
    },
    pagination: {
      color: '#a3a3a3',
      activeColor: '#242628',
      activeBg: '#fafafa',
      arrowBg: '#fff',
      arrow: '#a3a3a3',
      arrowHoverBg: '#fdfdfd',
      arrowHoverBorder: '#a3a3a3',
      arrowActiveBorder: '#242628',
    },
    image: '#dfdfdf',
  },
  manageBtn: IcoManageAddLight,
  form: {
    head: {
      bg: '#F1F4F9',
      title: '#242628',
    },
    cont: {
      bg: '#fff',
      border: '#DFDFDF',
      dashed: '#b7b7b7',
      navi: {
        border: '#dfdfdf',
        text: '#767676',
      },
      facility: {
        bg: '#fff',
        border: '#D5D5D5',
        siteName: '#242628',
      },
      stepBtn: {
        disabled: {
          bg: '#E8EBF1',
          color: '#9095AC',
        },
      },
      picker: {
        fromTo: '#242628',
      },
    },
  },
  company: {
    card: {
      bg: '#F1F4F9',
      border: '#DFDFDF',
      img: '#DFE2E8',
      dummy: IcoDummyW,
      edit: {
        bg: '#FFFFFF',
        border: '#DFDFDF',
        icon: IcoEditW,
      },
      name: '#242628',
      text: '#767676',
    },
  },
  surveyDown: {
    bg: '#fff',
    border: '#D5D5D5',
  },
  design: {
    contInfo: {
      border: '#D5D5D5',
      text: '#000000',
    },
  },
};

export const dark = {
  header: {
    wrap: '#273142',
    border: 'rgba(224, 224, 224, .21)',
    logoImg: Logo,
    menuItem: '#fff',
    bottomText: '#fff',
    bottomBtn: '#fff',
    toggle: { show: IcoMenuShow, hide: IcoMenuHide },
  },
  container: {
    contain: '#1c2531',
    titleBorder: '#333E4F',
    title: '#fff',
    version: '#fff',
    infoBtn: { ico: IcoSiteInfo, color: '#fff', border: '#444e5d' },
  },
  dashboard: {
    box: { border: '#323d4e', titleArea: '#323d4e', title: '#fff', cont: '#273142' },
    info: {
      title: '#A0AEC0',
      desc: '#fff',
      contBox: '#323D4E',
      contLabel: '#A0AEC0',
      contText: '#fff',
      icoShow: IcoContractShow,
      icoHide: IcoContractHide,
    },
    totalChart: { title: '#A0AEC0', percent: '#fff' },
    barChart: { label: '#A0AEC0', back: '#323D4E', percent: '#fff' },
    filter: {
      dt: '#fff',
      border: '#323d4e',
      dd: '#888',
      ddActive: '#4880ff',
      bg: 'rgba(72, 128, 255, 0.15)',
      after: '#323d4e',
    },
    table: {
      diameter: IcoDiameterLength,
      statistics: IcoStatistics,
      border: '#323d4e',
      th: '#fff',
      td: '#fff',
      tdBg: '#323D4e',
      tdBottom: '#1c2531',
      sort: IcoSort,
    },
  },
  confirm: {
    bg: '#1C2431',
    color: '#fff',
    btnBg: '#233250',
    btnSubmitBg: '#233250',
    btnActive: '#1B2431',
    btnColor: '#fff',
    btnSubmitColor: '#fff',
    btnHoverColor: '#0076FF',
    border: '#313d4f',
  },
  popup: {
    head: {
      bg: '#323D4E',
      title: '#fff',
      border: '#313D4F',
      icoClose: IcoPopClose,
      btnHoverColor: 'rgba(0, 118, 255, 0.25)',
      btnActiveColor: 'rgba(0, 118, 255, 0.15)',
    },
    cont: { shadow: '0 3px 5px rgba(0,0,0,.35)', bg: '#273142', color: '#fff' },
    foot: {
      border: '#313D4F',
      color: '#A0AEC0',
      ico: IcoPopInfo,
      bg: '#273142',
    },
    language: {
      bg: '#323d4e',
      color: '#fff',
      before: '#5B677A',
      activeBg: '#233250',
    },
  },
  dataFilter: {
    bg: '#1C2431',
    border: '#313D4F',
    text: '#a0aec0',
    tabColor: '#a0aec0',
    tabActiveColor: '#fff',
  },
  chart: {
    border: '#313D4F',
    bg: '#1C2431',
    tooltip: {
      bg: 'rgba(14, 14, 18, 0.9)',
      text: '#fff',
      unit: '#586A87',
    },
  },
  fileDown: {
    border: '#313d4f',
    bg: '#1c2431',
    color: '#fff',
    ico: IcoFileDown,
  },
  select: {
    selected: {
      color: '#94949a',
      border: '#313D4F',
      bg: '#323D4E',
      activeIco: IcoSelectUp,
      activeBg: '#323d4e',
      activeBorder: '#758294',
      labelBorder: '#273142',
    },
    item: {
      color: '#fff',
      bgHover: '#273142',
      colorHover: '#0076ff',
    },
    search: {
      bg: '#273142',
    },
  },
  map: {
    setting: {
      color: '#fff',
      border: '#313D4F',
      bg: '#273142',
      bgImg: bgShadowDark,
      closeBtn: {
        border: '#313D4F',
        bg: '#273142',
        ico: arrowDark,
        icoA: arrowA,
        bgA: '#213C6A',
      },
      tab: {
        bg: '#1B2431',
        color: '#fff',
        bgA: 'rgba(72, 128, 255,0.15)',
      },
      divider: {
        bg: '#313D4F',
      },
      jobId: {
        bg: '#323D4E',
        border: 'rgba(207, 207, 207, 0.11)',
      },
    },
    infoModal: {
      color: '#fff',
      bg: 'rgba(28, 36, 49, 0.88)',
      border: '#313D4F',
      titleBd: 'rgba(255,255,255,0.3)',
      icoMore: IcoMoreDark,
      icoMoreA: IcoMoreA,
      btnBg: '#323D4E',
      btnBorder: '#656577',
      imgBg: 'rgba(18, 18, 25, 0.88)',
      // closeBtn: "#D9D9D9",
      closeBtn: 'invert(100%) sepia(2%) saturate(140%) hue-rotate(269deg) brightness(105%) contrast(70%)',
      closeBtnBg: '#1F314D',
      // closeBtnHover: '#0076FF',
      closeBtnHover: 'invert(46%) sepia(98%) saturate(5727%) hue-rotate(203deg) brightness(104%) contrast(103%)',
      subInfoBg: '#1C2431',
    },
    moveCont: {
      bg: '#273142',
      border: '#313d4f',
      hr: '#313d4f',
      input: {
        bg: '#323d4e',
        label: '#fff',
        border: '#313D4F',
      },
      btn: {
        border: '#313d4f',
        bg: '#1c2431',
        color: '#fff',
        hoverBorder: '#0076ff',
        hoverColor: '#0076ff',
        hoverBg: '#233250',
      },
    },
  },
  imgModal: {
    head: {
      bg: '#323D4E',
      color: '#fff',
      btn: IcoPopClose,
      btnHover: 'rgba(0, 118, 255, 0.15)',
      btnActive: 'rgba(0, 118, 255, 0.25)',
    },
    bg: '#1C2431',
    border: '#313d4f',
    infoIco: IcoPopInfo,
    infoTextColor: '#A0AEC0',
    impactColor: '#A0AEC0',
    foot: {
      bg: '#1C2431',
      border: '#313d4f',
      btnBg: '#1C2431',
      btnBd: '#313D4F',
      btnFullBg: '#233250',
    },
  },
  imgAddModal: {
    boxBg: '#E5F1FF',
    boxHoverBg: '#daebff',
    btnWrapBg: '#192e4b',
    btnBg: '#192e4b',
    btnBdr: '#313d4f',
  },
  imgDelModal: {
    btnBg: '#233250',
    btnHoverBg: '#233250',
    btnHoverColor: '#0076FF',
  },
  drag_drop: {
    boxBg: '#192e4b',
    boxHoverBg: '#233e64',
    subTextColor: '#fff',
  },
  helpModal: {
    box: {
      bg: '#1C2431',
      border: '#313D4F',
    },
    header: {
      bg: '#273142',
      border: '#313D4F',
      color: '#fff',
    },
    cont: {
      bg: '#1C2431',
      border: '#313D4F',
      color: '#fff',
    },
  },
  delModal: {
    color: '#fff',
    mapBd: '#D9D9D9',
    list: {
      border: '#313D4F',
      divider: '#444E5D',
      header: {
        bg: '#323D4E',
      },
      cont: {
        bg: '#273142',
      },
    },
  },
  delModalDate: {
    color: '#fff',
    border: '#313D4F',
    type: {
      bg: '#1C2431',
    },
    date: {
      bg: '#323D4E',
      ico: IcoCalendarW,
    },
    list: {
      header: {
        bg: '#1C2431',
      },
      cont: {
        bg: '#273142',
      },
    },
  },
  notFound: {
    bg: '#1C2431',
    color: '#fff',
    bgImg: IcoFileDark,
  },
  checkbox: {
    ico: IcoCheckboxDark,
    activeIco: IcoCheckboxDarkA,
  },
  report: {
    text: '#fff',
    photoGroupBg: '#1C2431',
    infoHead: '#313D4F',
    photoBox: '#000',
    shadow: 'rgba(0,0,0,.1)',
    pagination: '#3A4961',
    saveBtn: '#444E5D',
    null: '#273142',
  },
  button: {
    type1: {
      default: {
        border: '#444E5D',
        bg: '#323D4E',
        color: '#fff',
      },
      active: { border: '#4880FF', bg: 'rgba(72, 128, 255, 0.5)' },
      disabled: { border: 'transparent', bg: '#1C2431', color: '#758292' },
    },
    type2: {
      disabled: { bg: '#293140', color: '#758292' },
    },
    greyLineToRed: {
      bg: '#273142',
      border: '#4A576B',
      color: '#fff',
      hoverBorder: '#F01A1A',
      hoverBg: 'rgba(240, 26, 26, 0.15)',
      hoverColor: '#f01a1a',
      hoverShadow: 'rgba(240, 26, 26, 0.2)',
    },
  },
  survey: {
    bg: '#273142',
    filter: '#1C2431',
    border: '#313D4F',
    text: '#A0AEC0',
    header: {
      border: '#313D4F',
      bg: '#202837',
      btnBorder: '#444E5D',
      btnColor: '#fff',
    },
    category: {
      area: {
        border: '#313D4F',
      },
      item: {
        bg: '#1C2431',
        border: '#313D4F',
        color: '#A0AEC0',
        activeBg: '#273142',
        ico: IcoCategoryDark,
      },
      btn: {
        border: '#313D4F',
        bg: '#1C2431',
        arrow: '#C5C9CE',
      },
    },
    tab: { border: '#313D4F', color: '#A0AEC0', activeColor: '#fff', ico: IcoSurveyTabIconDark },
    tabFilter: { border: '#4A576B', color: '#A0AEC0' },
    popFilter: {
      ico: '#A0AEC0',
      icoActive: '#4379EE',
      bg: '#323D4E',
      shadow: 'none',
    },
  },
  input: {
    bg: '#323D4E',
    border: '#313D4F',
    color: '#fff',
  },
  pagingTable: {
    table: {
      thBg: '#323D4E',
      thColor: '#fff',
      thBgType2: '#202a3e',
      tdBg: '#273142',
      tdColor: '#fff',
      border: '#444E5D',
      hoverTdBg: '#2a364e',
    },
    pagination: {
      color: '#a3a3a3',
      activeColor: '#fff',
      activeBg: '#4B596F',
      arrowBg: '#323D4E',
      arrow: '#A3A3A3',
      arrowHoverBg: '#323D4E',
      arrowHoverBorder: '#475365',
      arrowActiveBorder: '#fff',
    },
    image: '#111111',
  },
  manageBtn: IcoManageAdd,
  form: {
    head: {
      bg: '#323d4e',
      title: '#fff',
    },
    cont: {
      bg: '#273142',
      border: 'transparent',
      dashed: '#4B5A71',
      navi: {
        border: '#313d4f',
        text: '#a0aec0',
      },
      facility: {
        bg: '#273142',
        border: '#313d4f',
        siteName: '#fff',
      },
      stepBtn: {
        disabled: {
          bg: '#1c2431',
          color: '#9095AC',
        },
      },
      picker: {
        fromTo: '#fff',
      },
    },
  },
  company: {
    card: {
      bg: '#323d4e',
      border: '#4a576b',
      img: '#273142',
      dummy: IcoDummy,
      edit: {
        bg: '#293643',
        border: 'rgba(255, 255, 255, 0.2)',
        icon: IcoEdit,
      },
      name: '#fff',
      text: '#a0aec0',
    },
  },
  surveyDown: {
    bg: '#273142',
    border: '#313d4f',
  },
  design: {
    contInfo: {
      border: '#313d4f',
      text: '#fff',
    },
  },
};
