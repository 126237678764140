import Resizer from 'react-image-file-resizer';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { SetterOrUpdater } from 'recoil';

export const resizeFile = (file: File) =>
  new Promise((res) => {
    Resizer.imageFileResizer(
      file, // target file
      700, // maxWidth
      700, // maxHeight
      file.type === 'image/png' ? 'png' : 'jpeg',
      80, // quality : 0 and 100. Used for the JPEG compression
      0, // rotation
      (uri) => res(uri), // responseUriFunc
      'file', // outputType : Can be either base64, blob or file.(Default type is base64)
    );
  });

export const handleAddImg = async (e: ChangeEvent<HTMLInputElement>, setState: Dispatch<SetStateAction<File[]>>) => {
  const target = e.target.files;

  if (target && target.length) {
    const newFiles: File[] = Array.from(target);
    newFiles.map(async (file) => {
      try {
        const compressedFile = (await resizeFile(file)) as File;
        setState((prev) => [...prev, compressedFile]);
        console.log(compressedFile);
      } catch (err) {
        window.alert('사진을 불러올 수 없습니다.');
        throw err;
      }
    });
  }
};

export const dragImageResize = async (list: File[]) => {
  if (list.length) {
    const newFiles: File[] = Array.from(list);
    const newArr: File[] = [];

    const promises = newFiles.map(async (file) => {
      try {
        // 수정 후: 55kb 넘는 이미지만 리사이징
        if (file?.size < 55 * 1000) {
          console.log(file);
          newArr.push(file);
        } else {
          const compressedFile = (await resizeFile(file)) as File;
          // 변환한 파일이 4kb 미만일 시 한번 더 시도
          if (compressedFile.size < 4 * 1000) {
            const retryFile = (await resizeFile(file)) as File;
            newArr.push(retryFile);
          } else {
            newArr.push(compressedFile);
          }
        }
        // 기존: 모든 이미지 리사이징
        //   const compressedFile = (await resizeFile(file)) as File;
        //   newArr.push(compressedFile);
      } catch (err) {
        window.alert('사진을 불러올 수 없습니다.');
        throw err;
      } finally {
      }
    });
    await Promise.all(promises);
    return newArr;
  }
};

export const division = (array, divide) => {
  const length = array.length;
  const count = Math.floor(length / divide) + (Math.floor(length % divide) > 0 ? 1 : 0);
  const temp: any = [];
  for (let i = 0; i < count; i++) {
    temp.push(array.splice(0, divide));
  }
  return temp;
};
