import { Link, Outlet, RouterProvider, createBrowserRouter, redirect, useLocation } from 'react-router-dom';
import Container from 'components/templates/Container';
import Dashboard from 'pages/Dashboard';
import Report from 'pages/Report';
import Map from 'pages/Map';
//test
import { useEffect, useState } from 'react';
import useLoading from './hooks/useLoading';
import useAlert from './hooks/useAlert';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loaderState, progressState, themeState } from 'store/Atoms';
import { ThemeProvider } from 'styled-components';
import { dark, light } from './Theme';
import Login from './pages/Login';
import Loading from './components/templates/Loading';
import NotFound from 'pages/NotFound';
import ExportPdf from 'components/templates/pdf/ExportPdf';
import Survey from './pages/survey/Survey';
import LoadingBar from 'components/atoms/LoadingBar';
import { popupAddSite } from 'store/popupAtoms';
import PopupAddSite from 'components/templates/survey/popup/PopupAddSite';
import SurveyDetail from './pages/survey/SurveyDetail';
import CompanyList from './pages/superAdmin/Company';
import WorkPlaceRegister from './pages/admin/WorkPlaceRegister';
import CompanyRegister from './pages/superAdmin/CompanyRegister';
import CompanyDetail from './pages/superAdmin/CompanyDetail';
import UserRegister from './pages/admin/UserRegister';
import WorkPlace from './pages/admin/WorkPlace';
import User from './pages/admin/User';
import WorkPlaceDetail from './pages/admin/WorkPlaceDetail';
import Design from './pages/admin/Design';
import DesignRegister from './pages/admin/DesignRegister';

// import Fabric from './util/fabric';
import { onExportPhotoRegister } from './util/photoRegister/exportPhotoRegister';
import Pin from './pages/smartpin/Pin';
import PinRegister from './pages/smartpin/PinRegister';

function App() {
  const { isOpen: popupAddSiteOpen } = useRecoilValue(popupAddSite);
  const { show: loaderShow } = useRecoilValue(loaderState);
  const { show: progressShow } = useRecoilValue(progressState);

  const [isLoading] = useLoading();
  const { alertInfo, onAlertClose } = useAlert();
  // const isSuccessAll = true;
  const fixedTheme = sessionStorage.getItem('theme'); // 세션에 최근 저장된 테마값
  const [themeMode, setThemeMode] = useRecoilState(themeState);
  const theme = themeMode === 'dark' ? dark : light;

  // 세션값에 맞게 테마 셋팅
  useEffect(() => {
    if (fixedTheme) {
      setThemeMode(fixedTheme);
    }
  }, [fixedTheme]);
  const onToggleTheme = () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light');
    sessionStorage.setItem('theme', themeMode === 'light' ? 'dark' : 'light');
  };
  // console.log("loaderShow", loaderShow);

  // Nested Router 사용!
  const routers = createBrowserRouter(
    [
      {
        path: '/',
        element: sessionStorage.getItem('token') ? <Container onToggleTheme={onToggleTheme} /> : <Login />, // -> <> <Header/> <Container/> </>
        errorElement: <NotFound />,
        children:
          //super admin
          sessionStorage.getItem('auth') === 'ROLE_MASTER'
            ? [
                {
                  index: true,
                  loader: async () => redirect('/auth/list'),
                  element: <NotFound />,
                },
                {
                  path: 'auth',
                  element: null,
                  children: [
                    { path: 'list', element: <CompanyList /> },
                    { path: 'detail/:id/info', element: <CompanyDetail /> },
                    { path: 'detail/:id/members', element: <CompanyDetail /> },
                    { path: 'register', element: <CompanyRegister /> },
                  ],
                },
              ]
            : //admin
              sessionStorage.getItem('auth') === 'ROLE_ADMIN'
              ? [
                  {
                    index: true,
                    loader: async () => redirect('/dashboard'),
                    element: <NotFound />,
                  },
                  {
                    path: 'map',
                    element: <Map />,
                  },

                  {
                    path: 'dashboard',
                    element: <Dashboard />,
                  },
                  {
                    path: 'report',
                    element: <Report />,
                  },
                  {
                    path: 'export',
                    element: <ExportPdf />,
                  },
                  {
                    path: 'survey',
                    element: null,
                    children: [
                      { path: 'list', element: <Survey /> },
                      { path: 'detail', element: <SurveyDetail /> },
                    ],
                  },
                  {
                    path: 'workplace',
                    element: null,
                    children: [
                      { path: 'list', element: <WorkPlace /> },
                      { path: 'detail/:id/info', element: <WorkPlaceDetail /> },
                      { path: 'detail/:id/owner', element: <WorkPlaceDetail /> },
                      { path: 'detail/:id/contractor', element: <WorkPlaceDetail /> },
                      { path: 'detail/:id/subContractor', element: <WorkPlaceDetail /> },
                      { path: 'register', element: <WorkPlaceRegister /> },
                    ],
                  },
                  {
                    path: 'manager',
                    element: null,
                    children: [
                      { path: 'list', element: <User userType={'ROLE_MANAGER'} /> },
                      { path: 'register', element: <UserRegister userType={'ROLE_MANAGER'} /> },
                    ],
                  },
                  {
                    path: 'user',
                    element: null,
                    children: [
                      { path: 'list', element: <User userType={'ROLE_USER'} /> },
                      { path: 'register', element: <UserRegister userType={'ROLE_USER'} /> },
                    ],
                  },
                  {
                    path: 'design',
                    element: null,
                    children: [
                      { path: 'list', element: <Design /> },
                      { path: 'register', element: <DesignRegister /> },
                    ],
                  },
                  {
                    path: 'pin',
                    element: null,
                    children: [
                      { path: 'list', element: <Pin /> },
                      { path: 'register', element: <PinRegister /> },
                    ],
                  },
                ]
              : sessionStorage.getItem('auth') === 'ROLE_MANAGER'
                ? [
                    {
                      index: true,
                      loader: async () => redirect('/dashboard'),
                      element: <NotFound />,
                    },
                    {
                      path: 'map',
                      element: <Map />,
                    },

                    {
                      path: 'dashboard',
                      element: <Dashboard />,
                    },
                    {
                      path: 'report',
                      element: <Report />,
                    },
                    {
                      path: 'export',
                      element: <ExportPdf />,
                    },
                    {
                      path: 'survey',
                      element: null,
                      children: [
                        { path: 'list', element: <Survey /> },
                        { path: 'detail', element: <SurveyDetail /> },
                      ],
                    },

                    {
                      path: 'workplace',
                      element: null,
                      children: [
                        { path: 'list', element: <WorkPlace /> },
                        { path: 'detail/:id/info', element: <WorkPlaceDetail /> },
                        { path: 'detail/:id/owner', element: <WorkPlaceDetail /> },
                        { path: 'detail/:id/contractor', element: <WorkPlaceDetail /> },
                        { path: 'detail/:id/subContractor', element: <WorkPlaceDetail /> },
                        { path: 'register', element: <WorkPlaceRegister /> },
                      ],
                    },
                    {
                      path: 'manager',
                      element: null,
                      children: [
                        { path: 'list', element: <User userType={'ROLE_MANAGER'} /> },
                        { path: 'register', element: <UserRegister userType={'ROLE_MANAGER'} /> },
                      ],
                    },
                    {
                      path: 'user',
                      element: null,
                      children: [
                        { path: 'list', element: <User userType={'ROLE_USER'} /> },
                        { path: 'register', element: <UserRegister userType={'ROLE_USER'} /> },
                      ],
                    },
                  ]
                : sessionStorage.getItem('auth') === 'ROLE_USER'
                  ? [
                      {
                        index: true,
                        loader: async () => redirect('/dashboard'),
                        element: <NotFound />,
                      },
                      {
                        path: 'map',
                        element: <Map />,
                      },
                      {
                        path: 'dashboard',
                        element: <Dashboard />,
                      },
                      {
                        path: 'report',
                        element: <Report />,
                      },
                      {
                        path: 'survey',
                        element: null,
                        children: [
                          { path: 'list', element: <Survey /> },
                          { path: 'detail', element: <SurveyDetail /> },
                          { path: 'register', element: <WorkPlaceRegister /> },
                        ],
                      },
                    ]
                  : [
                      {
                        index: true,
                        loader: async () => redirect('/dashboard'),
                        element: <NotFound />,
                      },
                      {
                        path: 'map',
                        element: <Map />,
                      },

                      {
                        path: 'dashboard',
                        element: <Dashboard />,
                      },
                      {
                        path: 'report',
                        element: <Report />,
                      },
                    ],
      },
    ],
    { basename: '/' },
  );

  return (
    <>
      {/* onAlertOpen 이용시 */}
      {alertInfo.active ? (
        <div>
          <button type={'button'} onClick={onAlertClose}>
            얼럿 닫기
          </button>
          <p>얼럿제목:{alertInfo.title}</p>
          <p>얼럿내용:{alertInfo.desc}</p>
          <p>얼럿타입:{alertInfo.type}</p>
        </div>
      ) : null}

      {/* Theme */}
      <ThemeProvider theme={theme}>
        {/* react-query API 요청 미완료시 Loader */}
        {loaderShow ? <Loading /> : null}

        {/* 파일 업로드 progressBar  */}
        {progressShow && <LoadingBar />}

        {/* 현장 등록 팝업 - 측량성과등록 개발자용 */}
        {popupAddSiteOpen && <PopupAddSite />}

        {/* 라우터 */}
        <RouterProvider router={routers} />
      </ThemeProvider>
      {/* <Fabric url={require('../src/assets/images/img-login.png')} /> */}
    </>
  );
}

export default App;
