import React, { useState } from 'react';
import { ImageTempStyle } from '../_style';
import useMutateHook from '../../../hooks/RQuery/useMutateHook';
import ImgAddPopup from '../2dMap/ImgAddPopup';
import LargeImagePopup from '../popup/LargeImagePopup';
import { pipePointImgList, pointImgList, vmfImgList } from '../../../util/global';
import ImgDeletePopup from '../2dMap/ImgDeletePopup';
import { useTranslation } from 'react-i18next';

const SurveyImageTemp = ({ activeCategory, siteInfo, ptNum, id, src, typeNum, refetchApi, item, isPipe = false }) => {
  const [openLarge, setOpenLarge] = useState(false); // 사진 크게보기
  const { t } = useTranslation();
  const [openAddPop, setOpenAdd] = useState(false); // 사진 등록 팝업
  const [openDelete, setOpenDelete] = useState(false); // 사진 삭제 팝업
  const [imgNum, setImgNum] = useState<number>(0); // 선택한 이미지 번호 1,2,3,4
  const isVMF = item?.pitCde === 'PIT006' || item?.pitCde === 'PIT007' || item?.pitCde === 'PIT008';

  function getImageId(): string | null {
    if (isPipe) {
      if (typeNum === 1) return item.stImages.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageId;
      if (typeNum === 2) return item.stImages.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageId;
      if (typeNum === 3) return item.stImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageId;
      if (typeNum === 4) return item.stImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageId;
      if (typeNum === 5) return item.endImages.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageId;
      if (typeNum === 6) return item.endImages.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageId;
      if (typeNum === 7) return item.endImages.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageId;
      if (typeNum === 8) return item.endImages.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageId;
    } else {
      if (typeNum === 1) return item.images.filter((i) => i.imageType === 'NEAR_IMAGE')[0]?.imageId;
      if (typeNum === 2) return item.images.filter((i) => i.imageType === 'DISTANT_IMAGE')[0]?.imageId;
      if (typeNum === 3) return item.images.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0]?.imageId;
      if (typeNum === 4) return item.images.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0]?.imageId;
    }
    return null;
  }
  const getPitCde = () => {
    if (isPipe) {
      if (typeNum === 1 || typeNum === 2 || typeNum === 3 || typeNum === 4) {
        return item.stCoordinate.pitCde;
      } else {
        return item.endCoordinate.pitCde;
      }
    }
  };
  return (
    <>
      {/* 이미지 크게보기 */}
      {openLarge && <LargeImagePopup pointImgList={isPipe ? pipePointImgList : isVMF ? vmfImgList : pointImgList} imgData={{ ptNum: ptNum, item: item }} initNum={typeNum - 1} close={() => setOpenLarge(false)} isPipe={isPipe} />}

      {/* 사진 등록 팝업 */}
      {openAddPop && <ImgAddPopup activeCategory={activeCategory} siteInfo={siteInfo} pointId={id} ptNum={ptNum} imgNum={typeNum} close={() => setOpenAdd(false)} refetchApi={refetchApi} pitCde={isPipe ? getPitCde() : item.pitCde} />}

      {/* 삭제하기 팝업 */}
      {openDelete && <ImgDeletePopup imageId={getImageId()} imgNum={typeNum} close={() => setOpenDelete(false)} refetchApi={refetchApi} />}

      <ImageTempStyle.Wrap>
        {src ? (
          isPipe ? (
            <ImageTempStyle.Image
              src={src}
              alt={
                typeNum === 1
                  ? t('시점근경')
                  : typeNum === 2
                    ? t('시점원경')
                    : typeNum === 3
                      ? t('시점지반고근경')
                      : typeNum === 4
                        ? t('시점지반고원경')
                        : typeNum === 5
                          ? t('종점근경')
                          : typeNum === 6
                            ? t('종점원경')
                            : typeNum === 7
                              ? t('종점지반고근경')
                              : t('종점지반고원경')
              }
            />
          ) : (
            <ImageTempStyle.Image src={src} alt={typeNum === 1 ? t('근경') : typeNum === 2 ? t('원경') : typeNum === 3 ? t('지반고근경') : t('지반고원경')} />
          )
        ) : null}
        {src ? (
          <ImageTempStyle.HoverBox className={'hoverBox'}>
            <ImageTempStyle.Btn
              className="icoView"
              onClick={() => {
                setOpenLarge(true);
              }}
            ></ImageTempStyle.Btn>
            <ImageTempStyle.Btn
              className="icoDelete"
              onClick={() => {
                setOpenDelete(true);
              }}
            ></ImageTempStyle.Btn>
          </ImageTempStyle.HoverBox>
        ) : (
          <ImageTempStyle.EmptyBox>
            <ImageTempStyle.Btn
              className="icoAdd"
              onClick={() => {
                setOpenAdd(true);
              }}
            ></ImageTempStyle.Btn>
          </ImageTempStyle.EmptyBox>
        )}
      </ImageTempStyle.Wrap>
    </>
  );
};

export default SurveyImageTemp;
