import React, { useRef, useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { confirmOneBtn, popupFailState } from 'store/popupAtoms';
import { SurveyInitSt } from '../_style';
import { FILE_TYPES_PROPS } from 'util/global';
import { DragDropBoxSt } from '../drag&drop/_style';
import query from '../../../hooks/RQuery/utils';
import PopupFail from '../survey/popup/PopupFail';

// 페이지 내의 dnd ( Not for popup )
// multiple = false
//
function DesignUpload({ height, acceptType, multiple, mainText, subText, siteId, facilityKind }: { height?: number; acceptType?: string[]; multiple?: boolean; mainText?: string; subText?: string; siteId?: string; facilityKind?: string }) {
  const { t, i18n } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [popFail, setPopFail] = useRecoilState(popupFailState); // 미지원파일 에러 팝업
  const [confirmOneBtnState, setConfirmOneBtn] = useRecoilState(confirmOneBtn); // 경고문구 팝업
  const { mutate: mutateDesign } = query.designs.useUploadDesign({ siteId, facilityKind, file: file });

  const [isDragging, setIsDragging] = useState(false); //드래그중 스타일
  // input element 초기화 (연속으로 같은 value 인식 못하는 부분 해결)
  const clearInput = () => {
    if (inputRef?.current) inputRef.current.value = '';
  };

  // 파일선택 버튼 클릭
  const onClickInput = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };
  // 드래그 앤 드롭
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
      setIsDragging(true);
    }
  };

  // 1) 드래그 앤 드롭 완료 함수
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    let files = e.dataTransfer.files || [];
    setFile(files[0]);
    console.log(files[0].name.includes('dxf'));
    if (files[0].name.includes('.dxf')) {
      mutateDesign({
        siteId,
        facilityKind,
        file: files[0],
      });
    } else {
      setPopFail((prev) => ({
        ...prev,
        isOpen: true,
        fileName: files[0].name,
      }));
    }
    // 초기화
    clearInput();
  };

  // 2) input onchange 이벤트 완료 함수
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //  const { files } = e.target;
    //  console.log(files);

    let files = e.target.files || [];
    setFile(files[0]);
    mutateDesign({
      siteId,
      facilityKind,
      file: files[0],
    });
    // 초기화
    clearInput();
  };

  // [공통] 파일 거르기
  const isValidFile = (file: any) => {
    let type = file?.type; // 'image/jpeg', 'text/csv' ...
    let name = file?.name; // point.csv , pipe.csv ...

    let accept = acceptType; //['text/csv']

    // 1) 확장자 불일치
    if (!accept?.includes(type)) {
      setPopFail((prev) => ({
        ...prev,
        isOpen: true,
        fileName: name,
      }));

      return false;
    }
  };

  return (
    <>
      <DragDropBoxSt.DragDropBox $height={height} className={`${isDragging && 'isDragging'} `} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
        <>
          <div className="ico"></div>
          <p className="mainText">{mainText ? mainText : '드래그 & 드롭하여 파일을 업로드 하세요'}</p>
          <p className="subText">{subText ? subText : ''}</p>
          {subText ? <p className="or">{t('또는')}</p> : null}
        </>

        <button className="addFileBtn" onClick={onClickInput}>
          {t('파일선택')}
        </button>
        <input ref={inputRef} id="inputFile" type="file" accept={acceptType?.toString()} onChange={onInputChange} style={{ display: 'none' }} multiple={multiple} />
      </DragDropBoxSt.DragDropBox>

      {/* 파일 유효성 검사 - 웹에서 체크 결과 */}
      {popFail.isOpen && <PopupFail noDesc={true} />}
    </>
  );
}

export default DesignUpload;
