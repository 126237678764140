import React, { useEffect, useRef, useState } from 'react';
import Input from '../atoms/Input';
import IcoInputSearch from '../../assets/images/ico-input-search.svg';
import { SurveyStyle } from '../templates/_style';
import styled from 'styled-components';
import IcoSearch from 'assets/images/ico-search.svg';
import { InputStyle } from '../atoms/_style';
import query from 'hooks/RQuery/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { siteListState } from 'store/Atoms';
import { codeKeys } from 'hooks/RQuery/keys/queryKeys';
import { useTranslation } from 'react-i18next';

const SearchMapStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    position: relative;
  `,
  Btn: styled.button`
    width: 36px;
    height: 36px;
    background: #4379ee url(${IcoSearch}) no-repeat center;
    border-radius: 2px;
    box-sizing: border-box;
    transition: 0.4s;
    &:hover {
      background-color: #6b96f3;
    }
  `,
  InputWrap: styled.div`
    width: 212px;
    height: 36px;
    border: 1px solid #d5d5d5;
    border-right: 0;
    border-radius: 2px 0 0 2px;
    background: #fff;
    box-sizing: border-box;
    &:focus-within,
    &:focus {
      border-color: #0076ff;
      outline: 0;
    }
  `,
  Input: styled.input`
    border: none;
    color: #242628;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #94949a;
    }
  `,
  List: styled.ul`
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    max-height: 300px;
    overflow: auto;
    background: #fff;
    width: 100%;
    border: 1px solid #0076ff;
    display: none;
    &.active {
      display: block;
    }
  `,
  Item: styled.li`
    height: 36px;
    display: flex;
    align-items: center;
    color: #0076ff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    &.noHover {
      height: 80px;
      justify-content: center;
    }
    &:not(&.noHover) {
      &:hover,
      &:focus {
        background: #b8d9ff;
        outline: 0;
      }
    }
  `,
};

const SearchPtNum = ({ point, openPointInfo }) => {
  const { t } = useTranslation();
  const itemRef = useRef<HTMLLIElement[]>([]);
  //측점번호
  const [featurePtNum, setFeaturePtNum] = useState('');
  // 모든 포인트 데이터
  const [pointData, setPointData] = useState<any[]>([]);
  const [intervalId, setIntervalId] = useState<any>(null);
  //리스트 state
  const [listState, setListState] = useState({
    click: false,
    list: false,
  });

  // ------ 모든 포인트 정보 조회 (Map에서 사용하는 쿼리를 재사용해서, 재요청은 하지 않음) ------ //
  const siteId = sessionStorage?.getItem('siteId') || ''; // 현장 아이디
  const [siteList] = useRecoilState(siteListState);
  const facilityKinds = siteList.filter(({ active }) => active)[0]?.facilityKinds || []; // 볼 수 있는 관 종류(해당 현장에서)

  // 모든 측점 데이터 조회
  const { data: mapPointsData } = query.map.points.useMapPoints({ siteId, facilityKinds });

  // 모든 측점 데이터 flat 형태 - 저장
  useEffect(() => {
    if (mapPointsData?.['ALL']?.length > 0) {
      setPointData(mapPointsData['ALL']); // state 저장
    }
  }, [mapPointsData]);

  //측점번호검색 입력
  const onChangeFindFeature = (e) => {
    setFeaturePtNum(e.target.value);
    setListState({
      ...listState,
      click: false,
    });
  };

  // 검색 버튼 클릭
  const onClickFindFeature = () => {
    point?.findFeature(featurePtNum, openPointInfo);
    setListState({
      list: false,
      click: false,
    });
  };

  const onDown = (curIdx) => {};

  useEffect(() => {
    // 입력 항목 글자수가 2자 이상일때
    if (featurePtNum.length >= 2) {
      if (!listState.click) {
        setListState({
          ...listState,
          list: true,
        });
      } else {
        setListState({
          ...listState,
          list: false,
        });
      }
    } else {
      setListState({
        ...listState,
        list: false,
      });
    }
  }, [featurePtNum]);

  return (
    <SearchMapStyle.Wrap>
      <SearchMapStyle.InputWrap>
        <SearchMapStyle.Input
          type={'text'}
          onChange={onChangeFindFeature}
          placeholder={t('측점명검색')}
          style={{ background: '#fff' }}
          value={featurePtNum}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClickFindFeature();
            }
            if (e.key === 'ArrowDown' && itemRef.current.length > 0) {
              itemRef.current[0]?.focus();
            }
          }}
        />
      </SearchMapStyle.InputWrap>
      <SearchMapStyle.Btn type={'button'} onClick={onClickFindFeature}>
        <span className={'hidden'}>검색</span>
      </SearchMapStyle.Btn>
      <SearchMapStyle.List className={listState.list ? 'active cScroll' : 'cScroll'} id={'searchList'}>
        {featurePtNum.length >= 2 ? (
          pointData?.filter((i) => i.ptNum.startsWith(featurePtNum)).length ? (
            pointData
              ?.filter((i) => i.ptNum.startsWith(featurePtNum))
              .map((i, index) => {
                return (
                  <SearchMapStyle.Item
                    key={index}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      let curIdx = itemRef.current.findIndex((item) => item === document.activeElement);

                      if (e.key === 'ArrowDown' && index < itemRef.current.length - 1) {
                        // itemRef.current[index + 1]?.focus();
                        curIdx = curIdx + 1;
                        itemRef.current[curIdx].focus();
                        if (!intervalId) {
                          const id = setInterval(() => {
                            curIdx = curIdx + 1;
                            itemRef.current[curIdx]?.focus();
                          }, 100); // 0.1초마다 실행
                          setIntervalId(id);
                        }
                      } else if (e.key === 'ArrowUp' && index > 0) {
                        curIdx = curIdx - 1;
                        itemRef.current[curIdx].focus();
                        if (!intervalId) {
                          const id = setInterval(() => {
                            curIdx = curIdx - 1;
                            itemRef.current[curIdx]?.focus();
                          }, 100); // 0.1초마다 실행
                          setIntervalId(id);
                        }
                      } else if (e.key === 'Enter') {
                        setFeaturePtNum(itemRef.current[index].innerText);
                        point?.findFeature(itemRef.current[index].innerText, openPointInfo);
                        setListState({
                          click: true,
                          list: false,
                        });
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                        clearInterval(intervalId);
                        setIntervalId(null);
                      }
                    }}
                    ref={(e: HTMLLIElement) => {
                      itemRef.current[index] = e;
                    }}
                    onClick={() => {
                      setFeaturePtNum(i.ptNum);
                      point?.findFeature(i.ptNum, openPointInfo);
                      setListState({
                        click: true,
                        list: false,
                      });
                    }}
                  >
                    {i.ptNum}
                  </SearchMapStyle.Item>
                );
              })
          ) : (
            <SearchMapStyle.Item className={'noHover'}>일치하는 측점명이 없습니다.</SearchMapStyle.Item>
          )
        ) : null}
      </SearchMapStyle.List>
    </SearchMapStyle.Wrap>
  );
};

export default SearchPtNum;
