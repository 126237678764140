import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import './locale/i18n';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';

import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
let isTokenExpired = false;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      refetchOnMount: true,
      // staleTime: 0,
      // gcTime: 1000 * 60 * 5,
    },
  },
  queryCache: new QueryCache({
    onError(error: any, query) {
      const status = error.response?.data.status;

      // 권한 없음
      if (status === 401) {
        if (!isTokenExpired) {
          isTokenExpired = true;

          alert('인증이 만료되었습니다. 로그인페이지로 이동합니다.');
          sessionStorage.clear();
          window.location.href = '/';
        }
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <BrowserRouter>
  <QueryClientProvider client={queryClient}>
    {/* react-query Devtools */}
    <ReactQueryDevtools initialIsOpen={false} />
    {/* <RouterProvider router={routers} /> */}
    <RecoilRoot>
      <RecoilNexus />
      <App />
    </RecoilRoot>
  </QueryClientProvider>,
  // </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
