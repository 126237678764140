import React from 'react';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { AlertProps } from './_interface';
import { OptionProps } from '../components/atoms/_interface';
import { DateObject } from 'react-multi-date-picker';
import { RangeDateProps } from '../popups/_interface';
import { ConstructionInfoProps } from '../pages/_interface';
import { SelectProps } from './_interface';

// 새로운 저장소 객체 생성
export function newPersistAtom(key: string = 'sessionStorage') {
  // 스토리지에 저장하는 기능
  const { persistAtom } = recoilPersist({
    key: key,
    storage: sessionStorage || localStorage || undefined, // localStorage 또는 sessionStorage
  });
  return persistAtom;
}

export const alertState = atom<AlertProps>({
  key: 'alertState',
  default: {
    type: 'alert',
    title: '',
    desc: '',
    active: false,
  },
});

export const loaderState = atom({
  key: 'loaderState',
  default: {
    show: false,
  },
});

/* 파일 업로드 progressBar 로더 */
export const progressState = atom<any>({
  key: 'progressState',
  default: {
    show: false,
    fileName: '',
    domId: 'progress',
  },
});

export const statisticsOptionState = atom<OptionProps[]>({
  key: 'statisticsOptionState',
  default: [
    { text: '최근1개월', value: 1, active: true },
    { text: '최근3개월', value: 2, active: false },
    { text: '전체', value: 3, active: false },
    { text: '직접입력', value: 4, active: false },
  ],
});

export const statisticsDateState = atom<RangeDateProps>({
  key: 'statisticsDateState',
  default: {
    from: new DateObject().toFirstOfMonth(),
    to: new DateObject().toLastOfMonth(),
  },
});

const isBrowserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
// 초기 테마 저장 (시스템 기본값)
if (!sessionStorage.getItem('theme')) {
  sessionStorage.setItem('theme', isBrowserDarkMode ? 'dark' : 'light');
}
export const themeState = atom<string>({
  key: 'themeState',
  default: isBrowserDarkMode ? 'dark' : 'light',
});

export const constructionInfoState = atom<ConstructionInfoProps>({
  key: 'constructionInfo',
  default: {
    address: '',
    subContractor: [],
    contractor: [],
    owner: [],
    projectName: '',
    projection: '',
    facilityKinds: [],
  },
  effects_UNSTABLE: [newPersistAtom('constructionInfo')], // persist 적용
});

export const siteListState = atom<SelectProps[]>({
  key: 'siteList',
  default: [],
  // effects_UNSTABLE: [newPersistAtom('siteList')],
});

export const solutionState = atom<string[]>({
  key: 'solutionList',
  default: [],
});
