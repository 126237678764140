import styled from 'styled-components';
import IcoUpload from 'assets/images/2dMap/ico-upload.svg';
import IcoFileThumb from 'assets/images/ico-file-thumb.svg';

// 드래그 드롭 박스 공통
export const DragDropBoxSt = {
  DragDropBox: styled.div<{ $height?: number }>`
    width: 100%;
    height: ${(p) => (p.$height ? p.$height + 'px' : '305px')};
    border: 1px dashed #3facff;
    background-color: ${(p) => p.theme.drag_drop.boxBg};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &.disabled {
      position: relative;
      &::before {
        content: '파일 유형을 선택하세요';
        text-align: center;
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #989898ff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 20px;
      }
    }
    &.isDragging {
      background-color: ${(p) => p.theme.drag_drop.boxHoverBg};
    }
    &.contentFile {
      border-style: solid;
    }
    // 이미지 썸네일
    .thumbWrap {
      max-width: 176px;
      max-height: 132px;
      img {
        width: 100%;
        height: auto;
        max-height: 132px;
      }
    }
    // 파일 아이콘
    .fileThumbWrap {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .icoWrap {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(63, 172, 255, 0.3);
        border-radius: 4px;
        background-color: rgba(63, 172, 255, 0.15);
        > .ico {
          width: 32px;
          height: 32.8px;
          background: url(${IcoFileThumb}) no-repeat center;
        }
      }
      > .text {
        margin-top: 6px;
        line-height: 26px;
        font-weight: 600;
        font-size: 16px;
        color: #0076ff;
      }
      > .fileLength {
        line-height: 26px;
        font-weight: 500;
        font-size: 16px;
        color: #587ba3;
      }
    }
    > .ico {
      width: 80px;
      height: 52.19px;
      background: url(${IcoUpload}) no-repeat center;
    }
    .mainText {
      line-height: 26px;
      margin-top: 16px;
      color: #0076ff;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      white-space: break-spaces;
    }
    .subText {
      margin-top: 7px;
      color: ${(p) => p.theme.drag_drop.subTextColor};
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
    }
    .or {
      margin-top: 8px;
      line-height: 26px;
      color: #587ba3;
      font-weight: 500;
      font-size: 16px;
    }
    .addFileBtn {
      margin-top: 16px;
      width: 132px;
      height: 40px;
      border-radius: 2px;
      background-color: #4379ee;
      color: #fff;
      font-weight: 600;
      font-size: 14px;
    }
  `,
};
