import React, { useEffect, useState } from 'react';
import { RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { DashboardStyle } from './_style';
import { DashboardTypeDataProps } from '../../pages/_interface';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import { DateObject } from 'react-multi-date-picker';
import SkeletonPipeChart from '../skeleton/SkeletonPipeChart';
import { useTranslation } from 'react-i18next';

const DashboardTypeChart = ({ data }: { data: DashboardTypeDataProps[] }) => {
  const { t } = useTranslation();
  const [themeMode] = useRecoilState(themeState);
  //공통 코드 enum 쿼리
  return (
    <DashboardStyle.SectionBox style={{ marginLeft: 15 }}>
      <SkeletonPipeChart />
      <DashboardStyle.TitleArea>
        <DashboardStyle.Title>{t('관별공정률')}(%)</DashboardStyle.Title>
      </DashboardStyle.TitleArea>
      <DashboardStyle.DescArea>
        <DashboardStyle.DescInner>
          {data.length <= 5 ? (
            <DashboardStyle.ChartFlex style={{ justifyContent: data.length === 2 ? 'center' : 'space-between' }}>
              {data.map((i, index) => {
                const newData = data.filter((i) => i.name === 'total' || i.name === data[index].name);
                if (index !== 0) {
                  return (
                    <DashboardStyle.ChartBox key={i.name} style={{ margin: '0 5px' }}>
                      <DashboardStyle.ChartTotal>
                        <p style={{ fontSize: 20 }}>
                          {i.percent}
                          <span>%</span>
                        </p>
                      </DashboardStyle.ChartTotal>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <RadialBarChart cx="50%" cy="50%" innerRadius="100%" outerRadius="100%" barSize={8} data={newData} startAngle={270} endAngle={-90}>
                          <RadialBar cornerRadius={50} dataKey="percent" background={{ fill: themeMode === 'dark' ? '#323D4E' : '#E2E4ED' }}></RadialBar>
                        </RadialBarChart>
                      </ResponsiveContainer>
                      <DashboardStyle.Label>{t(i.name)}</DashboardStyle.Label>
                    </DashboardStyle.ChartBox>
                  );
                }
              })}
            </DashboardStyle.ChartFlex>
          ) : (
            <DashboardStyle.ChartFlex style={{ flexDirection: 'column' }}>
              <DashboardStyle.ChartRow>
                {data.map((i, index) => {
                  const newData = data.filter((i) => i.name === 'total' || i.name === data[index].name);
                  if (index !== 0 && index < 5) {
                    return (
                      <DashboardStyle.ChartBox key={i.name} style={{ margin: '0 8px', width: 80, height: 80 }}>
                        <DashboardStyle.ChartTotal>
                          <p style={{ fontSize: 20 }}>
                            {i.percent}
                            <span>%</span>
                          </p>
                        </DashboardStyle.ChartTotal>
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                          <RadialBarChart cx="50%" cy="50%" innerRadius="100%" outerRadius="100%" barSize={6} data={newData} startAngle={270} endAngle={-90}>
                            <RadialBar cornerRadius={50} dataKey="percent" background={{ fill: themeMode === 'dark' ? '#323D4E' : '#E2E4ED' }}></RadialBar>
                          </RadialBarChart>
                        </ResponsiveContainer>
                        <DashboardStyle.Label style={{ marginTop: 3 }}>{t(i.name)}</DashboardStyle.Label>
                      </DashboardStyle.ChartBox>
                    );
                  }
                })}
              </DashboardStyle.ChartRow>
              {data.length > 5 ? (
                <DashboardStyle.ChartRow>
                  {data.map((i, index) => {
                    const newData = data.filter((i) => i.name === 'total' || i.name === data[index].name);
                    if (index > 4) {
                      return (
                        <DashboardStyle.ChartBox key={i.name} style={{ margin: '0 8px', width: 80, height: 80 }}>
                          <DashboardStyle.ChartTotal>
                            <p style={{ fontSize: 20 }}>
                              {i.percent}
                              <span>%</span>
                            </p>
                          </DashboardStyle.ChartTotal>
                          <ResponsiveContainer width={'100%'} height={'100%'}>
                            <RadialBarChart cx="50%" cy="50%" innerRadius="100%" outerRadius="100%" barSize={6} data={newData} startAngle={270} endAngle={-90}>
                              <RadialBar cornerRadius={50} dataKey="percent" background={{ fill: themeMode === 'dark' ? '#323D4E' : '#E2E4ED' }}></RadialBar>
                            </RadialBarChart>
                          </ResponsiveContainer>
                          <DashboardStyle.Label style={{ marginTop: 3 }}>{t(i.name)}</DashboardStyle.Label>
                        </DashboardStyle.ChartBox>
                      );
                    }
                  })}
                </DashboardStyle.ChartRow>
              ) : null}
            </DashboardStyle.ChartFlex>
          )}
        </DashboardStyle.DescInner>
      </DashboardStyle.DescArea>
    </DashboardStyle.SectionBox>
  );
};

export default DashboardTypeChart;
