import styled from 'styled-components';
import query from 'hooks/RQuery/utils';

export const HoverTooltipSt = {
  Wrap: styled.div`
    height: 20px;
    position: relative;
    cursor: default;
    .title {
      cursor: pointer;
      &:hover {
        border-bottom: 2px solid ${(p) => p.theme.map.infoModal.color};
        & + .tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .tooltip {
      transition: all.4s;
      opacity: 0;
      visibility: hidden;
      width: max-content;
      /* max-width: 150px; */
      max-width: 178px;
      position: absolute;
      top: 26px;
      z-index: 9;
      color: #fff;
      line-height: 16px;
      font-weight: 500;
      font-size: 11px;
      background-color: #0076ff;
      border-radius: 2px;
      padding: 5px 8px;
      box-sizing: border-box;

      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;

      display: -webkit-box;
      -webkit-line-clamp: 3; // 원하는 라인수
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
    }
  `,
};

function HoverTooltip({ type, name, title }: { type: 'pip' | 'pt'; name: string; title?: string }) {
  //  코드 툴팁 조회
  const codeTooltip = query.codes.useCodesTooltip();
  const pipTooltip = codeTooltip?.data.PIPE; // 파이프 툴팁
  const pitTooltip = codeTooltip?.data.POINT; // 포인트 툴팁

  const item = type === 'pip' ? pipTooltip?.find((ele) => ele.name === name) : pitTooltip?.find((ele) => ele.name === name);

  return (
    <HoverTooltipSt.Wrap>
      <p className="title">{title || item?.value}</p>
      <span className="tooltip">{item?.tooltip}</span>
    </HoverTooltipSt.Wrap>
  );
}

export default HoverTooltip;
