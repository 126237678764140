import React, { SetStateAction, useEffect, useState } from 'react';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import { MonthsForLocale } from '../../util/global';
import { DataFilterProps } from './_interface';
import Select from '../atoms/Select';
import useParamChange from '../../hooks/useParamChange';
import { DataFilterStyle } from './_style';
import { OptionProps } from '../atoms/_interface';
import { useRecoilState } from 'recoil';
import { statisticsDateState, statisticsOptionState, themeState } from '../../store/Atoms';
import { useTranslation } from 'react-i18next';

interface ExportDateProps {
  workDate: string[];
  date: { from: DateObject; to: DateObject };
  setDate: React.Dispatch<SetStateAction<{ from: DateObject; to: DateObject }>>;
  max?: number;
}

const ExportDate = ({ workDate, date, setDate, max }: ExportDateProps) => {
  const { t, i18n } = useTranslation();
  const monthsKo = MonthsForLocale(i18n.language === 'ko' ? 'ko' : 'en', 'short');
  const [theme] = useRecoilState(themeState);
  const [option, setOption] = useRecoilState(statisticsOptionState);
  const [first, setFirst] = useState(true);
  const maxDate = new DateObject(new DateObject(date.from).year + '-' + new DateObject(date.from).month.number + '-' + (new DateObject(date.from).day + 1)).add(90, 'days');

  //기간 선택 변경
  const onChange = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    // onParamChange(e)
    const value = (e.target as any).ariaValueText;
    const newArr: OptionProps[] = [];
    option.map((i) => {
      newArr.push({ ...i, active: Number(value) === i.value });
    });
    setOption(newArr);
  };
  useEffect(() => {
    if (Number(option.filter(({ active }) => active)[0].value) === 1) {
      setDate({
        from: new DateObject().subtract(30, 'days'),
        to: new DateObject(),
      });
    } else if (Number(option.filter(({ active }) => active)[0].value) === 2) {
      setDate({
        from: new DateObject().subtract(90, 'days'),
        to: new DateObject(),
      });
    } else if (Number(option.filter(({ active }) => active)[0].value) === 3) {
      setDate({
        from: new DateObject(workDate[workDate.length - 1]),
        to: new DateObject(workDate[0]),
      });
    } else {
      setFirst(false);
    }
  }, [option]);
  return (
    <DataFilterStyle.Group className={theme === 'light' ? 'light' : undefined}>
      <Select option={option} width={132} height={40} name={'select'} value={option.filter(({ active }) => active)[0].text} onChange={onChange} max={max} />
      <DataFilterStyle.HeightBar></DataFilterStyle.HeightBar>
      <DatePicker
        style={{ width: 148 }}
        months={monthsKo}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        //작업 발생일 redDot
        showOtherDays={true}
        mapDays={({ date }) => {
          const newDate = new DateObject(date);
          if (workDate.includes(newDate.format('YYYY-MM-DD'))) {
            return {
              children: (
                <div
                  className={'customDay'}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#04E762',
                    }}
                  >
                    {date.format('D')}
                  </div>
                  <span
                    style={{
                      width: 4,
                      height: 4,
                      borderRadius: '50%',
                      background: '#04E762',
                      position: 'absolute',
                      left: '50%',
                      top: 24,
                      transform: 'translateX(-50%)',
                    }}
                  ></span>
                </div>
              ),
            };
          }
        }}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        // value={date}
        onChange={(selectedDates) => {
          setDate({ ...date, from: selectedDates as DateObject, to: selectedDates as DateObject });
        }}
        value={date.from}
        minDate={new DateObject(workDate[workDate.length - 1])}
        maxDate={date.to}
        disabled={Number(option.filter(({ active }) => active)[0].value) !== 4}
      />
      <DataFilterStyle.WidthBar></DataFilterStyle.WidthBar>
      <DatePicker
        style={{ width: 148 }}
        months={monthsKo}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        //작업 발생일 redDot
        showOtherDays={true}
        mapDays={({ date }) => {
          const newDate = new DateObject(date);
          if (workDate.includes(newDate.format('YYYY-MM-DD'))) {
            return {
              children: (
                <div
                  className={'customDay'}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#04E762',
                    }}
                  >
                    {date.format('D')}
                  </div>
                  <span
                    style={{
                      width: 4,
                      height: 4,
                      borderRadius: '50%',
                      background: '#04E762',
                      position: 'absolute',
                      left: '50%',
                      top: 24,
                      transform: 'translateX(-50%)',
                    }}
                  ></span>
                </div>
              ),
            };
          }
        }}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        onChange={(selectedDates) => {
          setDate({ ...date, to: selectedDates as DateObject });
        }}
        value={date.to}
        minDate={date.from}
        maxDate={maxDate > new DateObject(workDate[0]) ? new DateObject(workDate[0]) : maxDate}
        disabled={Number(option.filter(({ active }) => active)[0].value) !== 4}
      />
    </DataFilterStyle.Group>
  );
};

export default ExportDate;
