import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import PopupFrame from './PopupFrame';
import Select from 'components/atoms/Select';
import Ico404 from '../../../../assets/images/ico-404.svg';
//api
import { constructionInfoState, loaderState, progressState, statisticsOptionState } from 'store/Atoms';
import { useTranslation } from 'react-i18next';
import query from '../../../../hooks/RQuery/utils';
import ExportDate from '../../ExportDate';
import { DateObject } from 'react-multi-date-picker';
import { LabelForm } from '../../../atoms/LabelInput';
import styled from 'styled-components';
import { qsData } from '../../../../service/api/common';
import { DataFilterStyle } from '../../_style';
import LabelCheckbox, { LabelCheckboxSt } from '../../2dMap/LabelCheckbox';
import Checkbox from '../../../atoms/Checkbox';
import { CheckboxStyle } from '../../../atoms/_style';
import ImageTable from '../../../../util/photoRegister/ImageTable';

const SurveyDownStyle = {
  Wrap: styled.div`
    margin-top: 24px;

    ${LabelForm.Wrap} {
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 14px;
      }
      ${DataFilterStyle.Group} {
        flex: 1;
        justify-content: space-between;
      }
    }
  `,
  FacilityBox: styled.div`
    flex: 1;
    width: 326px;
    background: ${(p) => p.theme.surveyDown.bg};
    border: 1px solid ${(p) => p.theme.surveyDown.border};
    padding: 8px 6px;
    display: flex;
  `,
  FacilityList: styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    ${LabelCheckboxSt.Wrap} {
      justify-content: flex-start;
      margin: 8px 10px;
      width: calc(33.3% - 20px);
    }
  `,
};

// 파일유형 선택,기간 및 관종 선택 후 파일 다운로드 하는 팝업
function PopupDownloadSelect({ onClose }) {
  const workDay = query.calendars.useCalendars({ siteId: String(sessionStorage.getItem('survey_siteId')) });
  const codes = query.codes.useCodes();
  const [constructionInfo] = useRecoilState(constructionInfoState);
  const [statisticsOption, setStatisticsOption] = useRecoilState(statisticsOptionState);

  const { t } = useTranslation();
  const setLoader = useSetRecoilState(loaderState);
  const [reportList, setReportList] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [exportDate, setExportDate] = useState({
    from: new DateObject(),
    to: new DateObject(),
  });
  const [workDate, setWorkDate] = useState([]);
  const [facilityList, setFacilityList] = useState<{ text: string; value: string; checked: boolean; color: string }[]>([]);
  const [options, setOptions] = useState([
    { active: false, value: 'csv', text: 'CSV 파일 다운로드' },
    { active: false, value: 'photo', text: '사진 대지 다운로드' },
  ]);
  const [selected, setSelected] = useState({ value: '', text: '' }); // 선택된 항목

  const getCsv = query.files.useFacilitiesFile({
    siteId: String(sessionStorage.getItem('survey_siteId')),
    dateFrom: exportDate.from.format('YYYY-MM-DD'),
    dateTo: exportDate.to.format('YYYY-MM-DD'),
    facilityKinds: facilityList.filter((i) => i.checked),
  });

  const getReport = query.files.useReport({
    siteId: String(sessionStorage.getItem('survey_siteId')),
    dateFrom: exportDate.from.format('YYYY-MM-DD'),
    dateTo: exportDate.to.format('YYYY-MM-DD'),
    facilityKinds: facilityList.filter((i) => i.checked),
  });

  //시공일 리스트 호출 후 마지막 시공일 선택
  useEffect(() => {
    if (workDay.data) {
      const data = workDay.data;
      setWorkDate(data);
    }
  }, [workDay.data]);

  // useEffect(() => {
  //   if (workDate.length) {
  //     setExportDate({
  //       from: new DateObject(workDate[workDate.length - 1]),
  //       to: new DateObject(workDate[0]),
  //     });
  //   }
  // }, [workDate]);

  useEffect(() => {
    if (codes.isSuccessAll) {
      const newArr: { text: string; value: string; checked: boolean; color: string }[] = [];
      const facilityList = sessionStorage.getItem('survey_facilityKindList')!.split(',');
      facilityList.map((i) => {
        newArr.push({ text: codes.data.FACILITY.find(({ code }) => code === i).value, value: i, checked: false, color: codes.data.FACILITY.find(({ code }) => code === i).color });
      });
      setFacilityList(newArr);
    }
  }, [codes.isSuccessAll, codes.data.FACILITY]);

  const onCheck = (str) => {
    const newArr = [...facilityList];
    newArr.filter(({ value }) => value === str)[0].checked = !newArr.filter(({ value }) => value === str)[0].checked;
    setFacilityList(newArr);
  };

  // 파일 종류 select onChange 이벤트
  const onChangeSelect = (e, name) => {
    const { ariaValueText, innerText } = e.currentTarget;

    setOptions((prev) => {
      return prev.map((ele) => (ele?.text === innerText ? { ...ele, active: true } : { ...ele, active: false }));
    });
    setSelected({ value: ariaValueText, text: innerText }); // 선택항목 저장

    setStatisticsOption((prev) => {
      return prev.map((ele) => (ele?.value === 1 ? { ...ele, active: true } : { ...ele, active: false }));
    });
  };

  //원본데이터에 새로운 url 넣을 key 추가
  useEffect(() => {
    // if (getReport.isSuccess) {
    //   const originData = getReport.data.data.reportData;
    //   const newArr: any = [];
    //   originData.map((i) => {
    //     newArr.push({ ...i, transImage: '', isImageLoaded: false });
    //   });
    //   setReportList(newArr);
    // }
  }, [getReport.isSuccess]);
  return (
    <>
      <PopupFrame
        title="파일 다운로드"
        close={() => {
          onClose();
        }}
        leftText={'취소'}
        rightText={'다운로드'}
        leftFunc={() => {
          onClose();
        }}
        rightFunc={() => {
          if (options[0].active) {
            getCsv.mutate(
              {
                siteId: String(sessionStorage.getItem('survey_siteId')),
                dateFrom: exportDate.from.format('YYYY-MM-DD'),
                dateTo: exportDate.to.format('YYYY-MM-DD'),
                facilityKinds: facilityList.filter((i) => i.checked),
              },
              {
                onSuccess: async (data: any) => {
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(data);
                  link.download = `${exportDate.from.format('YYYY-MM-DD')}-${exportDate.to.format('YYYY-MM-DD')}.zip`; // 원하는 파일 이름으로 변경하세요.

                  // 링크를 문서에 추가하고 클릭하여 다운로드를 트리거
                  document.body.appendChild(link);
                  link.click();

                  // 링크를 문서에서 제거
                  document.body.removeChild(link);
                },
              },
            );
          } else if (options[1].active) {
            getReport.refetch().then((res) => {
              const originData = res.data.data.reportData;
              const newArr: any = [];
              originData.map((i) => {
                newArr.push({ ...i, transImage: '' });
              });
              setReportList(newArr);
              setIsReady(true);
            });
          }
        }}
      >
        <Select placeHolder="파일 유형 선택" option={options} onChange={onChangeSelect} />
        <SurveyDownStyle.Wrap>
          <LabelForm.Wrap>
            <LabelForm.Label required={true}>기간</LabelForm.Label>

            <ExportDate workDate={workDate} date={exportDate} setDate={setExportDate} max={options[1].active ? 90 : undefined} />
          </LabelForm.Wrap>
          <LabelForm.Wrap style={{ alignItems: 'flex-start' }}>
            <LabelForm.Label required={true} style={{ paddingTop: 10 }}>
              관종
            </LabelForm.Label>
            <SurveyDownStyle.FacilityBox>
              <SurveyDownStyle.FacilityList>
                {facilityList.map((i) => {
                  return (
                    <LabelCheckboxSt.Wrap key={i.value}>
                      <CheckboxStyle.Input id={i.value} className={'dark'} type={'checkbox'} checked={i.checked} onChange={() => onCheck(i.value)} />
                      <CheckboxStyle.Label htmlFor={i.value} size={18}></CheckboxStyle.Label>
                      <span
                        style={{
                          width: 12,
                          height: 12,
                          boxSizing: 'border-box',
                          borderRadius: 2,
                          border: `2px solid ${codes.data.FACILITY.find(({ code }) => i.value === code).color}`,
                          margin: '0 10px 0 12px',
                        }}
                      ></span>
                      <CheckboxStyle.Text htmlFor={i.value}>{codes.data.FACILITY.find(({ code }) => i.value === code).value}</CheckboxStyle.Text>
                    </LabelCheckboxSt.Wrap>
                    // <LabelForm.Wrap>
                    //   <LabelCheckboxSt.ColorTag $color={i.color} />
                    //   <LabelForm.Label>{i.text}</LabelForm.Label>
                    // </LabelForm.Wrap>
                  );
                })}
              </SurveyDownStyle.FacilityList>
            </SurveyDownStyle.FacilityBox>
          </LabelForm.Wrap>
        </SurveyDownStyle.Wrap>
        {isReady ? (
          <ImageTable title={getReport.data.data.projectName} setIsReady={setIsReady} reportList={reportList} setReportList={setReportList} date={{ from: exportDate.from.format('YYYY-MM-DD'), to: exportDate.to.format('YYYY-MM-DD') }} />
        ) : null}
      </PopupFrame>
    </>
  );
}

export default PopupDownloadSelect;
