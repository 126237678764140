import React, { useState } from 'react';
import PopupFrame from './PopupFrame';
import DragDropBox from 'components/templates/drag&drop/DragDropBox';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { dragDropBox, popupUpload } from 'store/popupAtoms';
import { loaderState, progressState } from 'store/Atoms';
import { FILE_TYPES } from 'util/global';
//api
import { showFileCheckPopup, showResultPopup } from '../common.func';
import ImageProgress from '../../../atoms/ImageProgress';
import { useQueryClient } from '@tanstack/react-query';
import queryKeys from 'hooks/RQuery/keys/queryKeys';
import query from '../../../../hooks/RQuery/utils';
import BasicDragDrop from 'components/templates/drag&drop/BasicDragDrop';

// 관로 파일 업로드   'pipe' <span>CSV 파일</span>만 추가 가능합니다.
// 사진 파일 업로드   'image' <span>이미지(jpg, png) 파일</span>만 추가 가능합니다.
// 파일 업로드
function PopupUpload({ siteInfo }) {
  const siteId = siteInfo?.id || '';
  const setLoader = useSetRecoilState(loaderState);
  const queryClient = useQueryClient();
  const resetDnd = useResetRecoilState(dragDropBox);
  const [dndState, setdndState] = useRecoilState(dragDropBox);
  const [popUploadState, setPopUploadState] = useRecoilState(popupUpload);
  const { isOpen, type } = popUploadState;

  // TOOD
  const [progressBar, setProgressBar] = useRecoilState(progressState);
  const [percentPopup, setPercentPopup] = useState(false);
  const [percent, setPercent] = useState(0);

  // csv 파일 체크
  const { mutate: checkCsv } = query.files.useCheckCsv({ siteId: siteId, csvFile: dndState.contentFileArr[0] });

  // csv 전송 (건너뛰기, 덮어쓰기)
  const { mutate: registCsv } = query.files.useRegistrationCsv();

  const refetchSurveyList = () => {
    queryClient.invalidateQueries({ queryKey: [...queryKeys.MainKey.SURVEY] });
  };

  // 이미지 파일 체크
  const { mutate: checkImages } = query.files.useCheckImages({ siteId: siteId, imageList: dndState.contentFileArr });

  //이미지 다수 업로드
  const { mutate: postImages } = query.files.useAddImages({ siteId: siteId, imageList: dndState.contentFileArr, setPercentPopup: setPercentPopup, resetDnd: resetDnd, refetchSurveyList: refetchSurveyList });

  const curItem = FILE_TYPES.find((ele) => ele.type === type);
  const curText = curItem?.text;
  const acceptType = curItem?.acceptType || [];
  const acceptText = curItem?.acceptText || ''; // OOO 파일만 추가 가능합니다.
  const multiple = curItem?.multiple || false; // 현재 'image'의 경우에만 가능

  const title = `${curText} 파일 업로드`;
  const mainText = `여기에 ${curText} 파일을 드래그 & 드롭하여 업로드 하세요`;
  const subText = '';
  const leftText = '취소';
  const leftFunc = () => closePopup();
  const rightText = '등록';
  const rightFunc = () => {
    onClickUpload();
  };

  //[등록] 버튼 클릭 이벤트
  const onClickUpload = () => {
    console.log('등록하기', dndState.contentFileArr);
    //파일명 에러 처리하기
    // mutate
    // 관로 csv 파일
    if (curItem?.type === 'pipe') {
      if (dndState.contentFileArr[0]) {
        checkMutateCsv();
      }
    }
    // 이미지 파일
    if (curItem?.type === 'image') {
      //TODO: imgMutate
      if (dndState.contentFileArr.length > 0) {
        mutateImg();
      }
    }
  };

  // 중복데이터 유무 확인 및 csv파일 서버에 임시 저장 요청
  const checkMutateCsv = async () => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndState?.contentFileArr[0]?.name }));

    await checkCsv(
      { siteId, csvFile: dndState.contentFileArr[0] },
      {
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));

          // [성공]
          if (data) {
            const response = { ...data?.data };
            const isDuplicate = response.ptNumDuplicateList.length > 0;
            // 중복데이터 없을때 - 기존처럼 csv 서버 전송 후 결과 팝업
            if (!isDuplicate) {
              registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true });
            } else {
              //중복 데이터 있을 때 - 팝업 오픈
              showFileCheckPopup({
                type: dndState.type,
                data,
                overwriteFunc: () => registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: true }),
                skipFunc: () => registMutateCsv({ siteId, inspectionKey: data?.data?.inspectionKey, overwrite: false }),
              });
            }
          }
          // [에러] - 결과 팝업
          if (error) {
            showResultPopup({ type: dndState.type, data, error });
          }
        },
      },
    );
  };

  // 서버에 파일 건너뛰기 또는 덮어쓰기 요청
  const registMutateCsv = async ({ siteId, inspectionKey, overwrite }) => {
    // 로더 표출
    setProgressBar((prev) => ({ ...prev, show: true, fileName: dndState?.contentFileArr[0]?.name }));

    // 서버
    await registCsv(
      { siteId, inspectionKey, overwrite },
      {
        onSettled(data: any, error: any, variables, context) {
          // 로더 제거
          setProgressBar((prev) => ({ ...prev, show: false }));

          // dnd 영역 데이터 초기화
          resetDnd();

          // 결과 처리 - 팝업
          showResultPopup({ type: dndState.type, data, error });
        },
      },
    );
  };

  //   // 이미지 업로드 함수
  //   const mutateImg = async () => {
  //     setPercentPopup(true);
  //     await postImages({ siteId: siteId, imageList: dndState.contentFileArr, setPercent: setPercent, setPercentPopup: setPercentPopup, resetDnd: resetDnd, refetchSurveyList: refetchSurveyList });
  //   };

  // 이미지 등록
  const mutateImg = async () => {
    // 1) 이미지 체크 진행
    await checkImages(
      {},
      {
        async onSuccess(data: any, variables, context) {
          const { duplicateList, failCount, failList, successCount } = data;
          const isDuplicate = duplicateList.length > 0;
          if (!isDuplicate) {
            uploadImages(); // 2) 기존처럼 이미지 등록
          } else {
            // 3) 건너뛰기&덮어쓰기 팝업 오픈
            showFileCheckPopup({
              type: dndState.type,
              data,
              overwriteFunc: () => uploadImages(), // 2) 기존처럼 이미지 등록
              skipFunc: () => uploadImages(duplicateList), // 중복 데이터를 제거한 파일만 전송
            });
          }
        },
        onError(error, variables, context) {
          console.log(error);
        },
        onSettled(data, error, variables, context) {},
      },
    );

    // [공통] 이미지 파일 필터링(필요시) -> 서버 전송
    const uploadImages = async (duplicateList: string[] = []) => {
      console.log(duplicateList);

      setPercentPopup(true);
      // 이미지 파일 - 필터링 (중복 데이터를 제거한 파일만 전송)
      await setdndState((prev) => {
        let newImgList = [...prev.contentFileArr].filter((file) => !duplicateList.includes(file.name));
        console.log('newImgList', newImgList);

        return duplicateList.length > 0 ? { ...prev, contentFileArr: newImgList } : prev;
      });

      await postImages({}); // 이미지 전송
    };
  };

  // 현재 팝업 닫기 - 업로드
  const closePopup = () => {
    setPopUploadState((prev) => ({
      ...prev,
      isOpen: false,
    }));
    resetDnd();
  };

  return (
    <>
      <PopupFrame title={title} leftText={leftText} rightText={rightText} leftFunc={leftFunc} rightFunc={rightFunc} close={closePopup} acceptText={acceptText} isHelpText>
        {/* <DragDropBox height={305} mainText={mainText} subText={subText} acceptType={acceptType} multiple={multiple} curItem={curItem} /> */}
        <BasicDragDrop curItem={curItem} />
      </PopupFrame>
      {percentPopup ? <ImageProgress width={percent} /> : null}
    </>
  );
}

export default React.memo(PopupUpload);
