import { useQueries, useQuery } from '@tanstack/react-query';
import * as keys from '../keys/queryKeys';
import api from '../service';
import useMutateHook from '../useMutateHook';

//설계도면 불러오기
export function useGetDesignDetail({ siteId, facilityKinds }) {
  const { data, pending, isSuccessAll } = useQueries({
    queries: facilityKinds.map((facilityKind) => {
      return {
        queryKey: [...keys.designKeys.all({ siteId, facilityKind })],
        queryFn: () => api.designs.getDesignDetail({ siteId, facilityKind }),
        // refetchOnMount: false,
      };
    }),
    combine: (results) => {
      const newData = {};
      facilityKinds.map((i, index) => {
        newData[i] = results[index].data;
      });
      return {
        data: newData,
        //   data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
        isSuccessAll: results.every((result) => result.isSuccess),
      };
    },
  });
  return { data, pending, isSuccessAll };
}

//설계도면 업로드
export function useUploadDesign({ siteId, facilityKind, file }) {
  return useMutateHook({
    fetchApi: () => api.designs.uploadDesign({ siteId, facilityKind, file }),
    invalidQuery: [[...keys.designKeys.all({ siteId, facilityKind })]],
  });
}

//설계도면 삭제
export function useDeleteDesign({ siteId, facilityKind }) {
  return useMutateHook({
    fetchApi: () => api.designs.deleteDesign({ siteId, facilityKind }),
    invalidQuery: [[...keys.designKeys.all({ siteId, facilityKind })]],
  });
}
