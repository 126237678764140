import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import OlCore from 'maps/wfs/OlCore';
import ObjectMapPipeLine from 'maps/wfs/Object/MapPipeLine';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { FullScreen } from 'ol/control.js';

// 선택 삭제 팝업용 지도
function SurveyDelMap({ bboxDataSuccess, bboxData, setCheckPoints, setCheckNeighborPipe }: { bboxDataSuccess?: boolean; bboxData: any; setCheckPoints?: Dispatch<SetStateAction<any>>; setCheckNeighborPipe?: Dispatch<SetStateAction<any>> }) {
  const [isObjectReady, setIsObjectReady] = useState(false);
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [line, setLine] = useState<ObjectMapPipeLine>();

  const [mapIsReady, setMapIsReady] = useState(false);
  const [pipeIsReady, setPipeIsReady] = useState(false);
  // 초기 화면 Fit
  useEffect(() => {
    if (mapIsReady) {
      line?.mapChange();
    }
  }, [mapIsReady]);

  useEffect(() => {
    if (pipeIsReady) {
      line?.mapChangePipe();
    }
  }, [pipeIsReady]);

  useEffect(() => {
    if (isObjectReady) {
      if (bboxData?.points?.length > 0) {
        line!.drawPointSingleBySurvey(bboxData?.points, true);

        setMapIsReady(true);
      }
      if (bboxData?.pipes?.length > 0) {
        const newPipes = bboxData?.pipes?.map((ele) => ({ ...ele, diameter: 800 }));
        line!.draw(newPipes, 'WATER', true); // (+moveLayer)
        //   line?.selectPointBySurvey(setCheckPoints, setCheckNeighborPipe);
        setPipeIsReady(true);
      }
    }
  }, [isObjectReady, bboxData, line]);

  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const line = new ObjectMapPipeLine(core);

      // ** FullScreen 컨트롤 삭제
      core.mapInstance.getControls().forEach(function (control) {
        if (control instanceof FullScreen) {
          core.mapInstance.removeControl(control);
        }
      });

      setMapInstance(core);
      setLine(line);
      setIsObjectReady(true);
    }
  }, []);

  // 풀스크린 버튼 위치 변경
  useEffect(() => {
    const zoomBtn: any = document.getElementsByClassName('ol-zoom')?.[0];

    if (zoomBtn) {
      zoomBtn.style.display = 'none';
      zoomBtn.style.bottom = '10px';
      zoomBtn.style.right = '10px';
    }
  }, [isObjectReady]);

  return (
    <>
      <div style={{ width: '100%', height: '100%', position: 'relative' }} id={'mapContainer'}></div>
      {/* <div>지도</div> */}
    </>
  );
}

export default SurveyDelMap;
