import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import DatePicker, { Calendar, CalendarRef, DateObject } from 'react-multi-date-picker';
import { CalendarStyle } from './_style';
import { DaysForLocale, MonthsForLocale } from '../../util/global';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import SkeletonCalendarCont from '../skeleton/SkeletonCalendarCont';
import { useTranslation } from 'react-i18next';

const ReportCalendar = ({ workDate, date, setDate }: { workDate: string[]; date: DateObject; setDate: Dispatch<SetStateAction<DateObject>> }) => {
  const { t, i18n } = useTranslation();
  const monthsKo = MonthsForLocale(i18n.language === 'ko' ? 'ko' : 'en', 'short');
  const weekdaysKo = DaysForLocale('ko', 'short');
  const [themeMode] = useRecoilState(themeState);

  // const [date, setDate] = useState<DateObject>(new DateObject());
  const [monthState, setMonthState] = useState(false);
  const [yearState, setYearState] = useState(false);
  //오늘 날짜 바로 가기
  const onToday = () => {
    setDate(new DateObject(new Date()));
  };
  // // 월별 작업 발생 횟수 정보 리스트
  // const monthlyWorkList = () => {
  //     const newArr: { year:number, month:number }[] = []
  //     workDate.filter((i) => new DateObject(i).year === date.year).map((i) => {
  //         const year = new DateObject(i).year
  //         const month = new DateObject(i).month.number
  //         newArr.push({
  //             year:year,
  //             month:month
  //         })
  //     })
  //     const data = newArr.reduce((acc:any, curr) => {
  //         const idx = acc.findIndex((obj:any) => obj['month'] === curr['month']);
  //         if (idx === -1) acc.push(curr);
  //         return acc;
  //     }, []);
  //     return [...data]
  // }
  //
  //
  // // 연별 작업 발생 횟수 정보 리스트
  // const yearlyWorkList = () => {
  //     const newArr:number[] = []
  //     workDate.map((i) => {
  //         newArr.push(new DateObject(i).year)
  //     })
  //     return [...new Set(newArr)]
  // }
  //
  //
  // //작업 발생 월의 첫번째 날짜 바로가기
  // const onLinkMonth = (i:{month:number,year:number}) => {
  //     (document.getElementsByClassName('rmdp-header-values')[0]?.children[2] as HTMLSpanElement).click()
  //     setDate(new DateObject(workDate.filter((j) => new DateObject(j).month.number === i.month && new DateObject(j).year === i.year)[0]))
  // }
  //
  // //작업 발생 년의 첫번째 날짜 바로가기
  // const onLinkYear = (i:number) => {
  //     (document.getElementsByClassName('rmdp-header-values')[0]?.children[0] as HTMLSpanElement).click()
  //     setDate(new DateObject(workDate.filter((j) => new DateObject(j).year === i)[0]))
  // }
  //

  return (
    <CalendarStyle.Wrap className={i18n.language === 'ko' ? 'ko' : undefined}>
      <SkeletonCalendarCont />
      {!(monthState || yearState) ? (
        workDate.filter((i) => i === new DateObject().format('YYYY-MM-DD')).length ? (
          <CalendarStyle.BtnToday type={'button'} onClick={onToday} disabled={new DateObject(date).format('YYYY-MM-DD') === new DateObject(new Date()).format('YYYY-MM-DD')}>
            오늘 날짜 바로 가기
          </CalendarStyle.BtnToday>
        ) : null
      ) : null}
      <Calendar
        className={themeMode === 'light' ? 'light' : undefined}
        months={monthsKo}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        //작업 발생일 redDot
        showOtherDays={true}
        mapDays={({ date }) => {
          const newDate = new DateObject(date);
          if (workDate.includes(newDate.format('YYYY-MM-DD'))) {
            return {
              children: (
                <div
                  className={'customDay'}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    width: '32px',
                    height: '32px',
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#04E762',
                    }}
                  >
                    {date.format('D')}
                  </div>
                  <span
                    style={{
                      width: 4,
                      height: 4,
                      borderRadius: '50%',
                      background: '#04E762',
                      position: 'absolute',
                      left: '50%',
                      top: 24,
                      transform: 'translateX(-50%)',
                    }}
                  ></span>
                </div>
              ),
            };
          } else {
            return {
              disabled: true,
            };
          }
        }}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        monthYearSeparator=" "
        value={date}
        // onMonthChange={(date) => {
        //     setDate(date);
        // }}
        // onYearChange={(date) => {
        //     setDate(date);
        // }}
        onChange={(selectedDates) => {
          setDate(selectedDates as DateObject);
          setMonthState(false);
          setYearState(false);
        }}
        maxDate={new DateObject().add(0, 'days')}
      />

      {/*<CalendarStyle.Wrap>*/}
      {/*    <CalendarStyle.WorkDateHead>*/}
      {/*        <CalendarStyle.WorkDateTitle>{!(monthState || yearState) ? '작업 발생일' : '작업 발생 횟수'}</CalendarStyle.WorkDateTitle>*/}
      {/*        {*/}
      {/*            !(monthState || yearState)*/}
      {/*                ? <CalendarStyle.BtnToday*/}
      {/*                    type={'button'} onClick={onToday}*/}
      {/*                    disabled={new DateObject(date).format('YYYY-MM-DD') === new DateObject(new Date()).format('YYYY-MM-DD')}*/}
      {/*                >*/}
      {/*                    오늘 날짜 바로 가기*/}
      {/*                </CalendarStyle.BtnToday>*/}
      {/*                : null*/}
      {/*        }*/}
      {/*    </CalendarStyle.WorkDateHead>*/}
      {/*    <CalendarStyle.WorkDateList>*/}
      {/*        {*/}
      {/*            !(monthState || yearState)*/}
      {/*                ? workDate.filter((i) => new DateObject(i).month.number === date.month.number && new DateObject(i).year === date.year)*/}
      {/*                    .map((i) => {*/}
      {/*                    return (*/}
      {/*                        <CalendarStyle.WorkDateItem key={i} onClick={() => {*/}
      {/*                            setDate(new DateObject(i))*/}
      {/*                        }}>*/}
      {/*                            {i.replace('-', '년 ').replace('-', '월 ')}일*/}
      {/*                        </CalendarStyle.WorkDateItem>*/}
      {/*                    )*/}
      {/*                }): null*/}

      {/*        }*/}
      {/*        {*/}
      {/*            monthState && !yearState*/}
      {/*                ? monthlyWorkList().map((i) => {*/}
      {/*                    return <CalendarStyle.WorkDateItem key={i.month} onClick={() => onLinkMonth(i)}>*/}
      {/*                        {i.year}년 {i.month}월 :*/}
      {/*                        {workDate.filter((j) => new DateObject(j).month.number === i.month && new DateObject(j).year === i.year).length}회*/}
      {/*                    </CalendarStyle.WorkDateItem>*/}
      {/*                })*/}
      {/*                : null*/}
      {/*        }*/}
      {/*        {*/}
      {/*            !monthState && yearState*/}
      {/*                ? yearlyWorkList().map((i) => {*/}
      {/*                    return <CalendarStyle.WorkDateItem key={i} onClick={() => onLinkYear(i)}>*/}
      {/*                        {i}년 :{workDate.filter((j) => new DateObject(j).year === i).length}회*/}
      {/*                    </CalendarStyle.WorkDateItem>*/}
      {/*                })*/}
      {/*                : null*/}
      {/*        }*/}
      {/*    </CalendarStyle.WorkDateList>*/}
      {/*</CalendarStyle.Wrap>*/}
    </CalendarStyle.Wrap>
  );
};

export default ReportCalendar;
