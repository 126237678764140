import { getImageBuffer } from '../../service/api/common';

const statisticsFunc = async (countData, statisticsData, wb) => {
  const statisticsSheet = wb.addWorksheet('통계');

  for (let i = 1; i <= 6; i++) {
    statisticsSheet.getColumn(i).width = 18;
  }
  console.log(statisticsData);
  let allData = {
    pipe: {
      count: {
        PIP001: 0,
        PIP002: 0,
        PIP003: 0,
        PIP004: 0,
        PIP005: 0,
        PIP006: 0,
        PIP999: 0,
      },
      distance: {
        PIP001: 0,
        PIP002: 0,
        PIP003: 0,
        PIP004: 0,
        PIP005: 0,
        PIP006: 0,
        PIP999: 0,
      },
    },
    manhole: {
      상수: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      오수: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      우수: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      가스: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      통신: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      전기: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      송유: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
      난방: {
        FOR001: 0,
        FOR002: 0,
        FOR024: 0,
        FOR000: 0,
        FOR999: 0,
      },
    },
    valve: {
      상수: {
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      오수: {
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      우수: {
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      가스: {
        GROUP1: 0,
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      송유: {
        GROUP1: 0,
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      난방: {
        GROUP2: 0,
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      전기: {
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
      통신: {
        PVE001: 0,
        PVE002: 0,
        PVE003: 0,
        PVE004: 0,
        PVE005: 0,
        PVE006: 0,
        PVE007: 0,
        PVE008: 0,
        PVE009: 0,
        PVE010: 0,
        PVE000: 0,
        PVE999: 0,
      },
    },
  };
  const pip3Arr: any = [];
  const pip4Arr: any = [];

  countData
    .filter(({ code }) => code.includes('PIP'))
    .map((i) => {
      allData.pipe.count[i.code] = allData.pipe.count[i.code] + i.quantity;
      if (i.distance) {
        allData.pipe.distance[i.code] = allData.pipe.distance[i.code] + Number(i.distance);
      }
    });
  statisticsData.map((i) => {
    i.manhole.map((manhole) => {
      allData.manhole[manhole.facilityKind][manhole.forCde] = allData.manhole[manhole.facilityKind][manhole.forCde] + 1;
    });
    i.valve.map((valve) => {
      //가스,송유 그룹1
      //난방 그룹2
      const { pveCde } = valve;
      if (valve.facilityKind === '가스' || valve.facilityKind === '송유') {
        if (pveCde === 'PVE001' || pveCde === 'PVE002' || pveCde === 'PVE003' || pveCde === 'PVE004' || pveCde === 'PVE005' || pveCde === 'PVE006' || pveCde === 'PVE007' || pveCde === 'PVE008' || pveCde === 'PVE009') {
          allData.valve[valve.facilityKind].GROUP1 = allData.valve[valve.facilityKind].GROUP1 + 1;
        }
      } else if (valve.facilityKind === '난방') {
        if (pveCde === 'PVE001' || pveCde === 'PVE002' || pveCde === 'PVE003' || pveCde === 'PVE005' || pveCde === 'PVE006' || pveCde === 'PVE007' || pveCde === 'PVE008' || pveCde === 'PVE009') {
          allData.valve[valve.facilityKind].GROUP2 = allData.valve[valve.facilityKind].GROUP2 + 1;
        }
      } else {
        allData.valve[valve.facilityKind][valve.pveCde] = allData.valve[valve.facilityKind][valve.pveCde] + 1;
      }
    });
  });
  let pip3Data = Array.from(new Set(pip3Arr.map((a) => JSON.stringify(a))), (json: any) => JSON.parse(json));
  let pip4Data = Array.from(new Set(pip4Arr.map((a) => JSON.stringify(a))), (json: any) => JSON.parse(json));
  console.log(pip3Data);
  console.log(pip3Arr);
  console.log(pip4Data);

  const sumData = (array) => {
    let val = 0;
    array.map((i) => {
      if (!!i) {
        val = val + i;
      }
    });
    return val;
  };

  //시설물별 합계(type === valve or manhole)
  const totalValue = (type) => {
    let total = 0;
    Object.keys(allData[type]).map((i) => {
      total = total + sumData(Object.values(allData[type][i]));
    });
    return total;
  };

  let allRow = statisticsSheet.addRows([
    ['시설물 통계', '', '', '', '', ''],
    ['관로 수량(EA)', '', '', '관로 연장(m)', '', ''],
    ['직관', allData.pipe.count.PIP001, '', '직관', allData.pipe.distance.PIP001, ''],
    ['단관', allData.pipe.count.PIP002, '', '단관', allData.pipe.distance.PIP002, ''],
    ['분기', allData.pipe.count.PIP003, '', '분기', allData.pipe.distance.PIP003, ''],
    ['곡관', allData.pipe.count.PIP004, '', '곡관', allData.pipe.distance.PIP004, ''],
    ['편락관', allData.pipe.count.PIP005, '', '편락관', allData.pipe.distance.PIP005, ''],
    ['관말', allData.pipe.count.PIP006, '', '관말', allData.pipe.distance.PIP006, ''],
    ['기타', allData.pipe.count.PIP999, '', '기타', allData.pipe.distance.PIP999, ''],
    ['총 수량', sumData(Object.values(allData.pipe.count)), '', '총 연장', sumData(Object.values(allData.pipe.distance)), ''], //10

    [],
    ['밸브 수량(EA)', '', '', '맨홀 수량(EA)', '', ''],
    ['상수', '제수밸브', allData.valve.상수.PVE001, '상수', '원형맨홀', allData.manhole.상수.FOR001],
    ['', '역지밸브', allData.valve.상수.PVE002, '', '이단철개', allData.manhole.상수.FOR002],
    ['', '이토밸브', allData.valve.상수.PVE003, '', '사각맨홀', allData.manhole.상수.FOR024],
    ['', '공기밸브', allData.valve.상수.PVE004, '', '미분류', allData.manhole.상수.FOR000],
    ['', '감압밸브', allData.valve.상수.PVE005, '', '기타', allData.manhole.상수.FOR999],
    ['', '안전밸브', allData.valve.상수.PVE006, '오수', '원형맨홀', allData.manhole.오수.FOR001],
    ['', '세척구', allData.valve.상수.PVE007, '', '이단철개', allData.manhole.오수.FOR002],
    ['', '점검구', allData.valve.상수.PVE008, '', '사각맨홀', allData.manhole.오수.FOR024], //20
    ['', '유량계', allData.valve.상수.PVE009, '', '미분류', allData.manhole.오수.FOR000],
    ['', '미분류', allData.valve.상수.PVE000, '', '기타', allData.manhole.오수.FOR999],
    ['', '기타', allData.valve.상수.PVE999, '우수', '원형맨홀', allData.manhole.우수.FOR001],
    ['오수', '제수밸브', allData.valve.오수.PVE001, '', '사각맨홀', allData.manhole.우수.FOR024],
    ['', '이토밸브', allData.valve.오수.PVE002, '', '미분류', allData.manhole.우수.FOR000],
    ['', '공기밸브', allData.valve.오수.PVE003, '', '기타', allData.manhole.우수.FOR999],
    ['', '미분류', allData.valve.오수.PVE000, '가스', '원형맨홀', allData.manhole.가스.FOR001],
    ['', '기타', allData.valve.오수.PVE999, '', '이단철개', allData.manhole.가스.FOR002],
    ['가스', '밸브', allData.valve.가스.GROUP1, '', '사각맨홀', allData.manhole.가스.FOR024],
    ['', '미분류', allData.valve.가스.PVE000, '', '미분류', allData.manhole.가스.FOR000], //30
    ['', '기타', allData.valve.가스.PVE999, '', '기타', allData.manhole.가스.FOR999],
    ['송유', '밸브', allData.valve.송유.GROUP1, '통신', '사각맨홀', allData.manhole.통신.FOR001],
    ['', '미분류', allData.valve.송유.PVE000, '', '미분류', allData.manhole.통신.FOR000],
    ['', '기타', allData.valve.송유.PVE999, '', '기타', allData.manhole.통신.FOR999],
    ['난방', '밸브', allData.valve.난방.GROUP2, '전기', '사각맨홀', allData.manhole.전기.FOR024],
    ['', '공기밸브', allData.valve.난방.PVE004, '', '미분류', allData.manhole.전기.FOR000],
    ['', '미분류', allData.valve.난방.PVE000, '', '기타', allData.manhole.전기.FOR999],
    ['', '기타', allData.valve.난방.PVE999, '송유', '원형맨홀', allData.manhole.송유.FOR001],
    ['총 수량', '', totalValue('valve'), '', '이단철개', allData.manhole.송유.FOR002],
    [null, null, null, '', '사각맨홀', allData.manhole.송유.FOR024], //40
    [null, null, null, '', '미분류', allData.manhole.송유.FOR000],
    [null, null, null, '', '기타', allData.manhole.송유.FOR999],
    [null, null, null, '난방', '원형맨홀', allData.manhole.난방.FOR001],
    [null, null, null, '', '이단철개', allData.manhole.난방.FOR002],
    [null, null, null, '', '사각맨홀', allData.manhole.난방.FOR024],
    [null, null, null, '', '미분류', allData.manhole.난방.FOR000],
    [null, null, null, '', '기타', allData.manhole.난방.FOR999],
    [null, null, null, '총 수량', '', totalValue('manhole')],
  ]);

  allRow.forEach((i, index) => {
    i.eachCell((cell, colNum) => {
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
      cell.font = { size: index === 0 ? 12 : 10, bold: index === 0, color: { argb: '000000' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
  });

  //한페이지 20줄 반복
  const mergeCellList = [
    'A1:F1',
    'A2:C2',
    'D2:F2',
    'B3:C3',
    'E3:F3',
    'B4:C4',
    'E4:F4',
    'B5:C5',
    'E5:F5',
    'B6:C6',
    'E6:F6',
    'B7:C7',
    'E7:F7',
    'B8:C8',
    'E8:F8',
    'B9:C9',
    'E9:F9',
    'B10:C10',
    'E10:F10',
    'A12:C12',
    'D12:F12',
    'D13:D17',
    'A13:A23',
    'D18:D22',
    'D23:D26',
    'A24:A28',
    'D27:D31',
    'A29:A31',
    'A32:A34',
    'D32:D34',
    'A35:A38',
    'D35:D37',
    'A39:B39',
    'D38:D42',
    'D43:D47',
    'D48:E48,',
  ];

  for (let j = 0; j < mergeCellList.length; j++) {
    statisticsSheet.mergeCells(mergeCellList[j]);
  }

  // 페이지 설정을 통해 한 페이지에 맞추기
  statisticsSheet.pageSetup.paperSize = 9;
  statisticsSheet.pageSetup.fitToPage = true;
  statisticsSheet.pageSetup.fitToHeight = 15;
  statisticsSheet.pageSetup.fitToWidth = 1;
  statisticsSheet.pageSetup.horizontalCentered = true; // 수평 가운데 정렬
  statisticsSheet.pageSetup.verticalCentered = true; // 수직 가운데 정렬
};

export default statisticsFunc;
