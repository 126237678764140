/* 
   새로운 utils 쿼리 파일 추가시, import-export 작업 필요
*/
import * as sites from './sites';
import * as codes from './codes';
import points from './points';
import pipes from './pipes';
import map from './map';
import survey from './survey';
import * as workLogs from './workLogs';
import * as files from './files';
import * as statistics from './statistics';
import * as calendars from './calendars';
import * as auths from './auths';
import * as designs from './designs';
import smartpin from './smartpin';
import * as shps from './shps';

//export 한꺼번에
const query = {
  sites,
  codes,
  points,
  pipes,
  map,
  survey,
  workLogs,
  files,
  statistics,
  calendars,
  auths,
  designs,
  smartpin,
  shps,
};

export default query;
