import * as pipe from './pipe';
import * as hole from './hole';
import * as pin from './pin';

const smartpin = {
  pipe,
  hole,
  pin,
};

export default smartpin;
