import styled from 'styled-components';
import IcoThemeLight from '../../assets/images/ico-theme-light.svg';
import IcoThemeLightActive from '../../assets/images/ico-theme-light-active.svg';
import IcoThemeDark from '../../assets/images/ico-theme-dark.svg';
import IcoThemeDarkActive from '../../assets/images/ico-theme-dark-active.svg';
import IcoFileDownHover from '../../assets/images/ico-file-down-hover.svg';
import IcoManageAdd from '../../assets/images/ico-manage-add-hover.svg';
import IcoSwiperPrev from '../../assets/images/ico-swiper-navi-left.svg';
import IcoSwiperPrevHover from '../../assets/images/ico-swiper-navi-left-hover.svg';
import IcoSwiperNext from '../../assets/images/ico-swiper-navi-right.svg';
import IcoSwiperNextHover from '../../assets/images/ico-swiper-navi-right-hover.svg';
import IcoMapSite from '../../assets/images/ico-map-site.svg';
import IcoNoImg from '../../assets/images/no-img.svg';
import { TocDep1Props } from './_interface';
import IcoPopClose from '../../assets/images/ico-pop-close.svg';
import IcoPopCloseLight from '../../assets/images/ico-pop-close-light.svg';
import IcoPopCloseBlue from '../../assets/images/ico-pop-close-blue.svg';
import IcoStatisticsHover from '../../assets/images/ico-total-statistics-hover.svg';
import IcoDiameterHover from '../../assets/images/ico-diameter-length-hover.svg';
import IcoSiteHover from '../../assets/images/ico-site-info-hover.svg';
import IcoSurveyTabIcon from 'assets/images/ico-survey-tab-icon.svg';
import IcoCategory from 'assets/images/ico-survey-category.svg';
import IcoCategoryActive from 'assets/images/ico-survey-category-active.svg';
import IcoInfoBlue from 'assets/images/ico-information-blue.svg';
import IcoSearch from 'assets/images/ico-search.svg';
import IcoView from 'assets/images/2dMap/ico-view.svg';
import IcoDelete from 'assets/images/2dMap/ico-delete.svg';
import IcoAddImg from 'assets/images/2dMap/ico-add-img.svg';
import { SurveyHeaderSt } from './popup/_style';
import LogoVn from 'assets/images/logo-vn.png';
const lang = navigator.language;
export const Sign = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.2);
  animation: 0.8s siren infinite linear;
  @keyframes siren {
    0% {
      background: rgba(255, 0, 0, 0.2);
    }
    33% {
      background: rgba(0, 255, 0, 0.2);
    }
    66% {
      background: rgba(0, 0, 255, 0.2);
    }
    100% {
      background: rgba(255, 0, 0, 0.2);
    }
  }
`;

export const HeaderStyleDefault = {
  Wrap: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 100%;
    box-sizing: border-box;
    background: ${(p) => p.theme.header.wrap};
    transition: 0.4s;
    display: flex;
    flex-direction: column;
  `,
  Logo: styled.h1`
    position: relative;
    height: 72px;
    background: url(${(p) => (lang.slice(0, 2) === 'vi' ? LogoVn : p.theme.header.logoImg)}) no-repeat center;
    ${() => lang.slice(0, 2) === 'vi' && 'background-size: 40%'};
    border-bottom: 1px solid ${(p) => p.theme.header.border};
    box-sizing: border-box;
  `,

  List: styled.ul`
    //height: calc(100% - 471px);
    flex: 1;
    padding: 16px 0;
  `,
  Item: styled.li<TocDep1Props<undefined>>`
    > span {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 48px);
      height: 54px;
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin: 0 auto;
      border-radius: 2px;
      text-indent: 68px;
      transition: 0.4s;
      color: ${(p) => p.theme.header.menuItem};

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.4s;
        background: ${(p) => (p.ico && p.themeMode ? `url(${p.themeMode === 'light' ? p.activeIco : p.ico})no-repeat center` : '#fff')};
      }

      &:after {
        position: absolute;
        right: -24px;
        content: '';
        width: 4px;
        height: 100%;
        background: transparent;
        border-radius: 4px 0 0 4px;
        transition: 0.4s;
      }
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &.active,
    &:hover {
      > span {
        font-weight: 500;
        background: #4880ff;
        color: #fff;

        &:before {
          background-image: url(${(p) => (p.ico ? p.ico : '')});
        }

        &:after {
          background: #4880ff;
        }
      }
    }

    &.hasDep {
      > span {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
          width: 6px;
          height: 6px;
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
          content: '';
          transform: rotate(-45deg);
          transition: 0.4s;
        }
      }

      &.active {
        > span {
          &:after {
            transform: rotate(135deg);
          }
        }
      }
    }
  `,
  Dep: styled.ul`
    display: none;
  `,
  DepItem: styled.li`
    color: #fff;
    line-height: 40px;
    padding-left: 20px;
    cursor: pointer;

    &.active {
      color: black;
    }
  `,
  Bottom: styled.div`
    margin-top: auto;
    /* height: 300px; */
  `,

  BottomBox: styled.div`
    box-sizing: border-box;
    border-top: 1px solid ${(p) => p.theme.header.border};
    padding: 16px 0 24px;

    span {
      display: block;
      padding-left: 24px;
      margin-bottom: 16px;
      transition: 0.4s;
      color: ${(p) => p.theme.header.bottomText};
    }
  `,
  BottomBtn: styled.button<{ $ico?: string; $icoActive?: string; themeMode: string }>`
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 48px);
    height: 54px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    border-radius: 2px;
    text-indent: 68px;
    transition: 0.4s;
    color: ${(p) => p.theme.header.bottomBtn};

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      left: 27px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.4s;
      background: ${(p) => (p.$ico && p.themeMode ? `url(${p.themeMode === 'dark' ? p.$ico : p.$icoActive})no-repeat center` : '#fff')};
    }

    &:hover {
      background-color: #4880ff;
      color: #fff;

      &:before {
        background-image: url(${(p) => (p.$ico ? p.$ico : '')});
      }
    }
  `,
  ChangeModeBtn: styled.button`
    position: relative;
    display: flex;
    align-items: center;
    width: 186px;
    height: 44px;
    padding: 4px;
    margin: 38px auto 0;
    background: #d8deea;
    border-radius: 4px;

    span {
      position: relative;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
      padding: 0;
      margin: 0;
      color: #9095ac;
      font-size: 14px;
      font-weight: 500;
      transition: 0.4s;

      &:first-child {
        margin-right: 8px;
        color: #242628;

        &:before {
          background-image: url(${IcoThemeLightActive});
        }
      }

      &:last-child {
        color: #9095ac;

        &:before {
          background-image: url(${IcoThemeDark});
        }
      }

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        margin-right: 6px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &:after {
      position: absolute;
      left: 4px;
      top: 4px;
      content: '';
      width: calc(50% - 8px);
      height: calc(100% - 8px);
      background: #fff;
      transition: 0.4s;
      border-radius: 2px;
    }

    &.active {
      background: #1c2431;

      span {
        &:first-child {
          color: #758292;

          &:before {
            background-image: url(${IcoThemeLight});
          }
        }

        &:last-child {
          color: #fff;

          &:before {
            background-image: url(${IcoThemeDarkActive});
          }
        }
      }

      &:after {
        background: #273142;
        left: calc(50% + 4px);
      }
    }
  `,
  Toggle: styled.button`
    position: absolute;
    right: 20px;
    top: 26px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: url(${(p) => p.theme.header.toggle.hide}) no-repeat center;
    z-index: 1;
    transition: 0.4s;
  `,
};

export const HeaderStyle = {
  ...HeaderStyleDefault,
  Wrap: styled(HeaderStyleDefault.Wrap)`
    &.hide {
      width: 96px;

      ${HeaderStyleDefault.Toggle} {
        right: -10px;
        background-image: url(${(p) => p.theme.header.toggle.show});
      }

      ${HeaderStyleDefault.Item} {
        > span {
          font-size: 0;
          width: calc(100% - 42px);

          &:before {
            left: 15px;
          }

          &:after {
            right: -21px;
          }
        }
      }

      ${HeaderStyleDefault.BottomBox} {
        span {
          font-size: 12px;
          padding: 0;
          display: block;
          text-align: center;
        }
      }

      ${HeaderStyleDefault.BottomBtn} {
        font-size: 0;
        width: calc(100% - 42px);

        &:before {
          left: 15px;
        }

        &:after {
          right: -21px;
        }
      }

      ${HeaderStyleDefault.ChangeModeBtn} {
        width: 44px;

        span {
          margin: 0;
          font-size: 0;

          &:before {
            margin: 0 auto;
          }

          &:first-child {
            display: flex;
          }

          &:last-child {
            display: none;
          }
        }

        &.active {
          span {
            &:first-child {
              display: none;
            }

            &:last-child {
              display: flex;
              font-size: 0;
            }
          }
        }

        &:after {
          left: 4px;
          top: 4px;
          width: calc(100% - 8px);
        }
      }
    }
  `,
};

export const DashboardStyle = {
  Contain: styled.div`
    width: 100%;
    height: 100%;
  `,
  Label: styled.strong`
    font-weight: 600;
    font-size: 14px;
    display: block;
    margin-top: 8px;
    color: ${(p) => p.theme.dashboard.totalChart.title};
  `,
  Inner: styled.div`
    height: calc(100% - 32px);
    padding: 16px 40px;
  `,
  NoData: styled.p`
    color: #a0aec0;
    font-weight: 500;
    font-size: 14px;
    margin-top: 22px;
  `,
  SectionBox: styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid ${(p) => p.theme.dashboard.box.border};
    box-sizing: border-box;
    border-radius: 2px;
    overflow: hidden;
    transition: 0.4s;
  `,
  TitleArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    min-height: 52px;
    background: ${(p) => p.theme.dashboard.box.titleArea};
  `,
  TitleGroup: styled.div`
    display: flex;
    align-items: center;
  `,
  Title: styled.h3`
    color: ${(p) => p.theme.dashboard.box.title};
    font-size: 16px;
    font-weight: 600;
  `,
  Date: styled.strong`
    color: ${(p) => p.theme.dashboard.box.title};
    font-size: 14px;
    font-weight: 500;
    margin-left: 16px;
  `,
  DescArea: styled.div<{ isLogin?: boolean }>`
    background: ${(p) => (p.isLogin ? '#273142' : p.theme.dashboard.box.cont)};
    flex: 1;
    #mapContainer .ol-zoom {
      right: 10px;
      bottom: 10px;
    }
  `,
  DescInner: styled.div`
    height: calc(100% - 44px);
    padding: 22px 24px;
  `,
  InfoItem: styled.div`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    strong {
      font-size: 14px;
      font-weight: 400;
      color: ${(p) => p.theme.dashboard.info.title};
      margin-bottom: 6px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: ${(p) => p.theme.dashboard.info.desc};
    }
  `,
  InfoBoxGroup: styled.div`
    &:not(:last-child) {
      margin-bottom: 5px;

      &.active {
        margin-bottom: 15px;
      }
    }
  `,
  InfoBox: styled.div`
    background: ${(p) => p.theme.dashboard.info.contBox};
    border-radius: 4px;
    display: none;
    padding: 13px 12px;
    margin-top: 13px;

    &.active {
      display: block;
    }
  `,
  InfoTitleRow: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    > strong {
      margin-bottom: 0;
    }

    > button {
      width: 20px;
      height: 20px;
      background: url(${(p) => p.theme.dashboard.info.icoShow}) no-repeat center;

      &.active {
        background: url(${(p) => p.theme.dashboard.info.icoHide}) no-repeat center;
      }
    }
  `,
  InfoRow: styled.div`
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: 11px;
    }

    > b {
      color: ${(p) => p.theme.dashboard.info.contLabel};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      min-width: 54px;
    }

    > span {
      color: ${(p) => p.theme.dashboard.info.contText};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      word-break: break-word;
    }

    > ul {
      li {
        color: ${(p) => p.theme.dashboard.info.contText};
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  `,
  Wrap: styled.div`
    display: flex;
    //height: calc(100% - 84px);
    height: 100%;
  `,
  DataArea: styled.div`
    flex: 1;
    height: 100%;
    margin-left: 24px;
  `,
  CalendarArea: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 286px;
    height: 100%;
    box-sizing: border-box;
  `,
  ChartArea: styled.div`
    display: flex;
    height: 330px;
    @media all and (max-width: 1440px) {
      > section {
        &:last-child {
          width: 0;
          overflow: hidden;
          visibility: hidden;
          margin-left: 0 !important;
        }
      }
    }
  `,
  ChartFlex: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  `,
  ChartRow: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50%;
    width: 100%;
    min-width: 368px;
  `,
  ChartBox: styled.div`
    position: relative;
    width: 115px;
    height: 115px;
    text-align: center;
    min-width: 80px;
  `,
  ChartTotal: styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 10;

    strong {
      display: block;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      color: ${(p) => p.theme.dashboard.totalChart.title};
    }

    p {
      font-size: 36px;
      font-weight: 700;
      color: ${(p) => p.theme.dashboard.totalChart.percent};

      span {
        font-weight: 400;
      }
    }
  `,
  TableArea: styled.div`
    margin-top: 16px;
    height: calc(100% - 346px);
    overflow: hidden;
  `,
};

export const CalendarStyle = {
  Wrap: styled.div`
    position: relative;
    width: 100%;
    height: 330px;
    .rmdp-header-values {
      span {
        &:first-child {
          &:after {
            content: '';
          }
        }
      }
    }
    &.ko {
      .rmdp-header-values {
        span {
          &:first-child {
            &:after {
              content: '년';
            }
          }
        }
      }
    }
  `,
  WorkDateHead: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  WorkDateTitle: styled.strong`
    font-size: 16px;
    font-weight: 500;
  `,
  WorkDateList: styled.ul``,
  WorkDateItem: styled.li`
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
  `,
  BtnToday: styled.button`
    position: absolute;
    right: 25px;
    top: 57px;
    z-index: 10;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    color: ${(p) => p.theme.dashboard.info.title};

    &:disabled {
      display: none;
    }
  `,
};

export const TableListStyle = {
  Wrap: styled.div`
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
  `,
  Head: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  `,
  Title: styled.strong`
    font-size: 24px;
    font-weight: 500;
  `,
  BtnStatistic: styled.button`
    padding: 0 12px;
    transition: 0.4s;
    height: 40px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid ${(p) => p.theme.button.type1.default.border};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.button.type1.default.color};
    background-color: ${(p) => p.theme.button.type1.default.bg};
    &:hover {
      border: 1px solid #0076ff;
      color: #0076ff;
      box-shadow: 0 4px 4px 0 rgba(0, 118, 255, 0.2);
      &:before {
        background: url(${IcoStatisticsHover}) no-repeat center;
      }
    }
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      transition: 0.4s;
      background: url(${(p) => p.theme.dashboard.table.statistics}) no-repeat center;
      margin-right: 9px;
    }
  `,
  BtnDiameterLength: styled.button`
    padding: 0 12px;
    transition: 0.4s;
    height: 40px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid ${(p) => p.theme.button.type1.default.border};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.button.type1.default.color};
    background-color: ${(p) => p.theme.button.type1.default.bg};
    &:hover {
      border: 1px solid #0076ff;
      color: #0076ff;
      box-shadow: 0 4px 4px 0 rgba(0, 118, 255, 0.2);
      &:before {
        background: url(${IcoDiameterHover}) no-repeat center;
      }
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      transition: 0.4s;
      background: url(${(p) => p.theme.dashboard.table.diameter}) no-repeat center;
      margin-right: 9px;
    }
  `,
  Cont: styled.div`
    display: flex;
    height: 100%;
  `,
  SelectArea: styled.div`
    display: flex;
    transition: 0.4s;
    &.w1600 {
      display: none;
      padding: 6px 24px;
      box-sizing: border-box;
      border-bottom: 1px solid ${(p) => p.theme.dashboard.table.border};
    }
    @media screen and (max-width: 1660px) {
      &.w1920 {
        display: none;
      }
      &.w1600 {
        display: flex;
      }
    }
  `,
  SelectWrap: styled.dl`
    position: relative;
    width: 188px;
    text-align: center;

    dt {
      font-size: 14px;
      color: ${(p) => p.theme.dashboard.filter.dt};
      font-weight: 600;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-bottom: 1px solid ${(p) => p.theme.dashboard.filter.border};

      span {
        flex: 1;
        border-right: 1px solid ${(p) => p.theme.dashboard.filter.border};
        line-height: 32px;
      }
    }

    dd {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 48px);
      height: 44px;
      margin: 0 auto;
      transition: 0.4s;
      font-size: 14px;
      font-weight: 600;
      color: ${(p) => p.theme.dashboard.filter.dd};
      cursor: pointer;

      &:nth-child(2) {
        margin-top: 20px;
      }

      &.active,
      &:hover {
        color: ${(p) => p.theme.dashboard.filter.ddActive};
        background: ${(p) => p.theme.dashboard.filter.bg};
        border-radius: 2px;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% - 49px);
      background: ${(p) => p.theme.dashboard.filter.after};
      position: absolute;
      right: 0;
      top: 49px;
    }
  `,
  TableContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  `,
  DailyArea: styled.div`
    display: flex;
    height: 191px;
    box-shadow: 0 -15px 10px -3px ${(p) => p.theme.report.shadow};
  `,
  DailyBox: styled.div`
    height: 100%;
    flex: 1;
    box-sizing: border-box;

    &:not(:last-child) {
      border-right: 1px solid ${(p) => p.theme.dashboard.filter.border};
    }
  `,
  DailyTitle: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: ${(p) => p.theme.report.photoGroupBg};
    color: ${(p) => p.theme.report.text};
    font-size: 16px;
    font-weight: 600;
  `,
  DailyCont: styled.div`
    height: calc(100% - 40px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
  `,
  DailyList: styled.ul`
    padding: 16px 0;
  `,
  DailyItem: styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 13px;
    }

    strong,
    span {
      color: ${(p) => p.theme.report.text};
      font-size: 14px;
      font-weight: 600;
    }

    strong {
      min-width: 90px;
    }
  `,
};

export const ReportStyle = {
  ...DashboardStyle,
  Map: styled.div`
    position: relative;
  `,
  MapLegend: styled.div`
    position: absolute;
    left: 10px;
    top: 57px;
    z-index: 10;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 140px;
    padding: 15px;
    border-radius: 2px;
  `,
  BtnGroup: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
  `,
  MapLinkBtn: styled.button`
    transition: 0.4s;
    width: 132px;
    height: 40px;
    color: #fff;
    background: #4379ee;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    &:hover {
      background: #6b96f3;
    }
  `,
  MapSelect: styled.div`
    display: flex;
    position: absolute;
    left: 10px;
    top: 12px;
    z-index: 10;
    border-radius: 2px;
    background: #fff;
    height: 36px;
    border: 1px solid #ccc;
    box-sizing: border-box;

    > button {
      padding: 0 10px;
      color: #777;
      width: 70px;
      font-weight: 500;
      font-size: 14px;
      transition: 0.4s;
      &:hover {
        background: #eee;
      }
      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }

      &.active {
        color: #111;
      }
    }
  `,
  MapSiteBtn: styled.button`
    position: absolute;
    right: 10px;
    bottom: 106px;
    width: 36px;
    height: 36px;
    z-index: 10;
    background: #fff url(${IcoMapSite}) no-repeat center;
    border: 1px solid #ccc;
    border-radius: 2px;
  `,
  Wrap: styled.div`
    height: 100%;
    display: flex;
  `,
  CalendarArea: styled(DashboardStyle.CalendarArea)`
    height: 100%;
  `,
  TableArea: styled(DashboardStyle.TableArea)`
    flex: 1;
    height: 100%;
    margin-top: 0;
    margin-left: 22px;
  `,
  DailyArea: styled.div`
    display: flex;
  `,
  DailyWrap: styled.dl`
    flex: 1;
    text-align: center;

    dt {
      font-weight: 500;
    }

    dd {
      font-weight: 200;
    }
  `,
  DownloadArea: styled.div``,
  DownloadDesc: styled.p`
    color: #999;
    font-size: 14px;
    font-weight: 200;
  `,
  DownloadBtnArea: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  DownloadBtn: styled.button<{ $type: string }>`
    border-radius: 50px;
    height: 50px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 180px;
    background: ${(p) => (p.$type === 'pdf' ? '#ff4949' : '#00cc0e')};

    &:not(:last-child) {
      margin-right: 10px;
    }
  `,
  PhotoArea: styled.div`
    width: 320px;
    height: 100%;
    box-sizing: border-box;
  `,
  PhotoWrap: styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 49px);
    border-left: 1px solid ${(p) => p.theme.dashboard.filter.border};
  `,
  PhotoHead: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 1px solid ${(p) => p.theme.dashboard.filter.border};
    height: 49px;

    &:after {
      content: '';
      width: 1px;
      height: 32px;
      background: ${(p) => p.theme.dashboard.filter.after};
      position: absolute;
      left: 0;
      top: 8px;
    }

    &:before {
      content: '';
      width: 4px;
      height: 1px;
      background: ${(p) => p.theme.dashboard.filter.after};
      position: absolute;
      right: 100%;
      bottom: -1px;
    }
  `,
  PhotoTitle: styled.strong`
    color: ${(p) => p.theme.report.text};
    font-size: 14px;
    font-weight: 600;
  `,
  PhotoCont: styled.div`
    > img {
      width: 100%;
      cursor: pointer;
    }
  `,
  PhotoInner: styled.div`
    padding: 16px 24px;
    border-bottom: 1px solid ${(p) => p.theme.dashboard.filter.border};
  `,
  PhotoItem: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid ${(p) => p.theme.dashboard.filter.border};
    box-sizing: border-box;
    border-radius: 2px;
    overflow: hidden;

    &:not(:last-child) {
      border-bottom: 0;
    }

    .photoSwiper {
      width: 100%;
      background: ${(p) => p.theme.report.photoGroupBg};

      .swiper-pagination {
        position: static;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 17px;
        bottom: 14px;

        .swiper-pagination-bullet {
          width: 20px;
          height: 4px;
          border-radius: 50px;
          background: ${(p) => p.theme.report.pagination};
          opacity: 1;
          margin: 0;

          &:not(:last-child) {
            margin: 0 8px 0 0;
          }

          &.swiper-pagination-bullet-active {
            background: #0076ff;

            &.swiper-pagination-bullet:only-child {
              display: block !important;
            }
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 20px;
        height: 20px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 1;

        &:after {
          display: none;
        }

        &.swiper-button-disabled {
          display: none;
        }

        &:hover {
          opacity: 1;
        }
      }

      .swiper-button-prev {
        background-image: url(${IcoSwiperPrev});
        transform: translateX(-6px);

        &:hover {
          background-image: url(${IcoSwiperPrevHover});
        }
      }

      .swiper-button-next {
        background-image: url(${IcoSwiperNext});
        transform: translateX(6px);

        &:hover {
          background-image: url(${IcoSwiperNextHover});
        }
      }
    }
  `,
  PhotoGroup: styled.div`
    display: flex;
    flex: 1;
    padding: 20px 12px 10px;
  `,
  PhotoName: styled.strong`
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.report.text};
    font-size: 14px;
    font-weight: 500;
    background: ${(p) => p.theme.report.photoGroupBg};
    box-sizing: border-box;
    border-bottom: 1px solid ${(p) => p.theme.dashboard.filter.border};

    > span {
      margin-right: 10px;
    }
  `,
  PhotoBox: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    background: ${(p) => p.theme.report.photoBox};
    border-radius: 4px;
    overflow: hidden;
    height: 90px;
    box-sizing: border-box;
    cursor: pointer;
    &.null {
      &:hover {
        cursor: default;
        &:after {
          display: none;
        }
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
    }

    > img {
      width: 100%;
      height: 100%;
    }

    > span {
      display: none;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 12px;
      font-weight: 500;
    }

    &:hover {
      > span {
        display: block;
      }

      &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 2px solid #0076ff;
        border-radius: 4px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.6);
      }
    }
  `,
  PhotoNull: styled.div`
    width: 100%;
    height: 100%;
    background: ${(p) => p.theme.report.null} url(${IcoNoImg}) no-repeat center;
  `,
  DetailInfoArea: styled.div`
    padding: 16px 24px;
  `,
  DetailInfoHead: styled.div`
    display: flex;
    align-items: center;
    height: 37px;
    padding: 0 16px;
    background: ${(p) => p.theme.report.infoHead};
  `,
  DetailInfoTitle: styled.strong`
    font-size: 14px;
    font-weight: 600;
    color: ${(p) => p.theme.report.text};
  `,
  DetailInfoCont: styled.div`
    display: flex;
  `,
  DetailInfoBox: styled.ul`
    flex: 1;
    padding: 14px 17px;
    border-bottom: 1px solid ${(p) => p.theme.dashboard.filter.border};
  `,
  DetailInfoItem: styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(p) => p.theme.report.text};
    font-size: 14px;
    font-weight: 500;

    > span {
      font-weight: 600;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  `,
  ImageDimmed: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    backdrop-filter: blur(10px);
  `,
  ImagePopup: styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    background: #fff;
  `,
  ImageInner: styled.div`
    padding: 40px;
    height: calc(100% - 80px);
    text-align: center;

    > img {
      height: 100%;
    }
  `,
  ImageCloseBtn: styled.button`
    position: absolute;
    right: -25px;
    top: -25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: red;
    color: #fff;
    font-size: 24px;
  `,
};

export const PopupStyle = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  `,
  Popup: styled.div<{ $width?: number }>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    box-shadow: ${(p) => p.theme.popup.cont.shadow};
    border-radius: 4px;
    background: ${(p) => p.theme.popup.cont.bg};
    width: ${(p) => (p.$width ? p.$width + 'px' : '1200px')};
    box-sizing: border-box;

    .thumbSwiper {
      padding: 12px 23px;
      background: #000;

      .swiper-scrollbar {
        opacity: 1;
        height: 6px;
        margin-top: 8px;
        background: #364257;
        border-radius: 50px;

        .swiper-scrollbar-drag {
          height: 6px;
          background: #67758b;
          border-radius: 50px;
        }
      }

      .swiper-slide {
        border-radius: 4px;
        overflow: hidden;

        &.swiper-slide-thumb-active {
          &:after {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 2px solid #0076ff;
            border-radius: 4px;
          }
        }
      }
    }
  `,
  Head: styled.div<{ $height?: number; $align?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    background: ${(p) => p.theme.popup.head.bg};
    padding: 0 24px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
    justify-content: ${(p) => (p.$align === 'left' ? 'flex-start' : 'center')};
    height: ${(p) => (p.$height ? p.$height + 'px' : '56px')};
    border-bottom: 1px solid ${(p) => p.theme.popup.head.border};
  `,
  Title: styled.h4`
    color: ${(p) => p.theme.popup.head.title};
    font-size: 16px;
    font-weight: 600;
  `,
  Inner: styled.div<{ $padding?: string }>`
    padding: ${(p) => (p.$padding ? p.$padding : '16px 24px')};
  `,
  CloseBtn: styled.button<{ $right?: number; $top?: number; $isDimmed?: boolean }>`
    position: absolute;
    transform: ${(p) => (p.$top ? 'none' : 'translateY(-50%)')};
    width: 42px;
    height: 42px;
    right: ${(p) => (p.$right ? p.$right + 'px' : '16px')};
    transition: 0.4s;
    top: ${(p) => (p.$top ? p.$top + 'px' : '50%')};
    background: url(${(p) => (p.$isDimmed ? IcoPopClose : p.theme.popup.head.icoClose)}) no-repeat center;
    border-radius: 4px;

    &:hover {
      background-color: ${(p) => p.theme.popup.head.btnHoverColor};
    }

    &:active {
      background-color: ${(p) => p.theme.popup.head.btnActiveColor};
    }
  `,
  Cont: styled.div``,
  ChartArea: styled.div`
    flex: 1;
    background: ${(p) => p.theme.chart.bg};
    border: 1px solid ${(p) => p.theme.chart.border};
    box-sizing: border-box;
    border-radius: 4px;
  `,
  Foot: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    height: 80px;
    background: ${(p) => p.theme.popup.foot.bg};
    border-top: 1px solid ${(p) => p.theme.popup.foot.border};
  `,
  FootDesc: styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: ${(p) => p.theme.popup.foot.color};

    b {
      color: #0076ff;
      font-size: 12px;
      font-weight: 500;
      margin: 0 2px 0 5px;
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${(p) => p.theme.popup.foot.ico}) no-repeat center;
      margin-right: 10px;
    }
  `,
  ImageTitle: styled.strong`
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 21px;
  `,
  ImageBox: styled.div`
    width: 100%;
    height: 600px;
    text-align: center;
    background: #000;
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    > img {
      height: 100%;
    }
  `,
  ImageList: styled.ul`
    background: #000;
    border-radius: 0 0 4px 4px;
  `,
  ImageItem: styled.div`
    position: relative;
    width: 120px;
    height: 90px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 12px;
    }

    > img {
      height: 100%;
    }
  `,
};
export const ContainerStyle = {
  Contain: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    transition: 0.4s;
    background: ${(p) => p.theme.container.contain};
  `,
  Wrap: styled.div`
    position: relative;
    flex: 1;
    overflow: auto;
  `,
  TitleArea: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    transition: 0.4s;
    border-bottom: 1px solid ${(p) => p.theme.container.titleBorder};
    height: 72px;
    box-sizing: border-box;
  `,
  Title: styled.h2`
    font-weight: 600;
    font-size: 24px;
    color: ${(p) => p.theme.container.title};
  `,
  InfoArea: styled.div`
    display: flex;
    align-items: center;
  `,
  Version: styled.strong`
    color: ${(p) => p.theme.container.version};
    font-size: 12px;
    font-weight: 500;
    margin-right: 20px;
  `,
  SiteInfoBtn: styled(TableListStyle.BtnStatistic)`
    background-color: transparent;
    &:before {
      background: url(${(p) => p.theme.container.infoBtn.ico}) no-repeat center;
      margin-right: 6px;
    }
    &:hover {
      &:before {
        background: url(${IcoSiteHover}) no-repeat center;
      }
    }
  `,
  Content: styled.div`
    min-width: 1360px;
    height: 100%;

    &.full {
      /* min-width: 1440px; */
    }

    &.mapWidth {
      min-width: auto;
    }

    &.orgWidth {
      min-width: 1550px;
    }
  `,
  Inner: styled.div`
    height: calc(100% - 72px); //margin:0 60px;
  `,
};

export const FileDownStyle = {
  Projection: styled.strong`
    font-weight: 400;
    font-size: 14px;
    color: ${(p) => p.theme.fileDown.color};
    margin-right: 25px;
  `,
  Group: styled.div`
    display: flex;
    align-items: center;

    > button {
      &:not(:last-child) {
        margin-right: 9px;
      }
    }
  `,
  Button: styled.button<{ $type?: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18.5px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 2px;
    transition: 0.4s;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid ${(p) => (p.$type === 2 ? p.theme.report.saveBtn : p.theme.fileDown.border)};
    background: ${(p) => (p.$type === 2 ? 'transparent' : p.theme.fileDown.bg)};
    color: ${(p) => p.theme.fileDown.color};

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url(${(p) => p.theme.fileDown.ico}) no-repeat center;
      transition: 0.4s;
      margin-right: 6px;
    }

    &:hover {
      border: 1px solid #0076ff;
      background: rgba(72, 128, 255, 0.15);
      color: #0076ff;
      box-shadow: 0 4px 4px 0 #0076ff33;

      &:before {
        background-image: url(${IcoFileDownHover});
      }
    }
  `,
};

export const SurveyStyle = {
  ...DashboardStyle,
  TableArea: styled.div`
    width: 100%;
    height: 100%;
  `,
  CategoryArea: styled.div`
    box-sizing: border-box;
    border-bottom: 1px solid ${(p) => p.theme.survey.category.area.border};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  CategoryWrap: styled.ul`
    display: flex;
  `,
  CategoryItem: styled.li`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(p) => p.theme.survey.category.item.bg};
    height: 48px;
    padding: 0 20px 0 28px;
    border: 1px solid ${(p) => p.theme.survey.category.item.border};
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
    font-size: 16px;
    color: ${(p) => p.theme.survey.category.item.color};
    > span {
      width: 24px;
      height: 24px;
      background: url(${(p) => p.theme.survey.category.item.ico}) no-repeat center;
      margin-right: 8px;
    }
    &.active {
      color: #0076ff;
      background: ${(p) => p.theme.survey.category.item.activeBg};
      transform: translateY(1px);
      z-index: 2;
      > span {
        background-image: url(${IcoCategoryActive});
      }
      &:before {
        position: absolute;
        z-index: 1;
        left: 100%;
        bottom: 0;
        content: '';
        border-left: 14px solid ${(p) => p.theme.survey.category.item.activeBg};
        border-top: 24px solid transparent;
        border-bottom: 24px solid ${(p) => p.theme.survey.category.item.activeBg};
        border-right: 14px solid transparent;
      }
    }
    &:not(:last-child) {
      margin-right: 14px;
    }
    &:before {
      position: absolute;
      z-index: 1;
      left: 100%;
      bottom: 0;
      content: '';
      border-left: 14px solid ${(p) => p.theme.survey.category.item.bg};
      border-top: 24px solid transparent;
      border-bottom: 24px solid ${(p) => p.theme.survey.category.item.bg};
      border-right: 14px solid transparent;
    }
    &:after {
      position: absolute;
      z-index: 1;
      right: -39px;
      top: 24px;
      content: '';
      width: 54px;
      height: 1px;
      transform: rotate(60deg);
      background: ${(p) => p.theme.survey.category.item.border};
    }
  `,
  CategoryBtnGroup: styled.div`
    display: flex;
    margin-left: 30px;
    align-items: center;
    > button {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  `,
  CategoryBtn: styled.button`
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.survey.category.btn.border};
    background-color: ${(p) => p.theme.survey.category.btn.bg};
    &.prev,
    &.next {
      &:after {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-bottom: 2px solid ${(p) => p.theme.survey.category.btn.arrow};
        border-left: 2px solid ${(p) => p.theme.survey.category.btn.arrow};
      }
    }
    &.prev {
      &:after {
        transform: translate(calc(-50% + 2px), -50%) rotate(45deg);
      }
    }
    &.next {
      &:after {
        transform: translate(calc(-50% - 2px), -50%) rotate(-135deg);
      }
    }
    &:hover {
      background: rgba(72, 128, 255, 0.25);
      border: none;
    }
  `,
  TabArea: styled.div`
    padding: 0 24px;
    height: 56px;
    box-sizing: border-box;
    border-bottom: 1px solid ${(p) => p.theme.survey.tab.border};
    display: flex;
    align-items: center;
  `,
  BtnMap: styled.button`
    width: 24px;
    height: 24px;
    background: url(${(p) => p.theme.survey.tab.ico}) no-repeat center;
    margin-right: 24px;
  `,
  SearchBtn: styled.button`
    width: 40px;
    height: 40px;
    background: #4379ee url(${IcoSearch}) no-repeat center;
    margin-left: 8px;
    border-radius: 2px;
  `,
  TabWrap: styled.ul`
    display: flex;
  `,
  TabItem: styled.li`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.survey.tab.color};
    font-size: 16px;
    font-weight: 500;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &.active {
      color: ${(p) => p.theme.survey.tab.activeColor};
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 100%;
        height: 3px;
        background: ${(p) => p.theme.survey.tab.activeColor};
      }
    }
  `,
  FilterArea: styled.div`
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 12px;
  `,
  FilterWrap: styled.ul`
    display: flex;
    align-items: center;
  `,
  FilterItem: styled.li`
    cursor: pointer;
    padding: 0 16px;
    height: 32px;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 50px;
    border: 1px solid ${(p) => p.theme.survey.tabFilter.border};
    color: ${(p) => p.theme.survey.tabFilter.color};
    font-weight: 500;
    font-size: 14px;
    &:not(:last-child) {
      margin-right: 4px;
    }

    &.active {
      color: #fff;
      background: #4379ee;
      border: 1px solid transparent;
    }
    &.more {
      position: relative;
      &.active {
        color: #fff;
        background: rgba(0, 118, 255, 0.15);
        border: 1px solid ${(p) => p.theme.survey.tabFilter.border};
        span {
          background: ${(p) => p.theme.survey.popFilter.icoActive};
        }
      }
    }
  `,
  FilterEllipsis: styled.div`
    display: flex;
    width: 20px;
    justify-content: space-between;
    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${(p) => p.theme.survey.popFilter.ico};
    }
  `,
  FilterBox: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 520px;
    right: 4px;
    top: 40px;
    z-index: 100;
    border-radius: 12px;
    background: ${(p) => p.theme.survey.popFilter.bg};
    box-shadow: ${(p) => p.theme.survey.popFilter.shadow};
  `,
  FilterBoxInner: styled.div`
    padding: 18px 22px 14px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  `,
  FilterBoxList: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  FilterClose: styled(SurveyHeaderSt.Button)`
    width: 100px;
    height: 40px;
    justify-content: center;
    margin: 12px 0;
  `,
  DescArea: styled(DashboardStyle.DescArea)`
    width: 100%;
    height: 100%;
    background: none;
  `,
  DescWrap: styled.div`
    width: 100%;
    height: calc(100% - 50px);
    background: ${(p) => p.theme.survey.bg};
    border: 1px solid ${(p) => p.theme.survey.border};
    border-top: 0;
  `,
  ScrollArea: styled.div`
    height: calc(100% - 86px);
    overflow: auto;
  `,
};

export const ContAddBtn = styled(FileDownStyle.Button)`
  &:before {
    background: url(${(p) => p.theme.manageBtn}) no-repeat center;
  }
  &:hover {
    &:before {
      background-image: url(${IcoManageAdd});
    }
  }
`;

export const DataFilterStyle = {
  Wrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${(p) => p.theme.dataFilter.bg};
    border: 1px solid ${(p) => p.theme.dataFilter.border};
    box-sizing: border-box;
    padding: 16px 24px;
    border-radius: 4px;
    .rmdp-header-values {
      span {
        &:first-child {
          &:after {
            content: '';
          }
        }
      }
    }
    &.ko {
      .rmdp-header-values {
        span {
          &:first-child {
            &:after {
              content: '년';
            }
          }
        }
      }
    }
  `,
  ListWrap: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: auto;
    .rmdp-header-values {
      span {
        &:first-child {
          &:after {
            content: '';
          }
        }
      }
    }
    &.ko {
      .rmdp-header-values {
        span {
          &:first-child {
            &:after {
              content: '년';
            }
          }
        }
      }
    }
  `,
  HeightBar: styled.span`
    margin: 0 12px;
    width: 1px;
    height: 40px;
    background: ${(p) => p.theme.dataFilter.border};
  `,
  WidthBar: styled.span`
    margin: 0 10px;
    width: 10px;
    height: 2px;
    background: ${(p) => p.theme.dataFilter.border};
  `,
  Group: styled.div`
    display: flex;
    align-items: center;
    > span {
      color: ${(p) => p.theme.form.cont.picker.fromTo};
    }
  `,
  Text: styled.p`
    font-weight: 500;
    font-size: 12px;
    color: ${(p) => p.theme.dataFilter.text};
    margin-right: 20px;
  `,
  BtnSearch: styled.button`
    width: 100px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4379ee;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: 0.4s;
    &:hover {
      background: #6b96f3;
    }
    &:active {
      background: #2961d8;
    }
    &:disabled {
      background: ${(p) => p.theme.form.cont.stepBtn.disabled.bg};
      color: ${(p) => p.theme.form.cont.stepBtn.disabled.color};
    }
  `,
};

export const LanguageStyle = {
  List: styled.ul`
    width: 100%;
  `,
  Item: styled.li`
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    background: ${(p) => p.theme.popup.language.bg};
    color: ${(p) => p.theme.popup.language.color};
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.4s;

    &:before {
      content: '';
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      margin-right: 10px;
      background: ${(p) => p.theme.popup.language.before};
      border-radius: 50%;
    }

    &.active {
      background: ${(p) => p.theme.popup.language.activeBg};
      color: #0076ff;

      &:before {
        background: #0076ff;
        width: 16px;
        height: 16px;
        outline: 2px solid #0076ff;
        border: 3px solid #fff;
        margin-right: 11px;
        margin-left: 1px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  `,
};

export const ConfirmStyle = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  `,
  Confirm: styled.div<{ $width?: number }>`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    border-radius: 4px;
    background: ${(p) => p.theme.confirm.bg};
    overflow: hidden;
    width: ${(p) => (p.$width ? p.$width + 'px' : '240px')};
  `,
  Cont: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    min-height: 91px;
    color: ${(p) => p.theme.confirm.color};
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    white-space: pre-line;
  `,
  Foot: styled.div`
    display: flex;
  `,
  Button: styled.button`
    flex: 1;
    height: 40px;
    background: ${(p) => p.theme.confirm.btnBg};
    color: ${(p) => p.theme.confirm.btnColor};
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;

    &:last-child {
      background: ${(p) => p.theme.confirm.btnSubmitBg};
      color: ${(p) => p.theme.confirm.btnSubmitColor};
    }

    &:not(:last-child) {
      border-right: 1px solid ${(p) => p.theme.confirm.border};
    }

    &:hover {
      color: ${(p) => p.theme.confirm.btnHoverColor};
    }

    &:active {
      background: ${(p) => p.theme.confirm.btnActive};
    }
  `,
};

export const TabStyle = {
  Wrap: styled.ul`
    display: flex;
    align-items: center;
    margin: 24px 0 22px;
  `,
  Item: styled.li`
    position: relative;
    color: ${(p) => p.theme.dataFilter.tabColor};
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 24px;
    }

    &.active {
      font-weight: 600;
      color: ${(p) => p.theme.dataFilter.tabActiveColor};

      &:after {
        position: absolute;
        left: 0;
        bottom: -7px;
        width: 100%;
        height: 2px;
        background: ${(p) => p.theme.dataFilter.tabActiveColor};
        content: '';
        display: block;
      }
    }
  `,
};

// 측량성과등록 _ 초기화면
export const SurveyInitSt = {
  PageWrap: styled.div`
    padding: 23px 24px;
  `,
  HelpArea: styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
  `,
  HelpBtn: styled.button`
    display: flex;
    align-items: center;
    color: #0076ff;
    font-size: 14px;
    font-weight: 600;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background: url(${IcoInfoBlue}) no-repeat center;
      background-size: cover;
    }
  `,
  HelpBox: styled.div`
    display: none;
    position: absolute;
    right: 0;
    top: 40px;
    width: 400px;
    background: ${(p) => p.theme.helpModal.box.bg};
    border: 1px solid ${(p) => p.theme.helpModal.box.border};
    &.active {
      display: block;
    }
  `,
  HelpBoxHead: styled.div`
    height: 48px;
    background: ${(p) => p.theme.helpModal.header.bg};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px 0 24px;
    color: ${(p) => p.theme.helpModal.header.color};
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid ${(p) => p.theme.helpModal.header.border};
  `,
  HelpBoxCont: styled.div`
    padding: 24px;
    p {
      color: ${(p) => p.theme.helpModal.cont.color};
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      word-break: keep-all;
    }
    hr {
      display: block;
      margin: 13px 0;
      height: 1px;
      background: ${(p) => p.theme.helpModal.cont.border};
      border: 0;
    }
  `,
  Close: styled.button`
    width: 36px;
    height: 36px;
    background: url(${(p) => p.theme.popup.head.icoClose}) no-repeat center;
    transition: 0.4s;
    &:hover {
      background-image: url(${IcoPopCloseBlue});
    }
  `,
  BlueList: styled.ul`
    margin-left: 5px;
  `,
  BlueItem: styled.li`
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #0076ff;
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      margin-right: 5px;
      border-radius: 50%;
      background: #0076ff;
      transform: translateY(7px);
    }
  `,
};

export const ImageTempStyle = {
  Wrap: styled.div`
    width: 78px;
    height: 60px;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid ${(p) => p.theme.pagingTable.image};
    margin: 9px auto;
    &:after {
      display: none;
      content: '';
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.6);
      border: 2px solid #0076ff;
      box-sizing: border-box;
      border-radius: 4px;
    }
    &:hover {
      > .hoverBox {
        display: flex;
      }
      &:after {
        display: block;
      }
    }
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    border-radius: 4px;
  `,
  HoverBox: styled.div`
    display: none;
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    width: 100%;
  `,
  EmptyBox: styled.div`
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #b2b5c0;
    border-radius: 4px;
    input[type='file'] {
      display: none;
    }
  `,
  Btn: styled.button`
    cursor: pointer;
    &.icoView {
      width: 24px;
      height: 24px;
      background: url(${IcoView}) no-repeat center;
    }
    &.icoDelete {
      width: 24px;
      height: 24px;
      background: url(${IcoDelete}) no-repeat center;
    }
    &.icoAdd {
      width: 24px;
      height: 24px;
      background: url(${IcoAddImg}) no-repeat center;
    }
    &:hover {
      &.icoView,
      &.icoDelete,
      &.icoAdd {
        filter: ${(p) => p.theme.map.infoModal.closeBtnHover};
      }
    }
  `,
};

export const CompanyStyle = {
  ...SurveyStyle,
};
