import React, { useEffect } from 'react';
import query from '../../../hooks/RQuery/utils';
import SurveyPipeTable from './SurveyPipeTable';
import SurveyPointTable from './SurveyPointTable';
import { DateObject } from 'react-multi-date-picker';
import { useSurveyPointsFirst } from '../../../hooks/RQuery/utils/survey/points';

const SurveyTable = ({ onColumnToggle, tabList, siteInfo, activeCategory, activeTab, date, searchTrigger, ptNum, onPopupToggle, firstCall, setFirstCall }) => {
  const firstCallData = query.survey.points.useSurveyPointsFirst({
    info: { type: 'points', siteId: siteInfo.id, pageSize: 10, pageNum: 1, sort: 'DESC', ptNum: '', startDate: '2001-01-01', endDate: new DateObject().format('YYYY-MM-DD'), facility: activeCategory },
  });
  //최초 전체측점 데이터 유무 체크
  useEffect(() => {
    firstCallData.refetch().then(({ data }: any) => {
      if (data?.page.totalElements > 0) {
        setFirstCall(false);
      } else {
        setFirstCall(true);
      }
    });
  }, []);

  //전체측점 totalElements 갯수 체크 후 화면 변경
  useEffect(() => {
    if (firstCallData.data?.page.totalElements > 0) {
      setFirstCall(false);
    } else {
      setFirstCall(true);
    }
  }, [firstCallData]);

  return (
    <>
      {tabList?.filter((i) => i.key === 'pipes')[0].active ? (
        <SurveyPipeTable
          onColumnToggle={onColumnToggle}
          siteInfo={siteInfo}
          activeCategory={activeCategory}
          activeTab={activeTab}
          date={date}
          searchTrigger={searchTrigger}
          ptNum={ptNum}
          onPopupToggle={onPopupToggle}
          firstCall={firstCall}
          setFirstCall={setFirstCall}
        />
      ) : (
        <SurveyPointTable
          onColumnToggle={onColumnToggle}
          siteInfo={siteInfo}
          activeCategory={activeCategory}
          activeTab={activeTab}
          date={date}
          searchTrigger={searchTrigger}
          ptNum={ptNum}
          onPopupToggle={onPopupToggle}
          firstCall={firstCall}
          setFirstCall={setFirstCall}
        />
      )}
    </>
  );
};

export default SurveyTable;
