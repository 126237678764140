import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { qsData } from 'service/api/common';

export default function useMapAutoFocus(autoFocusData) {
  const location = useLocation();
  const navigate = useNavigate();
  // 1) 작업일보 -> 2D 지도
  useEffect(() => {
    const { type, id, facilityKind } = location.state || {};
    //  console.log(type, id, facilityKind);
    if (id) {
      // 해당 정보 선택
      autoFocusData({ type, facilityKind, id });
      // state 비우기 처리
      setTimeout(() => {
        navigate(location.pathname, { replace: true, state: {} });
      }, 100);
      return;
    }
  }, [navigate, location, autoFocusData]);

  // 2) 유니티 -> 2D 지도
  useEffect(() => {
    if (qsData()?.id) {
      const { id, type, facilityKind, siteId } = qsData();
      // 해당 정보 선택
      autoFocusData({ type, facilityKind, id, siteId });
      return;
    }
  }, [autoFocusData]);
}
