import useMutateHook from 'hooks/RQuery/useMutateHook';
import { ImgPopupSt } from './_style';
import { useTranslation } from 'react-i18next';
import query from '../../../hooks/RQuery/utils';

function ImgDeletePopup({ imageId, imgNum, imgName, close, refetchApi }: { imageId: string | null; imgNum: number; imgName?: string; close?: () => void; refetchApi?: any }) {
  const { t, i18n } = useTranslation();

  const { mutate: deleteMutate } = query.files.useDeleteImage({ imageId });

  const onClickDelete = () => {
    deleteMutate(
      {
        imageId: imageId,
      },
      {
        onSuccess() {
          if (refetchApi) {
            refetchApi.refetch();
          }
          if (close) close();
        },
      },
    );
  };
  return (
    <>
      <ImgPopupSt.Dimmed></ImgPopupSt.Dimmed>
      <ImgPopupSt.Wrap $width={480}>
        <ImgPopupSt.Header>
          <p>{t('사진삭제')}</p>
          <button className="closeBtn" onClick={close}>
            {/* <span className="ico"></span> */}
          </button>
        </ImgPopupSt.Header>
        <ImgPopupSt.ContDelete>
          <p className="mainText">
            {imgName ? (
              i18n.language === 'ko' ? (
                <>
                  {imgName}
                  {t('사진삭제여부')}
                </>
              ) : (
                <>
                  {t('사진삭제여부')}
                  {imgName}?
                </>
              )
            ) : (
              t('사진삭제여부2')
            )}
          </p>
          <p className="subText">
            <span className="ico"></span>
            {t('사진복구불가')}
          </p>
        </ImgPopupSt.ContDelete>
        <ImgPopupSt.BtnWrapDelete>
          <button onClick={close}>{t('취소')}</button>
          <button onClick={onClickDelete}>{t('삭제')}</button>
        </ImgPopupSt.BtnWrapDelete>
      </ImgPopupSt.Wrap>
    </>
  );
}

export default ImgDeletePopup;
