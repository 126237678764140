import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import IcoBurger from '../../assets/images/ico-burger.svg';
import IcoBurgerDark from '../../assets/images/ico-burger-dark.svg';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import { useLocation, useNavigate } from 'react-router-dom';
import IcoStepFalse from '../../assets/images/ico-step-false.svg';
import IcoStepTrue from '../../assets/images/ico-step-true.svg';
import { SurveyHeaderSt } from './popup/_style';
import { useTranslation } from 'react-i18next';
const ManageContentStyle = {
  Contain: styled.div`
    width: 100%;
    height: 100%;
  `,
  Inner: styled.div`
    height: calc(100% - 20px);
    padding: 20px 40px 0;
  `,
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    border-radius: 2px 0 0 2px;

    box-sizing: border-box;
  `,
  Head: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    border-radius: 2px 0 0 2px;
    background: ${(p) => p.theme.form.head.bg};
    border: 1px solid ${(p) => p.theme.form.cont.border};
    border-bottom: 0;
  `,
  Title: styled.strong`
    color: ${(p) => p.theme.form.head.title};
    font-size: 16px;
    font-weight: 600;
  `,
  ListButton: styled(SurveyHeaderSt.Button)``,
  Cont: styled.div`
    height: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    background: ${(p) => p.theme.form.cont.bg};
    border: 1px solid ${(p) => p.theme.form.cont.border};
  `,
  Navi: styled.div`
    padding: 0 24px;
    height: 56px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(p) => p.theme.form.cont.navi.border};
  `,
  NaviGroup: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
  `,
  NaviButton: styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: ${(p) => p.theme.form.cont.navi.text};
    font-size: 16px;
    font-weight: 500;
    padding: 0 2px;
    &:not(:last-child) {
      margin-right: 28px;
    }
    &.step {
      &:before {
        content: '';
        width: 18px;
        height: 18px;
        margin-right: 7px;
        background: url(${IcoStepFalse}) no-repeat center;
      }
    }
    &.active {
      color: #0076ff;
      font-weight: 600;
      &.step {
        &:before {
          background-image: url(${IcoStepTrue});
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 100%;
        height: 3px;
        background: #0076ff;
      }
    }
  `,
  Form: styled.div`
    height: calc(100% - 60px);
    padding: 30px 24px;
  `,
};

export const Dash = styled.hr`
  position: relative;
  display: block;
  border: 0;
  border-top: 2px dashed ${(p) => p.theme.form.cont.dashed};
  margin: 30px 0;
  overflow: visible;
  &:before,
  &:after {
    border: 8px solid transparent;
    display: block;
    color: ${(p) => p.theme.form.cont.dashed};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &:before {
    content: '▶';

    left: -10px;
  }
  &:after {
    content: '◀︎';
    right: -10px;
  }
`;
export const Solid = styled.hr`
  display: block;
  border: 0;
  border-top: 2px solid ${(p) => p.theme.form.cont.dashed};
  margin: 30px 0;
`;

export interface ManageContentMenuProps {
  key: string;
  pathName: string;
  text: string;
}
export interface ManageContentStepProps {
  num: number;
  text: string;
  pass: boolean;
}
interface ManageContentProps {
  title: string;
  content: ReactNode;
  menu?: ManageContentMenuProps[];
  step?: ManageContentStepProps[];
  onTabClick?: (index: number) => void;
  listPath: string;
  naviRight?: ReactNode;
}

const ManageContent = ({ title, content, menu, step, listPath, onTabClick, naviRight }: ManageContentProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [themeMode] = useRecoilState(themeState);

  return (
    <ManageContentStyle.Contain>
      <ManageContentStyle.Inner>
        <ManageContentStyle.Wrap>
          <ManageContentStyle.Head>
            <ManageContentStyle.Title>{title}</ManageContentStyle.Title>
            <ManageContentStyle.ListButton Ico={themeMode === 'light' ? IcoBurger : IcoBurgerDark} type={'button'} style={{ height: 40 }} onClick={() => navigate(listPath)}>
              <div className="ico"></div>
              {t('목록가기')}
            </ManageContentStyle.ListButton>
          </ManageContentStyle.Head>
          <ManageContentStyle.Cont>
            <ManageContentStyle.Navi>
              <ManageContentStyle.NaviGroup>
                {/* 기본 메뉴형태*/}
                {menu?.map((i, index) => {
                  return (
                    <ManageContentStyle.NaviButton key={index} type={'button'} className={location.pathname.includes(i.key) ? 'active' : undefined} onClick={() => navigate(i.pathName)}>
                      {t(i.text)}
                    </ManageContentStyle.NaviButton>
                  );
                })}

                {/* 단계식 형태*/}
                {step?.map((i, index) => {
                  return (
                    <ManageContentStyle.NaviButton key={index} type={'button'} className={i.pass ? 'step active' : 'step'} disabled={!i.pass} onClick={() => onTabClick!(index)}>
                      {t(i.text)}
                    </ManageContentStyle.NaviButton>
                  );
                })}
              </ManageContentStyle.NaviGroup>
              {/* 탭이나 메뉴 우측에 버튼이 있을때*/}
              {naviRight}
            </ManageContentStyle.Navi>
            <ManageContentStyle.Form className={'cScroll'}>{content}</ManageContentStyle.Form>
          </ManageContentStyle.Cont>
        </ManageContentStyle.Wrap>
      </ManageContentStyle.Inner>
    </ManageContentStyle.Contain>
  );
};

export default ManageContent;
